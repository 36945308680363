<template>
    <div>
        <div class="b-sidebar sidebar_container" :class="{'moveSideBar': sideBar}">
            <div class="w-100">
                <router-link to="/">
                    <div v-if="!sideBar" class="sidebar_title">LogoName</div>
                    <div v-else class="sidebar_title">LN</div>
                </router-link>
                <div class="sidebar_tabs">
                    <router-link to="/dashboard">
                        <div class="sidebar_tab-item" :class="{'active_tab': activeRoute === '/dashboard' }">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="20" viewBox="0 0 20 22">
                                <defs>
                                </defs>
                                <g transform="translate(-2 -1)">
                                    <path class="a_svg" d="M3,9l9-7,9,7V20a2,2,0,0,1-2,2H5a2,2,0,0,1-2-2Z"/>
                                    <path class="a_svg" d="M9,22V12h6V22"/>
                                </g>
                            </svg>
                            <span v-if="!sideBar">Home</span>
                        </div>
                    </router-link>
                    <router-link to="/taskNotifications">
                        <div class="sidebar_tab-item" :class="{'active_tab': activeRoute === '/taskNotifications' }">
                            <svg xmlns="http://www.w3.org/2000/svg" width="19" height="24.224" viewBox="0 0 19 24.224">
                                <defs>
                                </defs>
                                <g transform="translate(459.173 -200.347)">
                                    <path class="a_fill"
                                          d="M-459.172,211.451q0-3.891,0-7.783a3.05,3.05,0,0,1,3.336-3.321q6.191,0,12.381,0a3.041,3.041,0,0,1,3.279,3.272q0,5.483,0,10.966a3.731,3.731,0,0,1-.009.454.828.828,0,0,1-.82.744.807.807,0,0,1-.782-.776c-.022-.37-.01-.741-.01-1.112q0-5.1,0-10.208c0-1.216-.5-1.719-1.713-1.719l-12.331,0c-1.21,0-1.712.5-1.712,1.722q0,7.757,0,15.515c0,1.2.51,1.716,1.72,1.719s2.426,0,3.638,0c.368,0,.712.125.794.49a1.2,1.2,0,0,1-.135.826.882.882,0,0,1-.652.289c-1.364.023-2.731.055-4.092-.007a2.966,2.966,0,0,1-2.89-3.089c-.007-2.662,0-5.323,0-7.985Z"/>
                                    <path class="a_fill"
                                          d="M-361.251,359.363c-.048.156-.047.31-.122.372-.231.191-.492.48-.741.479a.946.946,0,0,1-.719-.484.5.5,0,0,0-.588-.371c-.656.016-1.313.008-1.97,0-.711-.008-1.042-.359-.917-1.056a5.274,5.274,0,0,1,.4-.961,3.092,3.092,0,0,0,.276-.806c.064-.651-.007-1.322.114-1.958a3.346,3.346,0,0,1,2.2-2.558,1.287,1.287,0,0,0,.518-.4.8.8,0,0,1,1.356,0,1.141,1.141,0,0,0,.477.376,3.522,3.522,0,0,1,2.341,3.281c.006.252.006.505,0,.758a1.794,1.794,0,0,0,.427,1.531,1.387,1.387,0,0,1,.281.789.794.794,0,0,1-.878,1.006C-359.594,359.373-360.4,359.363-361.251,359.363Zm1-1.64c.01-.117.02-.182.02-.248,0-.74.011-1.481-.006-2.221a1.835,1.835,0,0,0-1.65-1.8,1.756,1.756,0,0,0-2.021,1.419,22.346,22.346,0,0,0-.026,2.85Z"
                                          transform="translate(-83.456 -135.643)"/>
                                    <path class="a_fill"
                                          d="M-377.9,240.934c-1.078,0-2.156,0-3.233,0-.633,0-1.009-.308-1.009-.809s.368-.809,1-.81q3.259,0,6.517,0c.638,0,1,.3,1,.806s-.36.808-1,.81C-375.711,240.936-376.805,240.934-377.9,240.934Z"
                                          transform="translate(-69.246 -35.028)"/>
                                    <path class="a_fill"
                                          d="M-377.83,279.088c1.094,0,2.188,0,3.282,0,.6,0,.952.3.952.8s-.35.814-.949.816q-3.307.011-6.614,0c-.592,0-.958-.331-.957-.813s.35-.8.954-.8c1.111-.008,2.221,0,3.332,0Z"
                                          transform="translate(-69.269 -70.779)"/>
                                    <path class="a_fill"
                                          d="M-377.876,320.281c-1.094,0-2.188,0-3.282,0-.6,0-.957-.305-.959-.8s.363-.817.952-.819q3.307-.012,6.614,0c.6,0,.951.317.954.81s-.342.8-.947.8C-375.655,320.285-376.765,320.281-377.876,320.281Z"
                                          transform="translate(-69.268 -106.354)"/>
                                    <path class="a_fill"
                                          d="M-421.3,317a1.072,1.072,0,0,1-1.075,1.1,1.066,1.066,0,0,1-1.107-1.061,1.087,1.087,0,0,1,1.1-1.128A1.079,1.079,0,0,1-421.3,317Z"
                                          transform="translate(-32.088 -103.883)"/>
                                    <path class="a_fill"
                                          d="M-421.327,277.327a1.092,1.092,0,0,1-1.091,1.089,1.1,1.1,0,0,1-1.094-1.087,1.085,1.085,0,0,1,1.119-1.108A1.076,1.076,0,0,1-421.327,277.327Z"
                                          transform="translate(-32.058 -68.206)"/>
                                    <path class="a_fill"
                                          d="M-421.332,237.514a1.1,1.1,0,0,1-1.082,1.1,1.113,1.113,0,0,1-1.1-1.136,1.1,1.1,0,0,1,1.109-1.072A1.1,1.1,0,0,1-421.332,237.514Z"
                                          transform="translate(-32.052 -32.416)"/>
                                </g>
                            </svg>
                            <span v-if="!sideBar">Task Notification</span>
                        </div>
                    </router-link>
                    <router-link to="/summary">
                        <div class="sidebar_tab-item" :class="{'active_tab': activeRoute === '/summary' }">
                            <svg xmlns="http://www.w3.org/2000/svg" width="22.001" height="22.001"
                                 viewBox="0 0 22.001 22.001">
                                <defs>
                                </defs>
                                <path class="a_fill"
                                      d="M79.645,81.793H80.5v.859a.645.645,0,1,0,1.289,0v-.859h.859a.645.645,0,1,0,0-1.289h-.859v-.859a.645.645,0,0,0-1.289,0V80.5h-.859a.645.645,0,0,0,0,1.289Z"
                                      transform="translate(-75.605 -75.605)"/>
                                <path class="a_fill"
                                      d="M312.645,115.289h2.578a.645.645,0,0,0,0-1.289h-2.578a.645.645,0,1,0,0,1.289Z"
                                      transform="translate(-298.593 -109.101)"/>
                                <path class="a_fill"
                                      d="M310.652,367h-3.008a.645.645,0,1,0,0,1.289h3.008a.645.645,0,1,0,0-1.289Z"
                                      transform="translate(-293.808 -351.23)"/>
                                <path class="a_fill"
                                      d="M310.652,317h-3.008a.645.645,0,1,0,0,1.289h3.008a.645.645,0,1,0,0-1.289Z"
                                      transform="translate(-293.808 -303.378)"/>
                                <path class="a_fill"
                                      d="M92.248,317.188a.645.645,0,0,0-.912,0l-.618.618-.619-.618a.645.645,0,0,0-.912.911l.619.619-.619.619a.645.645,0,0,0,.912.911l.619-.618.619.619a.645.645,0,0,0,.911-.912l-.618-.618.619-.619A.644.644,0,0,0,92.248,317.188Z"
                                      transform="translate(-85.175 -303.378)"/>
                                <path class="a_fill"
                                      d="M20.884,11.652V1.934A1.936,1.936,0,0,0,18.95,0H1.934A1.936,1.936,0,0,0,0,1.934V18.95a1.936,1.936,0,0,0,1.934,1.934h9.718a6.657,6.657,0,0,0,9.231-9.231ZM19.594,1.934V10.22a6.647,6.647,0,0,0-8.508,0V1.289H18.95A.645.645,0,0,1,19.594,1.934ZM1.934,1.289H9.8V9.8H1.289V1.934A.645.645,0,0,1,1.934,1.289ZM1.289,18.95V11.086H9.8v.566a6.647,6.647,0,0,0,.422,7.942H1.934A.645.645,0,0,1,1.289,18.95ZM15.34,20.712a5.371,5.371,0,1,1,5.371-5.371A5.377,5.377,0,0,1,15.34,20.712Z"/>
                            </svg>
                            <span v-if="!sideBar">Task Summary</span>
                        </div>
                    </router-link>
                    <router-link to="/discussion">
                        <div class="sidebar_tab-item" :class="{'active_tab': activeRoute === '/discussion' }">
                            <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23"
                                 viewBox="0 0 25.888 25.888">
                                <defs>
                                </defs>
                                <g transform="translate(-0.001)">
                                    <path class="a_fill"
                                          d="M25.072,1.347H13.761V.817a.817.817,0,1,0-1.633,0v.531H.818A.817.817,0,0,0,0,2.164V4.859a.817.817,0,0,0,.817.817h.531V19.681a.817.817,0,0,0,.817.817h9.146L8.249,24.581a.817.817,0,1,0,1.307.98l2.572-3.43v2.94a.817.817,0,0,0,1.633,0v-2.94l2.572,3.43a.817.817,0,0,0,1.307-.98L14.578,20.5h9.146a.817.817,0,0,0,.817-.817V5.676h.531a.817.817,0,0,0,.817-.817V2.164A.817.817,0,0,0,25.072,1.347ZM1.634,4.042V2.981H24.255V4.042ZM22.908,18.865H2.982V5.676H22.908V18.865Z"/>
                                    <path class="a_fill"
                                          d="M62.653,125.306H74.78a.817.817,0,1,0,0-1.633H62.653a.817.817,0,1,0,0,1.633Z"
                                          transform="translate(-56.445 -112.893)"/>
                                    <path class="a_fill"
                                          d="M62.653,94.388h2.695a.817.817,0,0,0,0-1.633H62.653a.817.817,0,1,0,0,1.633Z"
                                          transform="translate(-56.445 -84.67)"/>
                                    <path class="a_fill"
                                          d="M62.653,156.223h8.085a.817.817,0,0,0,0-1.633H62.653a.817.817,0,0,0,0,1.633Z"
                                          transform="translate(-56.445 -141.115)"/>
                                </g>
                            </svg>
                            <span v-if="!sideBar">Discussion Board</span>
                        </div>
                    </router-link>
                    <router-link to="/milestones">
                        <div class="sidebar_tab-item" :class="{'active_tab': activeRoute === '/milestones' }">
                            <svg xmlns="http://www.w3.org/2000/svg" width="23.887" height="27.315"
                                 viewBox="0 0 23.887 27.315">
                                <defs>
                                </defs>
                                <g transform="translate(-0.5)">
                                    <path class="a_fill"
                                          d="M24.3,22.728l-3.386-6.774a10.229,10.229,0,1,0-16.944,0L.584,22.728A.8.8,0,0,0,1.3,23.886H5.186l2.331,3.108a.8.8,0,0,0,1.356-.122l3.211-6.422c.119,0,.239.007.359.007s.24,0,.359-.007l3.211,6.422a.8.8,0,0,0,1.356.122L19.7,23.886h3.886a.8.8,0,0,0,.716-1.158ZM8.021,25,6.226,22.606a.8.8,0,0,0-.64-.32H2.595L5.078,17.32a10.225,10.225,0,0,0,5.316,2.932ZM3.814,10.229a8.628,8.628,0,1,1,8.628,8.628,8.638,8.638,0,0,1-8.628-8.628ZM19.3,22.286a.8.8,0,0,0-.64.32L16.865,25l-2.374-4.747a10.224,10.224,0,0,0,5.316-2.932l2.483,4.966H19.3Zm0,0"
                                          transform="translate(0)"/>
                                    <path class="a_fill"
                                          d="M113,72.393l2.235-2.667a.8.8,0,0,0-.419-1.29l-3.376-.844-1.847-2.95a.8.8,0,0,0-1.357,0l-1.846,2.95-3.376.844a.8.8,0,0,0-.419,1.29l2.235,2.667-.24,3.471a.8.8,0,0,0,1.1.8l3.228-1.3,3.228,1.3a.8.8,0,0,0,1.1-.8Zm-1.434-.781a.8.8,0,0,0-.185.569l.174,2.518-2.341-.944a.8.8,0,0,0-.6,0l-2.341.944.174-2.518a.8.8,0,0,0-.185-.569l-1.621-1.935,2.448-.612a.8.8,0,0,0,.484-.352l1.339-2.14,1.339,2.14a.8.8,0,0,0,.484.352l2.448.612Zm0,0"
                                          transform="translate(-96.476 -60.837)"/>
                                </g>
                            </svg>
                            <span v-if="!sideBar">Milestones</span>
                        </div>
                    </router-link>
                    <router-link to="/notes">
                        <div class="sidebar_tab-item" :class="{'active_tab': activeRoute === '/notes' }">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                <defs>
                                </defs>
                                <g transform="translate(0 -0.005)">
                                    <g transform="translate(0 0.005)">
                                        <path class="a_fill"
                                              d="M23.246,8.893a2.107,2.107,0,0,0-2.964.258l-2,2.368V4.652a2.118,2.118,0,0,0-.657-1.529L14.954.585A2.1,2.1,0,0,0,13.5,0H2.109A2.112,2.112,0,0,0,0,2.114V21.9A2.112,2.112,0,0,0,2.109,24H16.172A2.112,2.112,0,0,0,18.281,21.9V18.054l5.225-6.189A2.108,2.108,0,0,0,23.246,8.893ZM14.062,1.678c2.813,2.672,2.613,2.477,2.666,2.546H14.062ZM16.875,21.9a.7.7,0,0,1-.7.7H2.109a.7.7,0,0,1-.7-.7V2.114a.7.7,0,0,1,.7-.7H12.656V4.927a.7.7,0,0,0,.7.7h3.516v7.553L13.886,16.72a2.12,2.12,0,0,0-.436.866L12.8,20.322a.7.7,0,0,0,.964.809l2.581-1.119a2.12,2.12,0,0,0,.534-.335ZM15.2,17.341l1.077.9-.24.284a.706.706,0,0,1-.259.193l-1.29.559.327-1.368a.7.7,0,0,1,.144-.287Zm1.984-.171-1.077-.9L20,11.668l1.074.9Zm5.244-6.211-.452.536-1.074-.9.454-.537a.7.7,0,0,1,1.072.9Z"
                                              transform="translate(0 -0.005)"/>
                                    </g>
                                    <g transform="translate(2.812 4.224)">
                                        <path class="a_fill"
                                              d="M67.732,90H60.7a.7.7,0,0,0,0,1.406h7.031a.7.7,0,0,0,0-1.406Z"
                                              transform="translate(-59.998 -90.003)"/>
                                    </g>
                                    <g transform="translate(2.812 8.489)">
                                        <path class="a_fill"
                                              d="M71.951,181H60.7a.7.7,0,1,0,0,1.406h11.25a.7.7,0,1,0,0-1.406Z"
                                              transform="translate(-59.998 -181.001)"/>
                                    </g>
                                    <g transform="translate(2.812 12.708)">
                                        <path class="a_fill"
                                              d="M71.951,271H60.7a.7.7,0,0,0,0,1.406h11.25a.7.7,0,1,0,0-1.406Z"
                                              transform="translate(-59.998 -271)"/>
                                    </g>
                                    <g transform="translate(2.812 16.927)">
                                        <path class="a_fill"
                                              d="M67.732,361H60.7a.7.7,0,1,0,0,1.406h7.031a.7.7,0,1,0,0-1.406Z"
                                              transform="translate(-59.998 -360.998)"/>
                                    </g>
                                </g>
                            </svg>
                            <span v-if="!sideBar">Meeting Notes</span>
                        </div>
                    </router-link>
                    <router-link to="/depository">
                        <div class="sidebar_tab-item" :class="{'active_tab': activeRoute === '/depository' }">
                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25"
                                 viewBox="0 0 28.948 28.95">
                                <defs>
                                </defs>
                                <g transform="translate(0.981 191)">
                                    <g transform="translate(-0.981 -191)">
                                        <g transform="translate(0)">
                                            <path class="a_fill"
                                                  d="M25.348,288.038v10.855H3.637V288.038H.019v1.809H1.828V300.7H27.157V289.847h1.809v-1.809Z"
                                                  transform="translate(-0.019 -271.753)"/>
                                            <path class="a_fill"
                                                  d="M102.788,17.77a9.048,9.048,0,0,0,1.488.278v4.195l-1.622-1.622L101.374,21.9l3.806,3.806,3.806-3.806-1.279-1.279-1.622,1.622V18.048a9.053,9.053,0,0,0,8.1-9.919q-.04-.4-.116-.792l-1.777.341a7.228,7.228,0,1,1-5.179-5.609l.482-1.742a9.046,9.046,0,0,0-4.8,17.443Z"
                                                  transform="translate(-90.706 0)"/>
                                            <path class="a_fill"
                                                  d="M318.973,20.12a8.994,8.994,0,0,0-1.651-1.138l-.855,1.593a7.286,7.286,0,0,1,1.321.911Z"
                                                  transform="translate(-298.576 -17.909)"/>
                                            <path class="a_fill"
                                                  d="M359.91,67.651l1.657-.724a9.076,9.076,0,0,0-1-1.737l-1.456,1.073A7.213,7.213,0,0,1,359.91,67.651Z"
                                                  transform="translate(-338.805 -61.504)"/>
                                            <path class="a_fill"
                                                  d="M210.733,51.656h2.714V49.847h-1.809V48.038h-1.809v1.976a2.708,2.708,0,0,0,.9,5.261.9.9,0,1,1,0,1.809h-2.714v1.809h1.809V60.7h1.809V58.727a2.708,2.708,0,0,0-.9-5.261.9.9,0,0,1,0-1.809Z"
                                                  transform="translate(-196.259 -45.322)"/>
                                            <path class="a_fill" d="M99.637,385.847H97.828v-1.809H96.019v3.618h3.618Z"
                                                  transform="translate(-90.591 -362.325)"/>
                                            <path class="a_fill" d="M352.019,385.847v1.809h3.618v-3.618h-1.809v1.809Z"
                                                  transform="translate(-332.118 -362.325)"/>
                                        </g>
                                    </g>
                                </g>
                            </svg>
                            <span v-if="!sideBar">Depository</span>
                        </div>
                    </router-link>
                    <router-link to="/lesson">
                        <div class="sidebar_tab-item" :class="{'active_tab': activeRoute === '/lesson' }">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24.001" height="28.577"
                                 viewBox="0 0 24.001 28.577">
                                <defs>
                                </defs>
                                <g transform="translate(-40.994)">
                                    <path class="a_fill"
                                          d="M64.995,18.419a2.8,2.8,0,0,0-2.233-2.735V12.67a.837.837,0,0,0-1.131-.784l-1.6.6a9.761,9.761,0,0,0-3.723-2.417,5.582,5.582,0,1,0-6.632,0,9.762,9.762,0,0,0-3.723,2.417l-1.6-.6a.837.837,0,0,0-1.131.784v3.014a2.791,2.791,0,0,0,0,5.469v3.238a.837.837,0,0,0,.543.784L52.7,28.524a.847.847,0,0,0,.588,0l8.931-3.349a.837.837,0,0,0,.543-.784V21.154A2.8,2.8,0,0,0,64.995,18.419ZM49.087,5.582a3.907,3.907,0,1,1,3.907,3.907A3.912,3.912,0,0,1,49.087,5.582Zm3.907,5.582a8.081,8.081,0,0,1,5.3,1.975l-5.3,1.986-5.3-1.986A8.081,8.081,0,0,1,52.994,11.163ZM42.668,18.419A1.118,1.118,0,0,1,43.785,17.3h.558v2.233h-.558A1.118,1.118,0,0,1,42.668,18.419ZM44.9,21.182a1.4,1.4,0,0,0,1.116-1.367V17.024A1.4,1.4,0,0,0,44.9,15.657V13.878L52.157,16.6v9.933L44.9,23.811Zm8.931,5.35V16.6l7.256-2.721v1.778a1.4,1.4,0,0,0-1.116,1.367v2.791a1.4,1.4,0,0,0,1.116,1.367v2.629Zm8.372-7h-.558V17.3H62.2a1.116,1.116,0,0,1,0,2.233Z"
                                          transform="translate(0)"/>
                                </g>
                            </svg>
                            <span v-if="!sideBar">Lesson Learnt</span>
                        </div>
                    </router-link>
                    <router-link to="/message">
                        <div class="sidebar_tab-item" :class="{'active_tab': activeRoute === '/message' }">
                            <svg xmlns="http://www.w3.org/2000/svg" width="25.267" height="22.146"
                                 viewBox="0 0 25.267 22.146">
                                <defs>
                                </defs>
                                <g transform="translate(0 -31.626)">
                                    <path class="a_fill"
                                          d="M0,47.289a.741.741,0,0,0,1.17.6l4.07-2.9A1.991,1.991,0,0,1,6.4,44.617h8.951a2.861,2.861,0,0,0,2.857-2.857V32.366a.74.74,0,0,0-.74-.74H2.857A2.861,2.861,0,0,0,0,34.483ZM1.48,34.483a1.379,1.379,0,0,1,1.377-1.377H16.731v8.653a1.379,1.379,0,0,1-1.377,1.377H6.4a3.464,3.464,0,0,0-2.023.647l-2.9,2.069Z"/>
                                    <path class="a_fill"
                                          d="M161.182,150.824a2.861,2.861,0,0,0-2.857-2.858h-1.977a.74.74,0,1,0,0,1.48h1.977a1.379,1.379,0,0,1,1.377,1.377v11.368l-2.9-2.068a3.463,3.463,0,0,0-2.022-.647h-8.952a1.379,1.379,0,0,1-1.377-1.377v-.663a.74.74,0,1,0-1.48,0v.663a2.861,2.861,0,0,0,2.857,2.858h8.952a1.991,1.991,0,0,1,1.163.372l4.07,2.9a.741.741,0,0,0,1.17-.6V150.824Z"
                                          transform="translate(-135.914 -110.599)"/>
                                    <path class="a_fill"
                                          d="M93.6,114.82h8.553a.74.74,0,1,0,0-1.48H93.6a.74.74,0,1,0,0,1.48Z"
                                          transform="translate(-88.279 -77.68)"/>
                                    <path class="a_fill"
                                          d="M93.6,184.82h8.553a.74.74,0,1,0,0-1.48H93.6a.74.74,0,0,0,0,1.48Z"
                                          transform="translate(-88.279 -144.226)"/>
                                </g>
                            </svg>
                            <span v-if="!sideBar">Direct Message</span>
                        </div>
                    </router-link>
                    <router-link to="/members">

                        <div class="sidebar_tab-item" :class="{'active_tab': activeRoute === '/members' }">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="20.087" viewBox="0 0 24 20.087">
                                <g id="users_3_" data-name="users (3)" transform="translate(0 -1.913)">
                                    <path class="a_svg" id="Path_7049" data-name="Path 7049" d="M17,21V19a4,4,0,0,0-4-4H5a4,4,0,0,0-4,4v2" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                    <circle class="a_svg" id="Ellipse_414" data-name="Ellipse 414" cx="4" cy="4" r="4" transform="translate(5 3)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                    <path class="a_svg" id="Path_7050" data-name="Path 7050" d="M23,21V19a4,4,0,0,0-3-3.87" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                    <path class="a_svg" id="Path_7051" data-name="Path 7051" d="M16,3.13a4,4,0,0,1,0,7.75" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                </g>
                            </svg>
                            <span v-if="!sideBar">Members</span>
                        </div>

                    </router-link>
                </div>
                <div class="w-100 d-flex justify-content-center">
                    <b-button class="add_member_btn" :class="{'small_btn': sideBar}" id="member_btn" @click="memberModal()">
                        <span v-if="!sideBar">+ Add Member</span>
                        <span v-if="sideBar">
                            <svg xmlns="http://www.w3.org/2000/svg" width="27.918" height="27.918"
                                 viewBox="0 0 27.918 27.918">
                            <defs>
                            </defs>
                            <path class="a_add_member"
                                  d="M27.918,22.465a1.09,1.09,0,0,1-1.091,1.091H23.556v3.272a1.091,1.091,0,1,1-2.181,0V23.556H18.1a1.091,1.091,0,0,1,0-2.181h3.272V18.1a1.091,1.091,0,0,1,2.181,0v3.272h3.272A1.09,1.09,0,0,1,27.918,22.465Zm-8.724,4.362A1.09,1.09,0,0,1,18.1,27.918H3.272A3.275,3.275,0,0,1,0,24.646V22.629a6.53,6.53,0,0,1,2.215-4.907,15.164,15.164,0,0,1,6.829-3.257,8.065,8.065,0,1,1,4.916,1.675c-5.993,0-9.2,2.252-10.3,3.22a4.349,4.349,0,0,0-1.473,3.268v2.018a1.092,1.092,0,0,0,1.091,1.091H18.1a1.09,1.09,0,0,1,1.091,1.091ZM13.959,13.959A5.889,5.889,0,1,0,8.07,8.07,5.9,5.9,0,0,0,13.959,13.959Zm0,0"/>
                        </svg>
                        </span>
                    </b-button>
                </div>
            </div>
            <div class="sidebar_tab-item" @click="logOut()">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 16.006 13.066">
                    <defs>
                    </defs>
                    <g transform="translate(0 -41.115)">
                        <g transform="translate(0 41.115)">
                            <path class="a_fill"
                                  d="M6.533,53.2a.266.266,0,0,1-.021-.138c.007-.037-.017-.068-.071-.092s-.082-.043-.082-.056-.039-.024-.117-.031l-.117-.01H2.94a1.572,1.572,0,0,1-1.154-.48,1.573,1.573,0,0,1-.48-1.153V44.055a1.572,1.572,0,0,1,.48-1.153,1.573,1.573,0,0,1,1.154-.48H6.206a.308.308,0,0,0,.209-.066.339.339,0,0,0,.1-.2,1.718,1.718,0,0,0,.031-.24,2.54,2.54,0,0,0-.005-.271q-.01-.163-.01-.2a.331.331,0,0,0-.327-.327H2.94a2.832,2.832,0,0,0-2.077.863A2.832,2.832,0,0,0,0,44.055v7.186a2.831,2.831,0,0,0,.863,2.077,2.831,2.831,0,0,0,2.077.863H6.207a.31.31,0,0,0,.209-.066.339.339,0,0,0,.1-.2,1.721,1.721,0,0,0,.031-.24,2.537,2.537,0,0,0-.005-.27Q6.533,53.242,6.533,53.2Z"
                                  transform="translate(0 -41.115)"/>
                            <path class="a_fill"
                                  d="M121.526,55.993l-5.553-5.553a.653.653,0,0,0-1.113.459v2.94h-4.573a.662.662,0,0,0-.653.653v3.92a.663.663,0,0,0,.653.653h4.573v2.94a.65.65,0,0,0,1.113.459l5.553-5.553a.641.641,0,0,0,0-.919Z"
                                  transform="translate(-105.713 -49.92)"/>
                        </g>
                    </g>
                </svg>
                <span v-if="!sideBar">Log Out</span>
            </div>

            <!--workspace modal start-->
            <b-modal ref="member-modal" centered hide-header hide-footer hide-header-close>
                <div class="member_modal_content">
                    <b-icon icon="x" class="x_btn" aria-hidden="true" @click="memberModal()"></b-icon>
                    <div class="d-flex">
                        <div class="member_title">
                            Add Members
                        </div>
                    </div>
                    <div class="input_content">
                        <div class="input_item">
                            <label>Workspace</label>
<!--                            <b-form-input-->
<!--                                    v-model="sendData.workspace"-->
<!--                                    type="email"-->
<!--                                    trim-->
<!--                            ></b-form-input>-->
                            <div class="select_arrow">
                                <b-form-select @change="workspaceChange">
                                    <option
                                            v-for="(option, id) in menuWorkspaces"
                                            :key="id"
                                            :value="option.id"
                                            :title="option.name || null"
                                    >
                                        {{option.name}}
                                    </option>

                                </b-form-select>
                                <div class="arrow_down"></div>
                            </div>

                        </div>
                        <div class="input_item">
                            <label>Channel</label>
<!--                            <b-form-input-->
<!--                                    v-model="sendData.channel"-->
<!--                                    type="email"-->
<!--                                    trim-->
<!--                            ></b-form-input>-->
                            <div class="select_arrow">

                                <b-form-select @change="channelChange">
                                    <option
                                            v-for="(option, id) in channels"
                                            :key="id"
                                            :value="option.id"
                                            :title="option.name || null"
                                    >
                                        {{option.name}}
                                    </option>
                                </b-form-select>
                                <div class="arrow_down"></div>
                            </div>
                        </div>
                        <div class="input_item">
                            <label>Email</label>
                            <b-form-input
                                    v-model="sendData.memberEmail"
                                    type="email"
                                    trim
                            ></b-form-input>
                            <span v-if="emailError" class="text_danger">{{emailError}}</span>
                        </div>
                    </div>
                    <div class="done_btn-container">
                        <b-button class="done_btn" @click="addMember()">Done</b-button>
                    </div>
                </div>
            </b-modal>
            <!--modal end-->
        </div>
    </div>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";

    export default {
        name: "LeftSidebar",
        data() {
            return {
                sendData: {
                    workspace: null,
                    channel: null,
                    memberEmail: null
                },
                emailError: null,
                channels: []
            }
        },
        props: ['sideBar'],
        computed: {
            ...mapGetters(['menuWorkspaces']),
            activeRoute() {
                return this.$route.fullPath;
            }
        },
        methods: {
            ...mapActions(['memberCreate', 'getMembers']),
            memberModal() {
                this.sendData.workspace = null
                this.sendData.channel = null
                this.sendData.memberEmail = null
                this.$refs['member-modal'].toggle('#member_btn')
            },
            logOut() {
                this.$store.commit('clearUserData')
                this.$router.push('/')
            },
            workspaceChange(args) {
                var workSpace = this.menuWorkspaces.find((item) => {
                    return item.id == args
                })
                this.channels = workSpace.channels
                this.sendData.workspace = args
            },
            channelChange(args) {
                this.sendData.channel = args
            },
            addMember() {
                this.emailError = ''
                this.memberCreate(this.sendData)
                .then((res) => {
                    if(res.status === 200) {
                        this.memberModal()
                        this.getMembers()
                    } else if(res.response.data.error){
                        this.emailError = res.response.data.error
                    } else {
                        this.emailError = res.response.data.errors.email[0]
                    }
                })
            }
        }
    }
</script>

<style scoped lang="scss">
    .member {
        &_modal_content {
            position: relative;
            padding: 0 10px;
            & .x_btn {
                position: absolute;
                top: 0;
                right: 0;
                width: 25px;
                height: 25px;
                cursor: pointer;
                color: $light_purple;
            }
            & .input_content {
                & .input_item {
                    padding: 20px 0;
                    & .select_arrow {
                        position: relative;
                        & .arrow_down {
                            position: absolute;
                            top: 14px;
                            right: 10px;
                            width: 14px;
                            height: 10px;
                            pointer-events: none;
                            background: url("~@/assets/images/chevron_down.svg") right  no-repeat;
                        }
                    }
                    & label {
                        color: $primary;
                        font-weight: 600;
                        font-size: 14px;

                    }
                    & input {
                        border: none;
                        background: $greyBlue 0 0 no-repeat padding-box;
                        border-radius: 3px;
                        &:focus {
                            box-shadow: none;
                            outline: none;
                        }
                    }
                    & select {
                        /*<!--border: 1px solid $light_purple;-->*/
                        background: $greyBlue 0 0 no-repeat padding-box;
                        border-radius: 4px;


                    }
                }

            }
            & .done_btn-container {
                display: flex;
                justify-content: flex-end;
                padding: 10px 0;
                & .done_btn {
                    background: transparent linear-gradient(180deg, #84EEEB 0%, #3AE4DF 100%) 0% 0% no-repeat padding-box;
                    border-radius: 10px;
                    width: 110px;
                    height: 44px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: $primary;
                    font-weight: 600;
                    border: none;
                }
            }
        }
        &_title {
            width: 100%;
            font-size: 25px;
            font-weight: 500;
            color: $grey;
            padding-bottom: 10px;
            border-bottom: 1px solid #CACDF6;
        }
    }
    .b-sidebar {
        width: 290px;
        position: fixed;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: space-between;
        z-index: 15;
        padding: 35px 0 40px;
        background: transparent linear-gradient(180deg, #7882F3 0%, #5454EE 100%) 0% 0% no-repeat padding-box;
        @media screen and (max-width: 1440px) {
            /*width: 200px;*/
        }

        &.sidebar_container {
            max-width: 290px;
            position: relative;
        }

        &.moveSideBar {
            max-width: 116px;
        }

        & a {
            text-decoration: none;
        }

        & .add_member_btn {
            width: 195px;
            height: 44px;
            background: transparent linear-gradient(180deg, #84EEEB 0%, #3AE4DF 100%) 0% 0% no-repeat padding-box;
            border-radius: 10px;
            color: $primary;
            font-weight: 600;
            padding: 12px 30px;
            border: none;
            display: flex;
            align-items: center;
            justify-content: center;

            &:active {
                color: $primary !important;
            }

            & .a_add_member {
                fill: $primary;
            }

            &.small_btn {
                width: 55px;
                height: 44px;
            }

            @media screen and (max-width: 1440px) {
                font-size: 15px;
                padding: 12px 18px;
            }
        }
    }

    .sidebar {
        &_title {
            font-family: 'Poppins', sans-serif;
            font-weight: 600;
            font-size: 29px;
            text-align: center;
            color: $white;
            @media screen and (max-width: 1440px) {
                font-size: 26px;
            }
        }

        &_tabs {
            padding: 20px 0;

            & a {
                text-decoration: none;
            }

            & .active_tab {
                background: #7882F3 0 0 no-repeat padding-box;
                border-right: 6px solid $cyan;

                & span {
                    color: $cyan;
                }

                & .a_svg {
                    stroke: $cyan;
                }

                & .a_fill {
                    fill: $cyan;
                }
            }
        }

        &_tab-item {
            width: 100%;
            height: 65px;
            color: $white;
            font-family: 'Poppins', sans-serif;
            font-size: 16px;
            font-weight: 500;
            display: flex;
            padding: 0 30px 0 50px;
            cursor: pointer;
            align-items: center;
            @media screen and (max-width: 1440px) {
                height: 60px;
            }

            & .a_svg {
                fill: none;
                stroke: $white;
                stroke-linecap: round;
                stroke-linejoin: round;
                stroke-width: 2px;
            }

            & .a_fill {
                fill: $white
            }

            & span {
                margin-left: 30px;
                @media screen and (max-width: 1440px) {
                    margin-left: 20px;
                }
            }
        }

    }
</style>