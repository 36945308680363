<template>
    <div>
        <div class="page_content">
            <div class="page_head">
                <div class="page_title">
                    Discussion Board
                </div>
                <b-button class="create_btn" @click="createNewDiscussion()" id="discussion_btn">Create New Discussion
                </b-button>
            </div>
            <div class="discussion_cards" v-if="discussionSummary">
                <div class="card_item started">
                    <div class="item_status">
                        Not Started
                    </div>
                    <div class="item_point">
                        {{discussionSummary.not_started_count}}
                    </div>

                </div>
                <div class="card_item in_progress">
                    <div class="item_status">
                        In Progress
                    </div>
                    <div class="item_point">
                        {{discussionSummary.in_progress_count}}
                    </div>

                </div>
                <div class="card_item review">
                    <div class="item_status">
                        Postponed
                    </div>
                    <div class="item_point">
                        {{discussionSummary.under_review_discussion_count}}
                    </div>

                </div>
                <div class="card_item completed">
                    <div class="item_status">
                        Completed
                    </div>
                    <div class="item_point">
                        {{discussionSummary.completed_count}}
                    </div>

                </div>
            </div>

            <div class="discussion_table">
                <div class="table_title">
                    7 Days
                </div>
                <div class="table_container">
                    <b-table hover :items="discussion_day7_data" :fields="discussion_fields" responsive="sm">
                        <template v-slot:cell(index)="data">
                            <div class="item">
                                {{data.index+1}}
                            </div>
                        </template>

                        <template v-slot:cell(work_space)="data">
                            <div class="item_name" v-html="highlight(data.item.work_space_name.name)">
                                {{data.item.work_space_name.name}}
                            </div>
                        </template>

                        <template v-slot:cell(channel)="data">
                            <div class="" v-html="highlight(data.item.channel_name.name)">
                                {{data.item.channel_name.name}}
                            </div>
                        </template>

                        <template v-slot:cell(subject)="data">
                            <div class="" v-html="highlight(data.item.title)">
                                {{data.item.title}}
                            </div>
                        </template>

                        <template v-slot:cell(agenda)="data">
                            <div class="" v-html="highlight(data.item.discussion_agenda)">
                                {{data.item.discussion_agenda}}
                            </div>
                        </template>

                        <template v-slot:cell(originator)="data">
                            <div class="" v-html="highlight(data.item.originator.name)">
                                {{data.item.originator.name}}
                            </div>
                        </template>

                        <template v-slot:cell(date)="data">
                            <div class="">
                                {{getDate(data.item.created_at)}}
                            </div>
                        </template>

                        <template v-slot:cell(target)="data">
                            <div class="">
                                {{getDateNumber(data.item.date_end)}}
                            </div>
                        </template>

                        <template v-slot:cell(milestone_impacted)="data">
                            <div class="item_column milestones_item"
                                 @click="milestoneImpacted(data.item.milestones)">
                                {{data.item.milestones.length}}
                                <!--                                <div v-for="milestone in data.item.milestones">-->
                                <!--                                    {{milestone.title}}-->
                                <!--                                </div>-->
                            </div>
                        </template>

                        <template v-slot:cell(attach)="data">
                            <div class="milestones_item">
                                {{data.item.attachments.length}}
                                <!--                                <div class="plus_btn" @click="addAttachment()"></div>-->
                                <!--                                <div class="x_button" @click="removeAttachment"></div>-->
                            </div>
                        </template>

                        <template v-slot:cell(status)="data">
                            <div class="status_dots">
                                <div class="yellow_dot" :class="{'active_dot': data.item.status === 0}"
                                     @click="changeSummary(data.item.id, 0)"></div>
                                <div class="orange_dot" :class="{'active_dot': data.item.status === 1}"
                                     @click="changeSummary(data.item.id, 1)"></div>
                                <div class="purple_dot" :class="{'active_dot': data.item.status === 2}"
                                     @click="changeSummary(data.item.id, 2)"></div>
                                <div class="green_dot" :class="{'active_dot': data.item.status === 3}"
                                     @click="changeSummary(data.item.id, 3)"></div>
                            </div>
                        </template>

                        <template v-slot:cell(edit)="data">
                            <div class="edit_btn" @click="edit_discussion(data.item)">
                            </div>
                        </template>

                        <template v-slot:cell(delete)="data">
                            <div class="trash_button" @click="deleteDiscussionModal(data.item)">
                            </div>
                        </template>

                    </b-table>
                </div>
            </div>
            <div class="discussion_table">
                <div class="table_title">
                    14 Days
                </div>
                <div class="table_container">
                    <b-table hover :items="discussion_day14_data" :fields="discussion_fields" responsive="sm">
                        <template v-slot:cell(index)="data">
                            <div class="item">
                                {{data.index+1}}
                            </div>
                        </template>

                        <template v-slot:cell(work_space)="data">
                            <div class="item_name" v-html="highlight(data.item.work_space_name.name)">
                                {{data.item.work_space_name.name}}
                            </div>
                        </template>

                        <template v-slot:cell(channel)="data">
                            <div class="" v-html="highlight(data.item.channel_name.name)">
                                {{data.item.channel_name.name}}
                            </div>
                        </template>

                        <template v-slot:cell(subject)="data">
                            <div class="" v-html="highlight(data.item.title)">
                                {{data.item.title}}
                            </div>
                        </template>

                        <template v-slot:cell(agenda)="data">
                            <div class="" v-html="highlight(data.item.discussion_agenda)">
                                {{data.item.discussion_agenda}}
                            </div>
                        </template>

                        <template v-slot:cell(originator)="data">
                            <div class="" v-html="highlight(data.item.originator.name)">
                                {{data.item.originator.name}}
                            </div>
                        </template>

                        <template v-slot:cell(date)="data">
                            <div class="">
                                {{getDate(data.item.created_at)}}
                            </div>
                        </template>

                        <template v-slot:cell(target)="data">
                            <div class="">
                                {{getDateNumber(data.item.date_end)}}
                            </div>
                        </template>

                        <template v-slot:cell(milestone_impacted)="data">
                            <div class="item_column milestones_item"
                                 @click="milestoneImpacted(data.item.milestones)">
                                {{data.item.milestones.length}}
                                <!--                                <div v-for="milestone in data.item.milestones">-->
                                <!--                                    {{milestone.title}}-->
                                <!--                                </div>-->
                            </div>
                        </template>

                        <template v-slot:cell(attach)="data">
                            <div class="milestones_item">
                                {{data.item.attachments.length}}
                                <!--                                <div class="plus_btn" @click="addAttachment()"></div>-->
                                <!--                                <div class="x_button" @click="removeAttachment"></div>-->
                            </div>
                        </template>

                        <template v-slot:cell(status)="data">
                            <div class="status_dots">
                                <div class="yellow_dot" :class="{'active_dot': data.item.status === 0}"
                                     @click="changeSummary(data.item.id, 0)"></div>
                                <div class="orange_dot" :class="{'active_dot': data.item.status === 1}"
                                     @click="changeSummary(data.item.id, 1)"></div>
                                <div class="purple_dot" :class="{'active_dot': data.item.status === 2}"
                                     @click="changeSummary(data.item.id, 2)"></div>
                                <div class="green_dot" :class="{'active_dot': data.item.status === 3}"
                                     @click="changeSummary(data.item.id, 3)"></div>
                            </div>
                        </template>

                        <template v-slot:cell(edit)="data">
                            <div class="edit_btn" @click="edit_discussion(data.item)">
                            </div>
                        </template>

                        <template v-slot:cell(delete)="data">
                            <div class="trash_button" @click="deleteDiscussionModal(data.item)">
                            </div>
                        </template>

                    </b-table>
                </div>
            </div>
            <div class="discussion_table">
                <div class="table_title">
                    30 Days
                </div>
                <div class="table_container">
                    <b-table hover :items="discussion_day30_data" :fields="discussion_fields" responsive="sm">
                        <template v-slot:cell(index)="data">
                            <div class="item">
                                {{data.index+1}}
                            </div>
                        </template>

                        <template v-slot:cell(work_space)="data">
                            <div class="item_name" v-html="highlight(data.item.work_space_name.name)">
                                {{data.item.work_space_name.name}}
                            </div>
                        </template>

                        <template v-slot:cell(channel)="data">
                            <div class="" v-html="highlight(data.item.channel_name.name)">
                                {{data.item.channel_name.name}}
                            </div>
                        </template>

                        <template v-slot:cell(subject)="data">
                            <div class="" v-html="highlight(data.item.title)">
                                {{data.item.title}}
                            </div>
                        </template>

                        <template v-slot:cell(agenda)="data">
                            <div class="" v-html="highlight(data.item.discussion_agenda)">
                                {{data.item.discussion_agenda}}
                            </div>
                        </template>

                        <template v-slot:cell(originator)="data">
                            <div class="" v-html="highlight(data.item.originator.name)">
                                {{data.item.originator.name}}
                            </div>
                        </template>

                        <template v-slot:cell(date)="data">
                            <div class="">
                                {{getDate(data.item.created_at)}}
                            </div>
                        </template>

                        <template v-slot:cell(target)="data">
                            <div class="">
                                {{getDateNumber(data.item.date_end)}}
                            </div>
                        </template>

                        <template v-slot:cell(milestone_impacted)="data">
                            <div class="item_column milestones_item" id="milestone_impacted-btn"
                                 @click="milestoneImpacted(data.item.milestones)">
                                {{data.item.milestones.length}}
                                <!--                                <div v-for="milestone in data.item.milestones">-->
                                <!--                                    {{milestone.title}}-->
                                <!--                                </div>-->
                            </div>
                        </template>

                        <template v-slot:cell(attach)="data">
                            <div class="milestones_item">
                                {{data.item.attachments.length}}
                                <!--                                <div class="plus_btn" @click="addAttachment()"></div>-->
                                <!--                                <div class="x_button" @click="removeAttachment"></div>-->
                            </div>
                        </template>

                        <template v-slot:cell(status)="data">
                            <div class="status_dots">
                                <div class="yellow_dot" :class="{'active_dot': data.item.status === 0}"
                                     @click="changeSummary(data.item.id, 0)"></div>
                                <div class="orange_dot" :class="{'active_dot': data.item.status === 1}"
                                     @click="changeSummary(data.item.id, 1)"></div>
                                <div class="purple_dot" :class="{'active_dot': data.item.status === 2}"
                                     @click="changeSummary(data.item.id, 2)"></div>
                                <div class="green_dot" :class="{'active_dot': data.item.status === 3}"
                                     @click="changeSummary(data.item.id, 3)"></div>
                            </div>
                        </template>

                        <template v-slot:cell(edit)="data">
                            <div class="edit_btn" id="edit_discussion-btn" @click="edit_discussion(data.item)">
                            </div>
                        </template>

                        <template v-slot:cell(delete)="data">
                            <div class="trash_button" id="discussion_del_btn" @click="deleteDiscussionModal(data.item)">
                            </div>
                        </template>

                    </b-table>
                </div>
            </div>
            <!--create discussion modal start -->
            <b-modal ref="discussion-modal" centered hide-header hide-footer hide-header-close>
                <div class="discussion_modal_content">
                    <b-icon icon="x" class="x_btn" aria-hidden="true" @click="discussionModal()"></b-icon>
                    <div class="d-flex">
                        <div class="discussion_title">
                            Create New Discussion
                        </div>
                    </div>
                    <div class="input_content">
                        <div class="input_item">
                            <label>WorkSpace</label>
                            <div class="select_arrow">
                                <b-form-select @change="workspaceChange">
                                    <option
                                            v-for="(option, id) in workspaces"
                                            :key="id"
                                            :value="option.id"
                                            :title="option.name || null"
                                    >
                                        {{option.name}}
                                    </option>
                                </b-form-select>
                                <div class="arrow_down"></div>
                                <span v-if="createDiscussionErrors['work_space_id']" class="text_danger mt-2">{{createDiscussionErrors['work_space_id'][0]}}</span>
                            </div>
                        </div>
                        <div class="input_item">
                            <label>Channel</label>
                            <div class="select_arrow">
                                <b-form-select @change="channelChange" :disabled="!discussionData.work_space">
                                    <option
                                            v-for="(option, id) in channels"
                                            :key="id"
                                            :value="option.id"
                                            :title="option.name || null"
                                    >
                                        {{option.name}}
                                    </option>
                                </b-form-select>
                                <div class="arrow_down"></div>
                                <span v-if="createDiscussionErrors['channel_id']" class="text_danger mt-2">{{createDiscussionErrors['channel_id'][0]}}</span>
                            </div>
                        </div>
                        <div class="input_item">
                            <label>Subject Title</label>
                            <b-form-input
                                    v-model="discussionData.subject_title"
                                    type="text"
                                    trim
                            ></b-form-input>
                            <span v-if="createDiscussionErrors['title']" class="text_danger mt-2">{{createDiscussionErrors['title'][0]}}</span>
                        </div>
                        <div class="input_item">
                            <label>Discussion Agenda</label>
                            <b-form-input
                                    v-model="discussionData.discussion_agenda"
                                    type="text"
                                    trim
                            ></b-form-input>
                            <span v-if="createDiscussionErrors['discussion_agenda']" class="text_danger mt-2">{{createDiscussionErrors['discussion_agenda'][0]}}</span>
                        </div>


                        <div class="input_item">
                            <label>Target Completion Date</label>
                            <b-form-input
                                    v-model="discussionData.target_completion_date"
                                    type="date"
                                    trim
                            ></b-form-input>
                            <span v-if="createDiscussionErrors['date_end']" class="text_danger mt-2">{{createDiscussionErrors['date_end'][0]}}</span>
                        </div>

                        <div class="input_item">
                            <label>Milestone Impacted</label>
                            <div class="select_arrow">
                                <Multiselect
                                        v-model="choosen_milestone_impact"
                                        :options="allMilestones"
                                        label="title"
                                        track-by="id"
                                        placeholder=""
                                        :hide-selected="true"
                                        multiple
                                ></Multiselect>
                                <div class="arrow_down"></div>
                                <!--                                <span v-if="createDiscussionErrors['user_id']" class="text_danger mt-2">{{createDiscussionErrors['user_id'][0]}}</span>-->
                            </div>
                        </div>

                        <div class="input_item">
                            <label>Copied List</label>
                            <div class="select_arrow">
                                <Multiselect
                                        v-model="choosen_copied_list"
                                        :options="copied_list"
                                        label="name"
                                        track-by="id"
                                        placeholder=""
                                        :hide-selected="true"
                                        multiple
                                ></Multiselect>
                                <div class="arrow_down"></div>
                                <!--                                <span v-if="createDiscussionErrors['user_id']" class="text_danger mt-2">{{createDiscussionErrors['user_id'][0]}}</span>-->
                            </div>
                        </div>

                        <div class="input_item file_item">
                            <b-form-file
                                    v-model="discussionData.attach_file"
                                    placeholder="Choose a file(s)"
                                    multiple
                            ></b-form-file>
                            <div v-if="attachmentErrors.length" class="text_danger mt-1">
                                Attachments must be a file of type: jpg, jpeg, png, pdf, docx.
                                <p class="danger_p" v-for="item in attachmentErrors">
                                    Attachment {{Number(item[1]) + 1}}
                                </p>
                            </div>
                        </div>

                    </div>
                    <div class="done_btn-container">
                        <b-button class="done_btn" @click="create_new_discussion()">Done</b-button>
                    </div>
                </div>
            </b-modal>
            <!--create discussion modal  end  -->

            <!--edit discussion modal start -->
            <b-modal ref="discussion_edit-modal" centered hide-header hide-footer hide-header-close>
                <div class="discussion_modal_content">
                    <b-icon icon="x" class="x_btn" aria-hidden="true" @click="editDiscussionModal()"></b-icon>
                    <div class="d-flex">
                        <div class="discussion_title">
                            Edit Discussion
                        </div>
                    </div>
                    <div class="input_content">
                        <div class="input_item">
                            <label>WorkSpace</label>
                            <div class="passive_back">
                                {{edit_discussion_data.work_space}}
                            </div>
                        </div>
                        <div class="input_item">
                            <label>Channel</label>
                            <div class="passive_back">
                                {{edit_discussion_data.channel}}

                            </div>
                        </div>
                        <div class="input_item">
                            <label>Subject Title</label>
                            <b-form-input
                                    v-model="edit_discussion_data.subject_title"
                                    type="text"
                                    trim
                            ></b-form-input>
                            <span v-if="editDiscussionErrors['title']" class="text_danger mt-2">{{editDiscussionErrors['title'][0]}}</span>
                        </div>
                        <div class="input_item">
                            <label>Discussion Agenda</label>
                            <b-form-input
                                    v-model="edit_discussion_data.discussion_agenda"
                                    type="text"
                                    trim
                            ></b-form-input>
                            <span v-if="editDiscussionErrors['discussion_agenda']" class="text_danger mt-2">{{editDiscussionErrors['discussion_agenda'][0]}}</span>
                        </div>

                        <div class="input_item">
                            <label>Target Completion Date</label>
                            <b-form-input
                                    v-model="edit_discussion_data.target_completion_date"
                                    type="date"
                                    trim
                            ></b-form-input>
                            <span v-if="editDiscussionErrors['date_end']" class="text_danger mt-2">{{editDiscussionErrors['date_end'][0]}}</span>
                        </div>

                        <div class="input_item">
                            <label>Milestone Impacted</label>
                            <div class="select_arrow">
                                <Multiselect
                                        v-model="edit_discussion_data.milestone_impact"
                                        :options="allMilestones"
                                        label="title"
                                        track-by="id"
                                        placeholder=""
                                        :hide-selected="true"
                                        multiple
                                ></Multiselect>
                                <div class="arrow_down"></div>
                                <!--                                <span v-if="createDiscussionErrors['user_id']" class="text_danger mt-2">{{createDiscussionErrors['user_id'][0]}}</span>-->
                            </div>
                        </div>


                        <div class="input_item">
                            <label>Copied List</label>
                            <div class="select_arrow">
                                <Multiselect
                                        v-model="edit_discussion_data.selectedList"
                                        :options="copied_list"
                                        label="name"
                                        track-by="id"
                                        placeholder=""
                                        :hide-selected="true"
                                        multiple
                                ></Multiselect>
                                <div class="arrow_down"></div>
                                <!--                                <span v-if="createDiscussionErrors['user_id']" class="text_danger mt-2">{{createDiscussionErrors['user_id'][0]}}</span>-->
                            </div>
                        </div>

                        <div class="input_item file_item">
                            <b-form-file
                                    v-model="edit_discussion_data.attach_file"
                                    placeholder="Choose a file(s)"
                                    multiple
                            ></b-form-file>
<!--                            <div v-if="attachmentErrors.length" class="text_danger mt-1">-->
<!--                                Attachments must be a file of type: jpg, jpeg, png, pdf, docx.-->
<!--                                <p class="danger_p" v-for="item in attachmentErrors">-->
<!--                                    Attachment {{Number(item[1]) + 1}}-->
<!--                                </p>-->
<!--                            </div>-->
                        </div>


                        <div class="attached_files">
                            <div class="attached_item" v-for="file in attachedFiles">
                                {{file.attachment_name}}
                                <div class="remove_item" @click="removeFile(file)">

                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="done_btn-container">
                        <b-button class="done_btn" @click="edit_discussion_done()">Done</b-button>
                    </div>
                </div>
            </b-modal>
            <!--edit discussion modal end -->

            <!--delete modal start -->
            <b-modal ref="discussion_delete-modal" centered hide-header hide-footer hide-header-close>
                <div class="modal_content">
                    <b-icon icon="x" class="x_btn" aria-hidden="true" @click="deleteDiscussionModal()"></b-icon>
                    <div class="d-flex flex-column">
                        <div class="modal_title">
                            You are about to delete this discussion
                        </div>
                        <div class="modal_text">
                            Do you want to proceed?
                        </div>
                        <div class="modal_buttons">
                            <div class="cancel_btn" @click="deleteDiscussionModal()">Cancel</div>
                            <b-button class="delete_btn ml-3" @click="removeDiscussion()">Delete</b-button>
                        </div>

                    </div>
                </div>
            </b-modal>
            <!--delete modal end -->

            <!--milestone impacted modal start -->
            <b-modal ref="milestone_impacted-modal" centered hide-header hide-footer hide-header-close>
                <div class="discussion_modal_content">
                    <b-icon icon="x" class="x_btn" aria-hidden="true" @click="milestoneImpactedModal()"></b-icon>
                    <div class="d-flex">
                        <div class="discussion_title">
                            Milestone Impacted
                        </div>
                    </div>
                    <div class="milestone_item" v-for="item in all_milestone_items">
                        <div class="milestone_item-data">
                            <div class="item_name">Milestone Name</div>
                            <div>{{item.title}}</div>
                        </div>
                        <div class="milestone_item-data">
                            <div class="item_name">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" viewBox="0 0 20 22">
                                    <defs>
                                    </defs>
                                    <g transform="translate(1 1)">
                                        <rect class="a_fill " width="18" height="18" rx="2" transform="translate(0 2)"/>
                                        <line class="a_fill " y2="4" transform="translate(13)"/>
                                        <line class="a_fill " y2="4" transform="translate(5)"/>
                                        <line class="a_fill " x2="18" transform="translate(0 8)"/>
                                    </g>
                                </svg>
                                Milestone Date
                            </div>
                            <div>{{getDateNumber(item.milestone_date)}}</div>
                        </div>
                        <div class="milestone_item-data">
                            <div class="item_name">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" viewBox="0 0 20 22">
                                    <defs>
                                    </defs>
                                    <g transform="translate(1 1)">
                                        <rect class="a_fill " width="18" height="18" rx="2" transform="translate(0 2)"/>
                                        <line class="a_fill " y2="4" transform="translate(13)"/>
                                        <line class="a_fill " y2="4" transform="translate(5)"/>
                                        <line class="a_fill " x2="18" transform="translate(0 8)"/>
                                    </g>
                                </svg>
                                Countdown Days
                            </div>
                            <div>{{getCountDownDate(item.milestone_date)}}</div>
                        </div>


                    </div>

                    <div class="done_btn-container">
                        <!--                        <b-button class="done_btn" @click="create_new_discussion()">Done</b-button>-->
                    </div>
                </div>
            </b-modal>
            <!--milestone impacted modal  end  -->
        </div>
    </div>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";

    export default {
        name: "Discussion",
        data() {
            return {
                discussion_fields: [
                    {
                        key: 'index',
                        label: 'No',
                    },
                    {
                        key: 'work_space',
                        label: 'WorkSpace',
                    },
                    {
                        key: 'channel',
                        label: 'Channel',
                    },
                    {
                        key: 'subject',
                        label: 'Subject',
                    },
                    {
                        key: 'agenda',
                        label: 'Agenda',
                    },
                    {
                        key: 'originator',
                        label: 'Originator',
                    },
                    {
                        key: 'date',
                        label: 'Date'
                    },
                    {
                        key: 'target',
                        label: 'Target Completion'
                    },
                    {
                        key: 'milestone_impacted',
                        label: 'Milestone Impacted'
                    },
                    {
                        key: 'attach',
                        label: 'Attachment',
                    },
                    {
                        key: 'status',
                        label: 'Status',
                    },
                    {
                        key: 'edit',
                        label: 'Edit',
                    },
                    {
                        key: 'delete',
                        label: 'Delete',
                    },
                ],
                discussion_day7_data: [],
                discussion_day14_data: [],
                discussion_day30_data: [],
                edit_item_id: '',
                delete_item_id: '',
                channels: [],
                allMilestones: [],
                discussionData: {
                    work_space: null,
                    channel: null,
                    subject_title: null,
                    discussion_agenda: null,
                    target_completion_date: null,
                    milestone_impact: [],
                    selectedList: [],
                    attach_file: []
                },
                choosen_milestone_impact: null,
                choosen_copied_list: null,
                edit_discussion_data: {
                    work_space: null,
                    channel: null,
                    subject_title: null,
                    discussion_agenda: null,
                    target_completion_date: null,
                    milestone_impact: null,
                    selectedList: null,
                    id: null,
                    attach_file: [],
                    status: null,
                    newAttachedFiles: [],
                    file_delete_id: []
                },
                copied_list: [],
                status_options: [
                    {
                        id: 0,
                        name: 'Low'
                    },
                    {
                        id: 1,
                        name: 'Normal'
                    },
                    {
                        id: 2,
                        name: 'High'
                    },
                ],
                createDiscussionErrors: [],
                attachmentErrors: [],
                discussionSummary: null,
                all_milestone_items: null,
                editDiscussionErrors: [],
                attachedFiles: null
            }
        },
        created() {
            this.getAllWorkspaces()
            this.getAllDiscussions()
                .then(res => {
                    if (res.status === 200) {
                        this.discussion_day7_data = res.data.success.days_7
                        this.discussion_day14_data = res.data.success.days_14
                        this.discussion_day30_data = res.data.success.days_30
                    }
                })
            this.getDiscussionSummary()
                .then(res => {
                    if (res.status === 200) {
                        this.discussionSummary = res.data.success
                    }
                })
            this.getKeyWords()
        },
        computed: {
            ...mapGetters(['search_data', 'workspaces', 'keywords']),
            // filteredDiscussions() {
            //     let filteredItems = this.discussion_day7_data.filter((discussion) => {
            //         return discussion.title.toLowerCase().includes(this.search_data.toLowerCase());
            //     })
            //     return filteredItems.sort((a, b) => {
            //         return b.title - a.title;
            //     });
            // },
        },
        methods: {
            ...mapActions([
                'getAllWorkspaces',
                'getAllDiscussions',
                'createDiscussion',
                'editDiscussion',
                'deleteDiscussion',
                'getMilestones',
                'getWorkspaceUsers',
                'changeDiscussionStatus',
                'getDiscussionSummary',
                'getKeyWords'
            ]),
            highlight(text) {
                var values = this.keywords.join("|");
                if(values) {
                    return text.replace(new RegExp(`.*${values}.*`, 'gi'), '<mark style="background: yellow" >$&</mark>')
                } else {
                    return  text
                }
            },
            getDate(item) {
                return new Date(item).toLocaleString("en-US", {timeZoneName: "short"}).split(',')[0]
            },
            getDateNumber(sec) {
                return new Date(sec * 1000).toLocaleString("en-US", {timeZoneName: "short"}).split(',')[0]
            },
            getCountDownDate(sec) {
                if(Math.floor((sec * 1000 - Date.now() ) / 86400000) <= 0) {
                    return 0
                } else {
                    return Math.floor((sec * 1000 - Date.now() ) / 86400000)
                }
            },
            workspaceChange(args) {
                var workSpace = this.workspaces.find((item) => {
                    return item.id == args
                })
                this.getWorkspaceUsers(args)
                    .then((res) => {
                        if (res.status === 200) {
                            this.copied_list = res.data.success
                        }
                    })
                this.channels = workSpace.channels
                this.discussionData.work_space = args
            },
            channelChange(args) {
                this.discussionData.channel = args
                // this.getChannelMembers(args)
                //     .then((res) => {
                //         this.channelMembers = res.data.success
                //     })
            },
            // assigneeChange(args) {
            // this.taskData.user_id = args
            // this.filteredItems =  this.channelMembers.filter((item) => {
            //     return item.id !== args
            // })
            // },
            statusChange(args) {
                this.discussionData.status = args
            },
            createNewDiscussion() {
                this.createDiscussionErrors = []
                this.attachmentErrors = []
                this.discussionData.work_space = null
                this.discussionData.channel = null
                this.discussionData.subject_title = null
                this.discussionData.discussion_agenda = null
                this.discussionData.target_completion_date = null
                this.discussionData.milestone_impact = []
                this.discussionData.selectedList = []
                this.choosen_copied_list = []
                this.choosen_milestone_impact = []
                this.getMilestones()
                    .then(res => {
                        if (res.status === 200) {
                            this.allMilestones = res.data.success
                        }
                    })
                this.discussionModal()
            },
            create_new_discussion() {
                this.choosen_milestone_impact.forEach(item => {
                    this.discussionData.milestone_impact.push(item.id)
                })
                this.choosen_copied_list.forEach(item => {
                    this.discussionData.selectedList.push(item.id)
                })
                let formData = new FormData()
                for (let i = 0; i < this.discussionData.attach_file.length; i++) {
                    let file = this.discussionData.attach_file[i];
                    formData.append('attachments[' + i + ']', file);
                }
                formData.append('work_space_id', this.discussionData.work_space)
                formData.append('channel_id', this.discussionData.channel)
                formData.append('title', this.discussionData.subject_title)
                formData.append('discussion_agenda', this.discussionData.discussion_agenda)
                formData.append('date_end', this.discussionData.target_completion_date)
                for (let i = 0; i < this.discussionData.milestone_impact.length; i++) {
                    let id = this.discussionData.milestone_impact[i];
                    formData.append('milestones_id[' + i + ']', id);
                }
                for (let i = 0; i < this.discussionData.selectedList.length; i++) {
                    let id = this.discussionData.selectedList[i];
                    formData.append('copied_id[' + i + ']', id);
                }
                this.createDiscussion(formData)
                    .then(res => {
                        if (res.status === 200) {
                            this.discussionModal()
                            this.getAllDiscussions()
                                .then(res => {
                                    if (res.status === 200) {
                                        this.discussion_day7_data = res.data.success.days_7
                                        this.discussion_day14_data = res.data.success.days_14
                                        this.discussion_day30_data = res.data.success.days_30
                                        this.getDiscussionSummary()
                                            .then(res => {
                                                if (res.status === 200) {
                                                    this.discussionSummary = res.data.success
                                                }
                                            })
                                    }
                                })
                        } else {
                            this.createDiscussionErrors = res.response.data.errors
                            for (let key in this.createDiscussionErrors) {
                                if (key.split('.')[0] == "attachments") {
                                    this.attachmentErrors.push(key.split('.'))
                                }
                            }
                            this.discussionData.milestone_impact = []
                            this.discussionData.selectedList = []
                        }
                    })
            },
            discussionModal() {
                this.$refs['discussion-modal'].toggle('#discussion_btn')
            },
            edit_discussion(item) {
                this.editDiscussionErrors = []
                this.getMilestones()
                    .then(res => {
                        if (res.status === 200) {
                            this.allMilestones = res.data.success
                        }
                    })
                this.getWorkspaceUsers(item.work_space_id)
                    .then((res) => {
                        if (res.status === 200) {
                            this.copied_list = res.data.success
                        }
                    })
                this.edit_discussion_data.work_space = item.work_space_name.name
                this.edit_discussion_data.channel = item.channel_name.name
                this.edit_discussion_data.subject_title = item.title
                this.edit_discussion_data.discussion_agenda = item.discussion_agenda
                this.edit_discussion_data.target_completion_date = new Date(item.date_end * 1000 ).toLocaleString("en-CA", {timeZoneName: "short"}).split(',')[0]
                this.edit_discussion_data.milestone_impact =  item.milestones
                this.edit_discussion_data.selectedList =  item.copied
                this.edit_discussion_data.id = item.id
                this.attachedFiles = item.attachments
                this.edit_discussion_data.newAttachedFiles = []
                this.edit_discussion_data.file_delete_id = []
                this.edit_discussion_data.attach_file = []
                this.editDiscussionModal()
            },
            removeFile(item) {
                this.attachedFiles.forEach(file =>{
                    if(item.id === file.id) {
                        this.attachedFiles.splice(file, 1)
                        this.edit_discussion_data.file_delete_id.push(item.id)
                    }
                })
            },
            editDiscussionModal() {
                this.$refs['discussion_edit-modal'].toggle('#edit_discussion-btn')
            },
            edit_discussion_done() {
                if(this.edit_discussion_data.attach_file.length) {
                    this.edit_discussion_data.attach_file.forEach(file =>{
                        this.edit_discussion_data.newAttachedFiles.push(file)
                    })
                }
                this.editDiscussion(this.edit_discussion_data)
                    .then(res => {
                        if(res.status === 200) {
                            this.editDiscussionModal()
                            this.getAllDiscussions()
                                .then(res => {
                                    if (res.status === 200) {
                                        this.discussion_day7_data = res.data.success.days_7
                                        this.discussion_day14_data = res.data.success.days_14
                                        this.discussion_day30_data = res.data.success.days_30
                                    }
                                })

                        } else {
                            this.editDiscussionErrors = res.response.data.errors
                        }
                    })
            },
            deleteDiscussionModal(item) {
                if (item) {
                    this.delete_item_id = item.id
                }
                this.$refs['discussion_delete-modal'].toggle('#discussion_del_btn')
            },
            removeDiscussion() {
                this.deleteDiscussion(this.delete_item_id)
                    .then(res => {
                        if (res.status === 200) {
                            this.deleteDiscussionModal()
                            this.getAllDiscussions()
                                .then(res => {
                                    if (res.status === 200) {
                                        this.discussion_day7_data = res.data.success.days_7
                                        this.discussion_day14_data = res.data.success.days_14
                                        this.discussion_day30_data = res.data.success.days_30
                                    }
                                })
                            this.getDiscussionSummary()
                                .then(res => {
                                    if (res.status === 200) {
                                        this.discussionSummary = res.data.success
                                    }
                                })
                        }
                    })
            },
            changeSummary(itemId, status) {
                let sendData = {
                    discussion_id: itemId,
                    status: status
                }
                this.changeDiscussionStatus(sendData)
                    .then((res) => {
                        if (res.status === 200) {
                            this.getAllDiscussions()
                                .then(res => {
                                    if (res.status === 200) {
                                        this.discussion_day7_data = res.data.success.days_7
                                        this.discussion_day14_data = res.data.success.days_14
                                        this.discussion_day30_data = res.data.success.days_30
                                    }
                                })
                            this.getDiscussionSummary()
                                .then(res => {
                                    if (res.status === 200) {
                                        this.discussionSummary = res.data.success
                                    }
                                })
                        }
                    })
            },
            milestoneImpacted(args) {
                if (args.length) {
                    this.all_milestone_items = args
                    this.milestoneImpactedModal()
                }
            },
            milestoneImpactedModal() {
                this.$refs['milestone_impacted-modal'].toggle('#milestone_impacted-btn')
            }
            // addAttachment() {
            //     console.log('add attachment')
            // },
            // removeAttachment() {
            //     console.log('remove attachment')
            // }
        }
    }
</script>

<style scoped lang="scss">

    input[type="date"]::-webkit-calendar-picker-indicator {
        background: url("~@/assets/images/calendar.svg") center/80% no-repeat;
        cursor: pointer;
    }

    input[type="date"]::-webkit-calendar-picker-indicator:hover {
        background: url("~@/assets/images/calendar.svg") center/80% no-repeat;
    }

    .page {
        &_head {
            padding-bottom: 20px;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        &_title {
            color: $black;
            font-weight: 600;
            font-size: 25px;
        }

        &_content {
            padding: 20px 40px 20px 1px;

            & .create_btn {
                color: $primary;
                height: 44px;
                font-weight: 600;
                background: transparent linear-gradient(180deg, #84EEEB 0%, #3AE4DF 100%) 0% 0% no-repeat padding-box;
                border-radius: 10px;
                padding: 0 20px;
                border: none;
                display: flex;
                justify-content: center;
                align-items: center;

                &:focus,
                &:active {
                    box-shadow: none;
                    color: $primary;
                }
            }

            & .discussion_cards {
                display: flex;
                justify-content: space-between;
                padding: 30px 0 0;
                @media screen and (max-width: 1800px) {
                    flex-wrap: wrap;
                }

                & .card_item {
                    width: 300px;
                    height: 190px;
                    border-radius: 15px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    margin-bottom: 25px;

                    &.started {
                        background: $yellow;
                    }

                    &.in_progress {
                        background: $lemon;
                    }

                    &.review {
                        background: $light_purple;

                    }

                    &.completed {
                        background: $green;
                    }

                    & .item_status {
                        color: #000;
                        font-weight: 600;
                        font-size: 25px;
                        text-align: center;
                    }

                    & .item_point {
                        color: #000;
                        font-weight: 600;
                        font-size: 32px;
                        text-align: center;
                    }
                }
            }

            & .discussion_table {
                padding: 20px 0;

                & .table_title {
                    padding-bottom: 40px;
                    color: $primary;
                    font-size: 16px;
                    font-weight: 600;
                }

                & .table_head {
                    width: 100%;
                    height: 60px;
                    background: #84EEEB;
                    color: #4A4A4D;
                    font-weight: 600;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }

            /*/////////////*/
            & .table_container {
                font-size: 13px;

                ::v-deep thead {
                    height: 62px;
                    color: $primary;
                    background: #E7E8F5 0 0 no-repeat padding-box;

                    th {
                        vertical-align: middle;
                        max-width: 100px;
                        text-align: center;
                    }
                }

                ::v-deep tbody {
                    tr {
                        td {
                            vertical-align: middle;
                            text-align: center;
                            text-align: -webkit-center;
                        }
                    }
                }

                & .view_btn {
                    width: 22px;
                    height: 22px;
                    cursor: pointer;
                    background: url("~@/assets/images/eye_icon.svg") center no-repeat;
                }

                & .plus_btn {
                    width: 22px;
                    height: 22px;
                    cursor: pointer;
                    background: url("~@/assets/images/plus.svg") center no-repeat;
                    background-size: contain;
                }

                & .edit_btn {
                    width: 22px;
                    height: 22px;
                    cursor: pointer;
                    background: url("~@/assets/images/edit_icon.svg") center no-repeat;
                }

                & .trash_button {
                    width: 22px;
                    height: 22px;
                    cursor: pointer;
                    background: url("~@/assets/images/trash_icon.svg") center no-repeat;
                }

                & .x_button {
                    width: 22px;
                    height: 22px;
                    cursor: pointer;
                    background: url("~@/assets/images/x_icon.svg") center no-repeat;
                }

                & .item_attach {
                    display: flex;
                    justify-content: space-evenly;
                }

                & .milestones_item {
                    color: $purple;
                    font-size: 14px;
                    text-decoration: underline;
                    text-underline-offset: 2px;
                    cursor: pointer;
                }

                & .item {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 100%;

                    & .dropdown_content {
                        ::v-deep button {
                            width: 130px;
                            height: 32px;
                            background: $light_purple;
                            color: $white;
                            font-size: 12px;
                            border: none;
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                        }

                        ::v-deep .dropdown-menu {
                            min-width: 8rem;
                            border: none;
                            margin: 0;
                            padding: 0;
                        }

                        ::v-deep ul {
                            max-width: 130px;
                            width: 100%;
                            background: $greyBlue;

                            li {
                                border-top: 1px solid $light_purple;
                                font-size: 12px;
                                width: 100%;

                                a {
                                    white-space: normal;
                                    padding: 3px 8px;
                                }
                            }
                        }
                    }
                }

                & .status_item {
                    max-width: 95px;
                    text-align: start;
                }

                & .status_dots {
                    width: 110px;
                    display: flex;
                    justify-content: space-evenly;

                    & .dot_opy {
                        opacity: 1;
                    }
                }

                & .yellow_dot {
                    width: 20px;
                    height: 20px;
                    border-radius: 50%;
                    background: #F2EB1A;
                    opacity: 0.5;
                    cursor: pointer;
                }

                & .green_dot {
                    width: 20px;
                    height: 20px;
                    border-radius: 50%;
                    background: #1CD65A;
                    opacity: 0.5;
                    cursor: pointer;
                }

                & .orange_dot {
                    width: 20px;
                    height: 20px;
                    border-radius: 50%;
                    background: #FFB26E;
                    opacity: 0.5;
                    cursor: pointer;
                }

                & .purple_dot {
                    width: 20px;
                    height: 20px;
                    border-radius: 50%;
                    background: #7882F3;
                    opacity: 0.5;
                    cursor: pointer;
                }

                & .active_dot {
                    opacity: 1;
                    box-shadow: 0 0 0 3px rgb(255, 255, 255) inset;
                    border: 2px solid transparent;
                }
            }

            /*    ///////////*/
        }
    }

    .discussion {
        &_modal_content {
            position: relative;
            padding: 0 10px;

            & .x_btn {
                position: absolute;
                top: 0;
                right: 0;
                width: 25px;
                height: 25px;
                cursor: pointer;
                color: $light_purple;
            }

            & .input_content {
                & .input_item {
                    padding: 5px 0;
                    & .passive_back {
                        width: 100%;
                        height: 40px;
                        border: none;
                        background: #E7E8F5 0 0 no-repeat padding-box;
                        border-radius: 3px;
                        display: flex;
                        align-items: center;
                        padding: 0 10px;
                    }


                    &.file_item {
                        margin: 15px 0 5px;

                        & .danger_p {
                            margin-bottom: 2px;
                        }

                        ::v-deep .custom-file {
                            background: #E7E8F5 0% 0% no-repeat padding-box;
                            border: 1px dashed #4A4A4D75;
                            border-radius: 3px;
                            position: relative;
                        }

                        ::v-deep label {
                            background: transparent !important;
                            border: 1px solid transparent;
                            padding-left: 40px;

                            &:after {
                                display: none !important;
                            }

                            &:before {
                                content: '';
                                position: absolute;
                                left: 10px;
                                width: 20px;
                                height: 20px;
                                background: url("~@/assets/images/paper-clip.svg") no-repeat center;
                            }
                        }

                        ::v-deep input {
                            background: none;
                            border: none !important;
                            box-shadow: none !important;
                            outline: none !important;

                            &:focus {
                                box-shadow: none !important;
                                outline: none !important;
                            }
                        }
                    }

                    & .edit_workspace {
                        padding: 5px 0;
                        font-size: 22px;
                        font-weight: 600;
                        color: $primary;
                    }

                    & .select_arrow {
                        position: relative;

                        & .arrow_down {
                            position: absolute;
                            top: 14px;
                            right: 15px;
                            width: 14px;
                            height: 10px;
                            pointer-events: none;
                            background: url("~@/assets/images/chevron_down.svg") right no-repeat;
                        }
                    }

                    & label {
                        color: $primary;
                        font-weight: 600;
                        font-size: 14px;
                    }

                    & input {
                        border: none;
                        background: $greyBlue 0 0 no-repeat padding-box;
                        border-radius: 3px;

                        &:focus {
                            box-shadow: none;
                            outline: none;
                        }
                    }

                    & select {
                        background: $greyBlue 0 0 no-repeat padding-box;
                        border-radius: 4px;
                    }

                    ::v-deep .multiselect {
                        width: 100%;
                        height: 44px;
                        background: $greyBlue 0 0 no-repeat padding-box;
                        border-radius: 4px;
                        cursor: pointer;
                    }

                    ::v-deep .multiselect__tags {
                        background: transparent;
                        padding-left: 0;
                    }

                    ::v-deep .multiselect__tag {
                        background: transparent;
                        color: $black;
                    }

                    ::v-deep .multiselect__tag-icon:after {
                        color: red;
                    }

                    ::v-deep .multiselect__tag-icon:hover {
                        background: transparent;
                    }

                    ::v-deep .multiselect__option--highlight,
                    ::v-deep .multiselect__option--highlight:after {
                        background: $lightBlue;
                        color: $black;
                    }

                    ::v-deep .multiselect__select:before {
                        border-width: 0;
                        border-style: none;
                    }

                    ::v-deep .multiselect__input {
                        background: $greyBlue;
                        margin: 0;
                        padding: 0;
                    }
                }
                & .attached {
                    &_files {
                        padding: 5px 10px;
                        width: 300px;
                        display: flex;
                        flex-direction: column;
                    }
                    &_item {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        padding: 5px 0;
                        & .remove_item {
                            width: 22px;
                            height: 22px;
                            cursor: pointer;
                            background: url("~@/assets/images/x_icon.svg") center no-repeat;
                        }
                    }
                }
            }

            & .done_btn-container {
                display: flex;
                justify-content: flex-end;
                padding: 20px 0 10px;

                & .done_btn {
                    background: transparent linear-gradient(180deg, #84EEEB 0%, #3AE4DF 100%) 0% 0% no-repeat padding-box;
                    border-radius: 10px;
                    width: 110px;
                    height: 44px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: $primary;
                    font-weight: 600;
                    border: none;
                }
            }

            & .milestone_item {
                display: flex;
                flex-direction: column;
                width: 100%;
                margin: 15px 0;
                padding: 10px;
                border: 1px solid $lightBlue;
                border-radius: 10px;

                &-data {
                    display: flex;
                    padding: 4px 0;

                    & .item_name {
                        color: $primary;
                        width: 280px;

                        & svg {
                            margin-right: 10px;
                            & .a_fill {
                                fill: none;
                                stroke: #7882f3;
                                stroke-linecap: round;
                                stroke-linejoin: round;
                                stroke-width: 2px;
                            }
                        }

                    }
                }
            }
        }

        &_title {
            width: 100%;
            font-size: 25px;
            font-weight: 500;
            color: $grey;
            padding-bottom: 10px;
            border-bottom: 1px solid #CACDF6;
        }
    }

    ::v-deep .table {
        border-bottom: 1px solid #dee2e6;
    }

    ::v-deep .modal_content {
        border: none !important;
        width: 510px !important;
        position: relative;

        ::v-deep .modal-body {
            width: 510px !important;
        }

        & .x_btn {
            position: absolute;
            top: 0;
            right: 0;
            width: 25px;
            height: 25px;
            cursor: pointer;
            color: $light_purple;
        }

        & .modal_title {
            width: 100%;
            padding: 20px 0;
            font-size: 24px;
            color: $grey;
            border-bottom: 1px solid #CACDF6;
        }

        & .modal_text {
            font-size: 16px;
            color: $grey;
            padding: 25px 0;
        }

        & .modal_buttons {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            padding: 15px 0 30px;

            & .cancel_btn {
                color: $primary;
                font-weight: 600;
                padding: 10px 0;
                cursor: pointer;
            }

            & .delete_btn {
                width: 110px;
                height: 44px;
                background: transparent linear-gradient(180deg, #84EEEB 0%, #3AE4DF 100%) 0 0 no-repeat padding-box;
                border-radius: 10px;
                color: $primary;
                font-weight: 600;
                border: none;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }

        & .description_content {
            padding: 20px 0;

            & .description_subtitle {
                font-weight: 500;
                font-size: 18px;
                padding: 0 15px;
            }

            & .description_text {
                padding: 15px 20px;
            }
        }
    }

    ::v-deep .modal-content {
        border: none !important;
        width: 550px !important;
    }

    ::v-deep .custom-select {
        border: none;
    }

</style>