<template>
    <div class="page_content">
        <div class="page_head">
            <div class="page_title">
                Milestone History
            </div>
            <div class="back_btn" @click="goBack()">
                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="18.243" viewBox="0 0 28 18.243">
                    <g id="arrow-left_8_" data-name="arrow-left (8)" transform="translate(-3.5 -2.879)">
                        <line id="Line_1" data-name="Line 1" x1="25" transform="translate(5 12)" fill="none" stroke="#5454ee" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
                        <path id="Path_9" data-name="Path 9" d="M12,19,5,12l7-7" fill="none" stroke="#5454ee" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
                    </g>
                </svg>
            </div>
        </div>

        <div class="history_container">
            <div class="history_items">
                <div
                        v-for="item of history"
                        class="history_item"
                        :class="{ 'red_history': item.type === 2, 'yellow_history': item.type === 1, 'green_history': item.type === 0}"
                >
                    <div class="history_title">
                        <div class="history_name">
                            {{getHistoryType(item.type)}}
                        </div>

                        <div class="history_date">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#5454EE" class="bi bi-clock mr-2" viewBox="0 0 16 16">
                                <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z"/>
                                <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z"/>
                            </svg>
                            <div class="date_text">
                                {{getDate(item.created_at)}}
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapActions} from "vuex";

    export default {
        name: "MilestoneHistory",
        data() {
            return {
                itemId: '',
                history: []
            }
        },
        created() {
            this.itemId = this.$route.params.id
            this.getMilestoneHistory(this.itemId)
            .then(res => {
                if(res.status === 200) {
                    this.history = res.data.success
                }
            })

        },
        methods: {
            ...mapActions(['getMilestoneHistory']),
            getDate(item) {
                let d = new Date(item).toString().slice(0, 24)
                return d
            },
            getHistoryType(type) {
                switch (type) {
                    case 0: return 'Originator created a new milestone';
                    case 1: return 'Originator edited the milestone';
                    case 2: return 'Originator deleted a milestone';
                    default: return '';
                }
            },
            goBack() {
                this.$router.go(-1)
            },
        }
    }
</script>

<style scoped lang="scss">
    .page {
        &_content {
            padding: 20px 40px 20px 1px;
            & .history {
                &_container {
                    padding: 10px 0 20px;
                }
                &_items {
                    padding: 15px 0;
                }
                &_item {
                    width: 100%;
                    padding: 15px 0;
                    min-height: 30px;
                    display: flex;
                    flex-direction: column;
                    background: #EFF0FB;
                    margin: 8px 0;
                    box-sizing: content-box;
                    &.green_history {
                        border-left: 7px solid #3AE4DF;
                    }
                    &.red_history {
                        border-left: 7px solid #f82020;
                    }
                    &.yellow_history {
                        border-left: 7px solid #ff8c00;
                    }
                }
                &_title {
                    display: flex;
                    justify-content: space-between;
                    padding: 0 20px 10px;
                }
                &_name {
                    font-size: 14px;
                    font-weight: 600;
                    color: #000;
                }

                &_date {
                    display: flex;
                    justify-content: flex-end;
                    width: 300px;
                    & .date_text {
                        line-height: 18px;
                        color: $purple;
                        font-size: 14px;
                    }
                }

            }

        }
        &_head {
            padding-bottom: 10px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid #DEDEDE;
            & .back_btn {
                width: 40px;
                cursor: pointer;
                padding-bottom: 5px;
            }
        }
        &_title {
            color: $black;
            font-weight: 600;
            font-size: 25px;
        }
    }
</style>