import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Dashboard from "./components/layoutes/Dashboard";
import Default from "./components/layoutes/Default";
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'bootstrap/dist/css/bootstrap.css'
import interceptorsSetup from '@/helpers/interceptors'
import Vuelidate from 'vuelidate'
import VueAxios from 'vue-axios'
import axios from 'axios';
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import VueSignaturePad from 'vue-signature-pad';


import Echo from 'laravel-echo'
import VideoChat from "./views/VideoChat";
window.Pusher = require('pusher-js');
window._ = require('lodash');
window.Echo = new Echo({
  broadcaster: 'pusher',
  key: "207299b65d997a17e4b2",
  // key: "09060a0ccbd12ab53aa5",
  cluster: "ap2",
  encrypted: true
});


window.alert = new Vue();
Vue.use(VueSignaturePad);
Vue.use(Multiselect)
Vue.use(VueAxios, axios)
Vue.use(Vuelidate)
interceptorsSetup()
Vue.component('dashboard-layout', Dashboard);
Vue.component('default-layout', Default);
Vue.component('Multiselect', Multiselect);
Vue.component('VideoChat', VideoChat);
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
