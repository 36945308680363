<template>
    <div class="content">
        <div class="header_filters">
            <div class="d-flex">
                <div class="settings_title">
                    My Account
                </div>
            </div>
        </div>
        <div class="settings_container">
            <div class="settings_user-content">

                <div class="settings_user-img">
                    <div class="empty_user-img"></div>
                    <input type="file" ref="userImg" @change="onFileChange" />
                    <div
                            class="cover_img"
                            :style="{ backgroundImage: 'url(' + userImg + ')' }"
                            @click="addImg"
                    />
                    <b-button variant="secondary" class="remove_avatar" @click="removeAvatar()">Remove Avatar</b-button>
                </div>
                <div class="settings_user-data">
                    <div class="settings_user-name">
                        <div>
                            <b-icon icon="person-fill" style="color: #4FA7F8;" aria-hidden="true"></b-icon>
                            {{userData.name}}
                        </div>
                        <b-icon icon="pencil-fill"  style="color: #5454EE; cursor: pointer" aria-hidden="true" id="edit-btn" @click="editUser()"></b-icon>
                    </div>

                    <div class="settings_user-email">
                        <b-icon icon="envelope-fill" style="color: #4FA7F8;" aria-hidden="true"></b-icon>
                        {{userData.email}}
                    </div>

                </div>
            </div>
            <div class="settings_password">
                <div class="settings_password-title">
                    Change Password
                </div>
                <div class="settings_inputs">
                    <div class="input-item">
                        <label>Current password:</label>
                        <div class="d-flex flex-column">
                            <b-form-input
                                    v-model="changePassword.currentPassword"
                                    type="password"
                                    placeholder="Enter your current password"
                            ></b-form-input>
<!--                            <span v-if="errors['currentPassword']" class="text_danger">{{errors['currentPassword'][0]}}</span>-->
<!--                            <span v-if="currentErrors" class="text_danger">{{currentErrors}}</span>-->
                        </div>
                    </div>
                    <div class="input-item">
                        <label>New password:</label>
                        <div class="d-flex flex-column">
                            <b-form-input
                                    v-model="changePassword.newPassword"
                                    type="password"
                                    placeholder="Enter your new password"
                            ></b-form-input>
<!--                            <span v-if="errors['newPassword']" class="text_danger">{{errors['newPassword'][0]}}</span>-->
                        </div>

                    </div>
                    <div class="input-item">
                        <label>Confirm password:</label>
                        <div class="d-flex flex-column">
                            <b-form-input
                                    v-model="changePassword.confirmPassword"
                                    type="password"
                                    placeholder="Enter your new password"
                            ></b-form-input>
<!--                            <span v-if="errors['confirmPassword']" class="text_danger">{{errors['confirmPassword'][0]}}</span>-->
<!--                            <span v-if="success" class="success_text">{{success}}</span>-->
                        </div>

                    </div>
                </div>
                <div class="settings_save-btn">
                    <b-button variant="primary" @click="sendUserData()">Save</b-button>
                </div>
            </div>

<!--account edit modal start-->
<!--            <b-modal ref="edit-modal" centered size="lg"  hide-header hide-footer hide-header-close>-->
<!--                <div class="modal_content">-->
<!--                    <div class="modal_title">-->
<!--                        Edit User-->
<!--                    </div>-->

<!--                    <div class="input-item">-->
<!--                        <label for="email">Email:</label>-->
<!--                        <b-form-input-->
<!--                                id="email"-->
<!--                                v-model="editData.email"-->
<!--                                placeholder="Enter your email"-->
<!--                        ></b-form-input>-->
<!--                    </div>-->
<!--                    <div class="text_container">-->
<!--                        <label for="textarea">Description:</label>-->
<!--                        <b-form-textarea-->
<!--                                id="textarea"-->
<!--                                v-model="editData.description"-->
<!--                                placeholder="Enter something..."-->
<!--                                rows="4"-->
<!--                                max-rows="6"-->
<!--                        ></b-form-textarea>-->
<!--                    </div>-->
<!--                    <div class="edit-btn_container">-->
<!--                        <b-button variant="primary" @click="saveEditData()">Save</b-button>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </b-modal>-->
<!--account edit modal end-->
        </div>
    </div>
</template>

<script>
    import {mapActions} from "vuex";

    export default {
        name: "Account",
        data() {
            return {
                uploadFile: null,
                userImg: null,
                userData: {},
                userName: '',
                editData: {
                    email: '',
                    description: ''
                },
                changePassword: {
                    currentPassword: '',
                    newPassword: '',
                    confirmPassword: '',
                },
            }
        },
        created() {
            this.getUserData()
                .then((res) => {
                    this.userData = res.data.success.user
                })

        },
        computed: {

        },
        methods: {
            ...mapActions(['getUserData']),
            onFileChange(event) {
                this.uploadFile = event.target.files[0]
                this.createImage(this.uploadFile)
            },
            createImage(file) {
                var reader = new FileReader()
                reader.onload = event => {
                    this.userImg = event.target.result
                }
                reader.readAsDataURL(file)
            },
            addImg() {
                this.$refs.userImg.click()
            },
            sendUserData() {

            },

            removeAvatar() {
                this.userImg = null
            }

        }
    }
</script>

<style scoped lang="scss">
.content {
    @media screen and (max-width: 1600px) {
        padding-left: 10px;
    }
    @media screen and (max-width: 1200px) {
        padding-left: 20px;
    }
    & .header_filters {
        width: 100%;
        height: 70px;
        z-index: 7;
        background: #fff;
        border-bottom: 1px solid #00000029;
        display: flex;
        align-items: center;
        & .settings_title {
            color: $black;
            font-family: "Poppins", sans-serif;
            font-weight: 600;
            font-size: 25px;
        }
    }

    & .settings {
        &_container {
            padding: 25px 0 10px;
        }
        &_password {
            &-title {
                width: 100%;
                height: 70px;
                margin: 5px 0;
                padding: 0 40px;
                color: $purple;
                font-family: 'Poppins', sans-serif;
                font-weight: 600;
                font-size: 18px;
                display: flex;
                align-items: center;
                background: $greyBlue;
            }
        }
        &_notification {
            &-input {
                padding: 50px 40px;
                display: flex;
                align-items: center;
                ::v-deep.custom-control.custom-switch {
                    min-height: 2.5rem;
                }
                & label {
                    font-size: 18px;
                    font-family: 'Poppins', sans-serif;
                    font-weight: 600;
                    color: #545454;
                    width: 215px;
                }
            }
        }
        &_user {
            &-content {
                display: flex;
                padding-bottom: 40px;
                /*border-bottom: 1px solid #BBC1C5;*/
            }

            &-data {
                padding-left: 60px;
                min-height: 250px;
            }

            &-name {
                width: 330px;
                padding-bottom: 15px;
                padding-right: 35px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-size: 20px;
                font-weight: bold;
                font-family: 'Poppins', sans-serif;
                color: #000;
                & svg {
                    margin-right: 20px;
                    font-size: 20px;
                }
            }

            &-location {
                padding: 10px 0;
                display: flex;
                align-items: center;
                font-size: 16px;
                font-family: 'Poppins', sans-serif;
                color: #000;
                & svg {
                    margin-right: 20px;
                }
            }
            &-email {
                padding: 10px 0;
                display: flex;
                align-items: center;
                font-size: 16px;
                font-family: 'Poppins', sans-serif;
                color: #000;
                & svg {
                    margin-right: 20px;
                }
            }

            &-img {
                max-width: 175px;
                width: 100%;
                height: 175px;
                object-fit: cover;
                border-radius: 10px;
                position: relative;
                /*overflow: hidden;*/
                display: flex;
                justify-content: center;
                align-items: center;
                & .empty_user-img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    border-radius: 10px;
                    background-image: url("~@/assets/images/user_empty_img.svg");
                }
                & input {
                    opacity: 0;
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    cursor: pointer;
                }
                & .cover_img {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    border-radius: 10px;
                    background: no-repeat center / cover;
                    cursor: pointer;
                }
                & .remove_avatar {
                    position: absolute;
                    bottom: -50px;
                    font-size: 14px;
                    background: #E5EBF0;
                    color: #545454;
                    font-weight: 600;
                    border: none;
                    &:focus {
                        box-shadow: none;
                    }
                }
            }
        }
        &_inputs {
            padding: 20px 40px;
            & .input-item {
                display: flex;
                align-items: center;
                padding: 8px 0;
                & label {
                    font-size: 18px;
                    font-family: 'Poppins', sans-serif;
                    font-weight: 600;
                    color: #545454;
                    width: 215px;
                }
                & input {
                    width: 400px;
                    height: 44px;
                    border: 1px solid #09549860;
                    border-radius: 5px;
                }
            }
        }

        &_save-btn {
            padding: 65px 40px 40px;
            & .btn {
                width: 140px;
                height: 44px;
                background: transparent linear-gradient(180deg, #84EEEB 0%, #3AE4DF 100%) 0% 0% no-repeat padding-box;
                border-radius: 10px;
                border: none;
                color: $primary;
                font-weight: 600;
                &:focus-visible {
                    box-shadow: none;
                    outline: none;
                }
            }
        }
    }
}
.modal_content {
    padding: 20px;
    & .modal_title {
        color: #01315E;
        font-family: "Poppins", sans-serif;
        font-weight: 600;
        font-size: 22px;
    }
    & .input-item {
        display: flex;
        flex-direction: column;
        padding: 15px 0;
        & label {
            font-size: 16px;
            font-family: 'Poppins', sans-serif;
            font-weight: 600;
            color: #07054e;
            width: 215px;
        }
        & input {
            width: 400px;
            height: 44px;
            background: #F6F6F6 0 0 no-repeat padding-box;
            border-radius: 5px;
            &:focus {
                box-shadow: none;
                border: 1px solid #096BC4;
                border-radius: 3px;
            }
        }
    }
    & .text_container {
        display: flex;
        flex-direction: column;
        padding: 15px 0;
        & label {
            font-size: 16px;
            font-family: 'Poppins', sans-serif;
            font-weight: 600;
            color: #07054e;
            width: 215px;
        }
        & textarea {
            background: #F6F6F6 0 0 no-repeat padding-box;
            border-radius: 5px;
            &:focus {
                box-shadow: none;
                border: 1px solid #096BC4;
                border-radius: 3px;
            }
        }
    }
    & .edit-btn_container {
        padding-top: 15px;
        & button {
            width: 200px;
        }
    }
}
</style>