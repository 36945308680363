<template>
    <div>
        <div class="page_content">
            <div class="page_head">
<!--                <div class="back_btn" @click="goBack()">-->
<!--                    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="18.243" viewBox="0 0 28 18.243">-->
<!--                        <g id="arrow-left_8_" data-name="arrow-left (8)" transform="translate(-3.5 -2.879)">-->
<!--                            <line id="Line_1" data-name="Line 1" x1="25" transform="translate(5 12)" fill="none" stroke="#5454ee" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>-->
<!--                            <path id="Path_9" data-name="Path 9" d="M12,19,5,12l7-7" fill="none" stroke="#5454ee" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>-->
<!--                        </g>-->
<!--                    </svg>-->
<!--                </div>-->
                <div class="page_title">
                    New Meeting Note
                </div>
            </div>
            <div class="top_buttons-container">
                <div class="top_buttons-first_group">
<!--                    //retrive-->
                        <div class="select_arrow">
                            <b-form-select v-model="retrieve" @change="retrieveChange">
                                <template #first>
                                    <b-form-select-option :value="null" disabled>Retrieve</b-form-select-option>
                                </template>
                                <option
                                        v-for="(option, id) in templateMeetings"
                                        :key="id"
                                        :value="option.id"
                                        :title="option.project_title || null"
                                >
                                    {{option.project_title}}
                                </option>
                            </b-form-select>
                            <div class="arrow_down"></div>
                        </div>

                </div>
                <div class="success_msg" v-if="success_msg">
                    New meeting note has created successfully
                </div>
                <div class="top_buttons-last_group">
                    <b-button class="top_btn" @click="saveMeetingNoteData()">Saved</b-button>
                    <b-button class="top_btn" @click="exitModal()" id="exit_btn">Exit</b-button>
                    <b-button class="top_btn" @click="saveExit()">Saved and Exit</b-button>
                </div>
            </div>


            <div class="meeting_note-content">

                <div class="input_content">
                    <div class="input_item">
                        <label>Subject Title</label>
                        <b-form-input
                                v-model="meetingNoteData.subject_title"
                                type="text"
                                trim
                        ></b-form-input>
                        <span v-if="meetingNoteErrors['subject_title']" class="text_danger mt-1">{{meetingNoteErrors['subject_title'][0]}}</span>
                    </div>
                    <div class="input_item">
                        <label>Project Title</label>
                        <b-form-input
                                v-model="meetingNoteData.project_title"
                                type="text"
                                trim
                        ></b-form-input>
                    </div>
                </div>

                <div class="input_content">
                    <div class="input_item">
                        <label>Workspace</label>
                        <div class="select_arrow">
                            <b-form-select v-model="meetingNoteData.work_space" @change="workspaceChange">
                                <option
                                        v-for="(option, id) in workspaces"
                                        :key="id"
                                        :value="option.id"
                                        :title="option.name || null"
                                >
                                    {{option.name}}
                                </option>
                            </b-form-select>
                            <div class="arrow_down"></div>
                            <span v-if="meetingNoteErrors['work_space_id']" class="text_danger mt-1">{{meetingNoteErrors['work_space_id'][0]}}</span>
                        </div>
                    </div>
                    <div class="input_item">
                        <label>Channel</label>
                        <div class="select_arrow">
                            <b-form-select v-model="meetingNoteData.channel" @change="channelChange" :disabled="!channels.length">
                                <option
                                        v-for="(option, id) in channels"
                                        :key="id"
                                        :value="option.id"
                                        :title="option.name || null"
                                >
                                    {{option.name}}
                                </option>
                            </b-form-select>
                            <div class="arrow_down"></div>
                            <span v-if="meetingNoteErrors['channel_id']" class="text_danger mt-1">{{meetingNoteErrors['channel_id'][0]}}</span>
                        </div>
                    </div>
                </div>

                <div class="input_content">
                    <div class="input_item">
                        <label>Project Number</label>
                        <b-form-input
                                v-model="meetingNoteData.project_number"
                                type="number"
                                trim
                        ></b-form-input>
                    </div>
                    <div class="input_item">
                        <label>Meeting Note Document Number</label>
                        <b-form-input
                                v-model="meetingNoteData.meeting_note_document_number"
                                type="number"
                                trim
                        ></b-form-input>
                        <span v-if="meetingNoteErrors['meeting_note_document_number']" class="text_danger mt-1">{{meetingNoteErrors['meeting_note_document_number'][0]}}</span>
                    </div>
                </div>

                <div class="input_content">
                    <div class="input_item">
                        <label>Venue</label>
                        <b-form-input
                                v-model="meetingNoteData.venue"
                                type="text"
                                trim
                        ></b-form-input>
                    </div>
                    <div class="input_item">
                        <label>Date of Meeting</label>
                        <b-form-input
                                v-model="meetingNoteData.date_of_meeting"
                                type="date"
                                trim
                        ></b-form-input>
                    </div>
                </div>

                <div class="input_content">
                    <div class="input_item time">
                        <label>Start Time</label>
                        <b-form-timepicker v-model="meetingNoteData.start_time" locale="en"></b-form-timepicker>
                        <span v-if="meetingNoteErrors['start_time']" class="text_danger mt-1">{{meetingNoteErrors['start_time'][0]}}</span>
                    </div>
                    <div class="input_item time">
                        <label>End Time</label>
                        <b-form-timepicker v-model="meetingNoteData.end_time" locale="en"></b-form-timepicker>
                        <span v-if="meetingNoteErrors['end_time']" class="text_danger mt-1">{{meetingNoteErrors['end_time'][0]}}</span>
                    </div>
                </div>

                <div class="input_content">
                    <div class="input_item long">
                        <label>Work Space Users</label>
                        <div class="select_arrow">
                            <Multiselect
                                    v-model="channelUsers"
                                    :options="copiedItems"
                                    label="name"
                                    track-by="id"
                                    placeholder=""
                                    :disabled="!meetingNoteData.channel"
                                    :hide-selected="true"
                                    multiple
                            ></Multiselect>

                            <div class="arrow_down"></div>
<!--                            <span v-if="createTaskErrors['user_id']" class="text_danger mt-2">{{createTaskErrors['user_id'][0]}}</span>-->
                        </div>
                    </div>
                </div>

                <div class="table_container">
                    <b-table hover :items="channelUsers" :fields="fields"  responsive="sm">
                        <template v-slot:cell(index)="data">
                            <div class="">
                                {{data.index+1}}
                            </div>
                        </template>

                        <template v-slot:cell(name)="data">
                            <div class="item_name">
                                {{data.item.name}}
                            </div>
                        </template>

                        <template v-slot:cell(position)="data">
                            <div class="item_input">
                                <b-form-input
                                        v-model="data.item.position"
                                        type="text"
                                        trim
                                ></b-form-input>
                            </div>
                        </template>
                        <template v-slot:cell(organization)="data">
                            <div class="item_input">
                                <b-form-input
                                        v-model="data.item.organization"
                                        type="text"
                                        trim
                                ></b-form-input>
                            </div>
                        </template>

                        <template #cell(present)="data">
                            <div>
                                <b-form-checkbox v-model="data.item.present"/>
                            </div>
                        </template>

                    </b-table>
                </div>
                <div class="table_container">
                    <b-button class="add_button" @click="addRowHandler">Add Row</b-button>

                    <b-table class="b-table" :items="tableItems" :fields="meeting_fields" fixed>
                        <template v-for="(field, index) in meeting_fields" #[`cell(${field.key})`]="data">
                            <div class="d-flex justify-content-center">
                                <b-form-datepicker v-if="field.type === 'date' && tableItems[data.index].isEdit" :key="index" :type="field.type" :value="tableItems[data.index][field.key]" @input="(value) => inputHandler(value, data.index, field.key)"></b-form-datepicker>
                                <div class="select_arrow" v-else-if="field.type === 'select' && tableItems[data.index].isEdit">
                                    <b-form-select :key="index" :value="tableItems[data.index][field.key]" @input="(value) => inputHandler(value, data.index, field.key)">
                                        <option
                                            v-for="(option, id) in field.options"
                                            :key="id"
                                            :value="option.id"
                                            :title="option.label || null"
                                        >
                                            {{option.label}}
                                        </option>
                                    </b-form-select>
                                    <div class="arrow_down"></div>
                                </div>
                                    <b-button class="edit_done-btn" :key="index" v-else-if="field.type === 'edit'" @click="editRowHandler(data)">
                                    <span v-if="!tableItems[data.index].isEdit">Edit</span>
                                    <span v-else>Done</span>
                                </b-button>
                                <b-form-input v-else-if="field.type && tableItems[data.index].isEdit" :key="index" :type="field.type" :value="tableItems[data.index][field.key]" @blur="(e) => inputHandler(e.target.value, data.index, field.key)"></b-form-input>
                                <span :key="index" v-else>{{data.value}}</span>
                            </div>

                        </template>
                    </b-table>

                </div>
                <div class="table_container checked_items" v-if="getCheckedUsers.length">
                    <b-table hover :items="getCheckedUsers" :fields="checkedFields"  responsive="sm">
                        <template v-slot:cell(index)="data">
                            <div class="">
                                {{data.index+1}}
                            </div>
                        </template>

                        <template v-slot:cell(name)="data">
                            <div class="item_name">
                                {{data.item.name}}
                            </div>
                        </template>

                        <template v-slot:cell(position)="data">
                            <div class="item_name">
                                {{data.item.position}}
                            </div>
                        </template>
                        <template v-slot:cell(organization)="data">
                            <div class="item_name">
                               {{data.item.organization}}
                            </div>
                        </template>

                        <template #cell(signatory)="data">

                        </template>

                    </b-table>
                </div>
<!--                <div class="meeting_save-btn_container">-->
<!--                    <b-button class="meeting_save-btn" disabled>Completion of Meeting Note Signatory-->
<!--                    </b-button>-->

<!--                </div>-->
            </div>

<!--Exit modal start-->
            <b-modal ref="exit_modal" centered hide-header hide-footer hide-header-close>
                <div class="modal_content">
                    <b-icon icon="x" class="x_btn" aria-hidden="true" @click="exitModal()"></b-icon>
                    <div class="d-flex flex-column">
                        <div class="modal_title">
                            No actions will be transferred to the Task Notification
                        </div>
                        <div class="modal_text">
                            To transfer the tasks to Task Notification, please "Saved and Exit."
                        </div>
                        <div class="modal_buttons">
                            <div class="cancel_btn" @click="exitModal()">Cancel</div>
                            <b-button class="done_btn ml-3" @click="goBack()">Done</b-button>
                        </div>
                    </div>
                </div>
            </b-modal>
<!--Exit modal end-->

        </div>
    </div>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";
    // 'Open', 'Closed', 'Info'
    export default {
        name: "NewMeetingNote",
        data() {
            return {
                fields: [
                    {
                        key: 'index',
                        label: 'No',
                    },
                    {
                        key: 'name',
                        label: 'Name',
                    },
                    {
                        key: 'position',
                        label: 'Position',
                    },
                    {
                        key: 'organization',
                        label: 'Organization',
                    },
                    {
                        key: 'present',
                        label: 'Present',
                    },
                ],
                checkedFields: [
                    {
                        key: 'index',
                        label: 'No',
                    },
                    {
                        key: 'name',
                        label: 'Name',
                    },
                    {
                        key: 'position',
                        label: 'Position',
                    },
                    {
                        key: 'organization',
                        label: 'Organization',
                    },
                    {
                        key: 'signatory',
                        label: 'Signatory',
                    },
                ],
                meeting_fields: [
                    { key: "item_details", label: "Item Details", type: "text" },
                    { key: "action_by_id", label: "Action By", type: "select", options: [] },
                    { key: "due_date", label: "Due Date", type: "date" },
                    { key: "status", label: "Status", type: "select", options: [] },
                    { key: "edit", label: "", type: "edit" }
                ],
                action_by_status: [
                    { id: 0, label: 'Open'},
                    { id: 1, label: 'Closed'},
                    { id: 2, label: 'Info'},
                ],
                tableItems: [],
                meetingNoteData: {
                    work_space: null,
                    channel: null,
                    subject_title: null,
                    project_title: null,
                    project_number: null,
                    meeting_note_document_number: null,
                    venue: null,
                    date_of_meeting: null,
                    start_time: null,
                    end_time: null,
                    copied_id: [],
                    users: [],
                    approvals: [],
                    minutes_of_meetings: []

                },
                meetingNoteErrors: [],
                channels: [],
                copiedItems: [],
                success_msg: false,
                channelUsers: [],
                actionByUsers: [],
                actionChoosenUsers: [],
                retrieve: null,
                templateMeetings: []
            }
        },
        created() {
            this.getAllWorkspaces()
            this.getMeetings()
            .then(res => {
                if(res.status === 200) {
                    this.templateMeetings = res.data.success
                }
            })
        },
        computed: {
            ...mapGetters(['workspaces']),
            getCheckedUsers() {
                return this.channelUsers.filter((item) => {
                    return item.present === true;
                });
            },

        },
        methods: {
            ...mapActions([
                'getAllWorkspaces',
                'createMeetingNote',
                'getWorkspaceUsers',
                'getChannelMembers',
                'getMeetings',
                'taskCreate'
            ]),
            goBack() {
                this.$router.go(-1)
            },

            workspaceChange(args) {
                var workSpace = this.workspaces.find((item) => {
                    return item.id == args
                })
                this.channels = workSpace.channels
                this.meetingNoteData.work_space = args
            },
            channelChange(args) {
                this.meetingNoteData.channel = args
                this.getChannelMembers(args)
                    .then((res) => {
                        if(res.status === 200) {
                            res.data.success.forEach((item) => {
                                item.position = ''
                                item.organization = ''
                                item.present = false
                                this.channelUsers.push(item)
                            })
                            this.channelUsers.forEach(item => {
                                let actionItem = {
                                    id: item.id,
                                    label: item.name
                                }
                                this.meeting_fields[1].options.push(actionItem)
                                // this.actionByUsers
                            })
                            this.meeting_fields[3].options = this.action_by_status

                        }
                    })
                this.getWorkspaceUsers(this.meetingNoteData.work_space)
                    .then((res) => {
                        if (res.status === 200) {
                            res.data.success.forEach((item) => {
                                this.meetingNoteData.copied_id.push(item.id)
                            })
                            this.actionChoosenUsers = res.data.success
                            let mapped = this.channelUsers.map(ele => ele.id);
                             res.data.success.map((item) => {
                                if(mapped.includes(item.id)) {
                                    return
                                } else {
                                    item.position = ''
                                    item.organization = ''
                                    item.present = false
                                    this.copiedItems.push(item)
                                   this.actionByUsers.push(item) //action
                                }
                            })

                        }
                    })
            },
            saveMeetingNoteData() {
                this.meetingNoteErrors = []
                this.channelUsers.forEach(item => {
                    if(item.present === true) {
                        let sendUser = {
                            user_id: item.id,
                            position: item.position,
                            organization: item.organization,
                            present: item.present
                        }
                        let approvalData = {
                            user_id: item.id,
                            position: item.position,
                            organization: item.organization,
                            signatory: null
                        }
                        this.meetingNoteData.users.push(sendUser)
                        this.meetingNoteData.approvals.push(approvalData)
                    }
                })
                this.meetingNoteData.minutes_of_meetings = this.tableItems
                this.tableItems.forEach(item => {
                    let taskData = {
                        work_space: this.meetingNoteData.work_space,
                        channel: this.meetingNoteData.channel,
                        task_title: this.meetingNoteData.meeting_note_document_number.toString(),
                        task_description: item.item_details,
                        selectedAdmins: [],
                        user_id: item.action_by_id,
                        req_date: this.meetingNoteData.date_of_meeting,
                        due_date: item.due_date,
                        priority: 1
                    }
                    this.taskCreate(taskData)
                    .then(res => {
                        if(res.status === 200) {
                            console.log('Task created')
                        }
                    })
                })
                this.createMeetingNote(this.meetingNoteData)
                .then(res => {
                    if(res.status === 200) {
                        this.success_msg = true
                        setTimeout(() => {
                            this.success_msg = false
                        }, 5000)
                    } else {
                        this.meetingNoteErrors = res.response.data.errors
                    }
                })
            },
            saveExit() {
                this.meetingNoteErrors = []
                this.channelUsers.forEach(item => {
                    if(item.present === true) {
                        let sendUser = {
                            user_id: item.id,
                            position: item.position,
                            organization: item.organization,
                            present: item.present
                        }
                        let approvalData = {
                            user_id: item.id,
                            position: item.position,
                            organization: item.organization,
                            signatory: null
                        }
                        this.meetingNoteData.users.push(sendUser)
                        this.meetingNoteData.approvals.push(approvalData)
                    }
                })
                this.meetingNoteData.minutes_of_meetings = this.tableItems
                this.tableItems.forEach(item => {
                    let taskData = {
                        work_space: this.meetingNoteData.work_space,
                        channel: this.meetingNoteData.channel,
                        task_title: this.meetingNoteData.meeting_note_document_number.toString(),
                        task_description: item.item_details,
                        selectedAdmins: [],
                        user_id: item.action_by_id,
                        req_date: this.meetingNoteData.date_of_meeting,
                        due_date: item.due_date,
                        priority: 1
                    }
                    this.taskCreate(taskData)
                        .then(res => {
                            if(res.status === 200) {
                                console.log('Task created')
                            }
                        })
                })
                this.createMeetingNote(this.meetingNoteData)
                    .then(res => {
                        if(res.status === 200) {
                            this.success_msg = true
                            setTimeout(() => {
                                this.success_msg = false
                                this.goBack()
                            }, 5000)
                        } else {
                            this.meetingNoteErrors = res.response.data.errors
                        }
                    })

            },

            exitModal() {
                this.$refs['exit_modal'].toggle('#exit_btn')
            },

            editRowHandler(data) {
                this.tableItems[data.index].isEdit = !this.tableItems[data.index].isEdit;
            },
            inputHandler(value, index, key) {
                if(key === 'action_by_id') {
                    this.tableItems[index][key] = value;
                    // this.actionChoosenUsers.forEach(item => {
                    //     if(item.id === value) {
                    //         this.tableItems[index][key] = item.name;
                    //
                    //
                    //     }
                    // })
                } else if(key === 'status') {
                    this.tableItems[index][key] = value;
                    // switch (value) {
                    //     case 0: this.tableItems[index][key] = 'Open'; break;
                    //     case 1: this.tableItems[index][key] = 'Closed'; break;
                    //     case 2: this.tableItems[index][key] = 'Info'; break;
                    // }
                } else {
                    this.tableItems[index][key] = value;
                }
                this.$set(this.tableItems, index, this.tableItems[index]);
                // this.$emit("input", this.tableItems);
            },
            addRowHandler() {
                const newRow = this.fields.reduce((a, c) => ({...a, [c.key]: null}) ,{})
                newRow.isEdit = true;
                this.tableItems.unshift(newRow);
                // this.$emit('input', this.tableItems);
            },
            retrieveChange(args) {
                var retrieve = this.templateMeetings.find((item) => {
                    return item.id == args
                })
                // console.log(retrieve)
                this.meetingNoteData.subject_title = retrieve.subject_title
                this.meetingNoteData.project_title = retrieve.project_title
                this.workspaceChange(retrieve.work_space_id)
                this.channelChange(retrieve.channel_id)
                this.meetingNoteData.project_number = retrieve.project_number
                this.meetingNoteData.meeting_note_document_number = retrieve.meeting_note_document_number
                this.meetingNoteData.venue = retrieve.venue
                this.meetingNoteData.date_of_meeting = new Date(retrieve.date_of_meeting * 1000 ).toLocaleString("en-CA", {timeZoneName: "short"}).split(',')[0]
                this.meetingNoteData.start_time =  new Date(retrieve.start_time).toISOString().substr(11, 8)
                this.meetingNoteData.end_time =  new Date(retrieve.end_time).toISOString().substr(11, 8)
            }
        }
    }
</script>

<style scoped lang="scss">
.page {
    &_head {
        padding-bottom: 20px;
        display: flex;
        flex-direction: column;
        & .back_btn {
            width: 40px;
            cursor: pointer;
        }
    }
    &_title {
        padding-top: 10px;
        color: $black;
        font-weight: 600;
        font-size: 25px;
    }
    &_content {
        padding: 10px 40px 20px 1px;
        & .top_buttons {
            &-container {
                display: flex;
                justify-content: space-between;
                & .top_btn {
                    color: $primary;
                    height: 42px;
                    font-weight: 600;
                    background: transparent linear-gradient(180deg, #84EEEB 0%, #3AE4DF 100%) 0% 0% no-repeat padding-box;
                    border-radius: 10px;
                    padding: 0 20px;
                    border: none;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    &:focus,
                    &:active{
                        box-shadow: none;
                        color: $primary;
                    }
                }
                & .success_msg {
                    color: green;
                    font-weight: 600;
                    font-size: 20px;
                    padding: 0 30px;
                }
            }
            &-first_group {
                width: 270px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                & .select_arrow {
                    position: relative;
                    & .arrow_down {
                        position: absolute;
                        top: 15px;
                        right: 15px;
                        width: 14px;
                        height: 10px;
                        pointer-events: none;
                        background: url("~@/assets/images/chevron_down.svg") right  no-repeat;
                    }
                }
                & select {
                    width: 270px;
                    background: #84EEEB 0 0 no-repeat padding-box;
                    border-radius: 4px;
                    padding-right: 5px;
                }
            }
            &-last_group {
                width: 360px;
                display: flex;
                align-items: center;
                justify-content: space-between;

            }
        }
        & .meeting_note {
            &-content {
                padding-top: 20px;
               & .input_content {
                   width: 580px;
                   display: flex;
                   justify-content: space-between;
                   margin-bottom: 20px;
                   & .input_item {
                       padding: 5px 0;
                       width: 270px;
                       flex-direction: column;
                       &.long {
                           width: 100%;
                       }
                       & .field_item {
                           font-size: 20px;
                           font-weight: 600;
                           color: $light_purple;
                       }
                       &.send_alert {
                           width: 100%;
                       }
                       &.insert_milestone {
                           width: 100%;
                           & .radio_item {
                               padding-top: 10px;
                           }
                       }
                       & .edit_workspace {
                           padding: 5px 0;
                           font-size: 22px;
                           font-weight: 600;
                           color: $primary;
                       }
                       & .select_arrow {
                           position: relative;
                           & .arrow_down {
                               position: absolute;
                               top: 15px;
                               right: 15px;
                               width: 14px;
                               height: 10px;
                               pointer-events: none;
                               background: url("~@/assets/images/chevron_down.svg") right  no-repeat;
                           }
                       }
                       & label {
                           color: $primary;
                           font-weight: 600;
                           font-size: 14px;
                       }
                       & input {
                           border: none;
                           background: $greyBlue 0 0 no-repeat padding-box;
                           border-radius: 3px;
                           &:focus {
                               box-shadow: none;
                               outline: none;
                           }
                       }
                       input[type=number] {
                           -moz-appearance: textfield !important;
                       }
                       input::-webkit-outer-spin-button,
                       input::-webkit-inner-spin-button {
                           -webkit-appearance: none !important;
                           margin: 0 !important;
                       }
                       & select {
                           background: $greyBlue 0 0 no-repeat padding-box;
                           border-radius: 4px;
                           padding-right: 5px;
                       }
                       ::v-deep .multiselect {
                           width: 100%;
                           height: 40px;
                           background: $greyBlue 0 0 no-repeat padding-box;
                           border-radius: 4px;
                           cursor: pointer;
                       }
                       ::v-deep .multiselect__tags {
                           background: transparent;
                           padding-left: 0;
                           border: none;
                       }
                       ::v-deep .multiselect__tag {
                           background: transparent;
                           color: $black;
                       }
                       ::v-deep .multiselect__tag-icon:after {
                           color: red;
                       }
                       ::v-deep .multiselect__tag-icon:hover {
                           background: transparent;
                       }
                       ::v-deep .multiselect__option--highlight,
                       ::v-deep .multiselect__option--highlight:after {
                           background: $lightBlue;
                           color: $black;
                       }
                       ::v-deep .multiselect__select:before {
                           border-width: 0;
                           border-style: none;
                       }
                       ::v-deep .multiselect__input {
                           background: $greyBlue;
                           margin: 0;
                           padding: 0;
                       }
                       &.time {
                            & .b-form-timepicker {
                                background-color: #E7E8F5;
                                border: none;
                                ::v-deep svg {
                                    fill: $light_purple !important;
                                }
                            }
                       }
                   }

               }
                & .meeting_save-btn {
                    color: $primary;
                    height: 42px;
                    font-weight: 600;
                    background: transparent linear-gradient(180deg, #84EEEB 0%, #3AE4DF 100%) 0% 0% no-repeat padding-box;
                    border-radius: 10px;
                    padding: 0 20px;
                    border: none;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    &:focus,
                    &:active{
                        box-shadow: none;
                        color: $primary;
                    }
                    &_container {
                        padding: 30px 0;
                        display: flex;
                        align-items: center;
                    }
                }
                // table
                & .table_container {
                    font-size: 13px;
                    & .add_button {
                        color: $primary;
                        height: 42px;
                        font-weight: 600;
                        background: transparent linear-gradient(180deg, #84EEEB 0%, #3AE4DF 100%) 0% 0% no-repeat padding-box;
                        border-radius: 10px;
                        padding: 0 20px;
                        margin: 30px 0 15px;
                        border: none;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        &:focus,
                        &:active{
                            box-shadow: none;
                            color: $primary;
                        }
                    }
                    ::v-deep thead {
                        height: 60px;
                        color: $primary;
                        background: #E7E8F5 0 0 no-repeat padding-box;
                        th {
                            vertical-align: middle;
                            max-width: 100px;
                            text-align: center;
                        }
                    }
                    ::v-deep tbody {
                        tr {
                            td{
                                vertical-align: middle;
                                text-align: center;
                                text-align: -webkit-center;
                            }
                        }
                    }
                    & .item {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        height: 100%;
                    }
                    & .item_input {
                        width: 270px;
                        height: 38px;
                        padding: 3px 0;
                        input {
                            height: 32px;
                        }
                    }
                    & .item_signature {
                        width: 180px;
                        display: flex;
                        align-items: center;
                        & .signature_btn {
                            width: 60px;
                            height: 20px;
                            color: $primary;
                            font-size: 12px;
                            font-weight: 600;
                            background: transparent linear-gradient(180deg, #84EEEB 0%, #3AE4DF 100%) 0% 0% no-repeat padding-box;
                            border-radius: 10px;
                            padding: 0 5px;
                            border: none;
                            margin: 10px 0;
                            &-content {
                                display: flex;
                                flex-direction: column;
                                justify-content: space-between;
                                padding: 5px 10px;
                            }
                        }
                        ::v-deep canvas {
                            border: 1px solid $lightBlue !important;
                        }
                    }
                    &.checked_items {
                        margin-top: 40px;
                    }
                    input {
                        background: $lightBlue;
                        border: none;
                        border-radius: 3px;
                    }
                    & .select_arrow {
                        position: relative;
                        width: 240px;
                        margin-right: 20px;
                        & .arrow_down {
                            position: absolute;
                            top: 14px;
                            right: 15px;
                            width: 14px;
                            height: 10px;
                            pointer-events: none;
                            background: url("~@/assets/images/chevron_down.svg") right  no-repeat;
                        }
                    }
                    select {
                        background: $lightBlue;
                        position: relative;
                    }
                    ::v-deep .b-form-datepicker  {
                        background: $lightBlue;
                        & .btn svg {
                            fill: $light_purple;
                        }
                    }
                    & .edit_done-btn {
                        color: $primary;
                        height: 40px;
                        font-weight: 600;
                        background: transparent linear-gradient(180deg, #84EEEB 0%, #3AE4DF 100%) 0% 0% no-repeat padding-box;
                        border-radius: 10px;
                        padding: 0 20px;
                        border: none;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        &:focus,
                        &:active{
                            box-shadow: none;
                            color: $primary;
                        }
                    }
                }
            }
        }

    }
}

::v-deep .custom-select {
    border: none;
}
/*modal start*/
::v-deep .modal-content {
    border: none !important;
    width: 700px;
    ::v-deep .modal-body {
        width: 600px !important;
    }
}
::v-deep .modal_content {
    border: none !important;
    width: 600px;
    position: relative;
    ::v-deep .modal-body {
        width: 600px !important;
    }
    & .x_btn {
        position: absolute;
        top: 0;
        right: 0;
        width: 25px;
        height: 25px;
        cursor: pointer;
        color: $light_purple;
    }
    & .modal_title {
        width: 100%;
        padding: 20px 0;
        font-size: 25px;
        color: $grey;
        border-bottom: 1px solid #CACDF6;
        &.attachment {
            padding-left: 10px;
            color: $purple;
        }
    }
    & .modal_text {
        font-size: 16px;
        color: $grey;
        padding: 25px 0;
    }
    & .modal_buttons {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 15px 0 30px;
        & .cancel_btn {
            color: $primary;
            font-weight: 600;
            padding: 10px 0;
            cursor: pointer;

        }
        & .done_btn {
            width: 110px;
            height: 44px;
            background: transparent linear-gradient(180deg, #84EEEB 0%, #3AE4DF 100%) 0% 0% no-repeat padding-box;
            border-radius: 10px;
            color: $primary;
            font-weight: 600;
            border: none;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    & .description_content {
        padding: 10px 0 5px;
        & .description_subtitle {
            font-weight: 500;
            font-size: 18px;
            padding: 0 15px;
            color: $primary;

        }
        & .description_text {
            padding: 10px 20px 5px;
            border-bottom: 1px solid #e9e9e9;
        }

    }
}
/* modal end*/
input[type="date"]::-webkit-calendar-picker-indicator {
    background: url("~@/assets/images/calendar.svg") center/80% no-repeat;
    cursor: pointer;
}
input[type="date"]::-webkit-calendar-picker-indicator:hover {
    background: url("~@/assets/images/calendar.svg") center/80% no-repeat;
}
::v-deep .table {
    border-bottom: 1px solid #dee2e6;
}
</style>