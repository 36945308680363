<template>
  <div>
    <NavBar />
    <div class="home">
      Coming Soon
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import NavBar from "../components/NavBar";

export default {
  name: 'Home',
  components: {
    NavBar

  }
}
</script>
<style scoped lang="scss">

  .home {
    width: 100%;
    height: calc(100vh - 80px);
    background: transparent linear-gradient(180deg, #7882F3 0%, #7882F3 36%, #767FF2 57%, #6F76F1 69%, #6468F0 78%, #5454EE 99%, #5352EE 100%) 0% 0% no-repeat padding-box;
    display: flex;
    justify-content: center;
    padding-top: 70px;
    font-size: 50px;
    font-weight: 600;
  }
</style>
