<template>
    <div>
        <div class="page_content">
            <div class="page_head">
                <div class="page_title">
                    Meeting Notes
                </div>
                <div class="page_head-drop-btn">
                    <div class="input_item">
                        <div class="select_arrow">
                            <b-form-select @change="workspaceChange">
<!--                                <template #first>-->
<!--                                    <b-form-select-option :value="null" disabled>Select Workspace</b-form-select-option>-->
<!--                                </template>-->
                                <option
                                        v-for="(option, id) in menuWorkspaces"
                                        :key="id"
                                        :value="option.id"
                                        :title="option.name || null"
                                >
                                    {{option.name}}
                                </option>
                            </b-form-select>
                            <div class="arrow_down"></div>
                        </div>
                    </div>

                    <div class="input_item">
                        <div class="select_arrow">
                            <b-form-select @change="channelChange" :disabled="!channels.length">
<!--                                <template #first>-->
<!--                                    <b-form-select-option :value="null" disabled>Select Channel</b-form-select-option>-->
<!--                                </template>-->
                                <option
                                        v-for="(option, id) in channels"
                                        :key="id"
                                        :value="option.id"
                                        :title="option.name || null"
                                >
                                    {{option.name}}
                                </option>
                            </b-form-select>
                            <div class="arrow_down"></div>
                        </div>
                    </div>

                    <b-button class="create_btn" @click="meetingNoteCreate()">Create New Meeting Note</b-button>
                </div>
            </div>
            <div class="notes_table">
                <div class="table_title">
                    {{noteData.work_space_name}}  >> {{noteData.channel_name}}
                </div>
                <div class="table_container">
                    <b-table hover :items="meeting_notes" :fields="fields"  responsive="sm">
                        <template v-slot:cell(index)="data">
                            <div class="">
                                {{data.index+1}}
                            </div>
                        </template>

                        <template v-slot:cell(title)="data">
                            <div class="item_name">
                                {{data.item.subject_title}}
                            </div>
                        </template>

                        <template v-slot:cell(project_title)="data">
                            <div class="">
                                {{data.item.project_title}}
                            </div>
                        </template>

                        <template v-slot:cell(recorder)="data">
                            <div class="">
                                {{data.item.recorder.name}}
                            </div>
                        </template>

                        <template v-slot:cell(n_date)="data">
                            <div class="">
                                {{getDate(data.item.date_of_meeting)}}
                            </div>
                        </template>

                        <template v-slot:cell(attachment)="data" >
                            <div v-if="logUserData" class="d-flex justify-content-center">
                                <div v-if="logUserData.id !== data.item.recorder.id" class="view_btn" @click="viewAttachment(data.item)" id="attachment-btn"></div>
                            </div>
                        </template>
                        <template v-slot:cell(showEdit)="data">
                            <div v-if="logUserData" class="d-flex justify-content-center">
                                <router-link :to="{path: '/meetingNoteDetails/' + data.item.id}">
                                    <div v-if="logUserData.id === data.item.recorder.id" class="edit_btn"></div>
                                </router-link>
                            </div>

                        </template>

                        <template v-slot:cell(delete)="data">
                            <div v-if="logUserData" class="d-flex justify-content-center">
                                <div v-if="logUserData.id === data.item.recorder.id" class="trash_button" id="remove_note-btn" @click="meetingNoteDelete(data.item)"></div>
                            </div>
                        </template>
                    </b-table>
                </div>
            </div>
        </div>
<!--Meeting Notes Attachment modal start-->
        <b-modal ref="attachment-modal" centered hide-header hide-footer hide-header-close>
            <div class="modal_content">
                <b-icon icon="x" class="x_btn" aria-hidden="true" @click="attachmentModal()"></b-icon>
                <div class="d-flex flex-column" v-if="modalData">
                    <div class="modal_title attachment">
                        {{modalData.subject_title}}

                    </div>
                    <div class="description_content">
                        <div class="description_subtitle">
                            Project Title
                        </div>
                        <div class="description_text">
                            {{modalData.project_title}}
                        </div>
                    </div>
                    <div class="description_content">
                        <div class="description_subtitle">
                            Work Space
                        </div>
                        <div class="description_text">
                            {{modalData.work_space_name.name}}
                        </div>
                    </div>
                    <div class="description_content">
                        <div class="description_subtitle">
                            Channel
                        </div>
                        <div class="description_text">
                            {{modalData.channel_name.name}}
                        </div>
                    </div>
                    <div class="description_content">
                        <div class="description_subtitle">
                            Recorder
                        </div>
                        <div class="description_text">
                            {{modalData.recorder.name}}
                        </div>
                    </div>

                    <div class="description_content">
                        <div class="description_subtitle">
                            Project Number
                        </div>
                        <div class="description_text">
                            {{modalData.project_number}}
                        </div>
                    </div>

                    <div class="description_content">
                        <div class="description_subtitle">
                            Venue
                        </div>
                        <div class="description_text">
                            {{modalData.venue}}
                        </div>
                    </div>

                    <div class="description_content">
                        <div class="description_subtitle">
                            Date of Meeting
                        </div>
                        <div class="description_text">
                            {{getDate(modalData.date_of_meeting)}}
                        </div>
                    </div>
                    <div class="description_content">
                        <div class="description_subtitle">
                            Signatory
                        </div>

                        <div class="item_signature" v-if="!approval_data.signatory">
                            <VueSignaturePad width="200px" height="120px" ref="signaturePad" />
                            <div class="signature_btn-content">
                                <b-button class="signature_btn" @click="save">Save</b-button>
                                <b-button class="signature_btn" @click="undo">Undo</b-button>
                                <b-button class="signature_btn" @click="clear">Clear</b-button>
                            </div>
                        </div>
                        <img v-else :src="approval_data.signatory" alt="">
                    </div>
                    <b-icon icon="filter-left" class="mr-2"  aria-hidden="true"></b-icon>

                </div>
            </div>
        </b-modal>
<!--Meeting Notes Attachment modal end-->

<!--delete modal start -->
        <b-modal ref="meeting_note_delete-modal" centered hide-header hide-footer hide-header-close>
            <div class="modal_content">
                <b-icon icon="x" class="x_btn" aria-hidden="true" @click="deleteMeetingNoteModal()"></b-icon>
                <div class="d-flex flex-column">
                    <div class="modal_title">
                        You are about to delete this meeting note
                    </div>
                    <div class="modal_text">
                        Do you want to proceed?
                    </div>
                    <div class="modal_buttons">
                        <div class="cancel_btn" @click="deleteMeetingNoteModal()">Cancel</div>
                        <b-button class="delete_btn ml-3" @click="removeMeetingNote()">Delete</b-button>
                    </div>

                </div>
            </div>
        </b-modal>
<!--delete modal end -->

    </div>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";

    export default {
        name: "Notes",

        data() {
            return {
                channels: [],
                fields: [
                    {
                        key: 'index',
                        label: 'No',
                    },
                    {
                        key: 'title',
                        label: 'Subject Title',
                    },
                    {
                        key: 'project_title',
                        label: 'Project Title',
                    },
                    {
                        key: 'recorder',
                        label: 'Recorder',
                    },
                    {
                        key: 'n_date',
                        label: 'Date',
                    },
                    {
                        key: 'attachment',
                        label: 'Attachment',
                    },
                    {
                        key: 'showEdit',
                        label: 'Show/Edit',
                    },
                    {
                        key: 'delete',
                        label: 'Delete',
                    },
                ],
                meeting_notes: [],
                noteData: {
                    work_space_id: null,
                    work_space_name: null,
                    channel_id: null,
                    channel_name: null
                },
                deleteMeetingNoteId: null,
                modalData: null,
                logUserData: null,
                approval_data: {
                    approval_id: null,
                    signatory: null
                },

            }
        },
        computed: {
            ...mapGetters(['workspaces', 'menuWorkspaces', 'userData']),
        },
        created() {
            this.getAllMenuWorkspaces()

        },
        mounted() {
            this.logUserData = JSON.parse(localStorage.getItem('userData'))
        },
        methods: {
            ...mapActions([
                'getAllMenuWorkspaces',
                'getMeetingNote',
                'meetingNoteRemove',
                'sendSignatory'
            ]),
            getDate(item) {
                return new Date(item * 1000 ).toLocaleString("en-US", {timeZoneName: "short"}).split(',')[0]
            },
            meetingNoteCreate() {
                this.$router.push('/newMeetingNote')
            },
            workspaceChange(args) {
                var workSpace = this.menuWorkspaces.find((item) => {
                    return item.id === args
                })
                this.channels = workSpace.channels
                this.noteData.work_space_id = args
                this.noteData.work_space_name = workSpace.name
            },
            getStatus(args) {
                if(args === 0) {
                    return 'In Progress'
                } else if (args === 1){
                    return 'Completed'
                } else {
                    return 'Closed'
                }
            },
            channelChange(args) {
                var channel = this.channels.find((item) => {
                    return item.id === args
                })
                this.noteData.channel_id = args
                this.noteData.channel_name = channel.name
                this.getMeetingNote(this.noteData)
                .then(res => {
                    if(res.status === 200) {
                        this.meeting_notes = res.data.success
                    }
                })
            },
            meetingNoteDelete(item) {
                this.deleteMeetingNoteId = item.id
                this.deleteMeetingNoteModal()
            },
            deleteMeetingNoteModal() {
                this.$refs['meeting_note_delete-modal'].toggle('#remove_note-btn')
            },
            removeMeetingNote() {
                this.meetingNoteRemove(this.deleteMeetingNoteId)
                .then(res => {
                    if(res.status === 200) {
                        this.deleteMeetingNoteModal()
                        this.getMeetingNote(this.noteData)
                            .then(res => {
                                if(res.status === 200) {
                                    this.meeting_notes = res.data.success
                                }
                            })
                    }
                })
            },
            viewAttachment(item) {
                this.modalData = item
                this.attachmentModal()
                let user = JSON.parse(localStorage.getItem('userData'))
                this.modalData.approvals.forEach(item => {
                    if(item.user_id == user.id) {
                        this.approval_data.approval_id = item.id
                        this.approval_data.signatory = item.signatory
                    }
                })

                setTimeout(() => {
                    this.$refs.signaturePad.resizeCanvas();
                }, 500)
            },
            attachmentModal() {
                this.$refs['attachment-modal'].toggle('#attachment-btn')
            },
            undo() {
                this.$refs.signaturePad.undoSignature();
            },
            save() {
                const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
                // console.log(isEmpty);
                // console.log(data);
                let signatureData = {
                    approval_id: this.approval_data.approval_id,
                    signatory: data
                }
                this.sendSignatory(signatureData)
                .then(res => {
                    if(res.status === 200) {
                        this.attachmentModal()
                        this.getMeetingNote(this.noteData)
                            .then(res => {
                                if(res.status === 200) {
                                    this.meeting_notes = res.data.success
                                }
                            })
                    }
                })
            },
            clear() {
                this.$refs.signaturePad.clearSignature()
            },

        }
    }
</script>

<style scoped lang="scss">
    ::v-deep .custom-select {
        border: none;
    }
    ::v-deep .modal-content {
        border: none !important;
        width: 700px;
        ::v-deep .modal-body {
            width: 600px !important;
        }
    }
    ::v-deep .modal_content {
        border: none !important;
        width: 600px;
        position: relative;
        ::v-deep .modal-body {
            width: 600px !important;
        }
        & .x_btn {
            position: absolute;
            top: 0;
            right: 0;
            width: 25px;
            height: 25px;
            cursor: pointer;
            color: $light_purple;
        }
        & .modal_title {
            width: 100%;
            padding: 20px 0;
            font-size: 25px;
            color: $grey;
            border-bottom: 1px solid #CACDF6;
            &.attachment {
                padding-left: 10px;
                color: $purple;
            }
        }
        & .modal_text {
            font-size: 16px;
            color: $grey;
            padding: 25px 0;
        }
        & .modal_buttons {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            padding: 15px 0 30px;
            & .cancel_btn {
                color: $primary;
                font-weight: 600;
                padding: 10px 0;
                cursor: pointer;

            }
            & .delete_btn {
                width: 110px;
                height: 44px;
                background: transparent linear-gradient(180deg, #84EEEB 0%, #3AE4DF 100%) 0% 0% no-repeat padding-box;
                border-radius: 10px;
                color: $primary;
                font-weight: 600;
                border: none;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
        & .description_content {
            padding: 10px 0 5px;
            & .description_subtitle {
                font-weight: 500;
                font-size: 18px;
                padding: 0 15px;
                color: $primary;

            }
            & .description_text {
                padding: 10px 20px 5px;
                border-bottom: 1px solid #e9e9e9;
            }

        }
    }
     .item_signature {
        width: 200px;
        display: flex;
        align-items: center;
         ::v-deep canvas {
             width: 200px !important;
             height: 120px !important;
             border: 1px solid $lightBlue !important;
         }
        & .signature_btn {
            width: 60px;
            height: 20px;
            color: $primary;
            font-size: 12px;
            font-weight: 600;
            background: transparent linear-gradient(180deg, #84EEEB 0%, #3AE4DF 100%) 0% 0% no-repeat padding-box;
            border-radius: 10px;
            padding: 0 5px;
            border: none;
            margin: 10px 0;
            &:focus {
                outline: none;
                box-shadow: none;
                border: none;
            }
            &-content {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                padding: 5px 10px;
            }
        }

    }
.page {
    &_head {
        padding-bottom: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        &-drop-btn {
            display: flex;
            align-items: center;
            & .input_item {
                & .select_arrow {
                    position: relative;
                    width: 240px;
                    margin-right: 20px;
                    & .arrow_down {
                        position: absolute;
                        top: 14px;
                        right: 15px;
                        width: 14px;
                        height: 10px;
                        pointer-events: none;
                        background: url("~@/assets/images/chevron_down.svg") right  no-repeat;
                    }
                }
                & select {
                    background: $greyBlue 0 0 no-repeat padding-box;
                    //background: transparent;
                    border-radius: 4px;
                    padding-right: 5px;
                }
            }

        }
    }
    &_title {
        color: $black;
        font-weight: 600;
        font-size: 25px;
    }
    &_content {
        padding: 20px 40px 20px 1px;
        & .create_btn {
            color: $primary;
            height: 44px;
            font-weight: 600;
            background: transparent linear-gradient(180deg, #84EEEB 0%, #3AE4DF 100%) 0% 0% no-repeat padding-box;
            border-radius: 10px;
            padding: 0 20px;
            border: none;
            display: flex;
            justify-content: center;
            align-items: center;
            &:focus,
            &:active{
                box-shadow: none;
                color: $primary;
            }
        }
        & .notes_table {
            padding: 20px 0;
            & .table_title {
                width: 100%;
                height: 60px;
                background: #84EEEB;
                color: $grey;
                font-size: 16px;
                font-weight: 600;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
        & .table_container {
            font-size: 13px;
            & .cyan {
                color: #3AE4DF;
            }
            & .green {
                color: #1CD65A;
            }
            & .red {
                color: red;
            }
            ::v-deep thead {
                height: 62px;
                color: $primary;
                background: #E7E8F5 0 0 no-repeat padding-box;
                th {
                    vertical-align: middle;
                    max-width: 100px;
                    text-align: center;
                }
            }
            ::v-deep tbody {
                tr {
                    td{
                        vertical-align: middle;
                        text-align: center;
                        text-align: -webkit-center;
                    }
                }
            }
            & .view_btn {
                width: 22px;
                height: 22px;
                cursor: pointer;
                background: url("~@/assets/images/eye_icon.svg") center no-repeat;
            }
            & .edit_btn {
                width: 22px;
                height: 22px;
                cursor: pointer;
                background: url("~@/assets/images/edit_icon.svg") center no-repeat;
            }

            & .trash_button {
                width: 22px;
                height: 22px;
                cursor: pointer;
                background: url("~@/assets/images/trash_icon.svg") center no-repeat;
            }
            & .item {
                display: flex;
                align-items: center;
                justify-content: space-between;
                height: 100%;
                & .dropdown_content {
                    ::v-deep button {
                        width: 130px;
                        height: 32px;
                        background: $light_purple;
                        color: $white;
                        font-size: 12px;
                        border: none;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                    }
                    ::v-deep .dropdown-menu {
                        min-width: 8rem;
                        border: none;
                        margin: 0;
                        padding: 0;
                    }
                    ::v-deep ul {
                        max-width: 130px;
                        width: 100%;
                        background: $greyBlue;
                        li {
                            border-top: 1px solid $light_purple;
                            font-size: 12px;
                            width: 100%;

                            a {
                                white-space: normal;
                                padding: 3px 8px;
                            }
                        }
                    }
                }
            }
            & .reply_btn {
                background: $light_purple;
                border: none;
                font-size: 14px;
                border-radius: 5px;
                &:focus {
                    box-shadow: none;
                }
                &:hover {
                    opacity: .9;
                }
            }
            & .status_item {
                max-width: 110px;
                width: 100%;
            }
            & .status_dots {
                display: flex;
                justify-content: space-evenly;
                & .dot_opy {
                    opacity: 1;
                }
            }
            & .yellow_dot {
                width: 20px;
                height: 20px;
                border-radius: 50%;
                background: #F2EB1A;
                opacity: 0.5;
                cursor: pointer;
            }
            & .green_dot {
                width: 20px;
                height: 20px;
                border-radius: 50%;
                background: #1CD65A;
                opacity: 0.5;
                cursor: pointer;
            }
            & .orange_dot {
                width: 20px;
                height: 20px;
                border-radius: 50%;
                background: #FFB26E;
                opacity: 0.5;
                cursor: pointer;
            }
            & .purple_dot {
                width: 20px;
                height: 20px;
                border-radius: 50%;
                background: $light_purple;
                opacity: 0.5;
                cursor: pointer;
            }
            & .active_dot {
                opacity: 1;
                box-shadow: 0 0 0 3px rgb(255, 255, 255) inset;
                border: 2px solid transparent;
            }
        }
    }
}


</style>