<template>
    <div>
        <div class="bar" :class="{'close_bar': currentRoute === 'Message'}">
            <div class="head_tab">
                <div v-b-toggle="'workspace_accordion'" @click="workSpace = !workSpace" class="tab_title" v-if="currentRoute !== 'Message'">
                    WorkSpace
                    <b-icon :icon="workSpace ? 'chevron-down': 'chevron-up'" aria-hidden="true"></b-icon>
                </div>
                <button class="side_btn" @click="changeBar">
                    <b-icon v-if="sideBar" icon="chevron-right" aria-hidden="true"></b-icon>
                    <b-icon v-else icon="chevron-left" aria-hidden="true"></b-icon>
                </button>
            </div>
            <div class="projects">
                <div class="item_over">
                     <b-collapse visible accordion="workspace_accordion" role="tabpanel" id="workspace_accordion"  v-if="currentRoute !== 'Message'">
                        <div v-for="(item, index) in menuWorkspaces" :key="index" class="project_item">
                            {{item.name}}
                            <div class="plus_icon" v-if="item.plusStatus === true" @click="addToChose(item)">
                                <div>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="#fff" class="bi bi-plus-lg" viewBox="0 0 16 16">
                                        <path d="M8 0a1 1 0 0 1 1 1v6h6a1 1 0 1 1 0 2H9v6a1 1 0 1 1-2 0V9H1a1 1 0 0 1 0-2h6V1a1 1 0 0 1 1-1z"/>
                                    </svg>
                                </div>
                            </div>
                        </div>
                     </b-collapse>
                </div>
                <div class="btn_container"  v-if="currentRoute !== 'Message'">
                    <b-button class="new_workspace_btn" @click="workspaceModal()" id="workspace_btn">Create New Workspace</b-button>
                </div>

                <div  v-if="currentRoute !== 'Message'" class="channels">
                    <b-card v-for="(item, index) in addedItem" :key="item.id" class="selected_item">
                        <b-card-header role="tab">
                            <div v-b-toggle="'accordion'+index" class="accordion_item">{{item.name}}
                                <div class="minus_icon" @click="minusItem(item)">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="#fff" class="bi bi-dash-lg" viewBox="0 0 16 16">
                                        <path d="M0 8a1 1 0 0 1 1-1h14a1 1 0 1 1 0 2H1a1 1 0 0 1-1-1z"/>
                                    </svg>
                                </div>
                            </div>
                        </b-card-header>
                        <b-collapse :id="'accordion'+index" visible accordion="my-accordion" role="tabpanel">
                            <b-card-text v-for="(sub, idx) in item.channels" :key="idx">
                                {{sub.name}}
                            </b-card-text>
                        </b-collapse>
                    </b-card>
                </div>
                <div class="btn_container purple_container" v-if="currentRoute !== 'Message'">
                    <b-button class="new_workspace_btn" @click="channelModal()" id="channel_btn">Create New Channel</b-button>
                </div>
                <div v-if="currentRoute === 'Message'">

                </div>
            </div>

            <!--workspace modal start-->
            <b-modal ref="workspace-modal" centered hide-header hide-footer hide-header-close>
                <div class="workspace_modal_content">
                    <b-icon icon="x" class="x_btn" aria-hidden="true" @click="workspaceModal()"></b-icon>
                    <div class="d-flex">
                        <div class="workspace_name">
                            Create Workspace
                        </div>
                    </div>
                    <div class="input_content">
                        <div class="input_item">
                            <label>New Workspace</label>
                            <b-form-input
                                    v-model="newWorkspace"
                                    type="email"
                                    trim
                            ></b-form-input>
                            <span v-if="workspaceError" class="text_danger">{{workspaceError}}</span>
                        </div>
                    </div>
                    <div class="done_btn-container">
                        <b-button class="done_btn" @click="createNewWorkspace()">Done</b-button>
                    </div>
                </div>
            </b-modal>
            <!--modal end-->

            <!--channel modal start-->
            <b-modal ref="channel-modal" centered hide-header hide-footer hide-header-close>
                <div class="workspace_modal_content">
                    <b-icon icon="x" class="x_btn" aria-hidden="true" @click="openChannelModal()"></b-icon>
                    <div class="d-flex">
                        <div class="workspace_name">
                            Create Channel
                        </div>
                    </div>

                    <div class="input_content">
                        <div class="input_item">
                            <label>Workspace</label>
                            <b-form-select @change="workspaceChange">
                                <option
                                        v-for="(option, id) in menuWorkspaces"
                                        :key="id"
                                        :value="option.id"
                                        :title="option.name || null"
                                >
                                    {{option.name}}
                                </option>
                            </b-form-select>
                            <span v-if="channelWorkspaceError" class="text_danger">{{channelWorkspaceError}}</span>

                        </div>
                        <div class="input_item">
                            <label>New Channel</label>
                            <b-form-input
                                    v-model="newChanel.channelName"
                                    type="text"
                                    trim
                            ></b-form-input>
                            <span v-if="channelError" class="text_danger">{{channelError}}</span>
                        </div>
                    </div>
                    <div class="done_btn-container">
                        <b-button class="done_btn" @click="createNewChannel()">Done</b-button>
                    </div>
                </div>
            </b-modal>
            <!--modal end-->

        </div>
    </div>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";
    export default {
        name: "LeftSubBar",
        data() {
            return {
                workSpace: false,
                newWorkspace: null,
                workspaceError: null,
                channelError: null,
                channelWorkspaceError: null,
                newChanel: {
                  work_space_id: null,
                  channelName: null
                },
                addedItem: [],
                sideBar: false
            }
        },
        created() {
            this.getAllMenuWorkspaces()
            // this.getAllAdmins()
        },
        computed:{
            ...mapGetters(['accessToken', 'workspaces', 'menuWorkspaces']),
            currentRoute (){
                return this.$route.name
            },
            token() {
                return this.accessToken
            },
        },
        methods: {
            ...mapActions(['workspaceCreate', 'getAllMenuWorkspaces', 'channelCreate']),
            addToChose(item) {
                this.addedItem.push(item)
                item.plusStatus = false
            },
            minusItem(item) {
                item.plusStatus = true
                this.addedItem.splice(this.addedItem.indexOf(item), 1)
            },
            workspaceModal() {
                this.workspaceError = null
                this.newWorkspace = null
                this.$refs['workspace-modal'].toggle('#workspace_btn')
            },
            openChannelModal() {
                this.channelError = null
                this.newChanel.work_space_id = null
                this.newChanel.channelName = null
                this.$refs['channel-modal'].toggle('#channel_btn')
            },
            channelModal() {
                this.openChannelModal()
                // this.newChanel.id = args.id
            },
            workspaceChange(args) {
                this.newChanel.work_space_id = args
            },
            createNewWorkspace() {
                this.workspaceError = null
                this.workspaceCreate(this.newWorkspace)
                .then((res) => {
                    if(res.status === 200) {
                        this.addedItem = []
                        this.workspaceModal()
                        this.getAllMenuWorkspaces()
                    } else {
                        this.workspaceError = res.response.data.errors.name[0]
                    }
                })
            },
            changeBar() {
              this.sideBar = !this.sideBar
              this.$emit('changeBar')
            },

            createNewChannel() {
                this.channelError = null
                this.channelWorkspaceError = null
                this.channelCreate(this.newChanel)
                .then((res) => {
                    this.addedItem = []
                    if(res.status === 200) {
                        this.getAllMenuWorkspaces()
                        this.openChannelModal()
                    } else {
                        console.log( res.response.data, 'channel create error')
                        this.channelError = res.response.data.errors.name[0]
                        this.channelWorkspaceError = res.response.data.errors.work_space_id[0]
                    }
                    // this.addedItem.push(res.data.success)
                })

            }
        }
    }
</script>

<style scoped lang="scss">
::v-deep .modal-content {
    border: none;
}
::v-deep .custom-select{
    background-image: url("~@/assets/images/chevron_down.svg") no-repeat !important;
    z-index: 20;
}
.workspace {
    &_modal_content {
        position: relative;
        padding: 0 10px;
        & .x_btn {
            position: absolute;
            top: 0;
            right: 0;
            width: 25px;
            height: 25px;
            cursor: pointer;
            color: $light_purple;
        }
        & .input_content {
            & .input_item {
                padding: 20px 0;
                & label {
                    color: $primary;
                    font-weight: 600;
                    font-size: 14px;

                }
                & input {
                    border: none;
                    background: $greyBlue 0 0 no-repeat padding-box;
                    border-radius: 3px;
                    &:focus {
                        box-shadow: none;
                        outline: none;
                    }
                }
                & select {
                    border: 1px solid $light_purple;
                    /*background: $greyBlue 0 0 no-repeat padding-box;*/
                    border-radius: 4px;


                }
            }

        }
        & .done_btn-container {
            display: flex;
            justify-content: flex-end;
            padding: 10px 0;
            & .done_btn {
                background: transparent linear-gradient(180deg, #84EEEB 0%, #3AE4DF 100%) 0% 0% no-repeat padding-box;
                border-radius: 10px;
                width: 110px;
                height: 44px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: $primary;
                font-weight: 600;
                border: none;
            }
        }
    }
    &_name {
        width: 100%;
        font-size: 25px;
        font-weight: 500;
        color: $grey;
        padding-bottom: 10px;
        border-bottom: 1px solid #CACDF6;
    }
}
.bar {
    position: fixed;
    top: 0;
    width: 280px;
    height: 100%;
    background: $lightBlue;
    &.close_bar {
        width: 1px;
        & .head_tab {
            padding: 0 140px;
        }
    }

    & .head_tab {
        position: relative;
        width: 100%;
        height: 88px;
        background: #F4F4F8;
        display: flex;
        align-items: center;
        padding: 0;
        & .side_btn {
            position: absolute;
            top: 40px;
            left: -16px;
            width: 32px;
            height: 32px;
            border: 1px solid $cyan;
            border-radius: 50%;
            background: $blue;
            display: flex;
            justify-content: center;
            align-items: center;
            /*z-index: 20;*/
            & svg {
                color: $cyan;
            }
        }
        & .tab_title {
            color: $grey;
            font-size: 18px;
            font-weight: 600;
            display: flex;
            align-items: center;
            cursor: pointer;
            margin-left: 35px;
            & svg {
                margin-left: 10px;
                font-weight: 600;
            }
        }
    }
    & .projects {
        & .item_over {
            overflow-y: auto;
            max-height: 340px;
            & .project_item {
                width: 100%;
                /*height: 66px;*/
                border-bottom: 1px solid #CACEFE;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 20px 35px;
                font-size: 16px;
                font-weight: 600;
                color: $grey;
                cursor: pointer;
                & .plus_icon {
                    width: 26px;
                    height: 26px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 50%;
                    background: $purple;

                }
            }
        }
        & .channels {
            overflow-y: auto;
            max-height: 345px;
            & .selected_item {
                & .card-body {
                    padding: 0;
                    & .card-header {
                        padding: 5px 35px;
                        background: $white;
                        font-size: 16px;
                        font-weight: 600;
                        color: $purple;
                        cursor: pointer;
                    }
                    & .card-text {
                        margin: 0;
                        padding: 20px 35px;
                        background: $lightBlue;
                        border-bottom: 1px solid #CACEFE;
                        font-size: 16px;
                        font-weight: 600;
                        color: $grey;
                    }
                }
                & .accordion_item {
                    display: flex;
                    justify-content: space-between;
                    padding: 15px 0;
                    & .minus_icon {
                        width: 26px;
                        height: 26px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border-radius: 50%;
                        background: $purple;
                    }
                }
            }
        }

        & .btn_container {
            display: flex;
            justify-content: center;
            padding: 44px 0;
            width: 100%;
            & .new_workspace_btn{
                background: transparent linear-gradient(180deg, #84EEEB 0%, #3AE4DF 100%) 0% 0% no-repeat padding-box;
                border-radius: 10px;
                width: 220px;
                height: 44px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: $primary;
                font-weight: 600;
                border: none;
            }
            &.purple_container {
                background: $lightBlue;
            }
        }

    }
}
</style>