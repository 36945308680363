<template>
    <div>
        <div class="page_content">
            <div class="page_head">
                <div class="page_title">
                    Task Summary
                </div>
            </div>

            <div class="summary_cards">
                <div class="card_item started">
                    <div class="item_status">
                        Not Started
                    </div>
                    <div class="item_point">
                        {{taskSummary.not_started_count}}
                    </div>

                </div>
                <div class="card_item in_progress">
                    <div class="item_status">
                        In Progress
                    </div>
                    <div class="item_point">
                        {{taskSummary.in_progress_count}}
                    </div>

                </div>
                <div class="card_item review">
                    <div class="item_status">
                        Under Review & Discussion
                    </div>
                    <div class="item_point">
                        {{taskSummary.under_review_discussion_count}}
                    </div>

                </div>
                <div class="card_item completed">
                    <div class="item_status">
                        Completed
                    </div>
                    <div class="item_point">
                        {{taskSummary.completed_count}}
                    </div>

                </div>
            </div>

            <div class="summary_table">
                <div class="table_title">
                    7 Days
                </div>
                <div class="table_container">
                    <b-table hover :items="tasksDate.days_7" :fields="day_fields" responsive="sm">
                        <template v-slot:cell(index)="data">
                            <div class="">
                                {{data.index+1}}
                            </div>
                        </template>

                        <template v-slot:cell(work_spaces_name)="data">
                            <div class="item_name" v-html="highlight(data.item.work_spaces_name)">
                                {{data.item.work_spaces_name}}
                            </div>
                        </template>

                        <template v-slot:cell(channel)="data">
                            <div class="" v-html="highlight(data.item.channel_name)">
                                {{data.item.channel_name}}
                            </div>
                        </template>

                        <template v-slot:cell(title)="data">
                            <div class="" v-html="highlight(data.item.title)">
                                {{data.item.title}}
                            </div>
                        </template>

                        <template v-slot:cell(requestor)="data">
                            <div class="" v-html="highlight(data.item.requestor_name.name)">
                                {{data.item.requestor_name.name}}
                            </div>
                        </template>

                        <template v-slot:cell(assignee)="data">
                            <div class="" v-html="highlight(data.item.user_name.name)">
                                {{data.item.user_name.name}}
                            </div>
                        </template>

                        <template v-slot:cell(req_date)="data">
                            <div class="">
                                {{getDate(data.item.req_date)}}
                            </div>
                        </template>

                        <template v-slot:cell(due_date)="data">
                            <div class="">
                                {{getDate(data.item.due_date)}}
                            </div>
                        </template>

                        <template v-slot:cell(priority)="data">
                            <div class=""
                                 :class="{
                                'red': data.item.priority === 2,
                                'orange': data.item.priority === 0
                                 }"
                            >
                                {{getPriority(data.item.priority)}}
                            </div>
                        </template>

                        <template v-slot:cell(total)="data">
                            <div class="">
                                {{data.item.days_count}}
                            </div>
                        </template>

                        <template v-slot:cell(view)="data">
                            <div class="d-flex justify-content-center">
                                <div class="view_btn" @click="view(data.item)">
                                </div>
                            </div>
                        </template>

                        <template v-slot:cell(status)="data">
                            <div class="status_dots" v-if="userInfo.id === data.item.user_name.id">
                                <div class="yellow_dot" :class="{'active_dot': data.item.summary_status === 0}" @click="changeSummary(data.item.id, 0)"></div>
                                <div class="orange_dot" :class="{'active_dot': data.item.summary_status === 1}" @click="changeSummary(data.item.id, 1)"></div>
                                <div class="purple_dot" :class="{'active_dot': data.item.summary_status === 2}" @click="changeSummary(data.item.id, 2)"></div>
                                <div class="green_dot" :class="{'active_dot': data.item.summary_status === 3}" @click="changeSummary(data.item.id, 3)"></div>
                            </div>
                            <div v-if="userInfo.id !== data.item.user_name.id" class="status_dots">
                                <div class="dot_opy" :class="{
                                        'yellow_dot': data.item.summary_status === 0,
                                        'orange_dot': data.item.summary_status === 1,
                                        'purple_dot': data.item.summary_status === 2,
                                        'green_dot': data.item.summary_status === 3,
                                    }"></div>
                            </div>

                        </template>

                    </b-table>
                </div>
            </div>

            <div class="summary_table">
                <div class="table_title">
                    14 Days
                </div>
                <div class="table_container">
                    <b-table hover :items="tasksDate.days_14" :fields="day_fields" responsive="sm">
                        <template v-slot:cell(index)="data">
                            <div class="">
                                {{data.index+1}}
                            </div>
                        </template>

                        <template v-slot:cell(work_spaces_name)="data">
                            <div class="item_name" v-html="highlight(data.item.work_spaces_name)">
                                {{data.item.work_spaces_name}}
                            </div>
                        </template>

                        <template v-slot:cell(channel)="data">
                            <div class="" v-html="highlight(data.item.channel_name)">
                                {{data.item.channel_name}}
                            </div>
                        </template>

                        <template v-slot:cell(title)="data">
                            <div class="" v-html="highlight(data.item.title)">
                                {{data.item.title}}
                            </div>
                        </template>

                        <template v-slot:cell(requestor)="data">
                            <div class="" v-html="highlight(data.item.requestor_name.name)">
                                {{data.item.requestor_name.name}}
                            </div>
                        </template>

                        <template v-slot:cell(assignee)="data">
                            <div class="" v-html="highlight(data.item.user_name.name)">
                                {{data.item.user_name.name}}
                            </div>
                        </template>

                        <template v-slot:cell(req_date)="data">
                            <div class="">
                                {{getDate(data.item.req_date)}}
                            </div>
                        </template>

                        <template v-slot:cell(due_date)="data">
                            <div class="">
                                {{getDate(data.item.due_date)}}
                            </div>
                        </template>

                        <template v-slot:cell(priority)="data">
                            <div class=""
                                 :class="{
                                'red': data.item.priority === 2,
                                'orange': data.item.priority === 0
                                 }"
                            >
                                {{getPriority(data.item.priority)}}
                            </div>
                        </template>

                        <template v-slot:cell(total)="data">
                            <div class="">
                                {{data.item.days_count}}
                            </div>
                        </template>

                        <template v-slot:cell(view)="data">
                            <div class="d-flex justify-content-center">
                                <div class="view_btn" @click="view(data.item)">
                                </div>
                            </div>
                        </template>

                        <template v-slot:cell(status)="data">
                            <div class="status_dots" v-if="userInfo.id !== data.item.parent_id">
                                <div class="yellow_dot" :class="{'active_dot': data.item.summary_status === 0}" @click="changeSummary(data.item.id, 0)"></div>
                                <div class="orange_dot" :class="{'active_dot': data.item.summary_status === 1}" @click="changeSummary(data.item.id, 1)"></div>
                                <div class="purple_dot" :class="{'active_dot': data.item.summary_status === 2}" @click="changeSummary(data.item.id, 2)"></div>
                                <div class="green_dot" :class="{'active_dot': data.item.summary_status === 3}" @click="changeSummary(data.item.id, 3)"></div>
                            </div>
                            <div v-if="userInfo.id === data.item.parent_id" class="status_dots">
                                <div class="dot_opy" :class="{
                                        'yellow_dot': data.item.summary_status === 0,
                                        'orange_dot': data.item.summary_status === 1,
                                        'purple_dot': data.item.summary_status === 2,
                                        'green_dot': data.item.summary_status === 3,
                                    }"></div>
                            </div>

                        </template>

                    </b-table>
                </div>
            </div>

            <div class="summary_table">
                <div class="table_title">
                    30 Days
                </div>
                <div class="table_container">
                    <b-table hover :items="tasksDate.days_30" :fields="day_fields" responsive="sm">
                        <template v-slot:cell(index)="data">
                            <div class="">
                                {{data.index+1}}
                            </div>
                        </template>

                        <template v-slot:cell(work_spaces_name)="data">
                            <div class="item_name" v-html="highlight(data.item.work_spaces_name)">
                                {{data.item.work_spaces_name}}
                            </div>
                        </template>

                        <template v-slot:cell(channel)="data">
                            <div class="" v-html="highlight(data.item.channel_name)">
                                {{data.item.channel_name}}
                            </div>
                        </template>

                        <template v-slot:cell(title)="data">
                            <div class="" v-html="highlight(data.item.title)">
                                {{data.item.title}}
                            </div>
                        </template>

                        <template v-slot:cell(requestor)="data">
                            <div class="" v-html="highlight(data.item.requestor_name.name)">
                                {{data.item.requestor_name.name}}
                            </div>
                        </template>

                        <template v-slot:cell(assignee)="data">
                            <div class="" v-html="highlight(data.item.user_name.name)">
                                {{data.item.user_name.name}}
                            </div>
                        </template>

                        <template v-slot:cell(req_date)="data">
                            <div class="">
                                {{getDate(data.item.req_date)}}
                            </div>
                        </template>

                        <template v-slot:cell(due_date)="data">
                            <div class="">
                                {{getDate(data.item.due_date)}}
                            </div>
                        </template>

                        <template v-slot:cell(priority)="data">
                            <div class=""
                                 :class="{
                                'red': data.item.priority === 2,
                                'orange': data.item.priority === 0
                                 }"
                            >
                                {{getPriority(data.item.priority)}}
                            </div>
                        </template>

                        <template v-slot:cell(total)="data">
                            <div class="">
                                {{data.item.days_count}}
                            </div>
                        </template>

                        <template v-slot:cell(view)="data">
                            <div class="d-flex justify-content-center">
                                <div class="view_btn" @click="view(data.item)">
                                </div>
                            </div>
                        </template>

                        <template v-slot:cell(status)="data">
                            <div class="status_dots" v-if="userInfo.id !== data.item.parent_id">
                                <div class="yellow_dot" :class="{'active_dot': data.item.summary_status === 0}" @click="changeSummary(data.item.id, 0)"></div>
                                <div class="orange_dot" :class="{'active_dot': data.item.summary_status === 1}" @click="changeSummary(data.item.id, 1)"></div>
                                <div class="purple_dot" :class="{'active_dot': data.item.summary_status === 2}" @click="changeSummary(data.item.id, 2)"></div>
                                <div class="green_dot" :class="{'active_dot': data.item.summary_status === 3}" @click="changeSummary(data.item.id, 3)"></div>
                            </div>
                            <div v-if="userInfo.id === data.item.parent_id" class="status_dots">
                                <div class="dot_opy" :class="{
                                        'yellow_dot': data.item.summary_status === 0,
                                        'orange_dot': data.item.summary_status === 1,
                                        'purple_dot': data.item.summary_status === 2,
                                        'green_dot': data.item.summary_status === 3,
                                    }"></div>
                            </div>

                        </template>

                    </b-table>
                </div>
            </div>

<!--description modal start-->
            <b-modal ref="description-modal" centered hide-header hide-footer hide-header-close>
                <div class="modal_content">
                    <b-icon icon="x" class="x_btn" aria-hidden="true" @click="descriptionModal()"></b-icon>
                    <div class="d-flex flex-column" v-if="modalData">
                        <div class="modal_title">
                            {{modalData.work_spaces_name}}
                        </div>
                        <div class="description_content">
                            <div class="description_subtitle">
                                <b-icon icon="filter-left" class="mr-2"  aria-hidden="true"></b-icon>
                                Description
                            </div>
                            <div class="description_text">
                                {{modalData.description}}
                            </div>
                        </div>
                    </div>
                </div>
            </b-modal>
<!--description modal end-->
        </div>
    </div>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";

    export default {
        name: "Summary",
        data() {
            return {
                day_fields: [
                    {
                        key: 'index',
                        label: 'No',
                    },
                    {
                        key: 'work_spaces_name',
                        label: 'WorkSpace',
                    },
                    {
                        key: 'channel',
                        label: 'Channel',
                    },
                    {
                        key: 'title',
                        label: 'Title',
                    },
                    {
                        key: 'requestor',
                        label: 'Requestor',
                    },
                    {
                        key: 'assignee',
                        label: 'Assignee',
                    },
                    // // {
                    // //     key: 'copied_list',
                    // //     label: 'Copied List'
                    // // },
                    // // {
                    // //     key: 'req_in',
                    // //     label: 'Req In'
                    // // },
                    {
                        key: 'req_date',
                        label: 'Req Date',
                    },
                    {
                        key: 'due_date',
                        label: 'Due Date',
                    },
                    {
                        key: 'priority',
                        label: 'Priority',
                    },
                    {
                        key: 'total',
                        label: 'Worked/Total (days)',
                    },
                    {
                        key: 'view',
                        label: 'Description',
                    },
                    {
                        key: 'status',
                        label: 'Status',
                    },
                ],
                modalData: null,
            }
        },
        created() {
            this.getTaskDate()
            this.getTaskSummary()
            this.getKeyWords()
        },
        computed: {
            ...mapGetters(['taskSummary', 'tasksDate', 'keywords']),
            userInfo() {
                return JSON.parse(localStorage.getItem('userData'))
            },
        },
        methods: {
            ...mapActions([
                'getTaskDate',
                'getTaskSummary',
                'changeSummaryStatus',
                'getKeyWords'
            ]),
            highlight(text) {
                var values = this.keywords.join("|");
                if(values) {
                    return text.replace(new RegExp(`.*${values}.*`, 'gi'), '<mark style="background: yellow" >$&</mark>')
                } else {
                    return  text
                }
            },
            getDate(sec) {
                return new Date(sec * 1000 ).toLocaleString("en-US", {timeZoneName: "short"}).split(',')[0]
            },
            getPriority(args) {
                if(args === 0) {
                    return 'Low'
                } else if (args === 1){
                    return 'Normal'
                } else {
                    return 'High'
                }
            },
            view(item) {
                this.descriptionModal()
                this.modalData = item
            },
            descriptionModal() {
                this.$refs['description-modal'].toggle('#description-btn')
            },
            changeSummary(taskId, status) {
                let sendData = {
                    task_id: taskId,
                    status: status
                }
                this.changeSummaryStatus(sendData)
                    .then((res) => {
                        if(res.status === 200) {
                            this.getTaskDate()
                            this.getTaskSummary()
                        }
                    })
            }
        }
    }
</script>

<style scoped lang="scss">
    .page {
        &_head {
            padding-bottom: 20px;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        &_title {
            color: $black;
            font-weight: 600;
            font-size: 25px;
        }
        &_content {
            padding: 20px 40px 20px 1px;
            @media screen and (max-width: 1800px) {
                /*margin-left: 15px;*/
            }

            & .summary_table{
                padding-top: 20px;
                & .table_title {
                    padding-bottom: 40px;
                    color: $primary;
                    font-size: 16px;
                    font-weight: 600;
                }
                & .table_head {
                    width: 100%;
                    height: 60px;
                    background: #84EEEB;
                    color: #4A4A4D;
                    font-weight: 600;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

                & .table_container {
                    font-size: 13px;
                    & .red {
                        color: #FF0000;
                    }
                    & .orange {
                        color: #FF961D;
                    }
                    & .green {
                        color: #0FAC49;
                    }
                    ::v-deep thead {
                        height: 62px;
                        color: $primary;
                        background: #E7E8F5 0 0 no-repeat padding-box;
                        th {
                            vertical-align: middle;
                            max-width: 100px;
                            text-align: center;
                        }
                    }
                    ::v-deep tbody {
                        tr {
                            td{
                                vertical-align: middle;
                                text-align: center;
                                text-align: -webkit-center;
                            }
                        }
                    }
                    & .view_btn {
                        width: 22px;
                        height: 22px;
                        cursor: pointer;
                        background: url("~@/assets/images/eye_icon.svg") center no-repeat;
                    }
                    & .edit_btn {
                        width: 22px;
                        height: 22px;
                        cursor: pointer;
                        background: url("~@/assets/images/edit_icon.svg") center no-repeat;
                    }
                    & .trash_button {
                        width: 22px;
                        height: 22px;
                        cursor: pointer;
                        background: url("~@/assets/images/trash_icon.svg") center no-repeat;
                    }
                    & .x_button {
                        width: 22px;
                        height: 22px;
                        cursor: pointer;
                        background: url("~@/assets/images/x_icon.svg") center no-repeat;
                    }
                    & .item {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        height: 100%;
                        & .dropdown_content {
                            ::v-deep button {
                                width: 130px;
                                height: 32px;
                                background: $light_purple;
                                color: $white;
                                font-size: 12px;
                                border: none;
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                            }
                            ::v-deep .dropdown-menu {
                                min-width: 8rem;
                                border: none;
                                margin: 0;
                                padding: 0;
                            }
                            ::v-deep ul {
                                max-width: 130px;
                                width: 100%;
                                background: $greyBlue;
                                li {
                                    border-top: 1px solid $light_purple;
                                    font-size: 12px;
                                    width: 100%;

                                    a {
                                        white-space: normal;
                                        padding: 3px 8px;
                                    }
                                }
                            }
                        }
                    }
                    & .status_item {
                        max-width: 95px;
                        text-align: start;
                    }
                    & .status_dots {
                        display: flex;
                        justify-content: space-evenly;
                        & .dot_opy {
                            opacity: 1;
                        }
                    }
                    & .yellow_dot {
                        width: 20px;
                        height: 20px;
                        border-radius: 50%;
                        background: #F2EB1A;
                        opacity: 0.5;
                        cursor: pointer;
                    }
                    & .green_dot {
                        width: 20px;
                        height: 20px;
                        border-radius: 50%;
                        background: #1CD65A;
                        opacity: 0.5;
                        cursor: pointer;
                    }
                    & .orange_dot {
                        width: 20px;
                        height: 20px;
                        border-radius: 50%;
                        background: #FFB26E;
                        opacity: 0.5;
                        cursor: pointer;
                    }
                    & .purple_dot {
                        width: 20px;
                        height: 20px;
                        border-radius: 50%;
                        background: #7882F3;
                        opacity: 0.5;
                        cursor: pointer;
                    }
                    & .active_dot {
                        opacity: 1;
                        box-shadow: 0 0 0 3px rgb(255, 255, 255) inset;
                        border: 2px solid transparent;
                    }
                }
            }

            & .summary_cards{
                display: flex;
                justify-content: space-between;
                padding: 30px 0 0;
                @media screen and (max-width: 1800px) {
                    flex-wrap: wrap;
                }
                & .card_item {
                    width: 300px;
                    height: 190px;
                    border-radius: 15px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    margin-bottom: 25px;
                    &.started {
                        background: $yellow;
                    }
                    &.in_progress {
                        background: $lemon;
                    }
                    &.review {
                        background: $light_purple;

                    }
                    &.completed {
                        background: $green;
                    }
                    & .item_status {
                        color: #000;
                        font-weight: 600;
                        font-size: 25px;
                        text-align: center;
                    }
                    & .item_point {
                        color: #000;
                        font-weight: 600;
                        font-size: 32px;
                        text-align: center;
                    }
                }
            }

        }
    }

    ::v-deep .modal_content {
        position: relative;
        & .x_btn {
            position: absolute;
            top: 0;
            right: 0;
            width: 25px;
            height: 25px;
            cursor: pointer;
            color: $light_purple;
        }
        & .modal_title {
            width: 100%;
            padding: 20px 0;
            font-size: 25px;
            color: $grey;
            border-bottom: 1px solid #CACDF6;
        }
        & .modal_text {
            font-size: 16px;
            color: $grey;
            padding: 25px 0;
        }
        & .modal_buttons {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            padding: 15px 0 30px;
            & .cancel_btn {
                color: $primary;
                font-weight: 600;
                padding: 10px 0;
                cursor: pointer;

            }
            & .delete_btn {
                width: 110px;
                height: 44px;
                background: transparent linear-gradient(180deg, #84EEEB 0%, #3AE4DF 100%) 0% 0% no-repeat padding-box;
                border-radius: 10px;
                color: $primary;
                font-weight: 600;
                border: none;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
        & .description_content {
            padding: 20px 0;
            & .description_subtitle {
                font-weight: 500;
                font-size: 18px;
                padding: 0 15px;
            }
            & .description_text {
                padding: 15px 20px;
            }
        }
    }

    ::v-deep .modal-content {
        border: none !important;
    }
    ::v-deep .table {
        border-bottom: 1px solid #dee2e6;
    }
    ::v-deep .custom-select {
        border: none;
    }
    ::v-deep .table-responsive > .table {
        margin-bottom: 50px;
        padding-bottom: 30px;
    }
</style>