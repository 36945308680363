<template>
    <div>
        <div class="buttons_container">
            <b-button v-if="answerStatus" class="answer_btn" @click="answerToCall(caller_id)">
                <b-icon icon="telephone-fill" style="color: #0505A4;" aria-hidden="true"></b-icon>
                Answer
            </b-button>
            <b-button class="decline_btn" v-if="isDecline" @click="declineCall(caller_id)">
                <b-icon icon="telephone-x-fill" style="color: #ffffff;" aria-hidden="true"></b-icon>
                Decline
            </b-button>
        </div>

        <div class="container">
            <div class="video-container" ref="video-container">
                <video class="video-here" ref="video-here" autoplay></video>
                <video class="video-there" ref="video-there" autoplay></video>
<!--                <div class="text-right" v-for="(user,id) in others" :key="id">-->
<!--                    <button @click="startVideoChat(user.id)" v-text="`Talk with ${user.name}`"/>-->
<!--                </div>-->
            </div>
        </div>
    </div>

</template>

<script>
    import Pusher from 'pusher-js';
    import Peer from 'simple-peer';
    import axios from 'axios'
    export default {
        props: ['userId', 'others', 'pusherKey', 'pusherCluster', 'callerId', 'micStatus'],
        name: "VideoChat",
        data() {
            return {
                channel: null,
                stream: null,
                peers: {},
                pusherAppKey: null,
                pusherAppCluster: null,
                caller_id: null,
                userDataId: null,
                videoStream: null,
                isDecline: false,
                idD: null,
                destroyId: null,
                changeCallerId: null
            }
        },
        watch: {
            callerId: {
                handler(val) {
                    if(val) {
                        this.changeCallerId = val
                        this.idD = val
                        this.startVideoChat(val)
                    }
                },
                deep: true
            },
        },
        computed: {
          answerStatus() {
              return !!(this.caller_id && !this.changeCallerId);
          }
        },
        created() {
            this.pusherAppKey = process.env.VUE_APP_MIX_PUSHER_APP_KEY
            this.pusherAppCluster = process.env.VUE_APP_MIX_PUSHER_APP_CLUSTER
            this.userDataId = JSON.parse(localStorage.getItem('userData')).id
        },
        mounted() {
            this.setupVideoChat();
        },
        methods: {
            muteMic(val) {
                if(this.stream != null && this.stream.getAudioTracks().length > 0){
                    this.stream.getAudioTracks()[0].enabled = val;
                }
            },
            startVideoChat(userId) {
                this.getPeer(userId, true);
            },
            getPeer(userId, initiator) {
                this.destroyId = userId
                if(this.peers[userId] === undefined) {
                    let peer = new Peer({
                        initiator,
                        stream: this.stream,
                        trickle: false
                    });
                    peer.on('signal', (data) => {
                        this.channel.trigger(`client-signal-${userId}`, {
                            userId: this.userId,
                            data: data
                        });
                        if('signal') {
                            this.$emit('videoStatus', true)
                            this.isDecline = true
                        }
                    })
                    peer.on('stream', (stream) => {
                        this.videoStream = stream
                    })
                    peer.on('close', () => {
                        delete this.peers[this.destroyId];
                        this.setupVideoChat()
                        window.location.reload()
                    });
                    peer.on('connect', () => {
                        peer.send(this.userId)
                    })
                    peer.on('data', data => {
                        if(data) {
                            this.caller_id = data
                        }
                        if(data == 'answered') {
                            const videoThere = this.$refs['video-there'];
                            videoThere.srcObject = this.videoStream
                        }
                        if(data == "declineCall"){
                            this.$emit('videoStatus', false)
                            if(data) {
                                setTimeout(() => {
                                    window.location.reload()
                                }, 500)
                            }
                            this.getPeer(this.userId, false)
                        }
                    })
                    this.peers[userId] = peer;
                }
                return this.peers[userId];
            },
            answerToCall(userId) {
                const videoThere = this.$refs['video-there'];
                videoThere.srcObject = this.videoStream
                this.callId = userId
                let peer = this.peers[userId]
                peer.send( 'answered', () => {
                    const videoThere = this.$refs['video-there'];
                    videoThere.srcObject = this.videoStream
                })
                this.changeCallerId = this.caller_id
            },
            declineCall(userId) {
                this.$emit('videoStatus', false)
                let peer = this.peers[this.callerId]
                let peer1 = this.peers[this.callId]
                if(this.callId) {
                    peer1.send('declineCall', () => {
                    })
                    window.location.reload()
                }
                peer.destroy();
                this.videoStream = {}
                setTimeout(() => {
                    window.location.reload()
                }, 500)
            },
            async setupVideoChat() {
                // To show pusher errors
                // Pusher.logToConsole = true;
                const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
                const videoHere = this.$refs['video-here'];
                videoHere.srcObject = stream;
                this.stream = stream;
                Pusher.logToConsole = false;
                const pusher = this.getPusherInstance();
                this.channel = pusher.subscribe('presence-video-chat');
                this.channel.bind(`client-signal-${this.userId}`, (signal) => {
                    const peer = this.getPeer(signal.userId, false);
                    peer.signal(signal.data);
                });
            },
            getPusherInstance() {
                let baseUrl = process.env.VUE_APP_BASE_URL
                return new Pusher(this.pusherAppKey, {
                    authEndpoint: `${baseUrl}auth/video_chat`,
                    cluster: this.pusherAppCluster,
                    auth: {
                        headers: {
                            'Authorization': 'Bearer '+this.$store.state.access_token
                        }
                    }
                });
            },

        }
    }
</script>

<style scoped lang="scss">
    .buttons_container {
        display: flex;
        padding: 20px 15px 5px;
        & .answer_btn {
            background: transparent linear-gradient(180deg, #84EEEB 0%, #3AE4DF 100%) 0% 0% no-repeat padding-box;
            border-radius: 10px;
            width: 120px;
            height: 38px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: $primary;
            font-weight: 600;
            border: none;
            margin-right: 20px;
            & svg {
                margin-right: 8px;
            }
            &:active,
            &:focus {
                box-shadow: none;
            }
        }
        & .decline_btn {
            background: transparent linear-gradient(180deg, $light_purple 0%, $light_purple 100%) 0% 0% no-repeat padding-box;
            border-radius: 10px;
            width: 120px;
            height: 38px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: $white;
            font-weight: 600;
            border: none;
            margin-right: 20px;
            & svg {
                margin-right: 8px;
            }
            &:active,
            &:focus {
                box-shadow: none;
            }
        }
    }
    .video-container {
        width: 500px;
        height: 380px;
        margin: 8px auto;
        border: 3px solid $purple;
        position: relative;
        box-shadow: 1px 1px 1px #9e9e9e;
    }
    .video-here {
        width: 130px;
        position: absolute;
        left: 10px;
        bottom: 16px;
        border: 1px solid #000;
        border-radius: 2px;
        z-index: 2;
    }
    .video-there {
        width: 100%;
        height: 100%;
        z-index: 1;
    }
    .text-right {
        text-align: right;
    }
</style>