<template>
    <div>
        <b-row class="no-gutters" style="flex-wrap: nowrap">
            <b-col cols="2" class="sidebar_container" :class="{'moveSideBar': sideBar}">
                <LeftSidebar :sideBar="sideBar"/>

            </b-col>
            <b-col class="main_container" :class="{'sideBarChange': sideBar}">

                <b-row class="no-gutters main_content">
                    <div class="top_menu-container">
                        <div class="search_container">
                            <b-form-input
                                    id="example-input"
                                    v-model="search"
                                    @keyup="searchTerm"
                                    type="text"
                                    class="search_input"
                                    placeholder="Search..."
                                    autocomplete="off"
                            ></b-form-input>
                            <b-icon icon="search" style="color: #096BC4; width: 18px; height: 18px; z-index: 10"
                                    aria-hidden="true"></b-icon>
                        </div>
                        <div class="user_data">
                            <div class="notes">
                                <svg xmlns="http://www.w3.org/2000/svg" width="28.441" height="28.44"
                                     viewBox="0 0 28.441 28.44">
                                    <defs>
                                    </defs>
                                    <g transform="translate(0 0)">
                                        <g transform="translate(0 0)">
                                            <g transform="translate(0 0)">
                                                <path class="a_notes"
                                                      d="M27.547,10.537a2.5,2.5,0,0,0-3.512.306l-2.372,2.806V5.511A2.51,2.51,0,0,0,20.885,3.7L17.72.692A2.489,2.489,0,0,0,16,0H2.5A2.5,2.5,0,0,0,0,2.5V25.946a2.5,2.5,0,0,0,2.5,2.5H19.164a2.5,2.5,0,0,0,2.5-2.5V21.393l6.192-7.334A2.5,2.5,0,0,0,27.547,10.537ZM16.664,1.988C20,5.154,19.761,4.923,19.824,5H16.664ZM20,25.946a.834.834,0,0,1-.833.833H2.5a.834.834,0,0,1-.833-.833V2.5A.834.834,0,0,1,2.5,1.671H15V5.837a.833.833,0,0,0,.833.833H20v8.951l-3.542,4.192a2.512,2.512,0,0,0-.516,1.026l-.774,3.242a.833.833,0,0,0,1.142.958l3.058-1.326a2.512,2.512,0,0,0,.633-.4Zm-1.982-5.4,1.276,1.071-.284.336a.836.836,0,0,1-.307.229l-1.529.663.387-1.621a.834.834,0,0,1,.17-.34Zm2.351-.2-1.276-1.07,4.606-5.45,1.273,1.068Zm6.214-7.36-.536.635-1.273-1.068.538-.636a.83.83,0,0,1,1.271,1.069Z"
                                                      transform="translate(0 -0.005)"/>
                                            </g>
                                        </g>
                                        <g transform="translate(3.333 4.999)">
                                            <path class="a_notes"
                                                  d="M69.163,90H60.831a.833.833,0,1,0,0,1.666h8.332a.833.833,0,1,0,0-1.666Z"
                                                  transform="translate(-59.998 -90.003)"/>
                                        </g>
                                        <g transform="translate(3.333 10.054)">
                                            <path class="a_notes"
                                                  d="M74.163,181H60.831a.833.833,0,0,0,0,1.666H74.163a.833.833,0,0,0,0-1.666Z"
                                                  transform="translate(-59.998 -181.001)"/>
                                        </g>
                                        <g transform="translate(3.333 15.053)">
                                            <path class="a_notes"
                                                  d="M74.163,271H60.831a.833.833,0,1,0,0,1.666H74.163a.833.833,0,0,0,0-1.666Z"
                                                  transform="translate(-59.998 -271)"/>
                                        </g>
                                        <g transform="translate(3.333 20.053)">
                                            <path class="a_notes"
                                                  d="M69.163,361H60.831a.833.833,0,1,0,0,1.666h8.332a.833.833,0,1,0,0-1.666Z"
                                                  transform="translate(-59.998 -360.998)"/>
                                        </g>
                                    </g>
                                </svg>
                            </div>
                            <Notify />
                            <router-link to="/account">
                                <div class="name_container">
                                    <div class="avatar ml-3">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40"
                                             viewBox="0 0 24.532 24.531">
                                            <defs>
                                            </defs>
                                            <g transform="translate(0 -0.001)">
                                                <path class="a_profile"
                                                      d="M12.266,0A12.265,12.265,0,1,0,24.532,12.266,12.266,12.266,0,0,0,12.266,0Zm0,3.667A4.057,4.057,0,1,1,8.209,7.726,4.057,4.057,0,0,1,12.266,3.668Zm0,17.656A9,9,0,0,1,6.4,19.163a1.729,1.729,0,0,1-.607-1.314,4.092,4.092,0,0,1,4.111-4.09h4.721a4.086,4.086,0,0,1,4.1,4.09,1.725,1.725,0,0,1-.606,1.314A9,9,0,0,1,12.263,21.325Z"
                                                      transform="translate(0 0)"/>
                                            </g>
                                        </svg>
                                    </div>
                                    <div class="name ml-3">
                                        {{user.name}}
                                    </div>
                                </div>
                            </router-link>


                        </div>
                    </div>
                    <div class="sidebar_col">
                        <LeftSubBar @changeBar="changeSideBar"/>
                    </div>
                    <b-col class="content_col" :class="{'col_close': currentRoute === 'Message'}">
                        <slot :search="search"></slot>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    import LeftSidebar from "../LeftSidebar";
    import LeftSubBar from "../LeftSubBar";
    import {mapActions, mapGetters, mapMutations} from "vuex"
    import Notify from "../../views/Notify";

    export default {
        name: "Dashboard",
        data() {
            return {
                sideBar: false,
                search: '',
                user: {
                    name: ''
                }
            }
        },
        created() {
            this.getUserData()
                .then((res) => {
                    this.user.name = res.data.success.user.name
                })
            this.getKeyWords()
        },
        computed: {
          ...mapGetters(['keywords']),
            currentRoute (){
                return this.$route.name
            },
        },
        components: {
            Notify,
            LeftSubBar,
            LeftSidebar,
        },
        mounted() {

        },
        methods: {
            ...mapActions(['getUserData', 'getKeyWords']),
            ...mapMutations(['SEARCH_DATA']),
            changeSideBar() {
                this.sideBar = !this.sideBar
            },
            searchTerm(args) {
                alert.$emit("searchData", args.target.value);
                this.$store.commit('SEARCH_DATA', args.target.value)
            },
            // searchKeyword(item) {
                // var dom = document.getElementById('app').innerHTML
            //     var values = this.keywords.join("|");
            //     if (values) {
            //          return item.replace(new RegExp(values, 'gi'),  (match) => {
            //             return "<mark style='color: red'>" + match + "</mark>";
            //         });
            //
            //     }
            //
            // }

        }
    }
</script>

<style scoped lang="scss">

    .sidebar_container {
        margin-right: 0 !important;
        max-width: 290px;
        position: fixed;

        &.moveSideBar {
            max-width: 116px;
        }
    }
    .main_container {
        width: 100%;
        margin-left: 290px;
        &.sideBarChange {
            margin-left: 116px;
        }
        & .main_content {
            & .sidebar_col {
                width: 100%;
            }
            & .content_col {
                margin-left: 280px;
                padding-left: 40px;
                &.col_close {
                    margin-left: 0;
                    padding-left: 0;
                }
            }
            /*padding-left: 280px;*/
            & .top_menu-container {
                width: 100%;
                height: 88px;
                margin-left: 280px;
                background: #F6F6F6 0% 0% no-repeat padding-box;
                display: flex;
                justify-content: space-between;
                padding: 20px 40px 20px 36px;
                position: sticky;
                top: 0;
                z-index: 10;
                & .user_data {
                    display: flex;
                    align-items: center;
                    & .notes {
                        cursor: pointer;
                        & .a_notes {
                            fill: #5454ee;
                        }
                    }
                    & .bell {
                        cursor: pointer;
                    }
                    & .name_container {
                        display: flex;
                        align-items: center;
                        cursor: pointer;
                    }
                    & .avatar {
                        width: 40px;
                        height: 40px;
                        border-radius: 50%;
                        & .a_profile {
                            fill: #5454ee;
                        }
                    }
                    & .name {
                        color: $black;
                    }
                }
                & .search_container {
                    max-width: 530px;
                    width: 100%;
                    position: relative;
                    & .search_input {
                        width: 100%;
                        height: 44px;
                        background: $greyBlue;
                        padding: 0 50px 0 15px;
                        border-radius: 3px;
                        border: none;
                        &:focus {
                            box-shadow: none;
                            outline: none;
                        }
                    }
                    & svg {
                        position: absolute;
                        top: 14px;
                        right: 25px;
                    }
                }
            }

        }
    }
</style>