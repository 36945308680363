<template>
    <div>
        <div class="members_container">
            <div class="members_head">
                <div class="members_title">
                    Members
                </div>
            </div>
            <div class="members_content">
                <div class="table_container">
                    <b-table hover :items="allMembers" :fields="fields" responsive="sm">
                        <template v-slot:cell(index)="data">
                            <div class="">
                                {{data.index+1}}
                            </div>
                        </template>
                        <template v-slot:cell(work_spaces_name)="data">
                            <div class="item_name">
                                {{data.item.work_space[0].name}}
                            </div>
                        </template>

                        <template v-slot:cell(channel)="data">
                            <div class="">
                                {{data.item.channel[0].name}}
                            </div>
                        </template>

                        <template v-slot:cell(member)="data">
                            <div class="">
                                {{data.item.users[0].email}}
                            </div>
                        </template>

                        <template v-slot:cell(status)="data">
                            <div class="d-flex justify-content-center">
                                <b-form-checkbox switch v-model="data.item.users[0].role"/>
                            </div>
                        </template>
                        <template v-slot:cell(delete)="data">
                            <div class="d-flex justify-content-center">
                                <div class="trash_button" @click="memberDelete(data.item)"></div>
                            </div>
                        </template>

                    </b-table>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";

    export default {
        name: "Members",
        data() {
            return {
                selected: null,
                checked: false,
                fields: [
                    {
                        key: 'index',
                        label: 'No',
                    },
                    {
                        key: 'work_spaces_name',
                        label: 'WorkSpace',
                    },
                    {
                        key: 'channel',
                        label: 'Channel',
                    },
                    {
                        key: 'member',
                        label: 'Member',
                    },
                    {
                        key: 'status',
                        label: 'Status',
                    },
                    {
                        key: 'delete',
                        label: '',
                    },
                ],
            }
        },
        created() {
            this.getMembers()
        },
        computed: {
          ...mapGetters(['allMembers']),
        },
        methods: {
            ...mapActions(['getMembers', 'removeMember']),
            view(item) {
                console.log(item)
            },
            memberDelete(item) {
                this.removeMember(item)
                .then((res) => {
                    if(res.status === 200) {
                        this.getMembers()
                    }
                })
            }
        }
    }
</script>

<style scoped lang="scss">
    ::v-deep .custom-control-input:checked ~ .custom-control-label::before {
        background-color: $purple;
        border-color: $purple;
    }
.members {
    &_container {
        padding: 20px 40px 20px 1px;
    }
    &_head {
        padding-bottom: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    &_title {
        color: $black;
        font-weight: 600;
        font-size: 25px;
    }
    &_content {
        padding-top: 20px;
        & .table_container {
            font-size: 13px;

            ::v-deep thead {
                height: 62px;
                color: $primary;
                background: #E7E8F5 0 0 no-repeat padding-box;
                th {
                    vertical-align: middle;
                    max-width: 100px;
                    text-align: center;
                }
            }
            ::v-deep tbody {
                tr {
                    td{
                        vertical-align: middle;
                        text-align: center;
                        text-align: -webkit-center;
                    }
                }
            }
            & .view_btn {
                width: 22px;
                height: 22px;
                cursor: pointer;
                background: url("~@/assets/images/eye_icon.svg") center no-repeat;
            }
            & .trash_button {
                width: 22px;
                height: 22px;
                cursor: pointer;
                background: url("~@/assets/images/trash_icon.svg") center no-repeat;
            }
            & .item {
                display: flex;
                align-items: center;
                justify-content: space-between;
                height: 100%;
                & .dropdown_content {
                    ::v-deep button {
                        width: 130px;
                        height: 32px;
                        background: $light_purple;
                        color: $white;
                        font-size: 12px;
                        border: none;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                    }
                    ::v-deep .dropdown-menu {
                        min-width: 8rem;
                        border: none;
                        margin: 0;
                        padding: 0;
                    }
                    ::v-deep ul {
                        max-width: 130px;
                        width: 100%;
                        background: $greyBlue;
                        li {
                            border-top: 1px solid $light_purple;
                            font-size: 12px;
                            width: 100%;

                            a {
                                white-space: normal;
                                padding: 3px 8px;
                            }
                        }
                    }
                }
            }

        }

    }
}
::v-deep .table {
    border-bottom: 1px solid #dee2e6;
}
</style>