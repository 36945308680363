<template>
    <div>
        <div class="register_container">
            <div class="register_left-side">
                <router-link to="/">
                    <div class="register_title">
                        LogoName
                    </div>
                </router-link>
                <div class="register_left-content">
                    <img src="@/assets/images/register_img.png" alt="signUp_img">
                </div>
            </div>
            <div class="register_right-side">
                <div class="account_text">
                    <div class="dont_have">
                        Already have an account?  <router-link to="/login"><span class="sign_text"> Login</span></router-link>
                    </div>
                </div>
                <div class="register_right-side-content">
                    <form @submit.prevent="signUp()">
                        <div class="welcome_title">
                            Create account
                        </div>
                        <div class="input_item form-group">

                            <label >Username</label>
                            <b-form-input
                                    id="userName"
                                    v-model="userForm.name"
                                    type="text"
                                    :class="{ 'is-invalid': isSubmitted && $v.userForm.name.$error }"
                                    trim
                            ></b-form-input>
                            <div v-if="isSubmitted && !$v.userForm.name.required" class="invalid-feedback">Name field is required</div>
                        </div>
                        <div class="input_item form-group">

                            <label >Email</label>
                            <b-form-input
                                    id="email"
                                    v-model="userForm.email"
                                    type="email"
                                    :class="{ 'is-invalid': isSubmitted && $v.userForm.email.$error }"
                                    trim
                            ></b-form-input>
                            <div v-if="isSubmitted && $v.userForm.email.$error" class="invalid-feedback">
                                <span v-if="!$v.userForm.email.required">Email field is required</span>
                                <span v-if="!$v.userForm.email.email">Please provide valid email</span>
                            </div>
                        </div>
                        <div class="dual_inputs">
                            <div class="dual_input_item form-group">
                                <label >Password</label>
                                <b-form-input
                                        id="password"
                                        :type="r_pass"
                                        v-model="userForm.password"
                                        :class="{ 'is-invalid': isSubmitted && $v.userForm.password.$error }"
                                        trim
                                ></b-form-input>
                                <div class="eye_close" @click="showPassword('r_pass')"></div>
                                <div v-if="isSubmitted && $v.userForm.password.$error" class="invalid-feedback">
                                    <span v-if="!$v.userForm.password.required">Password field is required</span>
                                    <span v-if="!$v.userForm.password.minLength">Password should be at least 6 characters long</span>
                                </div>

                            </div>
                            <div class="dual_input_item form-group">
                                <label >Confirm</label>
                                <b-form-input
                                        id="confirm"
                                        :type="r_confirm"
                                        v-model="userForm.c_password"
                                        :class="{ 'is-invalid': isSubmitted && $v.userForm.c_password.$error }"
                                        trim
                                ></b-form-input>
                                <div class="eye_close" @click="showPassword('r_confirm')"></div>
                                <div v-if="isSubmitted && $v.userForm.c_password.$error" class="invalid-feedback">
                                    <span v-if="!$v.userForm.c_password.required">Confirm Password field is required</span>
                                    <span v-else-if="!$v.userForm.c_password.sameAsPassword">Passwords should be matched</span>
                                </div>

                            </div>
                        </div>

                        <div class="d-flex mt-4 mb-5 flex-column">
<!--                            <div class="agree_terms form-group form-check">-->
<!--                                <input type="checkbox" v-model="userForm.admin" id="admin" class="mt-1">-->
<!--                                <label for="admin">-->
<!--                                    Admin-->
<!--                                </label>-->
<!--                            </div>-->
                            <div class="agree_terms form-group form-check">
                                <input type="checkbox" v-model="userForm.agree" @change="$v.userForm.agree.$touch()" id="agree_terms" class="mt-1">
                                <label for="agree_terms" :class="{ 'is-invalid': isSubmitted && $v.userForm.agree.$error }">
                                    By creating an account, you agree to the Terms of Service and conditions and Privacy Policy.
                                </label>
                            </div>
                            <div v-if="isSubmitted && $v.userForm.agree.$error" class="invalid-feedback">
                                <span v-if="!$v.userForm.agree.required">Accept terms and conditions</span>
                            </div>

                        </div>
                        <div class="form-group">
                            <b-button variant="primary" class="register_btn" type="submit">Sign Up</b-button>
                        </div>
                    </form>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapActions} from "vuex"
    import {required, email, minLength, sameAs} from "vuelidate/lib/validators";
    export default {
        name: "Register",
        data() {
            return {
                userForm: {
                    name: '',
                    email: '',
                    password: '',
                    c_password : '',
                    agree: false,
                    // admin: false
                },
                isSubmitted: false,
                r_pass: 'password',
                r_confirm: 'password'
            }
        },
        validations: {
            userForm: {
                name: {
                    required
                },
                email: {
                    required,
                    email
                },
                password: {
                    required,
                    minLength: minLength(6)
                },
                c_password: {
                    required,
                    sameAsPassword: sameAs('password')
                },
                agree: {
                    required (val) {
                        return val
                    }
                }
            }
        },
        methods: {
            ...mapActions(['registerUser']),
            showPassword(item) {
                if(item === 'r_pass') {
                    if(this.r_pass === 'password') {
                        this.r_pass = 'text'
                    } else {
                        this.r_pass = 'password'
                    }
                } else {
                    if(this.r_confirm === 'password') {
                        this.r_confirm = 'text'
                    } else {
                        this.r_confirm = 'password'
                    }
                }
            },
            signUp() {
                this.isSubmitted = true;

                this.$v.$touch();
                if (this.$v.$invalid) {
                    return;
                }else {
                    this.registerUser(this.userForm)
                        .then(() => {
                            this.$router.push('/dashboard')
                        })
                }
            }
        }
    }
</script>

<style scoped lang="scss">
    .form-group > label {
        font-weight: 600;
    }
    .register {
        &_container {
            width: 100%;
            height: 100vh;
            border: 1px solid lightgray;
            background: #fff;
            display: flex;
        }
        &_left-side {
            width: 50%;
            background: transparent linear-gradient(180deg, #7882F3 0%, #7882F3 36%, #767FF2 57%, #6F76F1 69%, #6468F0 78%, #5454EE 99%, #5352EE 100%) 0% 0% no-repeat padding-box;
            /*border-radius: 0 30px 30px 0;*/
            padding: 65px 65px 0 140px;
            height: 100%;
            @media screen and (max-width: 1440px) {
                padding: 65px 65px 0 140px;
            }
            @media screen and (max-width: 1200px) {
                padding: 30px 40px 0 60px;
            }
            & a {
                text-decoration: none;
                &:hover {
                    text-decoration: none;
                }
            }
        }
        &_right-side {
            width: 50%;
            height: 99vh;
            display: flex;
            flex-direction: column;
            align-items: center;
            & .account_text {
                width: 100%;
                padding: 50px 150px;
                display: flex;
                justify-content: flex-end;
                @media screen and (max-width: 1440px) {
                    padding: 25px 90px;
                }
                & .dont_have {
                    color: $black;
                    font-weight: 500;
                    font-size: 18px;
                    @media screen and (max-width: 1440px) {
                        font-size: 16px;
                    }
                    @media screen and (max-width: 1200px) {
                        font-size: 14px;
                    }
                    & .sign_text {
                        color: $purple;
                        border-bottom: 1px solid $purple;
                    }
                }
            }
            &-content {
                max-width: 550px;
                width: 100%;
                height: 100%;
                display: flex;
                padding: 0 20px;
                justify-content: center;
                flex-direction: column;

                @media screen and (max-width: 1200px) {
                    max-width: 450px;
                }
                & .welcome_title {
                    font-size: 35px;
                    font-weight: 600;
                    color: $purple;
                    padding: 20px 0;
                    @media screen and (max-width: 1440px) {
                        font-size: 32px;
                    }
                    @media screen and (max-width: 1200px) {
                        font-size: 30px;
                        padding: 15px 0;
                    }
                }
                & .input_item {
                    width: 100%;
                    margin-top: 20px;
                    @media screen and (max-width: 1200px) {
                        margin-top: 0;
                    }
                    & label {
                        color: $darkBlue;
                        font-weight: 600;
                        font-size: 16px;
                    }
                    & input {
                        background: $greyBlue 0 0 no-repeat padding-box;
                        &:focus {
                            box-shadow: none;
                            border: 1px solid $purple;
                            border-radius: 3px;
                        }
                    }
                }
                & .dual_inputs {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    @media screen and (max-width: 1200px) {
                        flex-direction: column;
                    }
                    & .dual_input_item {
                        width: 250px;
                        position: relative;
                        margin-top: 20px;
                        @media screen and (max-width: 1200px) {
                            width: 100%;
                            margin-top: 0;
                        }
                        & .eye_close {
                            position: absolute;
                            width: 30px;
                            height: 36px;
                            right: 15px;
                            top: 33px;
                            background-image: url("~@/assets/images/eye_close.svg");
                            background-repeat: no-repeat;
                            background-position: center;
                            cursor: pointer;
                        }
                        & label {
                            color: $darkBlue;
                            font-weight: 600;
                            font-size: 16px;
                        }
                        & input {
                            background: $greyBlue 0 0 no-repeat padding-box;
                            &:focus {
                                box-shadow: none;
                                border: 1px solid $purple;
                                border-radius: 3px;
                            }
                        }
                    }
                }
                & .agree_terms {
                    display: flex;
                    & label {
                        margin-left: 15px !important;
                        color: $darkBlue;
                        font-size: 16px;
                        &.is-invalid {
                            color: #dc3545;
                        }
                    }
                    &.form-check {
                        padding-left: 0;
                    }
                }
                & .register_btn {
                    width: 100%;
                    height: 60px;
                    background: transparent linear-gradient(180deg, #84EEEB 0%, #3AE4DF 100%) 0% 0% no-repeat padding-box;
                    border-radius: 10px;
                    color: $primary;
                    font-weight: 600;
                    border: none;
                    @media screen and (max-width: 1200px) {
                        height: 50px;
                    }
                }
            }
        }
        &_title {
            color: #fff;
            font-size: 29px;
            font-weight: 500;
            line-height: 62px;
            @media screen and (max-width: 1440px) {
                font-size: 48px;
                line-height: 58px;
            }
            @media screen and (max-width: 1200px) {
                font-size: 46px;
                line-height: 48px;
            }
        }
        &_left-content {
            padding: 140px 25px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            & img {
                background-position: center;
                position: relative;
            }
        }
    }
</style>