<template>
    <div>
        <div class="page_content">
            <div class="page_head">
                <div class="page_title">
                    Lesson learnt
                </div>
                <div class="page_head-drop-btn">
                    <div class="input_item">
                        <div class="select_arrow">
                            <b-form-select  @change="workspaceChange">
                                <template #first>
                                    <b-form-select-option value="all" >All</b-form-select-option>
                                </template>
                                <option
                                        v-for="(option, id) in workspaces"
                                        :key="id"
                                        :value="option.id"
                                        :title="option.name || null"
                                >
                                    {{option.name}}
                                </option>
                            </b-form-select>
                            <div class="arrow_down"></div>
                        </div>
                    </div>
                    <b-button class="create_btn" @click="createLesson()" id="lesson-btn">Create New Lesson Learnt</b-button>
                    <b-button class="export_btn" @click="lessonExport()" id="export-btn" :disabled="!lessonLearn.work_space_id">
                        <svg xmlns="http://www.w3.org/2000/svg" width="19.162" height="19.146" viewBox="0 0 19.162 19.146">
                            <g id="Group_7438" data-name="Group 7438" transform="translate(-434.632 -314.63)">
                                <path id="Path_7053" data-name="Path 7053" d="M444.218,314.631h8.165a1.216,1.216,0,0,1,1.394,1.389q0,1.715,0,3.43a1.225,1.225,0,1,1-2.439-.007c0-.671-.011-1.342,0-2.013.006-.279-.095-.363-.368-.363q-6.767.009-13.534,0c-.269,0-.372.076-.371.359q.01,6.786,0,13.571c0,.264.1.342.351.341q6.786-.008,13.571,0c.293,0,.354-.121.35-.381-.012-.683-.007-1.367,0-2.051a1.22,1.22,0,1,1,2.438-.01q.008,1.808,0,3.616a1.174,1.174,0,0,1-1.26,1.259q-8.314,0-16.629,0a1.173,1.173,0,0,1-1.253-1.265q0-8.3,0-16.591a1.185,1.185,0,0,1,1.308-1.286Q440.08,314.628,444.218,314.631Z" fill="#fff"/>
                                <path id="Path_7054" data-name="Path 7054" d="M533.141,396.611c-.051-.2-.178-.267-.261-.372a1.238,1.238,0,0,1,.069-1.691,1.213,1.213,0,0,1,1.687-.031c.408.363.782.764,1.169,1.149s.776.771,1.159,1.16a1.277,1.277,0,0,1,.011,2q-1.075,1.087-2.161,2.161a1.249,1.249,0,1,1-1.8-1.714c.04-.045.078-.093.13-.154-.133-.107-.273-.062-.4-.062q-3.243-.005-6.486,0a1.222,1.222,0,0,1-.953-1.986,1.181,1.181,0,0,1,1.011-.457c2.125.006,4.249,0,6.374,0Z" transform="translate(-83.657 -73.628)" fill="#fff"/>
                            </g>
                        </svg>
                        Export
                    </b-button>
                </div>
            </div>
            <div class="lesson_table">
                <div class="table_title">
                     {{lessonLearn.workspace_name}}
                </div>
                <div class="table_container">
                    <b-table hover :items="lesson_data" :fields="fields"  responsive="sm">
                        <template v-slot:cell(index)="data">
                            <div class="">
                                {{data.index+1}}
                            </div>
                        </template>

                        <template v-slot:cell(category)="data">
                            <div class="item_name">
                                {{data.item.category}}
                            </div>
                        </template>

                        <template v-slot:cell(subject)="data">
                            <div class="">
                                {{data.item.subject}}
<!--                                <div class="view_btn"></div>-->
                            </div>
                        </template>

                        <template v-slot:cell(observation)="data">
                            <div class="">
                                {{data.item.observation}}
                            </div>
                        </template>

                        <template v-slot:cell(suggestions)="data">
                            <div class="">
                                {{data.item.suggestions_for_improvement}}
                            </div>
                        </template>

                        <template v-slot:cell(initiator)="data">
                            <div class="">
                                {{data.item.initiator.name}}
                            </div>
                        </template>

                        <template v-slot:cell(keywords)="data">
                            <div class="keyword_items">
                                <div v-for="word in data.item.keywords">{{word.keyword}}</div>
                                <!--                                {{getDate(data.item.created_at)}}-->
                            </div>
                        </template>

                        <template v-slot:cell(notifier)="data">
                            <div class="item_switch">
                                <b-form-checkbox
                                        switch
                                        size="lg"
                                        v-model="data.item.key_status_notify"
                                        value="1"
                                        unchecked-value="0"
                                        @change="statusChange($event, data.item)"
                                />
                            </div>
                        </template>

                        <template v-slot:cell(edit)="data">
                            <div class="d-flex justify-content-center">
                                <div class="edit_btn" id="lesson_edit-btn" @click="lessonEdit(data.item)"></div>
                            </div>
                        </template>
                        <template v-slot:cell(download)="data">
                            <div class="d-flex justify-content-center">
                                <div class="download_btn" id="lesson_download-btn" @click="lessonDownload(data.item)"></div>
                            </div>
                        </template>

                        <template v-slot:cell(delete)="data">
                            <div class="d-flex justify-content-center">
                                <div class="trash_button" id="remove_lesson-btn" @click="lessonDelete(data.item)"></div>
                            </div>
                        </template>

                    </b-table>
                </div>
            </div>
        </div>
<!--Lesson create modal start-->
        <b-modal ref="lesson-modal" centered hide-header hide-footer hide-header-close>
            <div class="lesson_modal_content">
                <b-icon icon="x" class="x_btn" aria-hidden="true" @click="lessonModal()"></b-icon>
                <div class="d-flex">
                    <div class="lesson_title">
                        Create New Lesson Learnt
                    </div>
                </div>

                <div class="input_content">
                    <div class="input_item">
                        <label>Workspace</label>
                        <div class="select_arrow">
                            <b-form-select v-model="lessonData.work_space_id"
                                           @change="lessonWorkspaceChange">
                                <option
                                        v-for="(option, id) in workspaces"
                                        :key="id"
                                        :value="option.id"
                                        :title="option.name || null"
                                >
                                    {{option.name}}
                                </option>
                            </b-form-select>
                            <div class="arrow_down"></div>
<!--                            <span v-if="depositoryErrors['work_space_id']" class="text_danger mt-1">{{depositoryErrors['work_space_id'][0]}}</span>-->
                        </div>
                    </div>

                    <div class="input_item">
                        <label>Project Name(if any)</label>
                        <b-form-input
                                v-model="lessonData.projectName"
                                type="text"
                                trim
                        ></b-form-input>
<!--                        <span v-if="depositoryErrors['depository_title']" class="text_danger mt-1">{{depositoryErrors['depository_title'][0]}}</span>-->
                    </div>

                    <div class="input_item">
                        <label>Discipline/Category</label>
                        <b-form-input
                                v-model="lessonData.category"
                                type="text"
                                trim
                        ></b-form-input>
                        <!--                        <span v-if="depositoryErrors['depository_title']" class="text_danger mt-1">{{depositoryErrors['depository_title'][0]}}</span>-->
                    </div>

                    <div class="input_item">
                        <label>Subject</label>
                        <b-form-input
                                v-model="lessonData.subject"
                                type="text"
                                trim
                        ></b-form-input>
                        <!--                        <span v-if="depositoryErrors['depository_title']" class="text_danger mt-1">{{depositoryErrors['depository_title'][0]}}</span>-->
                    </div>
                    <div class="input_item">
                        <label>Observation</label>
                        <b-form-input
                                v-model="lessonData.observation"
                                type="text"
                                trim
                        ></b-form-input>
                        <!--                        <span v-if="depositoryErrors['depository_title']" class="text_danger mt-1">{{depositoryErrors['depository_title'][0]}}</span>-->
                    </div>

                    <div class="input_item">
                        <label>Suggestions for Improvement</label>
                        <b-form-input
                                v-model="lessonData.suggestions"
                                type="text"
                                trim
                        ></b-form-input>
                        <!--                        <span v-if="depositoryErrors['depository_title']" class="text_danger mt-1">{{depositoryErrors['depository_title'][0]}}</span>-->
                    </div>
                    <div class="input_item">
                        <label>Keywords</label>
                        <multiselect
                                v-model="keywords"
                                tag-placeholder="Add new Keyword"
                                placeholder=""
                                label="name" @tag="addKeyword"
                                track-by="name"
                                :options="keyword_options"
                                :multiple="true"
                                :taggable="true"
                        ></multiselect>

                        <!--                        <b-form-input-->
<!--                                v-model="lessonData.keywords"-->
<!--                                type="text"-->
<!--                                trim-->
<!--                        ></b-form-input>-->
                        <!--                        <span v-if="depositoryErrors['depository_title']" class="text_danger mt-1">{{depositoryErrors['depository_title'][0]}}</span>-->
                    </div>


<!--                    <div class="input_item">-->
<!--                        <label>Settings</label>-->
<!--                        <b-form-checkbox-->
<!--                                switch-->
<!--                                size="lg"-->
<!--                                v-model="depositoryData.status"-->
<!--                                value="1"-->
<!--                                unchecked-value="0"-->
<!--                        >Disable editing-->
<!--                        </b-form-checkbox>-->
<!--                    </div>-->


                </div>

                <div class="done_btn-container">
                    <b-button class="done_btn" @click="createNewLesson()">Done</b-button>
                </div>
            </div>
        </b-modal>
<!--Lesson create modal end-->

<!--Lesson create modal start-->
        <b-modal ref="lesson_edit-modal" centered hide-header hide-footer hide-header-close>
            <div class="lesson_modal_content">
                <b-icon icon="x" class="x_btn" aria-hidden="true" @click="lessonEditModal()"></b-icon>
                <div class="d-flex">
                    <div class="lesson_title">
                        Edit Lesson Learnt
                    </div>
                </div>

                <div class="input_content">
                    <div class="input_item">
                        <label>Workspace</label>
                        <div class="select_arrow">
                            <b-form-select v-model="lessonEditData.work_space_id"
                                           @change="lessonEditWorkspaceChange">
                                <option
                                        v-for="(option, id) in workspaces"
                                        :key="id"
                                        :value="option.id"
                                        :title="option.name || null"
                                >
                                    {{option.name}}
                                </option>
                            </b-form-select>
                            <div class="arrow_down"></div>
                            <!--                            <span v-if="depositoryErrors['work_space_id']" class="text_danger mt-1">{{depositoryErrors['work_space_id'][0]}}</span>-->
                        </div>
                    </div>

                    <div class="input_item">
                        <label>Project Name(if any)</label>
                        <b-form-input
                                v-model="lessonEditData.projectName"
                                type="text"
                                trim
                        ></b-form-input>
                        <!--                        <span v-if="depositoryErrors['depository_title']" class="text_danger mt-1">{{depositoryErrors['depository_title'][0]}}</span>-->
                    </div>

                    <div class="input_item">
                        <label>Discipline/Category</label>
                        <b-form-input
                                v-model="lessonEditData.category"
                                type="text"
                                trim
                        ></b-form-input>
                        <!--                        <span v-if="depositoryErrors['depository_title']" class="text_danger mt-1">{{depositoryErrors['depository_title'][0]}}</span>-->
                    </div>

                    <div class="input_item">
                        <label>Subject</label>
                        <b-form-input
                                v-model="lessonEditData.subject"
                                type="text"
                                trim
                        ></b-form-input>
                        <!--                        <span v-if="depositoryErrors['depository_title']" class="text_danger mt-1">{{depositoryErrors['depository_title'][0]}}</span>-->
                    </div>
                    <div class="input_item">
                        <label>Observation</label>
                        <b-form-input
                                v-model="lessonEditData.observation"
                                type="text"
                                trim
                        ></b-form-input>
                        <!--                        <span v-if="depositoryErrors['depository_title']" class="text_danger mt-1">{{depositoryErrors['depository_title'][0]}}</span>-->
                    </div>

                    <div class="input_item">
                        <label>Suggestions for Improvement</label>
                        <b-form-input
                                v-model="lessonEditData.suggestions"
                                type="text"
                                trim
                        ></b-form-input>
                        <!--                        <span v-if="depositoryErrors['depository_title']" class="text_danger mt-1">{{depositoryErrors['depository_title'][0]}}</span>-->
                    </div>
                    <div class="input_item">
                        <label>Keywords</label>
                        <multiselect
                                v-model="editKeywords"
                                tag-placeholder="Add new Keyword"
                                placeholder=""
                                label="name" @tag="addEditKeyword"
                                track-by="name"
                                :options="edit_keyword_options"
                                :multiple="true"
                                :taggable="true"
                        ></multiselect>

                        <!--                        <b-form-input-->
                        <!--                                v-model="lessonData.keywords"-->
                        <!--                                type="text"-->
                        <!--                                trim-->
                        <!--                        ></b-form-input>-->
                        <!--                        <span v-if="depositoryErrors['depository_title']" class="text_danger mt-1">{{depositoryErrors['depository_title'][0]}}</span>-->
                    </div>


                    <!--                    <div class="input_item">-->
                    <!--                        <label>Settings</label>-->
                    <!--                        <b-form-checkbox-->
                    <!--                                switch-->
                    <!--                                size="lg"-->
                    <!--                                v-model="depositoryData.status"-->
                    <!--                                value="1"-->
                    <!--                                unchecked-value="0"-->
                    <!--                        >Disable editing-->
                    <!--                        </b-form-checkbox>-->
                    <!--                    </div>-->


                </div>

                <div class="done_btn-container">
                    <b-button class="done_btn" @click="editLesson()">Done</b-button>
                </div>
            </div>
        </b-modal>
<!--Lesson create modal end-->

<!--Lesson delete modal start -->
        <b-modal ref="lesson_delete-modal" centered hide-header hide-footer hide-header-close>
            <div class="modal_content">
                <b-icon icon="x" class="x_btn" aria-hidden="true" @click="lessonDeleteModal()"></b-icon>
                <div class="d-flex flex-column">
                    <div class="modal_title">
                        You are about to delete this lesson learnt
                    </div>
                    <div class="modal_text">
                        Do you want to proceed?
                    </div>
                    <div class="modal_buttons">
                        <div class="cancel_btn" @click="lessonDeleteModal()">Cancel</div>
                        <b-button class="delete_btn ml-3" @click="removeLesson()">Delete</b-button>
                    </div>

                </div>
            </div>
        </b-modal>
<!--Lesson delete modal end -->

    </div>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";

    export default {
        name: "Lesson",
        data() {
            return {
                fields: [
                    {
                        key: 'index',
                        label: 'No',
                    },
                    {
                        key: 'category',
                        label: 'Discipline/Category',
                    },
                    {
                        key: 'subject',
                        label: 'Subject',
                    },
                    {
                        key: 'observation',
                        label: 'Observation',
                    },
                    {
                        key: 'suggestions',
                        label: 'Suggestions for Improvement',
                    },
                    {
                        key: 'initiator',
                        label: 'Initiator',
                    },
                    {
                        key: 'keywords',
                        label: 'Keywords',
                    },
                    {
                        key: 'notifier',
                        label: 'Activate Keyword Notifier'
                    },
                    {
                        key: 'edit',
                        label: 'Edit',
                    },
                    {
                        key: 'download',
                        label: 'Download'
                    },
                    {
                        key: 'delete',
                        label: 'Delete',
                    },
                ],
                lesson_data: [],
                lessonData: {
                    work_space_id: null,
                    projectName: null,
                    category: null,
                    subject: null,
                    observation: null,
                    suggestions: null,
                    keywords: []
                },
                lessonEditData: {
                    id: null,
                    work_space_id: null,
                    projectName: null,
                    category: null,
                    subject: null,
                    observation: null,
                    suggestions: null,
                    key_status_notify: null,
                    keywords: []
                },
                lesson_delete_id: null,
                keywords: [],
                editKeywords: [],
                keyword_options: [],
                edit_keyword_options: [],
                lessonLearn: {
                    work_space_id: null,
                    workspace_name: 'All',
                    lesson_id: null
                }

            }
        },
        created() {
            this.getAllWorkspaces()
            this.getAllLessons()
            .then(res => {
                if(res.status === 200) {
                    this.lesson_data = res.data.success
                }
            })
        },
        computed: {
          ...mapGetters(['workspaces']),
        },
        methods: {
            ...mapActions([
                'getAllWorkspaces',
                'lessonCreate',
                'getAllLessons',
                'getLessonsById',
                'deleteLesson',
                'updateLesson',
                'getKeyWords',
                'exportLesson'
            ]),

            workspaceChange(args) {
                if(args == 'all') {
                    this.lessonLearn.work_space_id = null
                    this.getAllLessons()
                        .then(res => {
                            if(res.status === 200) {
                                this.lessonLearn.workspace_name = 'All'
                                this.lesson_data = res.data.success
                            }
                        })
                } else {
                    this.lessonLearn.work_space_id = args
                    this.workspaces.forEach(item => {
                        if(item.id == args) {
                            this.lessonLearn.workspace_name = item.name
                        }
                    })
                    this.getLessonsById(this.lessonLearn)
                        .then(res => {
                            if(res.status === 200) {
                                this.lesson_data = res.data.success
                            }
                        })
                }
            },
            lessonWorkspaceChange(args) {
                this.lessonData.work_space_id = args
            },
            lessonEditWorkspaceChange(args) {
                this.lessonEditData.work_space_id = args
            },
            addKeyword (word) {
                const keyWord = {
                    name: word,
                }
                this.keyword_options.push(keyWord)
                this.keywords.push(keyWord)
            },
            addEditKeyword (word) {
                const keyWord = {
                    name: word,
                }
                this.edit_keyword_options.push(keyWord)
                this.editKeywords.push(keyWord)
            },
            createLesson() {
                this.lessonData.work_space_id = null
                this.lessonData.projectName = null
                this.lessonData.category = null
                this.lessonData.subject = null
                this.lessonData.observation = null
                this.lessonData.suggestions = null
                this.lessonData.keywords = []
                this.lessonModal()
            },
            lessonModal() {
                this.$refs['lesson-modal'].toggle('#lesson-btn')
            },
            createNewLesson() {
                this.keywords.forEach(item => {
                    this.lessonData.keywords.push(item.name)
                })
                this.lessonCreate(this.lessonData)
                .then(res => {
                    if(res.status === 200) {
                        this.lessonModal()
                        if(this.lessonLearn.work_space_id) {
                            this.getLessonsById(this.lessonLearn)
                                .then(res => {
                                    if(res.status === 200) {
                                        this.lesson_data = res.data.success
                                    }
                                })
                        } else {
                            this.getAllLessons()
                                .then(res => {
                                    if(res.status === 200) {
                                        this.lesson_data = res.data.success
                                    }
                                })
                        }
                    }
                })
            },

            lessonEdit(item) {
                this.lessonEditData.id = item.id
                this.edit_keyword_options = []
                this.editKeywords =[]
                this.lessonEditData.keywords = []
                this.lessonEditData.work_space_id = item.work_space_id
                this.lessonEditData.projectName = item.project_name
                this.lessonEditData.category = item.category
                this.lessonEditData.subject = item.subject
                this.lessonEditData.observation = item.observation
                this.lessonEditData.suggestions = item.suggestions_for_improvement
                this.lessonEditData.key_status_notify = item.key_status_notify
                item.keywords.forEach(word => {
                    let keyword = {
                        name: word.keyword
                    }
                    this.edit_keyword_options.push(keyword)
                    this.editKeywords.push(keyword)
                })
                this.lessonEditData.keywords = []
                this.lessonEditModal()
            },
            lessonEditModal() {
                this.$refs['lesson_edit-modal'].toggle('#lesson_edit-btn')
            },
            editLesson() {
                this.lessonEditData.keywords = []
                this.editKeywords.forEach(item => {
                    this.lessonEditData.keywords.push(item.name)
                })
                this.updateLesson(this.lessonEditData)
                .then(res => {
                    if(res.status === 200) {
                        this.lessonEditModal()
                        if(this.lessonLearn.work_space_id) {
                            this.getLessonsById(this.lessonLearn)
                                .then(res => {
                                    if(res.status === 200) {
                                        this.lesson_data = res.data.success
                                    }
                                })
                        } else {
                            this.getAllLessons()
                                .then(res => {
                                    if(res.status === 200) {
                                        this.lesson_data = res.data.success
                                    }
                                })
                        }
                    }
                })

            },
            statusChange(args, data) {
                var allKeywords = []
                    data.keywords.forEach(item => {
                        allKeywords.push(item.keyword)
                    })
                let sendData = {
                    work_space_id: data.work_space_id,
                    projectName: data.project_name,
                    category: data.category,
                    subject: data.subject,
                    observation: data.observation,
                    suggestions: data.suggestions_for_improvement,
                    key_status_notify: args,
                    keywords: allKeywords,
                    id: data.id
                }
                this.updateLesson(sendData)
                .then(res => {
                    if(res.status === 200) {
                        if(this.lessonLearn.work_space_id) {
                            this.getLessonsById(this.lessonLearn)
                                .then(res => {
                                    if(res.status === 200) {
                                        this.lesson_data = res.data.success
                                    }
                                })
                        } else {
                            this.getAllLessons()
                                .then(res => {
                                    if(res.status === 200) {
                                        this.lesson_data = res.data.success
                                    }
                                })
                        }
                        this.getKeyWords()
                    }
                })
            },
            lessonExport() {
                this.exportLesson(this.lessonLearn)
                .then(res => {
                    if(res.status === 200) {
                        location.href = res.data
                    }
                })
            },
            lessonDownload(item) {
                this.lessonLearn.work_space_id = null
                this.lessonLearn.lesson_id = item.id
                this.exportLesson(this.lessonLearn)
                    .then(res => {
                        if(res.status === 200) {
                            location.href = res.data
                        }
                    })
            },
            lessonDelete(item) {
                this.lesson_delete_id = item.id
                this.lessonDeleteModal()
            },
            lessonDeleteModal() {
                this.$refs['lesson_delete-modal'].toggle('#remove_lesson-btn')
            },
            removeLesson() {
                this.deleteLesson(this.lesson_delete_id)
                .then(res => {
                    if(res.status === 200) {
                        this.lessonDeleteModal()
                        if(this.lessonLearn.work_space_id) {
                            this.getLessonsById(this.lessonLearn)
                                .then(res => {
                                    if(res.status === 200) {
                                        this.lesson_data = res.data.success
                                    }
                                })
                        } else {
                            this.getAllLessons()
                                .then(res => {
                                    if(res.status === 200) {
                                        this.lesson_data = res.data.success
                                    }
                                })
                        }
                        this.getKeyWords()
                    }
                })
            }
        }
    }
</script>

<style scoped lang="scss">
    ::v-deep .custom-select {
        border: none;
    }
.page {
    &_head {
        padding-bottom: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        &-drop-btn {
            display: flex;
            align-items: center;
            & .input_item {
                & .select_arrow {
                    position: relative;
                    width: 240px;
                    & .arrow_down {
                        position: absolute;
                        top: 14px;
                        right: 15px;
                        width: 14px;
                        height: 10px;
                        pointer-events: none;
                        background: url("~@/assets/images/chevron_down.svg") right  no-repeat;
                    }
                }
                & select {
                    background: $greyBlue 0 0 no-repeat padding-box;
                    border-radius: 4px;
                    padding-right: 5px;
                }
            }

        }
    }
    &_title {
        color: $black;
        font-weight: 600;
        font-size: 25px;
    }
    &_content {
        padding: 20px 40px 20px 1px;
        & .create_btn {
            color: $primary;
            height: 44px;
            font-weight: 600;
            background: transparent linear-gradient(180deg, #84EEEB 0%, #3AE4DF 100%) 0% 0% no-repeat padding-box;
            border-radius: 10px;
            padding: 0 20px;
            border: none;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 20px;
            &:focus,
            &:active{
                box-shadow: none;
                color: $primary;
            }
        }
        & .export_btn {
            color: $white;
            height: 44px;
            font-weight: 600;
            background: transparent linear-gradient(180deg, #7882F3 0%, #8F97EE 100%) 0% 0% no-repeat padding-box;
            border-radius: 10px;
            padding: 0 20px;
            border: none;
            display: flex;
            justify-content: center;
            align-items: center;
            & svg {
                margin-right: 10px;
            }
            &:focus,
            &:active{
                box-shadow: none;
            }
        }
        & .lesson_table {
            padding: 20px 0;
            & .table_title {
                width: 100%;
                height: 60px;
                background: #84EEEB;
                color: $grey;
                font-size: 16px;
                font-weight: 600;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
        & .table_container {
            font-size: 13px;
            ::v-deep thead {
                height: 62px;
                color: $primary;
                background: #E7E8F5 0 0 no-repeat padding-box;
                th {
                    vertical-align: middle;
                    max-width: 100px;
                    text-align: center;
                }
            }
            ::v-deep tbody {
                tr {
                    td{
                        vertical-align: middle;
                        text-align: center;
                        text-align: -webkit-center;
                    }
                }
            }
            & .view_btn {
                width: 22px;
                height: 22px;
                cursor: pointer;
                background: url("~@/assets/images/eye_icon.svg") center no-repeat;
            }
            & .edit_btn {
                width: 22px;
                height: 22px;
                cursor: pointer;
                background: url("~@/assets/images/edit_icon.svg") center no-repeat;
            }
            & .download_btn {
                width: 22px;
                height: 22px;
                cursor: pointer;
                background: url("~@/assets/images/arrow-left .svg") center no-repeat;
                transform: rotate(-90deg);
                background-size: contain;
            }
            & .trash_button {
                width: 22px;
                height: 22px;
                cursor: pointer;
                background: url("~@/assets/images/trash_icon.svg") center no-repeat;
            }
            & .item {
                display: flex;
                align-items: center;
                justify-content: space-between;
                height: 100%;
                &_switch {
                   ::v-deep label {
                       &:before {
                           background: #D4D4D4;
                       }
                       &:after {
                           background: #F3F3F3;
                       }
                   }
                    ::v-deep .custom-control-input:checked ~ .custom-control-label::after {
                        background: #3AE4DF !important;
                    }
                    ::v-deep .custom-control-input:checked ~ .custom-control-label::before {
                        background: $purple !important;
                    }
                }
                & .dropdown_content {
                    ::v-deep button {
                        width: 130px;
                        height: 32px;
                        background: $light_purple;
                        color: $white;
                        font-size: 12px;
                        border: none;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                    }
                    ::v-deep .dropdown-menu {
                        min-width: 8rem;
                        border: none;
                        margin: 0;
                        padding: 0;
                    }
                    ::v-deep ul {
                        max-width: 130px;
                        width: 100%;
                        background: $greyBlue;
                        li {
                            border-top: 1px solid $light_purple;
                            font-size: 12px;
                            width: 100%;

                            a {
                                white-space: normal;
                                padding: 3px 8px;
                            }
                        }
                    }
                }
            }
            .keyword_items {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-evenly;
                max-width: 130px;
            }
            & .reply_btn {
                background: $light_purple;
                border: none;
                font-size: 14px;
                border-radius: 5px;
                &:focus {
                    box-shadow: none;
                }
                &:hover {
                    opacity: .9;
                }
            }
            & .status_item {
                max-width: 110px;
                width: 100%;
            }
            & .status_dots {
                display: flex;
                justify-content: space-evenly;
                & .dot_opy {
                    opacity: 1;
                }
            }
            & .yellow_dot {
                width: 20px;
                height: 20px;
                border-radius: 50%;
                background: #F2EB1A;
                opacity: 0.5;
                cursor: pointer;
            }
            & .green_dot {
                width: 20px;
                height: 20px;
                border-radius: 50%;
                background: #1CD65A;
                opacity: 0.5;
                cursor: pointer;
            }
            & .orange_dot {
                width: 20px;
                height: 20px;
                border-radius: 50%;
                background: #FFB26E;
                opacity: 0.5;
                cursor: pointer;
            }
            & .purple_dot {
                width: 20px;
                height: 20px;
                border-radius: 50%;
                background: $light_purple;
                opacity: 0.5;
                cursor: pointer;
            }
            & .active_dot {
                opacity: 1;
                box-shadow: 0 0 0 3px rgb(255, 255, 255) inset;
                border: 2px solid transparent;
            }
        }
    }
}
    /*/////////////*/
    .lesson {
        &_modal_content {
            position: relative;
            padding: 0 10px;
            width: 580px;

            & .x_btn {
                position: absolute;
                top: 0;
                right: 0;
                width: 25px;
                height: 25px;
                cursor: pointer;
                color: $light_purple;
            }

            & .input_content {
                display: flex;
                flex-direction: column;

                margin-bottom: 15px;

                & .input_item {
                    padding: 5px 0;
                    width: 100%;
                    flex-direction: column;

                    ::v-deep .custom-control-input:checked ~ .custom-control-label::before {
                        background-color: $lightBlue;
                        border-color: $purple;
                    }

                    ::v-deep .custom-control-input:checked ~ .custom-control-label::after {
                        background-color: $purple;
                    }

                    ::v-deep .custom-control-label {
                        color: #4A4A4D;
                        padding-top: 4px;
                        font-size: 16px;
                        cursor: pointer;
                    }

                    & .select_arrow {
                        position: relative;

                        & .arrow_down {
                            position: absolute;
                            top: 14px;
                            right: 15px;
                            width: 14px;
                            height: 10px;
                            pointer-events: none;
                            background: url("~@/assets/images/chevron_down.svg") right no-repeat;
                        }
                    }

                    & label {
                        color: $primary;
                        font-weight: 600;
                        font-size: 14px;
                    }

                    & input {
                        border: none;
                        background: $greyBlue 0 0 no-repeat padding-box;
                        border-radius: 3px;

                        &:focus {
                            box-shadow: none;
                            outline: none;
                        }
                    }

                    & select {
                        background: $greyBlue 0 0 no-repeat padding-box;
                        border-radius: 4px;
                        padding-right: 5px;
                    }

                    ::v-deep .multiselect__tags {
                        /*border: 1px solid #4FA7F8 !important;*/
                        background: $greyBlue 0 0 no-repeat padding-box;
                        border-radius: 3px;
                        padding: 0 40px 0 8px;
                        .multiselect__tag {
                            /*background: #D8DDE2 0% 0% no-repeat padding-box;*/
                            background: $white 0% 0% no-repeat padding-box;
                            color: #000000;
                            margin: 8px 10px 0 0;
                            .multiselect__tag-icon {
                                &:after {
                                    color: $purple;
                                }
                                &:hover {
                                    background: #D8DDE2;
                                }
                            }
                        }
                    }
                    ::v-deep .multiselect {
                        .multiselect__select {
                            top: 5px;
                        }
                        .multiselect__element {
                            .multiselect__option.multiselect__option--highlight {
                                background: #D8DDE2;
                                color: #1c1c1c;
                                &:after {
                                    background: #D8DDE2;
                                    color: #1c1c1c;
                                }
                            }
                        }
                    }
                    ::v-deep .multiselect__content-wrapper {
                        font-size: 14px;
                    }
                    .multiselect__option--selected.multiselect__option--highlight {
                        background: #D8DDE2;
                    }











                }
            }

            & .done_btn-container {
                display: flex;
                justify-content: flex-end;
                padding: 20px 0 10px;

                & .done_btn {
                    background: transparent linear-gradient(180deg, #84EEEB 0%, #3AE4DF 100%) 0% 0% no-repeat padding-box;
                    border-radius: 10px;
                    width: 110px;
                    height: 44px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: $primary;
                    font-weight: 600;
                    border: none;
                }
            }
        }

        &_title {
            width: 100%;
            font-size: 25px;
            font-weight: 500;
            color: $grey;
            padding-bottom: 10px;
            margin-bottom: 20px;
            border-bottom: 1px solid #CACDF6;
        }
    }

    ::v-deep .modal-content {
        border: none !important;
        width: 700px;

        ::v-deep .modal-body {
            width: 600px !important;
        }
    }
    ::v-deep .modal_content {
        border: none !important;
        width: 600px;
        position: relative;

        ::v-deep .modal-body {
            width: 600px !important;
        }

        & .x_btn {
            position: absolute;
            top: 0;
            right: 0;
            width: 25px;
            height: 25px;
            cursor: pointer;
            color: $light_purple;
        }

        & .modal_title {
            width: 100%;
            padding: 20px 0;
            font-size: 25px;
            color: $grey;
            border-bottom: 1px solid #CACDF6;
        }

        & .modal_text {
            font-size: 16px;
            color: $grey;
            padding: 25px 0;
        }

        & .modal_buttons {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            padding: 15px 0 30px;

            & .cancel_btn {
                color: $primary;
                font-weight: 600;
                padding: 10px 0;
                cursor: pointer;

            }

            & .delete_btn {
                width: 110px;
                height: 44px;
                background: transparent linear-gradient(180deg, #84EEEB 0%, #3AE4DF 100%) 0% 0% no-repeat padding-box;
                border-radius: 10px;
                color: $primary;
                font-weight: 600;
                border: none;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }

        & .description_content {
            padding: 20px 0;

            & .description_subtitle {
                font-weight: 500;
                font-size: 18px;
                padding: 0 15px;
            }

            & .description_text {
                padding: 15px 20px;
            }
        }
    }
</style>