<template>
    <div>
        <div class="page_content">
            <div class="page_head">
                <div class="page_title">
                    Milestones
                </div>
                <div class="page_head-drop-btn">
                    <div class="select_arrow">
                        <b-form-select @change="headWorkspaceChange">
                            <option
                                    v-for="(option, id) in milestoneWorkspaces"
                                    :key="id"
                                    :value="option.id"
                                    :title="option.name || null"
                            >
                                {{option.name}}
                            </option>
                        </b-form-select>
                        <div class="arrow_down"></div>
                    </div>
                    <b-button class="create_btn" @click="milestoneCreate()" id="milestone-btn">Create New Milestones</b-button>
                </div>
            </div>

            <div class="milestone_table">
                <div class="table_title">
                    {{head_workspace.work_space}}
                </div>
                <div class="table_container">
                    <b-table hover :items="milestones_data" :fields="fields"  responsive="sm">
                        <template v-slot:cell(index)="data">
                            <div class="">
                                {{data.index+1}}
                            </div>
                        </template>

                        <template v-slot:cell(channel_name)="data">
                            <div class="item_name">
                                {{data.item.channel.name}}
                            </div>
                        </template>

                        <template v-slot:cell(title)="data">
                            <div class="">
                                {{data.item.title}}
                            </div>
                        </template>
                        <template v-slot:cell(originator)="data">
                            <div class="">
                                {{data.item.originator.name}}
                            </div>
                        </template>

                        <template v-slot:cell(m_date)="data">
                            <div class="">
                                {{getDate(data.item.milestone_date)}}
                            </div>
                        </template>

                        <template v-slot:cell(countdown_days)="data">
                            <div class="" :class="{'text-danger': getCountdownDays(data.item) === 'Overdue'}">
                                {{getCountdownDays(data.item)}}
                            </div>
                        </template>

                        <template v-slot:cell(view)="data">
                            <div class="d-flex justify-content-center">
                                <router-link :to="'/milestoneHistory/' + data.item.id">
                                    <div class="view_btn"></div>
                                </router-link>

                            </div>
                        </template>

                        <template v-slot:cell(edit)="data">
                            <div class="d-flex justify-content-center">
                                <div class="edit_btn" id="edit_milestone-btn" @click="editMilestone(data.item)">
                                </div>
                            </div>
                        </template>

                        <template v-slot:cell(delete)="data">
                            <div class="d-flex justify-content-center">
                                <div class="trash_button" id="remove_milestone-btn" @click="milestoneDelete(data.item)"></div>
                            </div>
                        </template>

                        <template v-slot:cell(status)="data">
                            <div
                                class="status_item"
                                :class="{
                                    'cyan': data.item.status === 0,
                                    'green': data.item.status === 1,
                                }">
                                {{getStatus(data.item.status)}}
                            </div>
                        </template>

                    </b-table>
                </div>
            </div>
            <div class="milestone_table mt-5">
                <div class="milestone_table-title">
                    Closed Milestones
                </div>
                <div class="table_container">
                    <b-table hover :items="closedMilestones" :fields="fields"  responsive="sm">
                        <template v-slot:cell(index)="data">
                            <div class="">
                                {{data.index+1}}
                            </div>
                        </template>

                        <template v-slot:cell(channel_name)="data">
                            <div class="item_name">
                                {{data.item.channel.name}}
                            </div>
                        </template>

                        <template v-slot:cell(title)="data">
                            <div class="">
                                {{data.item.title}}
                            </div>
                        </template>
                        <template v-slot:cell(originator)="data">
                            <div class="">
                                {{data.item.originator.name}}
                            </div>
                        </template>

                        <template v-slot:cell(m_date)="data">
                            <div class="">
                                {{getDate(data.item.milestone_date)}}
                            </div>
                        </template>

                        <template v-slot:cell(countdown_days)="data">
                            <div class="" :class="{'text-danger': getCountdownDays(data.item) === 'Overdue'}">
                                {{getCountdownDays(data.item)}}
                            </div>
                        </template>

                        <template v-slot:cell(view)="data">
                            <div class="d-flex justify-content-center">
                                <router-link :to="'/milestoneHistory/' + data.item.id">
                                    <div class="view_btn"></div>
                                </router-link>

                            </div>
                        </template>

                        <template v-slot:cell(edit)="data">
                            <div class="d-flex justify-content-center">
<!--                                <div class="edit_btn" id="edit_milestone-btn" @click="editMilestone(data.item)"></div>-->
                            </div>
                        </template>

                        <template v-slot:cell(delete)="data">
                            <div class="d-flex justify-content-center">
<!--                                <div class="trash_button" id="remove_milestone-btn" @click="milestoneDelete(data.item)"></div>-->
                            </div>
                        </template>

                        <template v-slot:cell(status)="data">
                            <div
                                    class="status_item"
                                    :class="{
                                    'cyan': data.item.status === 0,
                                    'green': data.item.status === 1,
                                    'red': data.item.status === 2,
                                }">
                                {{getStatus(data.item.status)}}
                            </div>
                        </template>

                    </b-table>
                </div>
            </div>

<!--Milestones modal start-->
            <b-modal ref="milestone-modal" centered hide-header hide-footer hide-header-close >
                <div class="milestone_modal_content">
                    <b-icon icon="x" class="x_btn" aria-hidden="true" @click="milestoneModal()"></b-icon>
                    <div class="d-flex">
                        <div class="milestone_title">
                            Create New Milestones
                        </div>
                    </div>

                    <div class="input_content">
                        <div class="input_item">
                            <label>WorkSpace</label>
                            <div class="select_arrow">
                                <b-form-select @change="workspaceChange">
                                    <option
                                            v-for="(option, id) in workspaces"
                                            :key="id"
                                            :value="option.id"
                                            :title="option.name || null"
                                    >
                                        {{option.name}}
                                    </option>
                                </b-form-select>
                                <div class="arrow_down"></div>
                                <span v-if="createMilestoneErrors['work_space_id']" class="text_danger mt-1">{{createMilestoneErrors['work_space_id'][0]}}</span>
                            </div>
                        </div>

                        <div class="input_item">
                            <label>Channel</label>
                            <div class="select_arrow">
                                <b-form-select @change="channelChange" :disabled="!channels.length">
                                    <option
                                            v-for="(option, id) in channels"
                                            :key="id"
                                            :value="option.id"
                                            :title="option.name || null"
                                    >
                                        {{option.name}}
                                    </option>
                                </b-form-select>
                                <div class="arrow_down"></div>
                                <span v-if="createMilestoneErrors['channel_id']" class="text_danger mt-1">{{createMilestoneErrors['channel_id'][0]}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="input_content">

                        <div class="input_item">
                            <label>Title</label>
                            <b-form-input
                                    v-model="milestoneData.title"
                                    type="text"
                                    trim
                            ></b-form-input>
                            <span v-if="createMilestoneErrors['title']" class="text_danger mt-1">{{createMilestoneErrors['title'][0]}}</span>
                        </div>
                        <div class="input_item">
                            <label>Milestone Date</label>

                            <b-form-input
                                    v-model="milestoneData.milestone_date"
                                    type="date"
                                    trim
                            ></b-form-input>
                            <span v-if="createMilestoneErrors['milestone_date']" class="text_danger mt-1">{{createMilestoneErrors['milestone_date'][0]}}</span>
                        </div>

                    </div>

                    <div class="input_content">

                        <div class="input_item insert_milestone">
                            <label>Insert Milestone</label>
                            <div class="select_arrow">
                                <b-form-select v-model="milestoneData.milestone_order_id" :disabled="!milestoneData.work_space || !all_milestones.length">
                                    <option
                                            v-for="(option, id) in all_milestones"
                                            :key="id"
                                            :value="option.id"
                                            :title="option.title || null"
                                    >
                                        {{option.title}}
                                    </option>
                                </b-form-select>

                                <div class="arrow_down"></div>

                            </div>
                            <div class="radio_item">
                                <b-form-radio-group
                                        v-model="milestoneData.previous_or_next"
                                        :options="milestone_radio"
                                        value-field="item"
                                        text-field="name"
                                        :disabled="!milestoneData.milestone_order_id"
                                ></b-form-radio-group>
                            </div>
                        </div>
                    </div>

                    <div class="input_content">

                        <div class="input_item send_alert">
                            <label>Send Alert To</label>
                            <div class="select_arrow">
                                <Multiselect
                                        v-model="send_alert_to_data"
                                        :options="workspaceUsers"
                                        label="name"
                                        track-by="id"
                                        :close-on-select="false"
                                        :hide-selected="true"
                                        multiple
                                ></Multiselect>
                                <div class="arrow_down"></div>
                            </div>
                        </div>
                    </div>

                    <div class="done_btn-container">
                        <b-button class="done_btn" @click="createMilestone()">Done</b-button>
                    </div>
                </div>
            </b-modal>
<!--Milestones modal end-->

<!--Edit Milestones modal start-->
            <b-modal ref="milestone_edit-modal" centered hide-header hide-footer hide-header-close >
                <div class="milestone_modal_content">
                    <b-icon icon="x" class="x_btn" aria-hidden="true" @click="editMilestoneModal()"></b-icon>
                    <div class="d-flex">
                        <div class="milestone_title">
                            Edit Milestones
                        </div>
                    </div>

                    <div class="input_content">
                        <div class="input_item">
                            <label>WorkSpace</label>

                            <div class="field_item">{{edit_milestone_data.workspace}}</div>
                        </div>

                        <div class="input_item">
                            <label>Channel</label>
                            <div class="field_item">{{edit_milestone_data.channel}}</div>
                        </div>
                    </div>
                    <div class="input_content">

                        <div class="input_item">
                            <label>Title</label>
                            <b-form-input
                                    v-model="edit_milestone_data.title"
                                    type="text"
                                    trim
                            ></b-form-input>
                            <span v-if="createMilestoneErrors['title']" class="text_danger mt-1">{{createMilestoneErrors['title'][0]}}</span>
                        </div>
                        <div class="input_item">
                            <label>Milestone Date</label>

                            <b-form-input
                                    v-model="edit_milestone_data.milestone_date"
                                    type="date"
                                    trim
                            ></b-form-input>
                            <span v-if="createMilestoneErrors['milestone_date']" class="text_danger mt-1">{{createMilestoneErrors['milestone_date'][0]}}</span>
                        </div>

                    </div>

                    <div class="input_content">

                        <div class="input_item insert_milestone">
                            <label>Insert Milestone</label>
                            <div class="select_arrow">
                                <b-form-select v-model="edit_milestone_data.milestone_id">
                                    <option
                                            v-for="(option, id) in all_milestone_edit_data"
                                            :key="id"
                                            :value="option.id"
                                            :title="option.title || null"
                                    >
                                        {{option.title}}
                                    </option>
                                </b-form-select>

                                <div class="arrow_down"></div>

                            </div>
                            <div class="radio_item">
                                <b-form-radio-group
                                        v-model="edit_milestone_data.previous_or_next"
                                        :options="milestone_radio"
                                        value-field="item"
                                        text-field="name"
                                        :disabled="!edit_milestone_data.milestone_id"
                                ></b-form-radio-group>
                            </div>
                        </div>
                    </div>

                    <div class="input_content">

                        <div class="input_item send_alert">
                            <label>Send Alert To</label>
                            <div class="select_arrow">
                                <Multiselect
                                        v-model="edit_milestone_data.copied_lists"
                                        :options="editWorkspaceUsers"
                                        label="name"
                                        track-by="id"
                                        :close-on-select="false"
                                        :hide-selected="true"
                                        multiple
                                ></Multiselect>
                                <div class="arrow_down"></div>
                            </div>
                        </div>
                    </div>

                    <div class="done_btn-container">
                        <b-button class="done_btn" @click="edit_milestone()">Done</b-button>
                    </div>
                </div>
            </b-modal>
<!--Edit Milestones modal end-->


<!--delete modal start -->
            <b-modal ref="milestone_delete-modal" centered hide-header hide-footer hide-header-close>
                <div class="modal_content">
                    <b-icon icon="x" class="x_btn" aria-hidden="true" @click="deleteMilestoneModal()"></b-icon>
                    <div class="d-flex flex-column">
                        <div class="modal_title">
                            You are about to delete this milestone
                        </div>
                        <div class="modal_text">
                            Do you want to proceed?
                        </div>
                        <div class="modal_buttons">
                            <div class="cancel_btn" @click="deleteMilestoneModal()">Cancel</div>
                            <b-button class="delete_btn ml-3" @click="removeMilestone()">Delete</b-button>
                        </div>

                    </div>
                </div>
            </b-modal>
<!--delete modal end -->
        </div>
    </div>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";
    // import Multiselect from 'vue-multiselect'

    export default {
        name: "Milestones",
        data() {
            return {
                fields: [
                    {
                        key: 'index',
                        label: 'No',
                    },
                    {
                        key: 'channel_name',
                        label: 'Channel',
                    },
                    {
                        key: 'title',
                        label: 'Title',
                    },
                    {
                        key: 'originator',
                        label: 'Originator',
                    },
                    {
                        key: 'm_date',
                        label: 'Milestone Date',
                    },
                    {
                        key: 'countdown_days',
                        label: 'Countdown Days',
                    },
                    {
                        key: 'view',
                        label: 'Milestone History',
                    },
                    {
                        key: 'edit',
                        label: 'Edit',
                    },
                    {
                        key: 'delete',
                        label: 'Delete',
                    },
                    {
                        key: 'status',
                        label: 'Status',
                    },
                ],
                milestones_data: [],
                all_milestones: [],
                channels: [],
                milestoneData: {
                    work_space: null,
                    channel: null,
                    title: null,
                    milestone_date: null,
                    milestone_order_id: null,
                    previous_or_next: null,
                    users_id: [],
                },
                send_alert_to_data: [],
                workspaceUsers: [],
                head_workspace: {
                    id: null,
                    work_space: null
                },
                selected_milestone: '',
                milestone_radio: [
                    { item: 0, name: 'Prev' },
                    { item: 1, name: 'Next' },
                ],
                remove_milestone_id: null,
                createMilestoneErrors: {},
                edit_milestone_data: {
                    workspace: null,
                    channel: null,
                    title: null,
                    milestone_date: null,
                    milestone_id: null,
                    previous_or_next: null,
                    copied_lists: [],
                    send_copied_lists: [],
                    milestone_order_id: null
                },
                all_milestone_edit_data: [],
                editWorkspaceUsers: [],
                closedMilestones: []
            }
        },
        computed: {
          ...mapGetters(['workspaces', 'milestoneWorkspaces']),
        },
        created() {
            this.getAllWorkspaces()
            this.getMilestoneWorkspaces()
            this.getMilestones()
            .then(res => {
                if(res.status === 200) {
                    this.all_milestones = res.data.success
                }
            })
        },
        methods: {
            ...mapActions([
                'getAllWorkspaces',
                'getMilestoneWorkspaces',
                'getWorkspaceUsers',
                'getMilestoneShow',
                'createNewMilestone',
                'getMilestones',
                'deleteMilestone',
                'milestoneEdit',
                'getClosedMilestones'
            ]),
            getStatus(args) {
                if(args === 0) {
                    return 'In Progress'
                } else if (args === 1){
                    return 'Completed'
                } else {
                    return 'Closed'
                }
            },
            getDate(item) {
                return new Date(item * 1000 ).toLocaleString("en-US", {timeZoneName: "short"}).split(',')[0]
            },
            headWorkspaceChange(args) {
                var head_workSpace = this.workspaces.find((item) => {
                    return item.id == args
                })
                this.head_workspace.id = head_workSpace.id
                this.head_workspace.work_space = head_workSpace.name
                this.getMilestoneShow(args)
                .then((res) => {
                    if(res.status === 200) {
                        this.milestones_data = res.data.success
                    }
                })
                this.getClosedMilestones(args)
                .then(res => {
                    if(res.status === 200) {
                        this.closedMilestones = res.data.success
                    }
                })
            },
            workspaceChange(args) {
                var workSpace = this.workspaces.find((item) => {
                    return item.id == args
                })
                this.getWorkspaceUsers(args)
                .then((res) => {
                    if(res.status === 200) {
                        this.workspaceUsers = res.data.success
                    }
                })
                this.channels = workSpace.channels
                this.milestoneData.work_space = args
                this.getMilestoneShow(args)
                .then(res => {
                    if(res.status === 200) {
                        this.all_milestones = res.data.success
                    }
                })
            },
            channelChange(args) {
                this.milestoneData.channel = args
            },

            getCountdownDays(item) {
              if(item.countdown_days <= 0  && item.status === 0) {
                  return 'Overdue'
              } else if(item.countdown_days <= 0 && item.status === 1){
                  return '-'
              } else if(item.countdown_days <= 0 && item.status === 2){
                  return 'Overdue'
              } else {
                  return item.countdown_days
              }
            },
            milestoneCreate() {
                this.milestoneData.work_space =null
                this.milestoneData.channel =null
                this.milestoneData.title =null
                this.milestoneData.milestone_date =null
                this.milestoneData.milestone_order_id =null
                this.milestoneData.users_id = []
                this.send_alert_to_data = []
                this.createMilestoneErrors = {}
                this.getAllWorkspaces()
                this.milestoneModal()
            },
            milestoneModal() {
                this.$refs['milestone-modal'].toggle('#milestone-btn')
            },
            createMilestone() {
                this.send_alert_to_data.forEach(item => {
                    this.milestoneData.users_id.push(item.id)
                })
                this.createNewMilestone(this.milestoneData)
                .then((res) => {
                    if(res.status === 200) {
                        this.getMilestoneWorkspaces()
                        this.milestoneModal()
                        this.headWorkspaceChange(this.head_workspace.id)
                    } else {
                        this.createMilestoneErrors = res.response.data.errors
                    }
                })
            },
            editMilestone(item) {
                this.edit_milestone_data.workspace = item.work_space.name
                this.edit_milestone_data.channel = item.channel.name
                this.edit_milestone_data.title = item.title
                this.edit_milestone_data.milestone_date = new Date(item.milestone_date * 1000 ).toLocaleString("en-CA", {timeZoneName: "short"}).split(',')[0]
                this.getMilestoneShow(item.work_space_id)
                    .then(res => {
                        if(res.status === 200) {
                            this.all_milestone_edit_data = res.data.success
                        }
                    })
                this.edit_milestone_data.milestone_id = item.milestone_order_id
                this.getWorkspaceUsers(item.work_space_id)
                    .then((res) => {
                        if(res.status === 200) {
                            this.editWorkspaceUsers = res.data.success
                        }
                    })
                item.copied_lists.forEach(item => {
                    this.edit_milestone_data.copied_lists.push(item.copied_list_names)
                })
                this.edit_milestone_data.previous_or_next = item.previous_or_next
                this.edit_milestone_data.milestone_id = item.id
                this.edit_milestone_data.milestone_order_id = item.milestone_order_id
                this.editMilestoneModal()
            },
            editMilestoneModal() {
                this.$refs['milestone_edit-modal'].toggle('#edit_milestone-btn')
            },
            edit_milestone() {
                this.edit_milestone_data.copied_lists.forEach(item => {
                    this.edit_milestone_data.send_copied_lists.push(item.id)
                })
              this.milestoneEdit(this.edit_milestone_data)
                .then(res => {
                    if(res.status === 200) {
                        this.editMilestoneModal()
                        this.headWorkspaceChange(this.head_workspace.id)
                    }
                })
            },

            milestoneDelete(item) {
                this.remove_milestone_id = item.id
                this.deleteMilestoneModal()
            },
            deleteMilestoneModal() {
                this.$refs['milestone_delete-modal'].toggle('#remove_milestone-btn')
            },
            removeMilestone() {
                this.deleteMilestone(this.remove_milestone_id)
                .then(res => {
                    if(res.status === 200) {
                        this.deleteMilestoneModal()
                        this.headWorkspaceChange(this.head_workspace.id)
                    }
                })
            }

        }
    }
</script>

<style scoped lang="scss">


::v-deep .select_arrow {
    .multiselect__tags {
        .multiselect__placeholder {
            padding-left: 10px;
        }
        .multiselect__input {
            padding-left: 10px !important;
        }
    }
}

.page {
    &_head {
        padding-bottom: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        &-drop-btn {
            display: flex;
            align-items: center;
            & .select_arrow {
                position: relative;
                width: 240px;
                margin-right: 20px;
                & .arrow_down {
                    position: absolute;
                    top: 14px;
                    right: 15px;
                    width: 14px;
                    height: 10px;
                    pointer-events: none;
                    background: url("~@/assets/images/chevron_down.svg") right  no-repeat;
                }
            }
            & select {
                background: $greyBlue 0 0 no-repeat padding-box;
                border-radius: 4px;
                padding-right: 5px;
            }
        }
    }
    &_title {
        color: $black;
        font-weight: 600;
        font-size: 25px;
    }
    &_content {
        padding: 20px 40px 20px 1px;
        & .create_btn {
            color: $primary;
            height: 44px;
            font-weight: 600;
            background: transparent linear-gradient(180deg, #84EEEB 0%, #3AE4DF 100%) 0% 0% no-repeat padding-box;
            border-radius: 10px;
            padding: 0 20px;
            border: none;
            display: flex;
            justify-content: center;
            align-items: center;
            &:focus,
            &:active{
                box-shadow: none;
                color: $primary;
            }
        }
        & .table_container {
            font-size: 13px;
            & .cyan {
                color: #3AE4DF;
            }
            & .green {
                color: #1CD65A;
            }
            & .red {
                color: red;
            }
            ::v-deep thead {
                height: 62px;
                color: $primary;
                background: #E7E8F5 0 0 no-repeat padding-box;
                th {
                    vertical-align: middle;
                    max-width: 100px;
                    text-align: center;
                }
            }
            ::v-deep tbody {
                tr {
                    td{
                        vertical-align: middle;
                        text-align: center;
                        text-align: -webkit-center;
                    }
                }
            }
            & .view_btn {
                width: 22px;
                height: 22px;
                cursor: pointer;
                background: url("~@/assets/images/eye_icon.svg") center no-repeat;
            }
            & .edit_btn {
                width: 22px;
                height: 22px;
                cursor: pointer;
                background: url("~@/assets/images/edit_icon.svg") center no-repeat;
            }
            & .trash_button {
                width: 22px;
                height: 22px;
                cursor: pointer;
                background: url("~@/assets/images/trash_icon.svg") center no-repeat;
            }
            & .x_button {
                width: 22px;
                height: 22px;
                cursor: pointer;
                background: url("~@/assets/images/x_icon.svg") center no-repeat;
            }
            & .item {
                display: flex;
                align-items: center;
                justify-content: space-between;
                height: 100%;
                & .dropdown_content {
                    ::v-deep button {
                        width: 130px;
                        height: 32px;
                        background: $light_purple;
                        color: $white;
                        font-size: 12px;
                        border: none;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                    }
                    ::v-deep .dropdown-menu {
                        min-width: 8rem;
                        border: none;
                        margin: 0;
                        padding: 0;
                    }
                    ::v-deep ul {
                        max-width: 130px;
                        width: 100%;
                        background: $greyBlue;
                        li {
                            border-top: 1px solid $light_purple;
                            font-size: 12px;
                            width: 100%;

                            a {
                                white-space: normal;
                                padding: 3px 8px;
                            }
                        }
                    }
                }
            }
            & .reply_btn {
                background: $light_purple;
                border: none;
                font-size: 14px;
                border-radius: 5px;
                &:focus {
                    box-shadow: none;
                }
                &:hover {
                    opacity: .9;
                }
            }
            & .status_item {
                max-width: 110px;
                width: 100%;
            }
            & .status_dots {
                display: flex;
                justify-content: space-evenly;
                & .dot_opy {
                    opacity: 1;
                }
            }
            & .yellow_dot {
                width: 20px;
                height: 20px;
                border-radius: 50%;
                background: #F2EB1A;
                opacity: 0.5;
                cursor: pointer;
            }
            & .green_dot {
                width: 20px;
                height: 20px;
                border-radius: 50%;
                background: #1CD65A;
                opacity: 0.5;
                cursor: pointer;
            }
            & .orange_dot {
                width: 20px;
                height: 20px;
                border-radius: 50%;
                background: #FFB26E;
                opacity: 0.5;
                cursor: pointer;
            }
            & .purple_dot {
                width: 20px;
                height: 20px;
                border-radius: 50%;
                background: $light_purple;
                opacity: 0.5;
                cursor: pointer;
            }
            & .active_dot {
                opacity: 1;
                box-shadow: 0 0 0 3px rgb(255, 255, 255) inset;
                border: 2px solid transparent;
            }
        }
        & .milestone_table {
            padding: 20px 0;
            &-title {
                color: $primary;
                font-weight: 600;
                padding: 0 0 20px;
            }
            & .table_title {
                width: 100%;
                height: 60px;
                background: #84EEEB;
                color: $grey;
                font-size: 16px;
                font-weight: 600;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }
}


/*/////////////*/
.milestone {
    &_modal_content {
        position: relative;
        padding: 0 10px;
        width: 580px;
        & .x_btn {
            position: absolute;
            top: 0;
            right: 0;
            width: 25px;
            height: 25px;
            cursor: pointer;
            color: $light_purple;
        }
        & .input_content {
            display: flex;
            justify-content: space-between;
            margin-bottom: 15px;
            & .input_item {
                padding: 5px 0;
                width: 270px;
                flex-direction: column;
                & .field_item {
                    font-size: 20px;
                    font-weight: 600;
                    color: $light_purple;
                }
                &.send_alert {
                    width: 100%;
                }
                &.insert_milestone {
                    width: 100%;
                    & .radio_item {
                        padding-top: 10px;
                    }
                }
                & .edit_workspace {
                    padding: 5px 0;
                    font-size: 22px;
                    font-weight: 600;
                    color: $primary;
                }
                & .select_arrow {
                    position: relative;
                    & .arrow_down {
                        position: absolute;
                        top: 14px;
                        right: 15px;
                        width: 14px;
                        height: 10px;
                        pointer-events: none;
                        background: url("~@/assets/images/chevron_down.svg") right  no-repeat;
                    }
                }
                & label {
                    color: $primary;
                    font-weight: 600;
                    font-size: 14px;
                }
                & input {
                    border: none;
                    background: $greyBlue 0 0 no-repeat padding-box;
                    border-radius: 3px;
                    &:focus {
                        box-shadow: none;
                        outline: none;
                    }
                }
                & select {
                    background: $greyBlue 0 0 no-repeat padding-box;
                    border-radius: 4px;
                    padding-right: 5px;
                }
                ::v-deep .multiselect {
                    width: 100%;
                    height: 44px;
                    background: $greyBlue 0 0 no-repeat padding-box;
                    border-radius: 4px;
                    cursor: pointer;
                }
                ::v-deep .multiselect__tags {
                    background: transparent;
                    padding-left: 0;
                }
                ::v-deep .multiselect__tag {
                    background: transparent;
                    color: $black;
                }
                ::v-deep .multiselect__tag-icon:after {
                    color: red;
                }
                ::v-deep .multiselect__tag-icon:hover {
                    background: transparent;
                }
                ::v-deep .multiselect__option--highlight,
                ::v-deep .multiselect__option--highlight:after {
                    background: $lightBlue;
                    color: $black;
                }
                ::v-deep .multiselect__select:before {
                    border-width: 0;
                    border-style: none;
                }
                ::v-deep .multiselect__input {
                    background: $greyBlue;
                    margin: 0;
                    padding: 0;
                }
            }
        }
        & .done_btn-container {
            display: flex;
            justify-content: flex-end;
            padding: 20px 0 10px;
            & .done_btn {
                background: transparent linear-gradient(180deg, #84EEEB 0%, #3AE4DF 100%) 0% 0% no-repeat padding-box;
                border-radius: 10px;
                width: 110px;
                height: 44px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: $primary;
                font-weight: 600;
                border: none;
            }
        }
    }
    &_title {
        width: 100%;
        font-size: 25px;
        font-weight: 500;
        color: $grey;
        padding-bottom: 10px;
        margin-bottom: 20px;
        border-bottom: 1px solid #CACDF6;
    }
}
::v-deep .modal-content {
    border: none !important;
    width: 700px;
    ::v-deep .modal-body {
        width: 600px !important;
    }
}
::v-deep .modal_content {
    border: none !important;
    width: 600px;
    position: relative;
    ::v-deep .modal-body {
        width: 600px !important;
    }
    & .x_btn {
        position: absolute;
        top: 0;
        right: 0;
        width: 25px;
        height: 25px;
        cursor: pointer;
        color: $light_purple;
    }
    & .modal_title {
        width: 100%;
        padding: 20px 0;
        font-size: 25px;
        color: $grey;
        border-bottom: 1px solid #CACDF6;
    }
    & .modal_text {
        font-size: 16px;
        color: $grey;
        padding: 25px 0;
    }
    & .modal_buttons {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 15px 0 30px;
        & .cancel_btn {
            color: $primary;
            font-weight: 600;
            padding: 10px 0;
            cursor: pointer;

        }
        & .delete_btn {
            width: 110px;
            height: 44px;
            background: transparent linear-gradient(180deg, #84EEEB 0%, #3AE4DF 100%) 0% 0% no-repeat padding-box;
            border-radius: 10px;
            color: $primary;
            font-weight: 600;
            border: none;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    & .description_content {
        padding: 20px 0;
        & .description_subtitle {
            font-weight: 500;
            font-size: 18px;
            padding: 0 15px;
        }
        & .description_text {
            padding: 15px 20px;
        }
    }
}
::v-deep .table {
    border-bottom: 1px solid #dee2e6;
}
::v-deep .custom-select {
    border: none;
}
::v-deep .table-responsive > .table {
    margin-bottom: 50px;
    padding-bottom: 30px;
}
::v-deep .table {
    border-bottom: 1px solid #dee2e6;
}

input[type="date"]::-webkit-calendar-picker-indicator {
    background: url("~@/assets/images/calendar.svg") center/80% no-repeat;
    cursor: pointer;
}
input[type="date"]::-webkit-calendar-picker-indicator:hover {
    background: url("~@/assets/images/calendar.svg") center/80% no-repeat;
}
</style>