import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
import Home from '../views/Home.vue';
import Login from "../components/Login";
import Register from "../components/Register";
import MainDashboard from "../views/MainDashboard";
import Summary from "../views/Summary";
import Discussion from "../views/Discussion";
import Milestones from "../views/Milestones";
import Notes from "../views/Notes";
import Depository from "../views/Depository";
import Lesson from "../views/Lesson";
import Message from "../views/Message";
import ForgotPassword from "../components/ForgotPassword";
import Members from "../views/Members";
import Notify from "../views/Notify";
import PageNotFound from "../views/PageNotFound";
import TaskNotification from "../views/TaskNotification";
import Notifications from "../views/Notifications";
import MilestoneHistory from "../views/MilestoneHistory";
import Account from "../views/Account";
import NewMeetingNote from "../views/NewMeetingNote";
import MeetingNoteDetails from "../views/MeetingNoteDetails";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {auth: false}
  },
  {
    path: '/register',
    name: 'register',
    component: Register,
    meta: {auth: false}
  },
  {
    path: '/forgot_password',
    name: 'ForgotPassword',
    component: ForgotPassword,
    meta: {auth: false}
  },
  {
    path: '/dashboard',
    name: 'MainDashboard',
    component: MainDashboard,
    meta: {layout: 'dashboard', auth: true}
  },
  {
    path: '/taskNotifications',
    name: 'TaskNotification',
    component: TaskNotification,
    meta: {layout: 'dashboard', auth: true}
  },
  {
    path: '/summary',
    name: 'Summary',
    component: Summary,
    meta: {layout: 'dashboard', auth: true}
  },
  {
    path: '/discussion',
    name: 'Discussion',
    component: Discussion,
    meta: {layout: 'dashboard', auth: true}
  },
  {
    path: '/milestones',
    name: 'Milestones',
    component: Milestones,
    meta: {layout: 'dashboard', auth: true}
  },
  {
    path: '/milestoneHistory/:id',
    name: 'MilestoneHistory',
    component: MilestoneHistory,
    meta: {layout: 'dashboard', auth: true},
    props: true
  },
  {
    path: '/notes',
    name: 'Notes',
    component: Notes,
    meta: {layout: 'dashboard', auth: true}
  },
  {
    path: '/newMeetingNote',
    name: 'NewMeetingNote',
    component: NewMeetingNote,
    meta: {layout: 'dashboard', auth: true}
  },
  {
    path: '/meetingNoteDetails/:id',
    name: 'MeetingNoteDetails',
    component: MeetingNoteDetails,
    meta: {layout: 'dashboard', auth: true},
    props: true
  },
  {
    path: '/depository',
    name: 'depository',
    component: Depository,
    meta: {layout: 'dashboard', auth: true}
  },
  {
    path: '/lesson',
    name: 'Lesson',
    component: Lesson,
    meta: {layout: 'dashboard', auth: true}
  },
  {
    path: '/message',
    name: 'Message',
    component: Message,
    meta: {layout: 'dashboard', auth: true}
  },
  {
    path: '/members',
    name: 'Members',
    component: Members,
    meta: {layout: 'dashboard', auth: true}
  },
  {
    path: '/notify',
    name: 'Notify',
    component: Notify,
    meta: {layout: 'dashboard', auth: true}
  },
  {
    path: '/notifications',
    name: 'Notifications',
    component: Notifications,
    meta: {layout: 'dashboard', auth: true}
  },
  {
    path: '/account',
    name: 'Account',
    component: Account,
    meta: {layout: 'dashboard', auth: true}
  },
  {
    path: '/404',
    name: '404',
    component: PageNotFound,
    meta: {auth: false}
  },
  {
    path: '*',
    redirect: '/404'
  }

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  if(to.meta.auth && !store.getters['accessToken']) {
    router.push({name: 'login'})
  } else {
    next()
  }
})


router.beforeResolve((to, from, next) => {
  // If this isn't an initial page load.
  if (to.name) {
    // Start the route progress bar.
    NProgress.start()
  }
  next()
})

router.afterEach((to, from) => {
  // Complete the animation of the route progress bar.
  NProgress.done()
})


export default router
