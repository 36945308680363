<template>
    <div>
        <div class="login_container">
            <div class="login_left-side">
                <router-link to="/">
                    <div class="login_title">
                        LogoName
                    </div>
                </router-link>
                <div class="login_left-content">
                </div>
            </div>

            <div class="login_right-side">
                <div class="account_text">
                    <div class="dont_have">
                        Don't have an account? <router-link to="/register"><span class="sign_text"> Sign Up</span></router-link>
                    </div>
                </div>

                <div class="login_right-side-content">
                    <div class="welcome_title">
                        Welcome to <span class="name">LogoName</span>
                    </div>
                    <form @submit.prevent="login()">
                        <div class="input_item form-group">

                            <label >Email</label>
                            <b-form-input
                                    id="email"
                                    v-model="userForm.email"
                                    type="email"
                                    :class="{ 'is-invalid': isSubmitted && $v.userForm.email.$error }"
                                    trim
                            ></b-form-input>
                            <div v-if="isSubmitted && $v.userForm.email.$error" class="invalid-feedback">
                                <span v-if="!$v.userForm.email.required">Email field is required</span>
                                <span v-if="!$v.userForm.email.email">Please provide valid email</span>
                            </div>
                        </div>
                        <div class="input_item form-group">
                            <label >Password</label>
                            <b-form-input
                                    id="password"
                                    :type="l_password"
                                    v-model="userForm.password"
                                    :class="{ 'is-invalid': isSubmitted && $v.userForm.password.$error }"
                                    trim
                            ></b-form-input>
                            <div class="eye_close" @click="showPassword()"></div>
                            <div v-if="isSubmitted && $v.userForm.password.$error" class="invalid-feedback">
                                <span v-if="!$v.userForm.password.required">Password field is required</span>
                                <span v-if="!$v.userForm.password.minLength">Password should be at least 6 characters long</span>
                            </div>
                            <div class="mt-1">
                                <span v-if="loginError" class="text-danger">{{loginError}}</span>
                            </div>
                        </div>
                        <div class="d-flex justify-content-between mt-4 mb-5">
                            <div class="remember">
                                <input type="checkbox" id="check_remember">
                                <label for="check_remember">Remember me</label>

                            </div>
                                <div class="forget" @click="forgotPassword()">
                                    Forgot your password?
                                </div>
                        </div>
                        <b-button variant="primary" class="login_btn" type="submit">Log In</b-button>

                    </form>
                </div>
            </div>

            <!--modal start-->
            <b-modal ref="edit-modal" centered size="md"  hide-header hide-footer >
                <div class="close_btn_content" @click="forgotPassword()">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#7882F3" class="bi bi-x-lg" viewBox="0 0 16 16">
                        <path d="M1.293 1.293a1 1 0 0 1 1.414 0L8 6.586l5.293-5.293a1 1 0 1 1 1.414 1.414L9.414 8l5.293 5.293a1 1 0 0 1-1.414 1.414L8 9.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L6.586 8 1.293 2.707a1 1 0 0 1 0-1.414z"/>
                    </svg>
                </div>
                <div class="modal_content">
                    <div class="modal_name">
                        Forgot password
                    </div>
                    <div class="forgot_text">
                        Enter your email address and we'll send you a link to reset your password.
                    </div>
                    <div class="modal_input">
                        <div class="input_item form-group">
                            <b-form-input
                                    id="forgot_email"
                                    placeholder="Email"
                                    v-model="forgot_email"
                                    type="email"
                                    trim
                            ></b-form-input>
                            <div class="mt-1">
                                <span v-if="forgotError" class="text-danger">{{forgotError}}</span>
                            </div>

<!--                            <div v-if="isSubmitted && $v.userForm.email.$error" class="invalid-feedback">-->
<!--                                <span v-if="!$v.userForm.email.required">Email field is required</span>-->
<!--                                <span v-if="!$v.userForm.email.email">Please provide valid email</span>-->
<!--                            </div>-->
                        </div>
                    </div>
                    <div class="modal_btn">
                        <b-button class="send_btn" @click="sendForgotPassword()">Send</b-button>
                    </div>

                </div>

            </b-modal>
            <!--modal end-->

        </div>
    </div>
</template>

<script>
    import {mapActions} from "vuex";
    import {required, email, minLength} from "vuelidate/lib/validators";
    export default {
        name: "Login",
        data() {
            return {
                userForm: {
                    email: "",
                    password: "",
                },
                forgot_email: '',
                isSubmitted: false,
                l_password: 'password',
                loginError: null,
                forgotError: null,
            }
        },
        validations: {
            userForm: {
                email: {
                    required,
                    email
                },
                password: {
                    required,
                    minLength: minLength(6)
                },
            }
        },
        methods: {
            ...mapActions(['loginUser', 'sendForgot']),
            showPassword() {
                if(this.l_password === 'password') {
                    this.l_password = 'text'
                } else {
                    this.l_password = 'password'
                }
            },
            login() {
                this.isSubmitted = true;
                this.loginError = null

                this.$v.$touch();
                if (this.$v.$invalid) {
                    return;
                }else {
                    this.loginUser(this.userForm)
                        .then((res) => {
                            if(res.status == 200) {
                                this.$router.push('/dashboard')
                            } else {
                                this.loginError = res.response.data.error
                            }
                        })
                }
            },

            forgotPassword() {
                this.$refs['edit-modal'].toggle('#edit-btn')
                this.forgotError = null

            },
            sendForgotPassword() {
                this.sendForgot(this.forgot_email)
                .then((res) => {
                    if(res.status === 200) {
                        this.$refs['edit-modal'].toggle('#edit-btn')
                    } else {
                        this.forgotError = res.response.data.errors.email[0]
                    }
                })
            }
        }
    }
</script>

<style scoped lang="scss">
    .form-group > label {
        font-weight: 600;
    }
    ::v-deep .modal-dialog {
        min-width: 550px;
    }
     .close_btn_content {
        position: absolute;
        display: flex;
        justify-content: flex-end;
        top: 20px;
        right: 25px;
        cursor: pointer;
    }
    .login {
        &_container {
            /*position: absolute;*/
            /*top: 0;*/
            /*left: 0;*/
            width: 100%;
            height: 100vh;
            border: 1px solid lightgray;
            background: #fff;
            display: flex;

        }
        &_left-side {
            width: 50%;
            background: transparent linear-gradient(180deg, #7882F3 0%, #7882F3 36%, #767FF2 57%, #6F76F1 69%, #6468F0 78%, #5454EE 99%, #5352EE 100%) 0% 0% no-repeat padding-box;
            padding: 55px 65px 0 140px;
            display: flex;
            flex-direction: column;
            height: 100%;
            @media screen and (max-width: 1440px) {
                padding: 65px 65px 0 140px;
            }
            @media screen and (max-width: 1200px) {
                padding: 30px 40px 0 60px;
            }
            & a {
                text-decoration: none;
                &:hover {
                    text-decoration: none;
                }
            }
        }
        &_right-side {
            width: 50%;
            display: flex;
            flex-direction: column;
            align-items: center;
            @media screen and (max-width: 1200px) {
                height: 83vh;
            }
            & .account_text {
                width: 100%;
                padding: 50px 150px 10px;
                display: flex;
                justify-content: flex-end;
                @media screen and (max-width: 1440px) {
                    padding: 30px 90px;
                }
                & .dont_have {
                    color: $black;
                    font-weight: 500;
                    font-size: 18px;
                    @media screen and (max-width: 1440px) {
                        font-size: 16px;
                    }
                    @media screen and (max-width: 1200px) {
                        font-size: 14px;
                    }
                    & .sign_text {
                        color: $purple;
                        border-bottom: 1px solid $purple;

                    }
                }
            }
            &-content {
                max-width: 550px;
                width: 100%;
                height: 100%;
                display: flex;
                padding: 0 20px 40px;
                justify-content: center;
                flex-direction: column;
                @media screen and (max-width: 1440px) {
                    padding: 0 20px 60px;
                }
                @media screen and (max-width: 1200px) {
                    max-width: 450px;
                }
                & .welcome_title {
                    font-size: 35px;
                    font-weight: 600;
                    color: $purple;
                    padding: 20px 0;
                    @media screen and (max-width: 1440px) {
                        font-size: 32px;
                    }
                    @media screen and (max-width: 1200px) {
                        font-size: 30px;
                        padding: 15px 0;
                    }
                    & .name {
                        color: $purple;
                    }
                }
                & .input_item {
                    width: 100%;
                    margin-top: 20px;
                    position: relative;
                    & .eye_close {
                        position: absolute;
                        width: 30px;
                        height: 36px;
                        right: 15px;
                        top: 33px;
                        background-image: url("~@/assets/images/eye_close.svg");
                        background-repeat: no-repeat;
                        background-position: center;
                        cursor: pointer;
                    }
                    & label {
                        color: $darkBlue;
                        font-weight: 600;
                        font-size: 16px;

                    }
                    & input {
                        background: $greyBlue 0 0 no-repeat padding-box;
                        &:focus {
                            box-shadow: none;
                            border: 1px solid $purple;
                            border-radius: 3px;
                        }
                    }
                }
                & .remember {
                    display: flex;
                    align-items: center;
                    & label {
                        margin-left: 15px !important;
                        color: $darkBlue;
                        font-size: 16px;
                        margin-bottom: 0;
                    }
                }
                & .forget {
                    color: $darkBlue;
                    font-size: 16px;
                    font-weight: 400;
                    cursor: pointer;
                }
                & .login_btn {
                    width: 100%;
                    height: 60px;
                    background: transparent linear-gradient(180deg, #84EEEB 0%, #3AE4DF 100%) 0 0 no-repeat padding-box;
                    border-radius: 10px;
                    color: $primary;
                    font-weight: 600;
                    border: none;
                    @media screen and (max-width: 1440px) {
                        height: 50px;
                    }
                }
            }
        }

        &_title {
            color: #fff;
            font-size: 29px;
            font-weight: 500;
            line-height: 62px;
            @media screen and (max-width: 1440px) {
                font-size: 48px;
                line-height: 58px;
            }
            @media screen and (max-width: 1200px) {
                font-size: 46px;
                line-height: 48px;
            }
        }
        &_left-content {
            padding: 30px;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            background: url("~@/assets/images/login_img.png");
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            @media screen and (max-width: 1440px) {
                padding: 110px 20px;
            }
            @media screen and (max-width: 1200px) {
                padding: 70px 20px;
            }

        }
    }
    .modal_content {
        padding: 5px 15px 15px;
        & .modal_name {
            font-size: 25px;
            font-weight: 600;
            color: $grey;
        }
        & .forgot_text {
            padding: 10px 0 25px;
            font-size: 13px;
            color: $blue;
        }
        & .modal_input {
            padding-bottom: 20px;
            & .input_item {
               & input {
                   background: #E7E8F5 0% 0% no-repeat padding-box;
                   border-radius: 3px;
                   width: 100%;
                   height: 45px;
                }
            }
        }
        & .modal_btn {
            width: 100%;
            display: flex;
            justify-content: flex-end;
            & .send_btn {
                background: transparent linear-gradient(180deg, #84EEEB 0%, #3AE4DF 100%) 0% 0% no-repeat padding-box;
                border-radius: 10px;
                width: 110px;
                height: 44px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: $primary;
                font-weight: 600;
                border: none;
            }

        }
    }

</style>