<template>
    <div>
        <div class="page_content">
            <div class="page_head">
                <div class="page_title">
                    Depository
                </div>
                <div class="page_head-drop-btn">
                    <div class="input_item">
                        <div class="select_arrow">
                            <b-form-select @change="workspaceChange">
                                <template #first>
                                    <b-form-select-option :value="null" disabled>Select Workspace</b-form-select-option>
                                </template>
                                <option
                                        v-for="(option, id) in workspaces"
                                        :key="id"
                                        :value="option.id"
                                        :title="option.name || null"
                                >
                                    {{option.name}}
                                </option>
                            </b-form-select>
                            <div class="arrow_down"></div>
                        </div>
                    </div>

                    <div class="input_item">
                        <div class="select_arrow">
                            <b-form-select @change="channelChange" :disabled="!channels.length">
                                <template #first>
                                    <b-form-select-option :value="null" disabled>Select Channel</b-form-select-option>
                                </template>
                                <option
                                        v-for="(option, id) in channels"
                                        :key="id"
                                        :value="option.id"
                                        :title="option.name || null"
                                >
                                    {{option.name}}
                                </option>
                            </b-form-select>
                            <div class="arrow_down"></div>
                        </div>
                    </div>

                    <b-button class="create_btn" @click="uploadAttachment()" id="depository-btn">Upload New
                        Attachments
                    </b-button>
                </div>
            </div>
            <div class="depository_table">
                <div class="table_title">
                    {{depository.workspace_name}} >> {{depository.channel_name}}
                </div>
                <div class="table_container">
                    <b-table hover :items="depository_data" :fields="fields" responsive="sm">
                        <template v-slot:cell(index)="data">
                            <div class="">
                                {{data.index+1}}
                            </div>
                        </template>

                        <template v-slot:cell(title)="data">
                            <div class="item_name">
                                {{data.item.depository_title}}
                            </div>
                        </template>

                        <template v-slot:cell(name)="data">
                            <div class="">
                                {{data.item.depositor.name}}
                            </div>
                        </template>

                        <template v-slot:cell(date)="data">
                            <div class="">
                                {{getDate(data.item.created_at)}}
                            </div>
                        </template>

                        <template v-slot:cell(attachment)="data">
                            <div class="d-flex justify-content-center">
                                <div class="view_btn"></div>
                            </div>
                        </template>

                        <template v-slot:cell(status)="data">
                            <div
                                    class="status_item"
                                    :class="{
                                    'cyan': data.item.status === 0,
                                    'green': data.item.status === 1,
                                }">
                                {{getStatus(data.item.status)}}
                            </div>
                        </template>

                        <template v-slot:cell(settings)="data">
                            <div class="d-flex justify-content-center">
                                <div class="edit_btn" id="edit_depository-btn" @click="editDepository(data.item)">
                                </div>
                            </div>
                        </template>
                        <template v-slot:cell(delete)="data">
                            <div class="d-flex justify-content-center">
                                <div class="trash_button" id="remove_depository-btn"
                                     @click="depositoryDelete(data.item)"></div>
                            </div>
                        </template>

                    </b-table>
                </div>
            </div>
            <!--Depository create modal start-->
            <b-modal ref="depository-modal" centered hide-header hide-footer hide-header-close>
                <div class="depository_modal_content">
                    <b-icon icon="x" class="x_btn" aria-hidden="true" @click="depositoryModal()"></b-icon>
                    <div class="d-flex">
                        <div class="depository_title">
                            Upload New Attachments
                        </div>
                    </div>

                    <div class="input_content">
                        <div class="input_item">
                            <label>Workspace</label>
                            <div class="select_arrow">
                                <b-form-select v-model="depositoryData.work_space_id"
                                               @change="depositoryWorkspaceChange">
                                    <option
                                            v-for="(option, id) in workspaces"
                                            :key="id"
                                            :value="option.id"
                                            :title="option.name || null"
                                    >
                                        {{option.name}}
                                    </option>
                                </b-form-select>
                                <div class="arrow_down"></div>
                                <span v-if="depositoryErrors['work_space_id']" class="text_danger mt-1">{{depositoryErrors['work_space_id'][0]}}</span>
                            </div>
                        </div>
                        <div class="input_item">
                            <label>Channel</label>
                            <div class="select_arrow">
                                <b-form-select v-model="depositoryData.channel_id" @change="depositoryChannelChange">
                                    <option
                                            v-for="(option, id) in depositoryChannels"
                                            :key="id"
                                            :value="option.id"
                                            :title="option.name || null"
                                    >
                                        {{option.name}}
                                    </option>
                                </b-form-select>
                                <div class="arrow_down"></div>
                                <span v-if="depositoryErrors['channel_id']" class="text_danger mt-1">{{depositoryErrors['channel_id'][0]}}</span>
                            </div>
                        </div>
                        <div class="input_item">
                            <label>Depository title</label>
                            <b-form-input
                                    v-model="depositoryData.attachmentTitle"
                                    type="text"
                                    trim
                            ></b-form-input>
                            <span v-if="depositoryErrors['depository_title']" class="text_danger mt-1">{{depositoryErrors['depository_title'][0]}}</span>
                        </div>

                        <div class="input_item">
                            <label>Assignee</label>
                            <div class="select_arrow">
                                <b-form-select v-model="depositoryData.user_id"
                                               :disabled="!depositoryData.work_space_id">
                                    <option
                                            v-for="(option, id) in workspaceUsers"
                                            :key="id"
                                            :value="option.id"
                                            :title="option.name || null"
                                    >
                                        {{option.name}}
                                    </option>
                                </b-form-select>
                                <div class="arrow_down"></div>
                            </div>
                        </div>

                        <div class="input_item">
                            <label>Settings</label>
                            <b-form-checkbox
                                    switch
                                    size="lg"
                                    v-model="depositoryData.status"
                                    value="1"
                                    unchecked-value="0"
                            >Disable editing
                            </b-form-checkbox>
                        </div>
                        <div class="input_item file_item">
                            <label>Upload attachment</label>
                            <b-form-file
                                    @change="onFileChange"
                                    placeholder="Choose a file(s)"
                                    multiple
                            ></b-form-file>
                            <span v-if="depositoryErrors['attachments']" class="text_danger mt-1">{{depositoryErrors['attachments'][0]}}</span>

                            <!--                            <div v-if="attachmentErrors.length" class="text_danger mt-1">-->
                            <!--                                Attachments must be a file of type: jpg, jpeg, png, pdf, docx.-->
                            <!--                                <p class="danger_p" v-for="item in attachmentErrors">-->
                            <!--                                    Attachment {{Number(item[1]) + 1}}-->
                            <!--                                </p>-->
                            <!--                            </div>-->
                        </div>

                    </div>

                    <div class="done_btn-container">
                        <b-button class="done_btn" @click="uploadNewAttachments()">Done</b-button>
                    </div>
                </div>
            </b-modal>
            <!--Depository create modal end-->

            <!--Update Depository modal start-->
            <b-modal ref="depository_edit-modal" centered hide-header hide-footer hide-header-close>
                <div class="depository_modal_content">
                    <b-icon icon="x" class="x_btn" aria-hidden="true" @click="depositoryEditModal()"></b-icon>
                    <div class="d-flex">
                        <div class="depository_title">
                            Edit Attachments
                        </div>
                    </div>

                    <div class="input_content">
                        <div class="input_item">
                            <label>Workspace</label>
                            <div class="select_arrow">
                                <b-form-select v-model="depositoryEditData.work_space_id"
                                               @change="depositoryEditWorkspaceChange">
                                    <option
                                            v-for="(option, id) in workspaces"
                                            :key="id"
                                            :value="option.id"
                                            :title="option.name || null"
                                    >
                                        {{option.name}}
                                    </option>
                                </b-form-select>
                                <div class="arrow_down"></div>
                                <!--                    <span v-if="depositoryErrors['work_space_id']" class="text_danger mt-1">{{depositoryErrors['work_space_id'][0]}}</span>-->
                            </div>
                        </div>
                        <div class="input_item">
                            <label>Channel</label>
                            <div class="select_arrow">
                                <b-form-select v-model="depositoryEditData.channel_id"
                                               @change="depositoryEditChannelChange">
                                    <option
                                            v-for="(option, id) in depositoryEditChannels"
                                            :key="id"
                                            :value="option.id"
                                            :title="option.name || null"
                                    >
                                        {{option.name}}
                                    </option>
                                </b-form-select>
                                <div class="arrow_down"></div>
                                <!--                    <span v-if="depositoryErrors['channel_id']" class="text_danger mt-1">{{depositoryErrors['channel_id'][0]}}</span>-->
                            </div>
                        </div>
                        <div class="input_item">
                            <label>Depository title</label>
                            <b-form-input
                                    v-model="depositoryEditData.attachmentTitle"
                                    type="text"
                                    trim
                            ></b-form-input>
                            <!--                <span v-if="depositoryErrors['depository_title']" class="text_danger mt-1">{{depositoryErrors['depository_title'][0]}}</span>-->
                        </div>
                        <div class="input_item">
                            <label>Assignee</label>
                            <div class="select_arrow">
                                <b-form-select v-model="depositoryEditData.user_id">
                                    <option
                                            v-for="(option, id) in workspaceUsers"
                                            :key="id"
                                            :value="option.id"
                                            :title="option.name || null"
                                    >
                                        {{option.name}}
                                    </option>
                                </b-form-select>
                                <div class="arrow_down"></div>
                                <!--                    <span v-if="depositoryErrors['channel_id']" class="text_danger mt-1">{{depositoryErrors['channel_id'][0]}}</span>-->
                            </div>
                        </div>
                        <div class="input_item">
                            <label>Settings</label>
                            <b-form-checkbox
                                    switch
                                    size="lg"
                                    v-model="depositoryEditData.status"
                                    value="1"
                                    unchecked-value="0"
                            >Disable editing
                            </b-form-checkbox>
                        </div>
                        <div class="input_item file_item">
                            <label>Upload attachment</label>
                            <b-form-file
                                    @change="onEditFileChange"
                                    placeholder="Choose a file(s)"
                                    multiple
                            ></b-form-file>
                            <div class="file_attachments-container" v-if="depositoryEditData.attach_file.length">
                                <div
                                        v-for="(item, index) in depositoryEditData.attach_file" :key="index"
                                        class="attach_img"
                                        :style="{ backgroundImage: 'url(' + item.d_attachment + ')' }"

                                >
                                    <div class="x_btn" @click="removeImage(item, index)"></div>
                                </div>
                            </div>
                            <!--                <span v-if="depositoryErrors['attachments']" class="text_danger mt-1">{{depositoryErrors['attachments'][0]}}</span>-->
                            <!--                            <div v-if="attachmentErrors.length" class="text_danger mt-1">-->
                            <!--                                Attachments must be a file of type: jpg, jpeg, png, pdf, docx.-->
                            <!--                                <p class="danger_p" v-for="item in attachmentErrors">-->
                            <!--                                    Attachment {{Number(item[1]) + 1}}-->
                            <!--                                </p>-->
                            <!--                            </div>-->
                        </div>
                    </div>
                    <div class="done_btn-container">
                        <b-button class="done_btn" @click="editAttachments()">Done</b-button>
                    </div>
                </div>
            </b-modal>
            <!--Update Depository modal end-->

            <!--Depository delete modal start -->
            <b-modal ref="depository_delete-modal" centered hide-header hide-footer hide-header-close>
                <div class="modal_content">
                    <b-icon icon="x" class="x_btn" aria-hidden="true" @click="deleteDepositoryModal()"></b-icon>
                    <div class="d-flex flex-column">
                        <div class="modal_title">
                            You are about to delete this depository
                        </div>
                        <div class="modal_text">
                            Do you want to proceed?
                        </div>
                        <div class="modal_buttons">
                            <div class="cancel_btn" @click="deleteDepositoryModal()">Cancel</div>
                            <b-button class="delete_btn ml-3" @click="removeDepository()">Delete</b-button>
                        </div>

                    </div>
                </div>
            </b-modal>
            <!--Depository delete modal end -->
        </div>
    </div>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";

    export default {
        name: "Depository",
        data() {
            return {
                channels: [],
                depositoryChannels: [],
                depositoryEditChannels: [],
                fields: [
                    {
                        key: 'index',
                        label: 'No',
                    },
                    {
                        key: 'title',
                        label: 'Depository Title',
                    },
                    {
                        key: 'name',
                        label: 'Depositor',
                    },
                    {
                        key: 'date',
                        label: 'Date',
                    },
                    {
                        key: 'attachment',
                        label: 'Attachment',
                    },
                    {
                        key: 'status',
                        label: 'Status',
                    },
                    {
                        key: 'settings',
                        label: 'Settings',
                    },
                    {
                        key: 'delete',
                        label: 'Delete',
                    },
                ],
                depository_data: [],
                depository: {
                    work_space_id: null,
                    workspace_name: null,
                    channel_id: null,
                    channel_name: null,
                },
                depositoryData: {
                    work_space_id: null,
                    work_space_name: null,
                    channel_id: null,
                    channel_name: null,
                    attachmentTitle: null,
                    status: 0,
                    attach_file: [],
                    user_id: null
                },
                depositoryEditData: {
                    work_space_id: null,
                    channel_id: null,
                    attachmentTitle: null,
                    status: null,
                    attach_file: [],
                    add_attach_file: [],
                    user_id: null,
                    depository_id: null,
                    file_delete_id: []
                },
                depositoryErrors: [],
                workspaceUsers: [],
                removeDepositoryId: null
            }
        },
        created() {
            this.getAllWorkspaces()
            // this.getDepository()
            //     .then(res => {
            //         if (res.status === 200) {
            //             this.depository_data = res.data.success
            //         }
            //     })
        },
        computed: {
            ...mapGetters(['workspaces', 'userData'])
        },
        methods: {
            ...mapActions([
                'getAllWorkspaces',
                'attachmentsUpload',
                'getDepositoryById',
                'depositoryRemove',
                'getWorkspaceUsers',
                'depositoryEdit'
            ]),
            getDate(item) {
                return new Date(item).toLocaleString("en-US", {timeZoneName: "short"}).split(',')[0]
            },
            onFileChange(event) {
                for (let i = 0; i < event.target.files.length; i++) {
                    this.createImage(event.target.files[i])
                }
            },
            createImage(file) {
                var reader = new FileReader()
                reader.onload = event => {
                    this.depositoryData.attach_file.push(event.target.result)
                }
                reader.readAsDataURL(file)
            },
            onEditFileChange(event) {
                for (let i = 0; i < event.target.files.length; i++) {
                    this.editImages(event.target.files[i])
                }
            },
            editImages(file) {
                var reader = new FileReader()
                reader.onload = event => {
                    let addData = {
                        d_attachment: event.target.result
                    }
                    this.depositoryEditData.attach_file.push(addData)
                    this.depositoryEditData.add_attach_file.push(event.target.result)
                }
                reader.readAsDataURL(file)
            },
            workspaceChange(args) {
                var workSpace = this.workspaces.find((item) => {
                    return item.id === args
                })
                this.channels = workSpace.channels
                this.depository.work_space_id = args
                this.depository.workspace_name = workSpace.name

            },
            channelChange(args) {
                this.depository.channel_id = args
                var channel = this.channels.find((item) => {
                    return item.id === args
                })
                this.depository.channel_name = channel.name
                this.getDepositoryById(this.depository)
                    .then(res => {
                        if(res.status === 200) {
                            this.depository_data = res.data.success
                        }
                    })
            },
            depositoryWorkspaceChange(args) {
                var workSpace = this.workspaces.find((item) => {
                    return item.id === args
                })
                this.depositoryData.work_space_id = args
                this.depositoryData.work_space_name = workSpace.name
                this.depositoryChannels = workSpace.channels
                this.getWorkspaceUsers(args)
                    .then(res => {
                        if (res.status === 200) {
                            this.workspaceUsers = res.data.success
                        }
                    })
            },
            depositoryChannelChange(args) {
                this.depositoryData.channel_id = args
                var channel = this.depositoryChannels.find((item) => {
                    return item.id === args
                })
                this.depositoryData.channel_name = channel.name
            },
            depositoryEditWorkspaceChange(args) {
                var workSpace = this.workspaces.find((item) => {
                    return item.id === args
                })
                this.depositoryEditData.work_space_id = args
                this.depositoryEditData.work_space_name = workSpace.name
                this.depositoryEditChannels = workSpace.channels
            },
            depositoryEditChannelChange(args) {
                this.depositoryEditData.channel_id = args
            },
            getStatus(args) {
                if (args === 0) {
                    return 'In Progress'
                } else if (args === 1) {
                    return 'Completed'
                } else {
                    return 'Closed'
                }
            },
            uploadAttachment() {
                this.depositoryErrors = []
                this.depositoryData.work_space_id = null
                this.depositoryData.work_space_name = null
                this.depositoryData.channel_id = null
                this.depositoryData.attachmentTitle = null
                this.depositoryData.status = null
                this.depositoryData.attach_file = []
                this.workspaceUsers = []
                this.depositoryModal()
            },
            depositoryModal() {
                this.$refs['depository-modal'].toggle('#depository-btn')
            },
            uploadNewAttachments() {
                this.depositoryErrors = []
                this.attachmentsUpload(this.depositoryData)
                    .then(res => {
                        if (res.status === 200) {
                            this.depositoryModal()
                            // this.getDepository()
                            //     .then(res => {
                            //         if (res.status === 200) {
                            //             this.depository_data = res.data.success
                            //         }
                            //     })
                            if(this.depository.work_space_id && this.depository.channel_id) {
                                this.getDepositoryById(this.depository)
                                    .then(res => {
                                        if(res.status === 200) {
                                            this.depository_data = res.data.success
                                        }
                                    })
                            }
                        } else {
                            this.depositoryErrors = res.response.data.errors
                        }
                    })
            },
            depositoryDelete(item) {
                this.removeDepositoryId = item.id
                this.deleteDepositoryModal()
            },
            deleteDepositoryModal() {
                this.$refs['depository_delete-modal'].toggle('#remove_depository-btn')
            },
            removeDepository() {
                this.depositoryRemove(this.removeDepositoryId)
                    .then(res => {
                        if (res.status === 200) {
                            this.deleteDepositoryModal()
                            // this.getDepository()
                            //     .then(res => {
                            //         if (res.status === 200) {
                            //             this.depository_data = res.data.success
                            //         }
                            //     })
                        }
                    })
            },
            editDepository(item) {
                this.depositoryEditData.attach_file = []
                this.depositoryEditData.file_delete_id = []
                this.depositoryEditData.add_attach_file = []
                this.workspaceUsers = []
                this.depositoryEditData.depository_id = item.id
                this.depositoryEditData.work_space_id = item.work_space_id
                this.depositoryEditWorkspaceChange(item.work_space_id)
                this.depositoryEditData.channel_id = item.channel_id
                this.depositoryEditData.attachmentTitle = item.depository_title
                this.depositoryEditData.status = item.status.toString()
                this.getWorkspaceUsers(item.work_space_id)
                    .then(res => {
                        if (res.status === 200) {
                            this.workspaceUsers = res.data.success
                        }
                    })
                this.depositoryEditData.user_id = item.user_id
                for (let i = 0; i < item.attachments.length; i++) {
                    this.depositoryEditData.attach_file.push(item.attachments[i])
                }
                // item.attachments.forEach(item => {
                //     this.depositoryEditData.attach_file.push(item.attachment)
                // })
                this.depositoryEditModal()
            },
            removeImage(item, index) {
                this.depositoryEditData.attach_file.splice(index, 1)
                if (item.id) {
                    this.depositoryEditData.file_delete_id.push(item.id)
                }
            },
            depositoryEditModal() {
                this.$refs['depository_edit-modal'].toggle('#edit_depository-btn')
            },
            editAttachments() {
                this.depositoryEdit(this.depositoryEditData)
                    .then(res => {
                        if (res.status === 200) {
                            this.depositoryEditModal()
                            // this.getDepository()
                            //     .then(res => {
                            //         if (res.status === 200) {
                            //             this.depository_data = res.data.success
                            //         }
                            //     })
                            this.getDepositoryById(this.depository)
                                .then(res => {
                                    if(res.status === 200) {
                                        this.depository_data = res.data.success
                                    }
                                })
                        }
                    })
            }
        }
    }
</script>

<style scoped lang="scss">
    ::v-deep .custom-select {
        border: none;
    }

    .page {
        &_head {
            padding-bottom: 20px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            &-drop-btn {
                display: flex;
                align-items: center;

                & .input_item {
                    & .select_arrow {
                        position: relative;
                        width: 240px;
                        margin-right: 20px;

                        & .arrow_down {
                            position: absolute;
                            top: 14px;
                            right: 15px;
                            width: 14px;
                            height: 10px;
                            pointer-events: none;
                            background: url("~@/assets/images/chevron_down.svg") right no-repeat;
                        }
                    }

                    & select {
                        background: $greyBlue 0 0 no-repeat padding-box;
                        //background: transparent;
                        border-radius: 4px;
                        padding-right: 5px;
                    }
                }

            }
        }

        &_title {
            color: $black;
            font-weight: 600;
            font-size: 25px;
        }

        &_content {
            padding: 20px 40px 20px 1px;

            & .create_btn {
                color: $primary;
                height: 44px;
                font-weight: 600;
                background: transparent linear-gradient(180deg, #84EEEB 0%, #3AE4DF 100%) 0% 0% no-repeat padding-box;
                border-radius: 10px;
                padding: 0 20px;
                border: none;
                display: flex;
                justify-content: center;
                align-items: center;

                &:focus,
                &:active {
                    box-shadow: none;
                    color: $primary;
                }
            }

            & .depository_table {
                padding: 20px 0;

                & .table_title {
                    width: 100%;
                    height: 60px;
                    background: #84EEEB;
                    color: $grey;
                    font-size: 16px;
                    font-weight: 600;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }

            & .table_container {
                font-size: 13px;

                & .cyan {
                    color: #3AE4DF;
                }

                & .green {
                    color: #1CD65A;
                }

                & .red {
                    color: red;
                }

                ::v-deep thead {
                    height: 62px;
                    color: $primary;
                    background: #E7E8F5 0 0 no-repeat padding-box;

                    th {
                        vertical-align: middle;
                        max-width: 100px;
                        text-align: center;
                    }
                }

                ::v-deep tbody {
                    tr {
                        td {
                            vertical-align: middle;
                            text-align: center;
                            text-align: -webkit-center;
                        }
                    }
                }

                & .view_btn {
                    width: 22px;
                    height: 22px;
                    cursor: pointer;
                    background: url("~@/assets/images/eye_icon.svg") center no-repeat;
                }

                & .edit_btn {
                    width: 22px;
                    height: 22px;
                    cursor: pointer;
                    background: url("~@/assets/images/edit_icon.svg") center no-repeat;
                }

                & .trash_button {
                    width: 22px;
                    height: 22px;
                    cursor: pointer;
                    background: url("~@/assets/images/trash_icon.svg") center no-repeat;
                }

                & .item {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    height: 100%;

                    & .dropdown_content {
                        ::v-deep button {
                            width: 130px;
                            height: 32px;
                            background: $light_purple;
                            color: $white;
                            font-size: 12px;
                            border: none;
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                        }

                        ::v-deep .dropdown-menu {
                            min-width: 8rem;
                            border: none;
                            margin: 0;
                            padding: 0;
                        }

                        ::v-deep ul {
                            max-width: 130px;
                            width: 100%;
                            background: $greyBlue;

                            li {
                                border-top: 1px solid $light_purple;
                                font-size: 12px;
                                width: 100%;

                                a {
                                    white-space: normal;
                                    padding: 3px 8px;
                                }
                            }
                        }
                    }
                }

                & .reply_btn {
                    background: $light_purple;
                    border: none;
                    font-size: 14px;
                    border-radius: 5px;

                    &:focus {
                        box-shadow: none;
                    }

                    &:hover {
                        opacity: .9;
                    }
                }

                & .status_item {
                    max-width: 110px;
                    width: 100%;
                }

                & .status_dots {
                    display: flex;
                    justify-content: space-evenly;

                    & .dot_opy {
                        opacity: 1;
                    }
                }

                & .yellow_dot {
                    width: 20px;
                    height: 20px;
                    border-radius: 50%;
                    background: #F2EB1A;
                    opacity: 0.5;
                    cursor: pointer;
                }

                & .green_dot {
                    width: 20px;
                    height: 20px;
                    border-radius: 50%;
                    background: #1CD65A;
                    opacity: 0.5;
                    cursor: pointer;
                }

                & .orange_dot {
                    width: 20px;
                    height: 20px;
                    border-radius: 50%;
                    background: #FFB26E;
                    opacity: 0.5;
                    cursor: pointer;
                }

                & .purple_dot {
                    width: 20px;
                    height: 20px;
                    border-radius: 50%;
                    background: $light_purple;
                    opacity: 0.5;
                    cursor: pointer;
                }

                & .active_dot {
                    opacity: 1;
                    box-shadow: 0 0 0 3px rgb(255, 255, 255) inset;
                    border: 2px solid transparent;
                }
            }
        }
    }

    /*/////////////*/
    .depository {
        &_modal_content {
            position: relative;
            padding: 0 10px;
            width: 580px;

            & .x_btn {
                position: absolute;
                top: 0;
                right: 0;
                width: 25px;
                height: 25px;
                cursor: pointer;
                color: $light_purple;
            }

            & .input_content {
                display: flex;
                flex-direction: column;

                margin-bottom: 15px;

                & .input_item {
                    padding: 5px 0;
                    width: 100%;
                    flex-direction: column;

                    ::v-deep .custom-control-input:checked ~ .custom-control-label::before {
                        background-color: $lightBlue;
                        border-color: $purple;
                    }

                    ::v-deep .custom-control-input:checked ~ .custom-control-label::after {
                        background-color: $purple;
                    }

                    ::v-deep .custom-control-label {
                        color: #4A4A4D;
                        padding-top: 4px;
                        font-size: 16px;
                        cursor: pointer;
                    }

                    &.file_item {
                        margin: 15px 0 5px;

                        & .danger_p {
                            margin-bottom: 2px;
                        }

                        & .file_attachments-container {
                            display: flex;
                            flex-wrap: wrap;
                            margin: 10px 0 5px;

                            & .attach_img {
                                position: relative;
                                margin: 5px;
                                width: 70px;
                                height: 70px;
                                background-position: center;
                                background-repeat: no-repeat;
                                background-size: contain;

                                & .x_btn {
                                    position: absolute;
                                    top: 2px;
                                    right: 2px;
                                    width: 16px;
                                    height: 16px;
                                    border-radius: 50%;
                                    background: url("~@/assets/images/x_icon.svg") no-repeat center;
                                    background-size: cover;
                                }
                            }
                        }

                        ::v-deep .custom-file {
                            background: #E7E8F5 0 0 no-repeat padding-box;
                            border: 1px dashed #4A4A4D75;
                            border-radius: 3px;
                            position: relative;
                        }

                        ::v-deep .custom-file-label {
                            background: transparent !important;
                            border: 1px solid transparent;
                            padding-left: 40px;

                            &:after {
                                display: none !important;
                            }

                            &:before {
                                content: '';
                                position: absolute;
                                left: 10px;
                                width: 20px;
                                height: 20px;
                                background: url("~@/assets/images/paper-clip.svg") no-repeat center;
                            }
                        }

                        ::v-deep input {
                            background: none;
                            border: none !important;
                            box-shadow: none !important;
                            outline: none !important;

                            &:focus {
                                box-shadow: none !important;
                                outline: none !important;
                            }
                        }
                    }

                    & .select_arrow {
                        position: relative;

                        & .arrow_down {
                            position: absolute;
                            top: 14px;
                            right: 15px;
                            width: 14px;
                            height: 10px;
                            pointer-events: none;
                            background: url("~@/assets/images/chevron_down.svg") right no-repeat;
                        }
                    }

                    & label {
                        color: $primary;
                        font-weight: 600;
                        font-size: 14px;
                    }

                    & input {
                        border: none;
                        background: $greyBlue 0 0 no-repeat padding-box;
                        border-radius: 3px;

                        &:focus {
                            box-shadow: none;
                            outline: none;
                        }
                    }

                    & select {
                        background: $greyBlue 0 0 no-repeat padding-box;
                        border-radius: 4px;
                        padding-right: 5px;
                    }

                }
            }

            & .done_btn-container {
                display: flex;
                justify-content: flex-end;
                padding: 20px 0 10px;

                & .done_btn {
                    background: transparent linear-gradient(180deg, #84EEEB 0%, #3AE4DF 100%) 0% 0% no-repeat padding-box;
                    border-radius: 10px;
                    width: 110px;
                    height: 44px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: $primary;
                    font-weight: 600;
                    border: none;
                }
            }
        }

        &_title {
            width: 100%;
            font-size: 25px;
            font-weight: 500;
            color: $grey;
            padding-bottom: 10px;
            margin-bottom: 20px;
            border-bottom: 1px solid #CACDF6;
        }
    }

    ::v-deep .modal-content {
        border: none !important;
        width: 700px;

        ::v-deep .modal-body {
            width: 600px !important;
        }
    }

    ::v-deep .modal_content {
        border: none !important;
        width: 600px;
        position: relative;

        ::v-deep .modal-body {
            width: 600px !important;
        }

        & .x_btn {
            position: absolute;
            top: 0;
            right: 0;
            width: 25px;
            height: 25px;
            cursor: pointer;
            color: $light_purple;
        }

        & .modal_title {
            width: 100%;
            padding: 20px 0;
            font-size: 25px;
            color: $grey;
            border-bottom: 1px solid #CACDF6;
        }

        & .modal_text {
            font-size: 16px;
            color: $grey;
            padding: 25px 0;
        }

        & .modal_buttons {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            padding: 15px 0 30px;

            & .cancel_btn {
                color: $primary;
                font-weight: 600;
                padding: 10px 0;
                cursor: pointer;

            }

            & .delete_btn {
                width: 110px;
                height: 44px;
                background: transparent linear-gradient(180deg, #84EEEB 0%, #3AE4DF 100%) 0% 0% no-repeat padding-box;
                border-radius: 10px;
                color: $primary;
                font-weight: 600;
                border: none;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }

        & .description_content {
            padding: 20px 0;

            & .description_subtitle {
                font-weight: 500;
                font-size: 18px;
                padding: 0 15px;
            }

            & .description_text {
                padding: 15px 20px;
            }
        }
    }
</style>