<template>
    <div>
        <div class="page_content">
            <div class="page_head">
                <div class="page_title">
                    Notifications
                </div>
                <div class="mark_read">Mark all as read</div>
            </div>

            <div class="notifications_container">
<!--                <div class="notifications_head">-->
<!--                    <div class="notifications_day">-->
<!--                        Today-->
<!--                    </div>-->
<!--                </div>-->
                <div class="notifications_items">
                    <div
                        v-for="item of notification"
                        class="notifications_item"
                        :class="{'notifications_unread': item.status === 1}"
                        @click="notificationRead(item)"
                    >
                        <div class="notifications_title">
                            <div class="notifications_name">
                                {{item.sender_name}}
                            </div>

                            <div class="notifications_date">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#5454EE" class="bi bi-clock mr-2" viewBox="0 0 16 16">
                                    <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z"/>
                                    <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z"/>
                                </svg>
                                <div class="date_text">
                                    {{getDate(item.created_at)}}
                                </div>

                            </div>
                        </div>
                        <div class="notifications_text">
                            {{item.data}}
                        </div>

                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    import {mapActions} from "vuex";

    export default {
        name: "Notifications",
        data() {
            return {
                notifications: [
                    {
                        name: 'John',
                        text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s',
                        time: '11:52 AM',
                        unread: true,
                    },
                    {
                        name: 'Mike',
                        text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s Lorem Ipsum I been the industry\'s standard dummy text ever since the 1500s',
                        time: '11:52 AM',
                        unread: true,
                    },
                    {
                        text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s',
                        time: '11:52 AM',
                        unread: false,
                    },
                ],
                notification: []
            }
        },
        computed: {

        },
        created() {
            this.notifyGet()
            .then((response) => {
                this.notification = response.data.success
                response.data.success.forEach((notification) => {
                    if(notification.status === 1) {
                        this.$store.commit('NOTIFY_STATUS')
                    }
                })
            })
        },
        methods: {
            ...mapActions(['notifyGet', 'changeNotifyStatus']),
            notificationRead(item) {
                if(item.status === 1) {
                    this.changeNotifyStatus(item.id)
                    .then((res) =>{
                        if(res) {
                            this.notifyGet()
                            .then((res) => {
                                this.notification = res.data.success
                                res.data.success.forEach((notification) => {
                                    if(notification.status === 1) {
                                        this.$store.commit('NOTIFY_STATUS')
                                    }
                                })
                            })
                        }
                    })
                    // this.notifications.indexOf(item.unread = false)
                }
            },
            getDate(item) {
                // let d = new Date(item).toString().substring(0,24).replace("T"," ")
                return new Date(item).toString().slice(0, 24)
            }
        }
    }
</script>

<style scoped lang="scss">
.page {
    &_content {
        padding: 20px 40px 20px 1px;
        & .notifications {
            &_container {
                padding: 10px 0 20px;
            }
            &_items {
                padding: 15px 0;
            }
            &_item {
                width: 100%;
                padding: 15px 0;
                min-height: 75px;
                display: flex;
                flex-direction: column;
                background: #F3F5F8;
                margin: 8px 0;
                box-sizing: content-box;
                border-image: linear-gradient(to right bottom, #84EEEB, #3AE4DF);
                border-image-slice: 1;
                border-left: 7px solid;
            }
            /*&_day {*/
            /*    color: #A5A5A5;*/
            /*    font-size: 14px;*/
            /*    line-height: 18px;*/
            /*    font-weight: 500;*/
            /*}*/
            &_title {
                display: flex;
                justify-content: space-between;
                padding: 0 20px 10px;
            }
            &_name {
                font-size: 14px;
                font-weight: 600;
                color: #000;
            }
            &_text {
                padding: 0 20px 1px;
                font-size: 16px;
                font-weight: 500;
                line-height: 20px;

            }

            &_date {
                display: flex;
                justify-content: flex-end;
                width: 300px;
                & .date_text {
                    line-height: 18px;
                    color: $purple;
                    font-size: 14px;
                }
            }
            &_unread {
                background: #EFF0FB;
                /*border-left: 7px solid #4FA7F8;*/
            }
        }

    }
    &_head {
        padding-bottom: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #DEDEDE;
        & .mark_read {
            color: $purple;
            font-size: 12px;
            font-weight: 500;
            text-decoration: underline;
            text-underline-offset: 2px;
            cursor: pointer;

        }
    }
    &_title {
        color: $black;
        font-weight: 600;
        font-size: 25px;
    }
}
</style>