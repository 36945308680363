<template>
    <div>
        <div class="page_content">
            <div class="page_head">
                <div class="page_title">
                    Task Notification
                </div>
                <b-button class="create_btn" @click="taskModal()" id="task-btn">Create New Task</b-button>
            </div>

            <div class="main_content">
<!-- Tasks table  start-->
                <div class="table_container">
                    <b-table hover :items="filteredTasks" :fields="fields" responsive>
                        <template v-slot:cell(index)="data">
                            <div class="">
                                {{data.index+1}}
                            </div>
                        </template>

                        <template v-slot:cell(work_spaces_name)="data">
                            <div class="item_name" v-html="highlight(data.item.work_spaces_name)">
                                {{data.item.work_spaces_name}}
                            </div>
                        </template>

                        <template v-slot:cell(channel_name)="data">
                            <div class="" v-html="highlight(data.item.channel_name)">
                                {{data.item.channel_name}}
                            </div>
                        </template>

                        <template v-slot:cell(title)="data">
                            <div class="" v-html="highlight(data.item.title)">
                                {{data.item.title}}
                            </div>
                        </template>

                        <template v-slot:cell(requestor_name)="data">
                            <div class="" v-html="highlight(data.item.requestor_name.name)">
                                {{data.item.requestor_name.name}}
                            </div>
                        </template>

                        <template v-slot:cell(user_name.name)="data">
                            <div class="" v-html="highlight(data.item.user_name.name)">
                                {{data.item.user_name.name}}
                            </div>
                        </template>

                        <template v-slot:cell(req_in)="data">
                            <div class="" v-html="highlight(data.item.req_in)">
                                {{data.item.req_in}}
                            </div>
                        </template>

                        <template v-slot:cell(req_date)="data">
                            <div class="">
                                {{getDate(data.item.req_date)}}
                            </div>
                        </template>

                        <template v-slot:cell(due_date)="data">
                            <div class="">
                                {{getDate(data.item.due_date) }}
                            </div>
                        </template>

                        <template v-slot:cell(priority)="data">
                            <div class=""
                                 :class="{
                                'red': data.item.priority === 2,
                                'orange': data.item.priority === 0
                                 }"
                            >
                                {{getPriority(data.item.priority)}}
                            </div>
                        </template>

                        <template v-slot:cell(status)="data">
                            <div v-if="userInfo.id === data.item.parent_id"
                                 class="status_item"
                                 :class="{
                        'red': data.item.status === 2,
                        'green': data.item.status === 1,
                        'orange': data.item.status === 0
                    }">
                                {{getStatus(data.item.status)}}
                            </div>
                            <div v-if="userInfo.id !== data.item.parent_id"
                                 class="status_item"
                                 :class="{
                        'red': data.item.status === 2,
                        'green': data.item.status === 1,
                        'orange': data.item.status === 0
                    }">
                                {{getUserStatus(data.item.status)}}
                            </div>
                        </template>

                        <template v-slot:cell(view)="data">
                            <div class="d-flex justify-content-center">
                                <div class="view_btn" @click="view(data.item)" id="description-btn">
                                </div>
                            </div>
                        </template>

                        <template v-slot:cell(edit)="data">
                            <div class="d-flex justify-content-center" v-if="userInfo.id === data.item.parent_id">
                                <div class="edit_btn" @click="openTaskEdit(data.item)" id="edit-btn">
                                </div>
                            </div>
                        </template>

                        <template v-slot:cell(delete)="data">
                            <div class="d-flex justify-content-center" v-if="userInfo.id === data.item.parent_id">
                                <div class="trash_button" @click="taskDeleteModal(data.item.id)" id="delete_task"></div>
                            </div>
                        </template>

                        <template v-slot:cell(action)="data">
                            <div v-if="userInfo.id === data.item.user_id && data.item.status !== 2" class="d-flex justify-content-center">
                                <div class="item" >
                                    <b-dropdown id="dropdown-1" dropdown text="Choose" class="dropdown_content">
                                        <b-dropdown-item @click="acceptTask(data.item)">Accept</b-dropdown-item>
                                        <b-dropdown-item @click="reassignModalData(data.item)" id="reassign_task">Reassign</b-dropdown-item>
                                        <b-dropdown-item @click="disputeModal(data.item)" id="dispute_btn">Dispute</b-dropdown-item>
                                    </b-dropdown>
                                </div>
                            </div>
                            <div v-if="data.item.status === 2 && data.item.requestor_name.id === userInfo.id" class="d-flex justify-content-center">
                                <b-button class="reply_btn" @click="adminDisputeModal(data.item)" id="admin_dispute_btn">To Reply</b-button>
                            </div>
                        </template>

                    </b-table>
                </div>
<!-- tasks table end-->

<!--description modal start-->
            <b-modal ref="description-modal" centered hide-header hide-footer hide-header-close>
                    <div class="modal_content">
                        <b-icon icon="x" class="x_btn" aria-hidden="true" @click="descriptionModal()"></b-icon>
                        <div class="d-flex flex-column" v-if="modalData">
                            <div class="modal_title">
                                {{modalData.work_spaces_name}}
                            </div>
                            <div class="description_content">
                                <div class="description_subtitle">
                                    <b-icon icon="filter-left" class="mr-2"  aria-hidden="true"></b-icon>
                                    Description
                                </div>
                                <div class="description_text">
                                    {{modalData.description}}
                                </div>

                            </div>
<!--                            <div>{{modalData}}</div>-->
                        </div>
                    </div>
                </b-modal>
<!--description modal end-->

<!--create task modal start-->
            <b-modal ref="task-modal" centered hide-header hide-footer hide-header-close>
                    <div class="task_modal_content">
                        <b-icon icon="x" class="x_btn" aria-hidden="true" @click="taskModal()"></b-icon>
                        <div class="d-flex">
                            <div class="task_title">
                                Create New Task
                            </div>
                        </div>
                        <div class="input_content">
                            <div class="input_item">
                                <label>WorkSpace</label>
                                <div class="select_arrow">
                                    <b-form-select @change="workspaceChange">
                                        <option
                                                v-for="(option, id) in workspaces"
                                                :key="id"
                                                :value="option.id"
                                                :title="option.name || null"
                                        >
                                            {{option.name}}
                                        </option>
                                    </b-form-select>
                                    <div class="arrow_down"></div>
                                    <span v-if="createTaskErrors['work_space_id']" class="text_danger mt-2">{{createTaskErrors['work_space_id'][0]}}</span>
                                </div>
                            </div>
                            <div class="input_item">
                                <label>Channel</label>
                                <div class="select_arrow">
                                    <b-form-select @change="channelChange" :disabled="!channels.length">
                                        <option
                                                v-for="(option, id) in channels"
                                                :key="id"
                                                :value="option.id"
                                                :title="option.name || null"
                                        >
                                            {{option.name}}
                                        </option>
                                    </b-form-select>
                                    <div class="arrow_down"></div>
                                    <span v-if="createTaskErrors['channel_id']" class="text_danger mt-2">{{createTaskErrors['channel_id'][0]}}</span>
                                </div>
                            </div>
                            <div class="input_item">
                                <label>Task Title</label>
                                <b-form-input
                                        v-model="taskData.task_title"
                                        type="text"
                                        trim
                                ></b-form-input>
                                <span v-if="createTaskErrors['title']" class="text_danger mt-2">{{createTaskErrors['title'][0]}}</span>
                            </div>
                            <div class="input_item">
                                <label>Task Description</label>
                                <b-form-input
                                        v-model="taskData.task_description"
                                        type="text"
                                        trim
                                ></b-form-input>
                                <span v-if="createTaskErrors['description']" class="text_danger mt-2">{{createTaskErrors['description'][0]}}</span>
                            </div>
                            <div class="input_item">
                                <label>Assignee</label>
                                <div class="select_arrow">
                                    <b-form-select @change="assigneeChange">
                                        <option
                                                v-for="(option, id) in channelMembers"
                                                :key="id"
                                                :value="option.id"
                                                :title="option.name || null"
                                        >
                                            {{option.name}}
                                        </option>
                                    </b-form-select>
                                    <div class="arrow_down"></div>
                                    <span v-if="createTaskErrors['user_id']" class="text_danger mt-2">{{createTaskErrors['user_id'][0]}}</span>
                                </div>
                            </div>
                            <div class="input_item">
                                <label>Copied List</label>
                                <div class="select_arrow">

<!--                                    <multiselect v-model="value" :options="options"></multiselect>-->

                                    <Multiselect
                                            v-model="taskData.selectedAdmins"
                                            :options="filteredItems"
                                            label="name"
                                            track-by="id"
                                            placeholder=""
                                            :disabled="!taskData.user_id"
                                            :hide-selected="true"
                                            multiple
                                    ></Multiselect>
<!--                                    <b-form-select multiple v-model="taskData.selectedAdmins">-->
<!--                                        <option-->
<!--                                                v-for="(option, id) in allAdmins"-->
<!--                                                :key="id"-->
<!--                                                :value="option.id"-->
<!--                                                :title="option.name || null"-->
<!--                                        >-->
<!--                                            {{option.name}}-->
<!--                                        </option>-->
<!--                                    </b-form-select>-->
                                    <div class="arrow_down"></div>
                                    <span v-if="createTaskErrors['user_id']" class="text_danger mt-2">{{createTaskErrors['user_id'][0]}}</span>
                                </div>
                            </div>

                            <div class="input_item">
                                <label>Req Date</label>
                                <b-form-input
                                        v-model="taskData.req_date"
                                        type="date"
                                        trim
                                ></b-form-input>
                                <span v-if="createTaskErrors['req_date']" class="text_danger mt-2">{{createTaskErrors['req_date'][0]}}</span>
                            </div>
                            <div class="input_item">
                                <label>Due Date</label>
                                <b-form-input
                                        v-model="taskData.due_date"
                                        type="date"
                                        trim
                                ></b-form-input>
                                <span v-if="createTaskErrors['due_date']" class="text_danger mt-2">{{createTaskErrors['due_date'][0]}}</span>
                            </div>
                            <div class="input_item">
                                <label>Priority</label>
                                <div class="select_arrow">
                                    <b-form-select @change="priorityChange">
                                        <option
                                            v-for="(option, id) in priority"
                                            :key="id"
                                            :value="option.id"
                                            :title="option.name || null"
                                        >
                                            {{option.name}}
                                        </option>
                                    </b-form-select>
                                    <div class="arrow_down"></div>
                                    <span v-if="createTaskErrors['priority']" class="text_danger mt-2">{{createTaskErrors['priority'][0]}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="done_btn-container">
                            <b-button class="done_btn" @click="createTask()">Done</b-button>
                        </div>
                    </div>
                </b-modal>
<!--create task modal end-->

<!--edit modal start-->
                <b-modal ref="task_edit-modal" centered hide-header hide-footer hide-header-close>
                    <div class="task_modal_content">
                    <b-icon icon="x" class="x_btn" aria-hidden="true" @click="openEditModal()"></b-icon>
                        <div class="d-flex">
                            <div class="task_title">
                                Edit Task
                            </div>
                        </div>
                    <div class="input_content">
                            <div class="input_item">
                                <div class="edit_workspace">
                                    {{editTaskData.work_space}}
                                </div>
                            </div>
                            <div class="input_item">
                                <label>Channel</label>
                                <div class="select_arrow">
                                    <b-form-select v-model="channelSelected">
                                        <option
                                            v-for="(option, id) in editChannels"
                                            :key="id"
                                            :value="option.id"
                                            :title="option.name || null"
                                        >
                                            {{option.name}}
                                        </option>
                                    </b-form-select>
                                    <div class="arrow_down"></div>
                                </div>
                            </div>
                            <div class="input_item">
                                <label>Task Title</label>
                                <b-form-input
                                        v-model="editTaskData.task_title"
                                        type="text"
                                        trim
                                ></b-form-input>
                            </div>
                            <div class="input_item">
                                <label>Task Description</label>
                                <b-form-input
                                        v-model="editTaskData.task_description"
                                        type="text"
                                        trim
                                ></b-form-input>
                            </div>

                            <div class="input_item">
                                <label>Assignee</label>
                                <div class="select_arrow">
                                    <b-form-select v-model="selectedUser" >
                                        <option
                                                v-for="(option, id) in editTaskData.allAssignee"
                                                :key="id"
                                                :value="option.id"
                                                :title="option.name || null"
                                        >
                                            {{option.name}}
                                        </option>
                                    </b-form-select>
                                    <div class="arrow_down"></div>
                                </div>
                            </div>

                            <div class="input_item">
                                <label>Copied List</label>
                                <div class="select_arrow">
                                    <!--                                    <multiselect v-model="value" :options="options"></multiselect>-->
                                    <Multiselect
                                            v-model="editSelectedAdmins"
                                            :options="editTaskData.allAdmins"
                                            label="name"
                                            track-by="id"
                                            placeholder=""
                                            :hide-selected="true"
                                            multiple
                                    ></Multiselect>
                                    <!--                                    <b-form-select multiple v-model="taskData.selectedAdmins">-->
                                    <!--                                        <option-->
                                    <!--                                                v-for="(option, id) in allAdmins"-->
                                    <!--                                                :key="id"-->
                                    <!--                                                :value="option.id"-->
                                    <!--                                                :title="option.name || null"-->
                                    <!--                                        >-->
                                    <!--                                            {{option.name}}-->
                                    <!--                                        </option>-->
                                    <!--                                    </b-form-select>-->
                                    <div class="arrow_down"></div>
                                </div>
                            </div>

                            <div class="input_item">
                                <label>Req Date</label>
                                <b-form-input
                                        v-model="editTaskData.req_date"
                                        type="date"
                                        trim
                                ></b-form-input>
                            </div>
                            <div class="input_item">
                                <label>Due Date</label>
                                <b-form-input
                                        v-model="editTaskData.due_date"
                                        type="date"
                                        trim
                                ></b-form-input>
                            </div>
                            <div class="input_item">
                                <label>Priority</label>
                                <div class="select_arrow">
                                    <b-form-select v-model="prioritySelected">
                                        <option
                                                v-for="(option, id) in priority"
                                                :key="id"
                                                :value="option.id"
                                                :title="option.name || null"
                                        >
                                            {{option.name}}
                                        </option>
                                    </b-form-select>
                                    <div class="arrow_down"></div>
                                </div>
                            </div>
                        </div>
                    <div class="done_btn-container">
                        <b-button class="done_btn" @click="editTask()">Save</b-button>
                    </div>
                </div>
            </b-modal>
<!--edit modal end-->

<!--delete modal start -->
            <b-modal ref="task_delete-modal" centered hide-header hide-footer hide-header-close>
    <div class="modal_content">
        <b-icon icon="x" class="x_btn" aria-hidden="true" @click="taskDeleteModal()"></b-icon>
        <div class="d-flex flex-column">
            <div class="modal_title">
                You are about to delete this task
            </div>
            <div class="modal_text">
                Do you want to proceed?
            </div>
            <div class="modal_buttons">
                <div class="cancel_btn" @click="taskDeleteModal()">Cancel</div>
                <b-button class="delete_btn ml-3" @click="removeTask()">Delete</b-button>
            </div>

        </div>
    </div>
</b-modal>
<!--delete modal end -->

<!--reassign modal start -->
                <b-modal ref="reassign-modal" centered hide-header hide-footer hide-header-close>
                    <div class="modal_content">
                        <b-icon icon="x" class="x_btn" aria-hidden="true" @click="openReassignModal()"></b-icon>
                        <div class="d-flex flex-column">
                            <div class="modal_title">
                                Reassign task
                            </div>
                            <div class="reassign_modal-content">
                                <div class="input_item">
                                    <div class="select_arrow">
                                        <b-form-select @change="reassignUserChange">
                                            <option
                                                    v-for="(option, id) in reassignUsers"
                                                    :key="id"
                                                    :value="option.id"
                                                    :title="option.name || null"
                                            >
                                                {{option.name}}
                                            </option>
                                        </b-form-select>
                                        <div class="arrow_down"></div>
                                    </div>
                                </div>
                            </div>
                            <div class="modal_buttons">
                                <b-button class="delete_btn ml-3" @click="reassignTask()">Done</b-button>
                            </div>

                        </div>
                    </div>
                </b-modal>
<!--reassign modal end -->

<!--dispute modal start -->
                <b-modal ref="dispute-modal" centered hide-header hide-footer hide-header-close>
                    <div class="modal_content">
                        <b-icon icon="x" class="x_btn" aria-hidden="true" @click="disputeModal()"></b-icon>
                        <div class="d-flex flex-column">
                            <div class="modal_title">
                                Dispute
                            </div>
                            <div class="dispute_modal-content">
                                <div class="input_item">
                                    <label>Reply</label>
                                    <b-form-textarea
                                            v-model="disputeData.description"
                                            placeholder="Enter something..."
                                            rows="6"
                                            max-rows="8"
                                    ></b-form-textarea>
                                </div>

<!--                                <div class="radio_part">-->
<!--                                    <b-form-radio-group-->
<!--                                            v-model="disputeData.selected"-->
<!--                                            :options="radioOptions"-->
<!--                                            class="radio_group"-->
<!--                                            value-field="item"-->
<!--                                            text-field="name"-->
<!--                                    ></b-form-radio-group>-->
<!--                                </div>-->
                            </div>
                            <div class="modal_buttons">
                                <b-button class="delete_btn ml-3" @click="disputeTask()">Done</b-button>
                            </div>
                        </div>
                    </div>
                </b-modal>
<!--dispute modal end -->

<!--admin dispute modal start -->
                <b-modal ref="admin-dispute-modal" centered hide-header hide-footer hide-header-close>
                    <div class="modal_content">
                        <b-icon icon="x" class="x_btn" aria-hidden="true" @click="openAdminDisputeModal()"></b-icon>
                        <div class="d-flex flex-column">
                            <div class="modal_title">
                                Dispute
                            </div>
                            <div class="dispute_modal-content">
                                <div class="input_item">
                                    <label>Reply</label>
                                    <p class="description_p">{{disputeData.description}}</p>
<!--                                    <label>Reply</label>-->
<!--                                    <b-form-textarea-->
<!--                                            v-model="disputeData.description"-->
<!--                                            placeholder="Enter something..."-->
<!--                                            rows="6"-->
<!--                                            max-rows="8"-->
<!--                                    ></b-form-textarea>-->
                                </div>

                                <div class="radio_part">
                                    <b-form-radio-group
                                            v-model="disputeData.selected"
                                            :options="radioOptions"
                                            class="radio_group"
                                            value-field="item"
                                            text-field="name"
                                    ></b-form-radio-group>
                                </div>
                            </div>
                            <div class="modal_buttons">
                                <b-button class="delete_btn ml-3" @click="adminDisputeTask()">Done</b-button>
                            </div>
                        </div>
                    </div>
                </b-modal>
<!--admin dispute modal end -->

            </div>
<!--summary_content-->
            <div class="summary_content">
                <div class="page_head">
                    <div class="page_title">
                        Task Summary
                    </div>
                </div>
                <div class="summary_cards">
                    <div class="card_item started">
                        <div class="item_status">
                            Not Started
                        </div>
                        <div class="item_point">
                            {{taskSummary.not_started_count}}
                        </div>

                    </div>
                    <div class="card_item in_progress">
                        <div class="item_status">
                            In Progress
                        </div>
                        <div class="item_point">
                            {{taskSummary.in_progress_count}}
                        </div>

                    </div>
                    <div class="card_item review">
                        <div class="item_status">
                            Under Review & Discussion
                        </div>
                        <div class="item_point">
                            {{taskSummary.under_review_discussion_count}}
                        </div>

                    </div>
                    <div class="card_item completed">
                        <div class="item_status">
                            Completed
                        </div>
                        <div class="item_point">
                            {{taskSummary.completed_count}}
                        </div>

                    </div>
                </div>
                <div class="summary_table">
                    <div class="table_title">
                        7 Days
                    </div>
                    <div class="table_container">
                        <b-table hover :items="tasksDate.days_7" :fields="day_fields" responsive="sm">
                            <template v-slot:cell(index)="data">
                                <div class="">
                                    {{data.index+1}}
                                </div>
                            </template>

                            <template v-slot:cell(work_spaces_name)="data">
                                <div class="item_name" v-html="highlight(data.item.work_spaces_name)">
                                    {{data.item.work_spaces_name}}
                                </div>
                            </template>

                            <template v-slot:cell(channel)="data">
                                <div class="" v-html="highlight(data.item.channel_name)">
                                    {{data.item.channel_name}}
                                </div>
                            </template>

                            <template v-slot:cell(title)="data">
                                <div class="" v-html="highlight(data.item.title)">
                                    {{data.item.title}}
                                </div>
                            </template>

                            <template v-slot:cell(requestor)="data">
                                <div class="" v-html="highlight(data.item.requestor_name.name)">
                                    {{data.item.requestor_name.name}}
                                </div>
                            </template>

                            <template v-slot:cell(assignee)="data">
                                <div class="" v-html="highlight(data.item.user_name.name)">
                                    {{data.item.user_name.name}}
                                </div>
                            </template>

                            <template v-slot:cell(req_date)="data">
                                <div class="">
                                    {{getDate(data.item.req_date)}}
                                </div>
                            </template>

                            <template v-slot:cell(due_date)="data">
                                <div class="">
                                    {{getDate(data.item.due_date)}}
                                </div>
                            </template>

                            <template v-slot:cell(priority)="data">
                                <div class=""
                                     :class="{
                                'red': data.item.priority === 2,
                                'orange': data.item.priority === 0
                                 }"
                                >
                                    {{getPriority(data.item.priority)}}
                                </div>
                            </template>

                            <template v-slot:cell(total)="data">
                                <div class="">
                                    {{data.item.days_count}}
                                </div>
                            </template>

                            <template v-slot:cell(view)="data">
                                <div class="d-flex justify-content-center">
                                    <div class="view_btn" @click="view(data.item)">
                                    </div>
                                </div>
                            </template>

                            <template v-slot:cell(status)="data">
                                <div class="status_dots" v-if="userInfo.id !== data.item.parent_id">
                                    <div class="yellow_dot" :class="{'active_dot': data.item.summary_status === 0}" @click="changeSummary(data.item.id, 0)"></div>
                                    <div class="orange_dot" :class="{'active_dot': data.item.summary_status === 1}" @click="changeSummary(data.item.id, 1)"></div>
                                    <div class="purple_dot" :class="{'active_dot': data.item.summary_status === 2}" @click="changeSummary(data.item.id, 2)"></div>
                                    <div class="green_dot" :class="{'active_dot': data.item.summary_status === 3}" @click="changeSummary(data.item.id, 3)"></div>
                                </div>
                                <div v-if="userInfo.id === data.item.parent_id" class="status_dots">
                                    <div class="dot_opy" :class="{
                                        'yellow_dot': data.item.summary_status === 0,
                                        'orange_dot': data.item.summary_status === 1,
                                        'purple_dot': data.item.summary_status === 2,
                                        'green_dot': data.item.summary_status === 3,
                                    }"></div>
                                </div>

                            </template>

                        </b-table>
                    </div>
                </div>

                <div class="summary_table">
                    <div class="table_title">
                        14 Days
                    </div>
                    <div class="table_container">
                        <b-table hover :items="tasksDate.days_14" :fields="day_fields" responsive="sm">
                            <template v-slot:cell(index)="data">
                                <div class="">
                                    {{data.index+1}}
                                </div>
                            </template>

                            <template v-slot:cell(work_spaces_name)="data">
                                <div class="item_name" v-html="highlight(data.item.work_spaces_name)">
                                    {{data.item.work_spaces_name}}
                                </div>
                            </template>

                            <template v-slot:cell(channel)="data">
                                <div class="" v-html="highlight(data.item.channel_name)">
                                    {{data.item.channel_name}}
                                </div>
                            </template>

                            <template v-slot:cell(title)="data">
                                <div class="" v-html="highlight(data.item.title)">
                                    {{data.item.title}}
                                </div>
                            </template>

                            <template v-slot:cell(requestor)="data">
                                <div class="" v-html="highlight(data.item.requestor_name.name)">
                                    {{data.item.requestor_name.name}}
                                </div>
                            </template>

                            <template v-slot:cell(assignee)="data">
                                <div class="" v-html="highlight(data.item.user_name.name)">
                                    {{data.item.user_name.name}}
                                </div>
                            </template>

                            <template v-slot:cell(req_date)="data">
                                <div class="">
                                    {{getDate(data.item.req_date)}}
                                </div>
                            </template>

                            <template v-slot:cell(due_date)="data">
                                <div class="">
                                    {{getDate(data.item.due_date)}}
                                </div>
                            </template>

                            <template v-slot:cell(priority)="data">
                                <div class=""
                                     :class="{
                                'red': data.item.priority === 2,
                                'orange': data.item.priority === 0
                                 }"
                                >
                                    {{getPriority(data.item.priority)}}
                                </div>
                            </template>

                            <template v-slot:cell(total)="data">
                                <div class="">
                                    {{data.item.days_count}}
                                </div>
                            </template>

                            <template v-slot:cell(view)="data">
                                <div class="d-flex justify-content-center">
                                    <div class="view_btn" @click="view(data.item)">
                                    </div>
                                </div>
                            </template>

                            <template v-slot:cell(status)="data">
                                <div class="status_dots" v-if="userInfo.id !== data.item.parent_id">
                                    <div class="yellow_dot" :class="{'active_dot': data.item.summary_status === 0}" @click="changeSummary(data.item.id, 0)"></div>
                                    <div class="orange_dot" :class="{'active_dot': data.item.summary_status === 1}" @click="changeSummary(data.item.id, 1)"></div>
                                    <div class="purple_dot" :class="{'active_dot': data.item.summary_status === 2}" @click="changeSummary(data.item.id, 2)"></div>
                                    <div class="green_dot" :class="{'active_dot': data.item.summary_status === 3}" @click="changeSummary(data.item.id, 3)"></div>
                                </div>
                                <div v-if="userInfo.id === data.item.parent_id" class="status_dots">
                                    <div class="dot_opy" :class="{
                                        'yellow_dot': data.item.summary_status === 0,
                                        'orange_dot': data.item.summary_status === 1,
                                        'purple_dot': data.item.summary_status === 2,
                                        'green_dot': data.item.summary_status === 3,
                                    }"></div>
                                </div>

                            </template>

                        </b-table>
                    </div>
                </div>

                <div class="summary_table">
                    <div class="table_title">
                        30 Days
                    </div>
                    <div class="table_container">
                        <b-table hover :items="tasksDate.days_30" :fields="day_fields" responsive="sm">
                            <template v-slot:cell(index)="data">
                                <div class="">
                                    {{data.index+1}}
                                </div>
                            </template>

                            <template v-slot:cell(work_spaces_name)="data">
                                <div class="item_name" v-html="highlight(data.item.work_spaces_name)">
                                    {{data.item.work_spaces_name}}
                                </div>
                            </template>

                            <template v-slot:cell(channel)="data">
                                <div class="" v-html="highlight(data.item.channel_name)">
                                    {{data.item.channel_name}}
                                </div>
                            </template>

                            <template v-slot:cell(title)="data">
                                <div class="" v-html="highlight(data.item.title)">
                                    {{data.item.title}}
                                </div>
                            </template>

                            <template v-slot:cell(requestor)="data">
                                <div class="" v-html="highlight(data.item.requestor_name.name)">
                                    {{data.item.requestor_name.name}}
                                </div>
                            </template>

                            <template v-slot:cell(assignee)="data">
                                <div class="" v-html="highlight(data.item.user_name.name)">
                                    {{data.item.user_name.name}}
                                </div>
                            </template>

                            <template v-slot:cell(req_date)="data">
                                <div class="">
                                    {{getDate(data.item.req_date)}}
                                </div>
                            </template>

                            <template v-slot:cell(due_date)="data">
                                <div class="">
                                    {{getDate(data.item.due_date)}}
                                </div>
                            </template>

                            <template v-slot:cell(priority)="data">
                                <div class=""
                                     :class="{
                                'red': data.item.priority === 2,
                                'orange': data.item.priority === 0
                                 }"
                                >
                                    {{getPriority(data.item.priority)}}
                                </div>
                            </template>

                            <template v-slot:cell(total)="data">
                                <div class="">
                                    {{data.item.days_count}}
                                </div>
                            </template>

                            <template v-slot:cell(view)="data">
                                <div class="d-flex justify-content-center">
                                    <div class="view_btn" @click="view(data.item)">
                                    </div>
                                </div>
                            </template>

                            <template v-slot:cell(status)="data">
                                <div class="status_dots" v-if="userInfo.id !== data.item.parent_id">
                                    <div class="yellow_dot" :class="{'active_dot': data.item.summary_status === 0}" @click="changeSummary(data.item.id, 0)"></div>
                                    <div class="orange_dot" :class="{'active_dot': data.item.summary_status === 1}" @click="changeSummary(data.item.id, 1)"></div>
                                    <div class="purple_dot" :class="{'active_dot': data.item.summary_status === 2}" @click="changeSummary(data.item.id, 2)"></div>
                                    <div class="green_dot" :class="{'active_dot': data.item.summary_status === 3}" @click="changeSummary(data.item.id, 3)"></div>
                                </div>
                                <div v-if="userInfo.id === data.item.parent_id" class="status_dots">
                                    <div class="dot_opy" :class="{
                                        'yellow_dot': data.item.summary_status === 0,
                                        'orange_dot': data.item.summary_status === 1,
                                        'purple_dot': data.item.summary_status === 2,
                                        'green_dot': data.item.summary_status === 3,
                                    }"></div>
                                </div>

                            </template>

                        </b-table>
                    </div>
                </div>
            </div>
<!--discussion_content-->
            <div class="discussion_content">
                <div class="page_head">
                    <div class="page_title">
                        Discussion Board
                    </div>
                    <b-button class="create_btn">Create New Discussion</b-button>
                </div>
                <div class="discussion_cards">
                    <div class="card_item started">
                        <div class="item_status">
                            Not Started
                        </div>
                        <div class="item_point">
                            10
                        </div>

                    </div>
                    <div class="card_item in_progress">
                        <div class="item_status">
                            In Progress
                        </div>
                        <div class="item_point">
                            10
                        </div>

                    </div>
                    <div class="card_item review">
                        <div class="item_status">
                            Under Review & Discussion
                        </div>
                        <div class="item_point">
                            10
                        </div>

                    </div>
                    <div class="card_item completed">
                        <div class="item_status">
                            Completed
                        </div>
                        <div class="item_point">
                            10
                        </div>
                    </div>
                </div>
                <div class="discussion_table">
                    <div class="table_title">
                        7 Days
                    </div>
                    <div class="table_container">
                        <b-table hover :items="discussion_day7_data" :fields="discussion_day7_fields" responsive="sm">
                            <template v-slot:cell(index)="data">
                                <div class="item no">
                                    {{data.index+1}}
                                    <div class="x_button" @click="removeTask(data.item)"></div>
                                </div>
                            </template>


                            <template v-slot:cell(work_space)="data">
                                <div class="item_name" v-html="highlight(data.item.work_space)">
                                    {{data.item.work_space}}
                                </div>
                            </template>

                            <template v-slot:cell(channel)="data">
                                <div class="" v-html="highlight(data.item.channel)">
                                    {{data.item.channel}}
                                </div>
                            </template>

                            <template v-slot:cell(title)="data">
                                <div class="" v-html="highlight(data.item.title)">
                                    {{data.item.title}}
                                </div>
                            </template>

                            <template v-slot:cell(requestor)="data">
                                <div class="" v-html="highlight(data.item.requestor)">
                                    {{data.item.requestor}}
                                </div>
                            </template>

                            <template v-slot:cell(assignee)="data">
                                <div class="" v-html="highlight(data.item.assignee)">
                                    {{data.item.assignee}}
                                </div>
                            </template>

                            <template v-slot:cell(req_date)="data">
                                <div class="">
                                    {{data.item.req_date}}
                                </div>
                            </template>

                            <template v-slot:cell(pcd_date)="data">
                                <div class="">
                                    {{data.item.pcd_date}}
                                </div>
                            </template>

                            <template v-slot:cell(priority)="data">
                                <div class=""
                                     :class="{
                                'red': data.item.priority === 'High',
                                'orange': data.item.priority === 'Low'
                                 }"
                                >
                                    {{data.item.priority}}
                                </div>
                            </template>

                            <template v-slot:cell(impact)="data">
                                <div class=""
                                     :class="{
                                'red': data.item.priority === 'High',
                                'orange': data.item.priority === 'Low'
                                 }"
                                >
                                    {{data.item.impact}}
                                </div>
                            </template>

                            <template v-slot:cell(attach)="data">
                                <div class="">
                                    {{data.item.attach}}
                                </div>
                            </template>

                            <template v-slot:cell(status)="data">
                                <div class="item_status_dot"
                                     :class="{
                        'yellow_dot': data.item.status ==='started',
                        'green_dot': data.item.status ==='completed',
                        'orange_dot': data.item.status ==='progress',
                        'purple_dot': data.item.status ==='review'
                    }">

                                </div>
                            </template>

                        </b-table>
                    </div>
                </div>
            </div>
<!--milestone_content-->
            <div class="milestone_content">
                <div class="page_head">
                    <div class="page_title">
                        Milestones
                    </div>
                    <div class="milestone_select-btn">
<!--                        <b-dropdown id="dropdown-1" text="WorkSpace" class="dropdown_content mx-4">-->
<!--                            <b-dropdown-item v-for="item in workspaces ">-->
<!--                                {{item.name}}-->
<!--                            </b-dropdown-item>-->
<!--                        </b-dropdown>-->
                        <b-form-select v-model="selected">
                            <b-form-select-option :value="null">Select WorkSpace</b-form-select-option>
                            <b-form-select-option :value="option.name" v-for="option in workspaces">{{option.name}}</b-form-select-option>
                        </b-form-select>

                        <b-button class="create_btn">Create New Milestones</b-button>
                    </div>

                </div>

                <div class="milestone_table">
                    <div class="table_head">
                        Project Name 1
                    </div>
                    <div class="table_container">
                        <b-table hover :items="discussion_day7_data" :fields="discussion_day7_fields" responsive="sm">
                            <template v-slot:cell(index)="data">
                                <div class="item no">
                                    {{data.index+1}}
                                    <div class="x_button" @click="removeTask(data.item)"></div>
                                </div>
                            </template>


                            <template v-slot:cell(work_space)="data">
                                <div class="item_name" v-html="highlight(data.item.work_space)">
                                    {{data.item.work_space}}
                                </div>
                            </template>

                            <template v-slot:cell(channel)="data">
                                <div class="" v-html="highlight(data.item.channel)">
                                    {{data.item.channel}}
                                </div>
                            </template>

                            <template v-slot:cell(title)="data">
                                <div class="" v-html="highlight(data.item.title)">
                                    {{data.item.title}}
                                </div>
                            </template>

                            <template v-slot:cell(requestor)="data">
                                <div class="" v-html="highlight(data.item.requestor)">
                                    {{data.item.requestor}}
                                </div>
                            </template>

                            <template v-slot:cell(assignee)="data">
                                <div class="" v-html="highlight(data.item.assignee)">
                                    {{data.item.assignee}}
                                </div>
                            </template>

                            <template v-slot:cell(req_date)="data">
                                <div class="">
                                    {{data.item.req_date}}
                                </div>
                            </template>

                            <template v-slot:cell(pcd_date)="data">
                                <div class="">
                                    {{data.item.pcd_date}}
                                </div>
                            </template>

                            <template v-slot:cell(priority)="data">
                                <div class=""
                                     :class="{
                                'red': data.item.priority === 'High',
                                'orange': data.item.priority === 'Low'
                                 }"
                                >
                                    {{data.item.priority}}
                                </div>
                            </template>

                            <template v-slot:cell(impact)="data">
                                <div class=""
                                     :class="{
                                'red': data.item.priority === 'High',
                                'orange': data.item.priority === 'Low'
                                 }"
                                >
                                    {{data.item.impact}}
                                </div>
                            </template>

                            <template v-slot:cell(attach)="data">
                                <div class="">
                                    {{data.item.attach}}
                                </div>
                            </template>

                            <template v-slot:cell(status)="data">
                                <div class="item_status_dot"
                                     :class="{
                        'yellow_dot': data.item.status ==='started',
                        'green_dot': data.item.status ==='completed',
                        'orange_dot': data.item.status ==='progress',
                        'purple_dot': data.item.status ==='review'
                    }">

                                </div>
                            </template>

                        </b-table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";

    export default {
        name: "MainDashboard",
        data() {
          return {
              selected: null,
              fields: [
                  {
                      key: 'index',
                      label: 'No',
                  },
                  {
                      key: 'work_spaces_name',
                      label: 'WorkSpace',
                  },
                  {
                      key: 'channel_name',
                      label: 'Channel',
                  },
                  {
                      key: 'title',
                      label: 'Title',
                  },
                  {
                      key: 'requestor_name',
                      label: 'Requestor',
                  },
                  {
                      key: 'user_name.name',
                      label: 'Assignee',
                  },
                  {
                      key: 'req_date',
                      label: 'Req Date',
                  },
                  {
                      key: 'due_date',
                      label: 'Due Date',
                  },
                  {
                      key: 'priority',
                      label: 'Priority',
                  },
                  {
                      key: 'status',
                      label: 'Status',
                  },
                  {
                      key: 'view',
                      label: 'Description',
                  },
                  {
                      key: 'edit',
                      label: 'Edit',
                  },
                  {
                      key: 'delete',
                      label: 'Delete',
                  },
                  {
                      key: 'action',
                      label: 'Action',
                  }
              ],
              day_fields: [
                  {
                      key: 'index',
                      label: 'No',
                  },
                  {
                      key: 'work_spaces_name',
                      label: 'WorkSpace',
                  },
                  {
                      key: 'channel',
                      label: 'Channel',
                  },
                  {
                      key: 'title',
                      label: 'Title',
                  },
                  {
                      key: 'requestor',
                      label: 'Requestor',
                  },
                  {
                      key: 'assignee',
                      label: 'Assignee',
                  },
                  // // {
                  // //     key: 'copied_list',
                  // //     label: 'Copied List'
                  // // },
                  // // {
                  // //     key: 'req_in',
                  // //     label: 'Req In'
                  // // },
                  {
                      key: 'req_date',
                      label: 'Req Date',
                  },
                  {
                      key: 'due_date',
                      label: 'Due Date',
                  },
                  {
                      key: 'priority',
                      label: 'Priority',
                  },
                  {
                      key: 'total',
                      label: 'Worked/Total (days)',
                  },
                  {
                      key: 'view',
                      label: 'Description',
                  },
                  {
                      key: 'status',
                      label: 'Status',
                  },
              ],
              discussion_day7_fields: [
                  {
                      key: 'index',
                      label: 'No',
                  },
                  {
                      key: 'work_space',
                      label: 'WorkSpace',
                  },
                  {
                      key: 'channel',
                      label: 'Channel',
                  },
                  {
                      key: 'title',
                      label: 'Title',
                  },
                  {
                      key: 'requestor',
                      label: 'Requestor',
                  },
                  {
                      key: 'assignee',
                      label: 'Assignee',
                  },
                  {
                      key: 'req_date',
                      label: 'Req Date',
                  },
                  {
                      key: 'pcd_date',
                      label: 'PCD',
                  },
                  {
                      key: 'priority',
                      label: 'Priority',
                  },
                  {
                      key: 'impact',
                      label: 'Impact',
                  },
                  {
                      key: 'attach',
                      label: 'Attach',
                  },
                  {
                      key: 'status',
                      label: 'Status',
                  },
              ],
              discussion_day7_data: [
                  { work_space: 'Sales', channel: 'Channel a', title: 'example', requestor: 'Name', assignee: 'Name', req_date: '23 June', pcd_date: '23 June', priority: 'High', impact: 'High', attach: 'View', status: 'completed', id: '1' },
                  { work_space: 'Sales', channel: 'Channel b', title: 'example', requestor: 'Name', assignee: 'Name', req_date: '23 June', pcd_date: '23 June', priority: 'Normal', impact: 'Normal', attach: 'Add/delete', status: 'review', id: '2' },
              ],
              modalData: null,
              copied_list: [
                  {
                      id: 1,
                      name: 'list 1'
                  },
                  {
                      id: 2,
                      name: 'list 2'
                  }
              ],
              assignee: [
                  {
                      id: 1,
                      name: 'assignee 1'
                  },
                  {
                      id: 2,
                      name: 'assignee 2'
                  }
              ],
              priority: [
                  {
                      id: 0,
                      name: 'Low'
                  },
                  {
                      id: 1,
                      name: 'Normal'
                  },
                  {
                      id: 2,
                      name: 'High'
                  },
              ],
              taskData: {
                  work_space: null,
                  channel: null,
                  task_title: null,
                  task_description: null,
                  selectedAdmins: [],
                  assignee: null,
                  req_date: null,
                  due_date: null,
                  priority: null,
                  user_id: null
              },
              editTaskData: {
                  task_id: null,
                  work_space: null,
                  work_space_id: null,
                  channel: null,
                  task_title: null,
                  task_description: null,
                  allAssignee: [],
                  assignee: null,
                  allAdmins: [],
                  selectedAdmins: null,
                  req_date: null,
                  due_date: null,
                  priority: null,
                  user_id: null
              },
              channels: [],
              editChannels: [],
              removeItem: null,
              createTaskErrors: {},
              channelMembers: [],
              filteredItems: [],
              channelId: null,
              reassignUsers: null,
              reassignData: {
                  task_id: null,
                  user_id: null
              },
              disputeData: {
                  user_id: null,
                  task_id: null,
                  description: null,
                  selected: null
              },
              radioOptions: [
                  { item: 'delete', name: 'Delete Task' },
                  { item: 'reassign', name: 'Reassign Task' },
                  { item: 'keep', name: 'Keep Task', },
              ]
          }
        },
        created() {
            this.getAllTasks()
            this.getTaskDate()
            this.getTaskSummary()
            this.getAllWorkspaces()
            this.getKeyWords()
        },

        computed: {
            ...mapGetters([
                'workspaces',
                'userData',
                'allAdmins',
                'allUsers',
                'allTasks',
                'tasksDate',
                'taskSummary',
                'allMembers',
                'search_data',
                'keywords'
            ]),

            userInfo() {
                return JSON.parse(localStorage.getItem('userData'))
            },

            filteredTasks() {
                let filteredItems = this.allTasks.filter((task) => {
                    return task.title.toLowerCase().includes(this.search_data.toLowerCase());
                })
                let orderedItems = filteredItems.sort((a, b) => {
                    return b.title - a.title;
                })
                return orderedItems;
            },
            // startedCount() {
            //   var count = 0
            //   this.allTasks.forEach(item => {
            //       if(item.status === 0) {
            //           count++
            //       }
            //   })
            //     return count
            // },

            channelSelected: {
                get () {
                    return this.editTaskData.channel
                },
                set (newValue) {
                    this.editTaskData.channel = newValue
                },
            },
            prioritySelected: {
                get() {
                    return this.editTaskData.priority
                },
                set(newVal) {
                    this.editTaskData.priority = newVal
                }
            },
            selectedUser: {
                get() {
                    return this.editTaskData.user_id
                },
                set(newVal) {
                    this.editTaskData.user_id = newVal
                }
            },
            editSelectedAdmins: {
                get() {
                    return this.editTaskData.selectedAdmins
                },
                set(newVal) {
                  this.editTaskData.selectedAdmins = newVal
                }
            }
        },

        watch: {
            selectedUser: {
                handler(newVal, oldVal){
                    if( oldVal == null) {
                    } else {
                        this.editTaskData.selectedAdmins = []
                        var admins
                        this.getChannelMembers(this.channelId)
                            .then((res) => {
                                admins = res.data.success.filter(user => {
                                    return user.id !== newVal
                                })
                                this.editTaskData.allAdmins = admins
                            })
                    }
                },
                deep: true
            }
        },
        mounted() {

        },
        methods: {
            ...mapActions([
                'taskCreate',
                'getAllTasks',
                'getAllWorkspaces',
                'deleteTask',
                'taskEdit',
                'getChannelMembers',
                'getTaskDate',
                'taskAccept',
                'getTaskSummary',
                'changeSummaryStatus',
                'getReassignMembers',
                'taskReassign',
                'openDispute',
                'getDisputeDescription',
                'disputeKeep',
                'getKeyWords'
            ]),
            // searchKeyword(item) {
            //     var values = this.keywords.join("|");
            //     if (values) {
            //         return   item.replace(new RegExp(values, 'gi'),  (match) => {
            //             return "<mark>" + match + "</mark>"
            //             // return "<mark style='color: red'>" + match + "</mark>";
            //         })
            //     }
            // },
            highlight(text) {
                var values = this.keywords.join("|");
                if(values) {
                    return text.replace(new RegExp(`.*${values}.*`, 'gi'), '<mark style="background: yellow" >$&</mark>')
                } else {
                    return  text
                }
            },



            view(item) {
                this.descriptionModal()
                this.modalData = item
            },
            descriptionModal() {
                this.$refs['description-modal'].toggle('#description-btn')
            },
            openTaskEdit(item) {
                this.openEditModal()
                this.channelId = item.channel_id
                this.editTaskData.work_space_id = item.work_space_id
                this.editTaskData.task_id = item.id
                this.editTaskData.channel = item.channel_id
                this.editTaskData.work_space = item.work_spaces_name
                console.log(this.workspaces, 'workspaces')
                var workSpace = this.workspaces.find((work_space) => {
                    return work_space.id === item.work_space_id
                })
                this.editChannels = workSpace.channels
                this.editTaskData.task_title = item.title
                this.editTaskData.task_description = item.description
                this.getChannelMembers(item.channel_id)
                    .then((res) => {
                        this.editTaskData.allAssignee = res.data.success
                    })
                this.editTaskData.assignee = item.user_name

                var admins = []
                this.getChannelMembers(item.channel_id)
                    .then((res) => {
                        admins = res.data.success.filter(user => {
                            return user.id !== item.user_name.id
                        })
                        this.editTaskData.allAdmins = admins
                    })
                if(item.copied.length) {
                    this.editTaskData.selectedAdmins = item.copied[0].admin_name
                }
                this.editTaskData.req_date = new Date(item.req_date * 1000 ).toLocaleString("en-CA", {timeZoneName: "short"}).split(',')[0]
                this.editTaskData.due_date = new Date(item.due_date * 1000 ).toLocaleString("en-CA", {timeZoneName: "short"}).split(',')[0]
                this.editTaskData.priority = item.priority
                this.editTaskData.user_id = item.user_name.id

            },
            editTask() {
                this.taskEdit(this.editTaskData)
                .then((res) => {
                    if(res.status === 200) {
                        this.openEditModal()
                        this.getAllTasks()
                    }
                })
            },
            openEditModal() {
                this.$refs['task_edit-modal'].toggle('#edit-btn')
            },

            taskModal() {
                this.taskData.work_space = null
                this.taskData.channel = null
                this.taskData.task_title = null
                this.taskData.task_description = null
                this.taskData.selectedAdmins = []
                this.taskData.assignee = null
                this.taskData.req_date = null
                this.taskData.due_date = null
                this.taskData.priority = null
                this.taskData.user_id = null
                this.createTaskErrors = {}
                this.$refs['task-modal'].toggle('#task-btn')
            },
            workspaceChange(args) {
                var workSpace = this.workspaces.find((item) => {
                    return item.id == args
                })
                this.channels = workSpace.channels
                this.taskData.work_space = args
            },
            channelChange(args) {
                this.taskData.channel = args
                this.getChannelMembers(args)
                .then((res) => {
                    this.channelMembers = res.data.success
                })

            },
            changeCopiedList(args) {
                console.log(args,  'copy list')
            },
            // copyListChange(args) {
            //     this.taskData.copied_list = args
            // },
            assigneeChange(args) {
                this.taskData.user_id = args
                this.filteredItems =  this.channelMembers.filter((item) => {
                    return item.id !== args
                })
            },
            priorityChange(args) {
                this.taskData.priority = args
            },
            getDate(sec) {
                return new Date(sec * 1000 ).toLocaleString("en-US", {timeZoneName: "short"}).split(',')[0]
            },
            createTask() {
                this.taskCreate(this.taskData)
                .then((res) => {
                    if(res.status === 200) {
                        this.getAllTasks()
                        this.taskModal()
                    } else {
                        this.createTaskErrors = res.response.data.errors
                    }
                })
            },
            taskDeleteModal(item) {
                if(item) {
                    this.removeItem = item
                }
                this.$refs['task_delete-modal'].toggle('#delete_task')
            },
            removeTask() {
                this.deleteTask(this.removeItem)
                .then((res) => {
                    if(res.status === 200 ) {
                        this.getAllTasks()
                        this.taskDeleteModal()
                        this.getTaskDate()
                    }
                })
            },
            getPriority(args) {
                if(args === 0) {
                    return 'Low'
                } else if (args === 1){
                    return 'Normal'
                } else {
                    return 'High'
                }
            },
            getStatus(args) {
                if(args === 0) {
                    return 'Awaiting Confirmation'
                } else if (args === 1){
                    return 'Open. Pending Action'
                } else if(args === 2){
                    return 'Awaiting Resolution'
                } else {
                    return 'Dispute. Pending resolution'
                }
            },
            getUserStatus(args) {
                if(args === 0) {
                    return 'Open. Pending Action'
                } else if (args === 1){
                    return 'Open. Pending Action'
                } else if(args === 2){
                    return 'Awaiting Resolution'
                } else {
                    return 'Dispute. Pending resolution'
                }
            },
            acceptTask(item) {
                let data = {
                    status: 1,
                    task_id: item.id
                }
                this.taskAccept(data)
                .then((res) => {
                    if(res.status === 200) {
                        this.getAllTasks()
                        this.getTaskDate()
                        this.getTaskSummary()
                    }
                })
            },
            reassignModalData(item) {
                if(item) {
                    this.reassignData.task_id = item.id
                    this.getReassignMembers(item.channel_id)
                        .then((res) => {
                            this.reassignUsers = res
                        })
                }
                this.openReassignModal()
            },
            openReassignModal() {
                this.$refs['reassign-modal'].toggle('#reassign_task')
            },
            reassignUserChange(args) {
              this.reassignData.user_id = args
            },
            reassignTask() {
                this.taskReassign(this.reassignData)
                .then((res) => {
                    if(res.status === 200) {
                        this.$refs['reassign-modal'].toggle('#reassign_task')
                        this.getAllTasks()
                    }
                })

            },
            changeSummary(taskId, status) {
                let sendData = {
                    task_id: taskId,
                    status: status
                }
                this.changeSummaryStatus(sendData)
                .then((res) => {
                    if(res.status === 200) {
                        this.getTaskDate()
                        this.getTaskSummary()
                    }
                })
            },
            disputeModal(item) {
                this.disputeData.description = null
                if(item) {
                    this.disputeData.user_id = item.user_id
                    this.disputeData.task_id = item.id
                }
                this.$refs['dispute-modal'].toggle('#dispute_btn')
            },
            disputeTask() {
                this.openDispute(this.disputeData)
                .then((res) => {
                    if(res.status === 200) {
                        this.disputeModal()
                        this.getAllTasks()
                    }
                })
            },
            openAdminDisputeModal() {
                this.$refs['admin-dispute-modal'].toggle('#admin_dispute_btn')
            },
            adminDisputeModal(item) {
                this.disputeData.selected = null
                this.getDisputeDescription(item.id)
                .then((res)=> {
                    if(res.status === 200) {
                        this.disputeData.description = res.data.success.description
                    }
                })
                this.reassignData.task_id = item.id
                this.getReassignMembers(item.channel_id)
                    .then((res) => {
                        this.reassignUsers = res
                    })
                this.disputeData.task_id = item.id
                this.openAdminDisputeModal()
            },
            adminDisputeTask() {
                if(this.disputeData.selected) {
                    if(this.disputeData.selected === 'delete') {
                        this.openAdminDisputeModal()
                        this.taskDeleteModal(this.disputeData.task_id)
                    } else if(this.disputeData.selected === 'keep') {
                        this.disputeKeep(this.disputeData)
                        .then((res) => {
                            if(res.status === 200) {
                                this.openAdminDisputeModal()
                                this.getAllTasks()
                            }
                        })
                    } else if(this.disputeData.selected === 'reassign') {
                        this.openAdminDisputeModal()
                        this.openReassignModal()
                    }
                }

            }
        }
    }
</script>

<style scoped lang="scss">
    input[type="date"]::-webkit-calendar-picker-indicator {
        background: url("~@/assets/images/calendar.svg") center/80% no-repeat;
        cursor: pointer;
    }
    input[type="date"]::-webkit-calendar-picker-indicator:hover {
        background: url("~@/assets/images/calendar.svg") center/80% no-repeat;
    }
    ::v-deep .table-responsive > .table {
        margin-bottom: 50px;
        padding-bottom: 30px;
    }

    ::v-deep .modal_content {
        position: relative;
        & .x_btn {
            position: absolute;
            top: 0;
            right: 0;
            width: 25px;
            height: 25px;
            cursor: pointer;
            color: $light_purple;
        }
        & .modal_title {
            width: 100%;
            padding: 20px 0;
            font-size: 25px;
            color: $grey;
            border-bottom: 1px solid #CACDF6;
        }
        & .modal_text {
            font-size: 16px;
            color: $grey;
            padding: 25px 0;
        }
        & .modal_buttons {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            padding: 15px 0 30px;
            & .cancel_btn {
                color: $primary;
                font-weight: 600;
                padding: 10px 0;
                cursor: pointer;

            }
            & .delete_btn {
                width: 110px;
                height: 44px;
                background: transparent linear-gradient(180deg, #84EEEB 0%, #3AE4DF 100%) 0% 0% no-repeat padding-box;
                border-radius: 10px;
                color: $primary;
                font-weight: 600;
                border: none;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
        & .description_content {
            padding: 20px 0;
            & .description_subtitle {
                font-weight: 500;
                font-size: 18px;
                padding: 0 15px;
            }
            & .description_text {
                padding: 15px 20px;
            }
        }
        &  .reassign_modal-content {
            padding: 20px 0;

            & select {
                background: $greyBlue 0 0 no-repeat padding-box;
                border-radius: 4px;
            }
            & .select_arrow {
                position: relative;
                & .arrow_down {
                    position: absolute;
                    top: 14px;
                    right: 15px;
                    width: 14px;
                    height: 10px;
                    pointer-events: none;
                    background: url("~@/assets/images/chevron_down.svg") right  no-repeat;
                }
            }
        }
        & .dispute_modal-content {
            padding: 10px 0;
            & .input_item {
                padding: 0 10px;
                & label {
                    color: $primary;
                    font-weight: 600;
                    font-size: 14px;
                }
                & textarea {
                    background: $greyBlue 0 0 no-repeat padding-box;
                    border-radius: 4px;
                    &:focus {
                        box-shadow: none;
                        border: 0.5px solid $light_purple;
                        border-radius: 3px;
                    }
                }
                & .description_p {
                    padding: 15px 10px;
                    border-radius: 4px;
                    background: $greyBlue 0 0 no-repeat padding-box;
                }
            }
            & .radio_part {
                display: flex;
                justify-content: center;
                padding: 10px;
                & .radio_group {
                    width: 100%;
                    display: flex;
                    justify-content: space-around;
                }
            }

        }
    }

    ::v-deep .custom-select {
        border: none;
    }
    .task {
        &_modal_content {
            position: relative;
            padding: 0 10px;
            & .x_btn {
                position: absolute;
                top: 0;
                right: 0;
                width: 25px;
                height: 25px;
                cursor: pointer;
                color: $light_purple;
            }
            & .input_content {
                & .input_item {
                    padding: 5px 0;
                    & .edit_workspace {
                        padding: 5px 0;
                        font-size: 22px;
                        font-weight: 600;
                        color: $primary;
                    }
                    & .select_arrow {
                        position: relative;
                        & .arrow_down {
                            position: absolute;
                            top: 14px;
                            right: 15px;
                            width: 14px;
                            height: 10px;
                            pointer-events: none;
                            background: url("~@/assets/images/chevron_down.svg") right  no-repeat;
                        }
                    }
                    & label {
                        color: $primary;
                        font-weight: 600;
                        font-size: 14px;
                    }
                    & input {
                        border: none;
                        background: $greyBlue 0 0 no-repeat padding-box;
                        border-radius: 3px;
                        &:focus {
                            box-shadow: none;
                            outline: none;
                        }
                    }
                    & select {
                        background: $greyBlue 0 0 no-repeat padding-box;
                        border-radius: 4px;
                    }
                    ::v-deep .multiselect {
                        width: 100%;
                        height: 44px;
                        background: $greyBlue 0 0 no-repeat padding-box;
                        border-radius: 4px;
                        cursor: pointer;
                    }
                    ::v-deep .multiselect__tags {
                        background: transparent;
                        padding-left: 0;
                    }
                    ::v-deep .multiselect__tag {
                        background: transparent;
                        color: $black;
                    }
                    ::v-deep .multiselect__tag-icon:after {
                        color: red;
                    }
                    ::v-deep .multiselect__tag-icon:hover {
                        background: transparent;
                    }
                    ::v-deep .multiselect__option--highlight,
                    ::v-deep .multiselect__option--highlight:after {
                        background: $lightBlue;
                        color: $black;
                    }
                    ::v-deep .multiselect__select:before {
                        border-width: 0;
                        border-style: none;
                    }
                    ::v-deep .multiselect__input {
                        background: $greyBlue;
                        margin: 0;
                        padding: 0;
                    }
                }
            }
            & .done_btn-container {
                display: flex;
                justify-content: flex-end;
                padding: 20px 0 10px;
                & .done_btn {
                    background: transparent linear-gradient(180deg, #84EEEB 0%, #3AE4DF 100%) 0% 0% no-repeat padding-box;
                    border-radius: 10px;
                    width: 110px;
                    height: 44px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: $primary;
                    font-weight: 600;
                    border: none;
                }
            }
        }
        &_title {
            width: 100%;
            font-size: 25px;
            font-weight: 500;
            color: $grey;
            padding-bottom: 10px;
            border-bottom: 1px solid #CACDF6;
        }
    }
.page {
    &_content {
        padding: 20px 40px 20px 1px;
        @media screen and (max-width: 1800px) {
            /*margin-left: 15px;*/
        }
        & .create_btn {
            color: $primary;
            height: 44px;
            font-weight: 600;
            background: transparent linear-gradient(180deg, #84EEEB 0%, #3AE4DF 100%) 0% 0% no-repeat padding-box;
            border-radius: 10px;
            padding: 0 20px;
            border: none;
            display: flex;
            justify-content: center;
            align-items: center;
            &:focus,
            &:active{
                box-shadow: none;
                color: $primary;
            }
        }
        & .main_content,
        & .summary_content,
        & .discussion_content,
        & .milestone_content {
            padding-top: 20px;
            & .milestone_select-btn {
                width: 100%;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                /*& .custom-select {*/
                /*    width: 20% !important;*/
                /*    margin-right: 30px;*/
                /*}*/
            }
            & .table_container {
                font-size: 13px;
                & .red {
                    color: #FF0000;
                }
                & .orange {
                    color: #FF961D;
                }
                & .green {
                    color: #0FAC49;
                }
                ::v-deep thead {
                    height: 62px;
                    color: $primary;
                    background: #E7E8F5 0 0 no-repeat padding-box;
                    th {
                        vertical-align: middle;
                        max-width: 100px;
                        text-align: center;
                    }
                }
                ::v-deep tbody {
                    tr {
                        td{
                            vertical-align: middle;
                            text-align: center;
                            text-align: -webkit-center;
                        }
                    }
                }
                & .view_btn {
                    width: 22px;
                    height: 22px;
                    cursor: pointer;
                    background: url("~@/assets/images/eye_icon.svg") center no-repeat;
                }
                & .edit_btn {
                    width: 22px;
                    height: 22px;
                    cursor: pointer;
                    background: url("~@/assets/images/edit_icon.svg") center no-repeat;
                }
                & .trash_button {
                    width: 22px;
                    height: 22px;
                    cursor: pointer;
                    background: url("~@/assets/images/trash_icon.svg") center no-repeat;
                }
                & .x_button {
                    width: 22px;
                    height: 22px;
                    cursor: pointer;
                    background: url("~@/assets/images/x_icon.svg") center no-repeat;
                }
                & .item {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    height: 100%;
                    & .dropdown_content {
                        ::v-deep button {
                            width: 130px;
                            height: 32px;
                            background: $light_purple;
                            color: $white;
                            font-size: 12px;
                            border: none;
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                        }
                        ::v-deep .dropdown-menu {
                            min-width: 8rem;
                            border: none;
                            margin: 0;
                            padding: 0;
                        }
                        ::v-deep ul {
                            max-width: 130px;
                            width: 100%;
                            background: $greyBlue;
                            li {
                                border-top: 1px solid $light_purple;
                                font-size: 12px;
                                width: 100%;

                                a {
                                    white-space: normal;
                                    padding: 3px 8px;
                                }
                            }
                        }
                    }
                }
                & .reply_btn {
                    background: $light_purple;
                    border: none;
                    font-size: 14px;
                    border-radius: 5px;
                    &:focus {
                        box-shadow: none;
                    }
                    &:hover {
                        opacity: .9;
                    }
                }
                & .status_item {
                    max-width: 110px;
                    width: 100%;
                    text-align: start;
                }
                & .status_dots {
                    display: flex;
                    justify-content: space-evenly;
                    & .dot_opy {
                        opacity: 1;
                    }
                }
                & .yellow_dot {
                    width: 20px;
                    height: 20px;
                    border-radius: 50%;
                    background: #F2EB1A;
                    opacity: 0.5;
                    cursor: pointer;
                }
                & .green_dot {
                    width: 20px;
                    height: 20px;
                    border-radius: 50%;
                    background: #1CD65A;
                    opacity: 0.5;
                    cursor: pointer;
                }
                & .orange_dot {
                    width: 20px;
                    height: 20px;
                    border-radius: 50%;
                    background: #FFB26E;
                    opacity: 0.5;
                    cursor: pointer;
                }
                & .purple_dot {
                    width: 20px;
                    height: 20px;
                    border-radius: 50%;
                    background: $light_purple;
                    opacity: 0.5;
                    cursor: pointer;
                }
                & .active_dot {
                    opacity: 1;
                    box-shadow: 0 0 0 3px rgb(255, 255, 255) inset;
                    border: 2px solid transparent;
                }
            }
        }
        & .summary_content,
        & .discussion_content,
        & .milestone_content {
            padding-top: 20px;
            & .summary_cards,
            & .discussion_cards {
                display: flex;
                justify-content: space-between;
                padding: 30px 0 0;
                @media screen and (max-width: 1800px) {
                    flex-wrap: wrap;
                }
                & .card_item {
                    width: 300px;
                    height: 190px;
                    border-radius: 15px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    margin-bottom: 25px;
                    &.started {
                        background: $yellow;
                    }
                    &.in_progress {
                        background: $lemon;
                    }
                    &.review {
                       background: $light_purple;

                    }
                    &.completed {
                        background: $green;
                    }
                    & .item_status {
                        color: #000;
                        font-weight: 600;
                        font-size: 25px;
                        text-align: center;
                    }
                    & .item_point {
                        color: #000;
                        font-weight: 600;
                        font-size: 32px;
                        text-align: center;
                    }
                }
            }
            & .summary_table,
            & .discussion_table,
            & .milestone_table {
                padding: 20px 0;
                & .table_title {
                    padding-bottom: 40px;
                    color: $primary;
                    font-size: 16px;
                    font-weight: 600;
                }
                & .table_head {
                    width: 100%;
                    height: 60px;
                    background: #84EEEB;
                    color: #4A4A4D;
                    font-weight: 600;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
        }
        & .milestone_content {
            ::v-deep .custom-select {
                width: 200px !important;
            }
        }
    }
    &_head {
        padding-bottom: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    &_title {
        color: $black;
        font-weight: 600;
        font-size: 25px;
    }

    ::v-deep .modal-content {
        border: none !important;
    }
}
    ::v-deep .table {
        border-bottom: 1px solid #dee2e6;
    }
</style>