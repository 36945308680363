<template>
    <div class="page_content">
        <div class="page_container">
            <div class="users_container">
                <h3>Direct Message</h3>
                <label>Workspace</label>
                <div class="select_arrow">
                    <b-form-select @change="workspaceChange">
                        <option
                                v-for="(option, id) in menuWorkspaces"
                                :key="id"
                                :value="option.id"
                                :title="option.name || null"
                        >
                            {{option.name}}
                        </option>
                    </b-form-select>
                    <div class="arrow_down"></div>
                </div>

                <ul class="nav flex-column">
                    <li v-for="user in users"
                        class="nav-link"
                        :key="user.id"
                        @click="openChat(user)"
                        :class="{'active_user': chatUserID === user.id}">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 20 18">
                            <g id="Group_64" data-name="Group 64" transform="translate(-1752.227 -37.5)">
                                <path id="Path_906" data-name="Path 906" d="M1785,50a6,6,0,1,0-6-6A6,6,0,0,0,1785,50Z"
                                      transform="translate(-23)" fill="none" stroke="#1717F2" stroke-width="1"/>
                                <path id="Path_907" data-name="Path 907" d="M1775.91,55.249a10.494,10.494,0,0,1,18.18,0"
                                      transform="translate(-23)" fill="none" stroke="#1717F2" stroke-linecap="round"
                                      stroke-linejoin="round" stroke-width="1"/>
                            </g>
                        </svg>
                        <a href="#">{{ user.name }}</a>
                    </li>
                </ul>
            </div>
            <div class="message_content">
                <div class="active_user">{{activeUser.name}}
                    <div class="call_content" v-if="activeUser.name">
                        <div class="video_call" @click="videoCall(activeUser.id)"></div>
                        <div class="mic_call" @click="changeMic()">
                            <b-icon v-if="micStatus" icon="mic-fill" style="color: #7882F3;" aria-hidden="true"></b-icon>
                            <b-icon v-else icon="mic-mute-fill" style="color: #7882F3;" aria-hidden="true"></b-icon>
                        </div>
                    </div>
                </div>
                <div class="messenger_container" v-show="chatOpen && !loadingMessages">
                    <div class="messages_content" ref="messageBox">
                        <div class="message_col" v-for="message in messages"
                             :class="{'user_text': message.sender_id !== chatUserID}">
                            <div class="message_item-name">
                                <span class="name">{{ message.sender.name }}</span>
                                <span class="time">{{ getTime(message.created_at) }}</span>
                            </div>
                            <div class="message_item">
                                <span
                                        class="message_dot"
                                        v-if="message.colors"
                                        :style="{background: getColor(message.colors.color_id)}"
                                ></span>
                                <div v-if="message.message_quotes" class="quoted_msg">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                                         class="bi bi-quote" viewBox="0 0 16 16">
                                        <path d="M12 12a1 1 0 0 0 1-1V8.558a1 1 0 0 0-1-1h-1.388c0-.351.021-.703.062-1.054.062-.372.166-.703.31-.992.145-.29.331-.517.559-.683.227-.186.516-.279.868-.279V3c-.579 0-1.085.124-1.52.372a3.322 3.322 0 0 0-1.085.992 4.92 4.92 0 0 0-.62 1.458A7.712 7.712 0 0 0 9 7.558V11a1 1 0 0 0 1 1h2Zm-6 0a1 1 0 0 0 1-1V8.558a1 1 0 0 0-1-1H4.612c0-.351.021-.703.062-1.054.062-.372.166-.703.31-.992.145-.29.331-.517.559-.683.227-.186.516-.279.868-.279V3c-.579 0-1.085.124-1.52.372a3.322 3.322 0 0 0-1.085.992 4.92 4.92 0 0 0-.62 1.458A7.712 7.712 0 0 0 3 7.558V11a1 1 0 0 0 1 1h2Z"/>
                                    </svg>
                                    {{message.message_quotes.messages.message}}
                                </div>
                                <span class="message_text">{{ message.message }}</span>
                            </div>
                            <div class="right_side">
                                <div class="item" @click="forward(message)" v-b-popover.hover.bottom="'Quote'">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="21.654" height="21.645"
                                         viewBox="0 0 21.654 21.645">
                                        <g id="Group_7444" data-name="Group 7444"
                                           transform="translate(-2828.87 908.035)">
                                            <path id="Path_7055" data-name="Path 7055"
                                                  d="M2836.087-898.16c0,.987-.006,1.859,0,2.731,0,.372-.03.713-.435.872-.385.151-.637-.063-.884-.319q-2.781-2.894-5.568-5.783c-.447-.464-.444-.761.007-1.229q2.784-2.891,5.568-5.783c.247-.257.5-.465.885-.309s.43.475.427.835c-.008.885,0,1.77,0,2.659.7.086,1.373.126,2.028.255a8.786,8.786,0,0,1,6.993,8.418c.007.465,0,.93,0,1.394a.689.689,0,0,1-.544.79c-.4.09-.627-.138-.8-.464a7.226,7.226,0,0,0-5.4-3.911A22.744,22.744,0,0,0,2836.087-898.16Z"
                                                  transform="translate(0 0)" fill="#0505a4"/>
                                            <path id="Path_7056" data-name="Path 7056"
                                                  d="M2839.772-847.291q-3.973,0-7.945,0a2.712,2.712,0,0,1-2.877-2.889q0-3.381,0-6.762c0-.113-.006-.226,0-.338a.9.9,0,0,1,.9-.833.878.878,0,0,1,.882.845c.019.323.008.648.008.972q0,3.022,0,6.043c0,.833.322,1.161,1.142,1.162q7.882,0,15.764,0c.8,0,1.139-.332,1.139-1.123q0-6.107,0-12.214c0-.768-.339-1.095-1.116-1.1s-1.578,0-2.367,0a.924.924,0,0,1-1.034-.921.92.92,0,0,1,1.031-.875c.845-.005,1.691-.009,2.536,0a2.681,2.681,0,0,1,2.747,2.756q.008,6.255,0,12.51a2.7,2.7,0,0,1-2.79,2.762Q2843.787-847.285,2839.772-847.291Z"
                                                  transform="translate(-0.072 -39.099)" fill="#0505a4"/>
                                        </g>
                                    </svg>
                                </div>
                                <div class="item" @click="sendTo(message)" id="message_btn"
                                     v-b-popover.hover.bottom="'Forward'">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24.17" height="21.002"
                                         viewBox="0 0 24.17 21.002">
                                        <path id="Path_7057" data-name="Path 7057"
                                              d="M3641.9-654.612c0-1.118-.024-2.237.011-3.353a.762.762,0,0,0-.588-.885c-1.874-.687-3.727-1.431-5.591-2.143-.363-.138-.67-.308-.675-.743s.3-.616.663-.754q11.168-4.259,22.333-8.525a.822.822,0,0,1,.966.081.813.813,0,0,1,.125.919q-2.819,9.221-5.631,18.444c-.248.812-.689.958-1.364.458-1.947-1.443-3.9-2.878-5.831-4.343-.375-.285-.5-.2-.683.177-.745,1.526-1.523,3.035-2.279,4.555-.191.385-.433.68-.906.572s-.554-.493-.552-.917C3641.9-652.25,3641.9-653.431,3641.9-654.612Zm15-12.939c-.161-.013-.207.082-.268.146q-4.839,5.1-9.683,10.206c-.253.265-.141.362.08.525q2.469,1.82,4.929,3.653c.419.311.42.311.574-.2q1.687-5.521,3.373-11.043C3656.241-665.352,3656.569-666.447,3656.9-667.55Zm-3.639-.006-.044-.089-15.474,5.906c1.521.587,2.939,1.121,4.345,1.689a.736.736,0,0,0,.808-.114q4.044-2.9,8.1-5.781Zm-.632,2.215c-.081.047-.131.071-.176.1q-4.438,3.16-8.878,6.318a.555.555,0,0,0-.26.509c.009,1.353,0,2.706.008,4.059,0,.093-.059.227.125.275.473-.937.949-1.874,1.419-2.813a2.493,2.493,0,0,1,.426-.617q3.527-3.712,7.046-7.432A.781.781,0,0,0,3652.632-665.34Z"
                                              transform="translate(-3635.058 671.132)" fill="#0505a4"/>
                                    </svg>
                                </div>
                                <div class="item" @click="choseCategory(message)" id="color_change"
                                     v-b-popover.hover.bottom="'Color'">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="32.634" height="19.758"
                                         viewBox="0 0 32.634 19.758">
                                        <path id="Path_7058" data-name="Path 7058"
                                              d="M3398.5-994.929c0-.87,0-1.67,0-2.47a1.664,1.664,0,0,1,1.884-1.875c1.676,0,3.354,0,5.03,0h.773c0-1.324-.017-2.589.01-3.854a2.635,2.635,0,0,1,.227-1.1,1.516,1.516,0,0,1,1.482-.8c2.038.009,4.076,0,6.114,0a1.612,1.612,0,0,1,1.769,1.786c.009,2.886,0,5.771,0,8.658v.777h.718c1.721,0,3.439-.005,5.159,0a1.616,1.616,0,0,1,1.789,1.765q.012,2.387,0,4.774a1.846,1.846,0,0,1-1.988,1.987q-14.328.006-28.655,0a1.841,1.841,0,0,1-1.979-2q-.012-2.928,0-5.857a1.63,1.63,0,0,1,1.821-1.8c1.7-.009,3.4,0,5.095,0Zm15.328,7.714v-15.846h-5.683v15.846Zm-7.681.006v-10.116h-5.686v10.116Zm-13.364-5.772v5.759h5.678v-5.759Zm23.041,5.751h5.688v-4.623h-5.687Z"
                                              transform="translate(-3390.825 1005.028)" fill="#0505a4"/>
                                    </svg>
                                </div>
                                <div class="item" @click="copyMessage(message)" v-b-popover.hover.bottom="`${text}`">
                                    <svg @click=" " xmlns="http://www.w3.org/2000/svg" width="26.806" height="18.849"
                                         viewBox="0 0 26.806 18.849">
                                        <g id="Group_7445" data-name="Group 7445"
                                           transform="translate(-3829.981 1042.015)">
                                            <path id="Path_7059" data-name="Path 7059"
                                                  d="M3843.4-1042.014q5.391,0,10.782,0a2.375,2.375,0,0,1,2.607,2.592q0,6.831,0,13.661a2.376,2.376,0,0,1-2.605,2.594q-10.808,0-21.617,0a2.371,2.371,0,0,1-2.582-2.566q0-6.856,0-13.713a2.369,2.369,0,0,1,2.58-2.567Q3837.979-1042.017,3843.4-1042.014Zm-10.651,1.587c.13.144.194.221.265.292q4.8,4.818,9.6,9.635a.952.952,0,0,0,1.571-.027q4.785-4.8,9.565-9.6c.077-.078.215-.131.178-.3Zm.012,15.536.083.127H3854c-.077-.1-.112-.151-.156-.2q-3.118-3.132-6.233-6.267c-.22-.222-.34-.187-.536.017-.616.641-1.248,1.267-1.88,1.892a2.466,2.466,0,0,1-3.632-.01q-.948-.937-1.882-1.89c-.167-.17-.278-.255-.5-.036Q3835.981-1028.063,3832.757-1024.891Zm-1.179-1a1.91,1.91,0,0,0,.182-.131c2.094-2.1,4.182-4.2,6.286-6.289.252-.25.178-.37-.029-.576q-3.074-3.064-6.136-6.142c-.078-.078-.133-.206-.3-.194Zm23.614-13.393a2.056,2.056,0,0,0-.185.136c-2.094,2.1-4.179,4.2-6.286,6.286-.3.3-.116.423.073.612q3.031,3.034,6.059,6.07c.09.091.161.221.339.245Z"
                                                  transform="translate(0)" fill="#0505a4"/>
                                        </g>
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row message_send-content">
                        <div class="quote_msg" v-if="quoteMsg">
                            <div class="x_btn" @click="clearQuote()"></div>
                            <span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                     class="bi bi-quote" viewBox="0 0 16 16">
                                    <path d="M12 12a1 1 0 0 0 1-1V8.558a1 1 0 0 0-1-1h-1.388c0-.351.021-.703.062-1.054.062-.372.166-.703.31-.992.145-.29.331-.517.559-.683.227-.186.516-.279.868-.279V3c-.579 0-1.085.124-1.52.372a3.322 3.322 0 0 0-1.085.992 4.92 4.92 0 0 0-.62 1.458A7.712 7.712 0 0 0 9 7.558V11a1 1 0 0 0 1 1h2Zm-6 0a1 1 0 0 0 1-1V8.558a1 1 0 0 0-1-1H4.612c0-.351.021-.703.062-1.054.062-.372.166-.703.31-.992.145-.29.331-.517.559-.683.227-.186.516-.279.868-.279V3c-.579 0-1.085.124-1.52.372a3.322 3.322 0 0 0-1.085.992 4.92 4.92 0 0 0-.62 1.458A7.712 7.712 0 0 0 3 7.558V11a1 1 0 0 0 1 1h2Z"/>
                                </svg>
                                <span class="ml-3">{{quoteName}}</span>
                            </span>
                            {{quoteMsg}}
                        </div>
                        <div class="col-12">
                            <div class="input-group mb-3">
                                <textarea
                                        class="form-control"
                                        placeholder="New message"
                                        aria-label="New message"
                                        aria-describedby="button-addon2"
                                        v-model="newMessage"
                                        @keydown.enter="sendMessage"
                                />
                                <div class="input-group-append">
                                    <b-button class="send_btn "
                                              type="button"
                                              id="button-addon2"
                                              @click="sendMessage">
                                        Send message
                                    </b-button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-show="loadingMessages">
                    <p>Loading messages... Please wait</p>
                </div>
                <div v-show="!chatOpen && !loadingMessages">
                    <p>No chat room is open. Please click on user to start a conversation</p>
                </div>
            </div>
        </div>

<!--Change color modal start -->
        <b-modal ref="change_color_modal" centered hide-header hide-footer hide-header-close>
            <div class="modal_content">
                <b-icon icon="x" class="x_btn" aria-hidden="true" @click="changeModal()"></b-icon>
                <div class="d-flex flex-column">
                    <div class="modal_title">
                        Categories
                    </div>
                    <div class="modal_colors">
                        <div class="color_item" v-for="(item, index) in categories" :key="index">
                            <div class="name">{{item.name}} <span class="edit_name" v-b-toggle="`collapse-${index}`"
                                                                  @click="color_text = item.name"></span>
                                <b-collapse :id="`collapse-${index}`" class="collapse_input">
                                    <div class="color_text-content">
                                        <b-form-input v-model="color_text" placeholder="Enter your name"></b-form-input>
                                        <b-button class="text_btn ml-3" v-b-toggle="`collapse-${index}`"
                                                  @click="saveColorName(item)">Save
                                        </b-button>
                                    </div>
                                </b-collapse>
                            </div>
                            <div class="color_dot" :style="{background: item.color}" @click="chooseColor(item)"></div>

                        </div>
                        <div class="clear_btn-container">
                            <b-button class="clear_btn" @click="clearColor()">Clear Color</b-button>
                        </div>
                    </div>
                </div>
            </div>
        </b-modal>
<!--Change color modal end -->

<!--MessageTo modal start-->
        <b-modal ref="message-modal" centered hide-header hide-footer hide-header-close>
            <div class="modal_content">
                <b-icon icon="x" class="x_btn" aria-hidden="true" @click="messageToModal()"></b-icon>
                <div class="d-flex flex-column">
                    <div class="modal_title">
                        Share Message
                    </div>
                    <div class="message_to_items">
                        <div class="search_input">
                            <b-form-input
                                    v-model="searchUser"
                                    type="text"
                                    placeholder="Search for person"
                                    trim
                            ></b-form-input>
                            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="23" viewBox="0 0 19.942 20.414">
                                <g id="search_24_" data-name="search (24)" transform="translate(0.968 1)">
                                    <circle id="Ellipse_10" data-name="Ellipse 10" cx="8" cy="8" r="8"
                                            transform="translate(0.032)" fill="none" stroke="#1717F2"
                                            stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                    <line id="Line_11" data-name="Line 11" x1="4.35" y1="4.35"
                                          transform="translate(13.21 13.65)" fill="none" stroke="#1717F2"
                                          stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                </g>
                            </svg>

                        </div>
                        <div>
                            <ul class="nav flex-column">
                                <li
                                        v-for="user in filteredUsers"
                                        class="nav-link"
                                        :key="user.id"
                                        @click="sendToMessage(user)"
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 20 18">
                                        <g id="Group_64" data-name="Group 64" transform="translate(-1752.227 -37.5)">
                                            <path id="Path_906" data-name="Path 906"
                                                  d="M1785,50a6,6,0,1,0-6-6A6,6,0,0,0,1785,50Z"
                                                  transform="translate(-23)" fill="none" stroke="#1717F2"
                                                  stroke-width="1"/>
                                            <path id="Path_907" data-name="Path 907"
                                                  d="M1775.91,55.249a10.494,10.494,0,0,1,18.18,0"
                                                  transform="translate(-23)" fill="none" stroke="#1717F2"
                                                  stroke-linecap="round"
                                                  stroke-linejoin="round" stroke-width="1"/>
                                        </g>
                                    </svg>
                                    <a href="#">{{ user.name }}</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </b-modal>
<!--MessageTo modal end-->

        <div class="videochat_container" :class="{'video': isVideo}">
            <VideoChat
                :userId="userId"
                :others="users"
                :pusher-key="pusherAppKey"
                :pusher-cluster="pusherAppCluster"
                :callerId="caller_id"
                :micStatus="micStatus"
                @videoStatus="videoStatus"
                ref="vChat"
            />
        </div>
    </div>
</template>

<script>
    import axios from 'axios'
    import {mapActions, mapGetters} from "vuex";
    import VideoChat from "./VideoChat";

    export default {
        name: "Message",
        data() {
            return {
                text: 'Copy',
                users: [],
                messages: [],
                chatOpen: false,
                chatUserID: null,
                loadingMessages: false,
                newMessage: '',
                userId: '',
                activeUser: '',
                activeMessage: null,
                categories: [],
                color_text: null,
                quoteMsg: null,
                quoteName: null,
                quoteId: null,
                searchUser: '',
                pusherAppKey: null,
                pusherAppCluster: null,
                caller_id: null,
                micStatus: true,
                isVideo: false,
            }
        },
        components: {
            VideoChat
        },
        created() {
            this.getAllMenuWorkspaces()
            this.getCategoryColors()
                .then(res => {
                    if (res.status === 200) {
                        this.categories = res.data.success
                    }
                })
            this.userId = JSON.parse(localStorage.getItem('userData')).id
            // this.loadUsers()
               this.pusherAppKey = process.env.VUE_APP_MIX_PUSHER_APP_KEY
               this.pusherAppCluster = process.env.VUE_APP_MIX_PUSHER_APP_CLUSTER
        },
        watch: {
            messages: function () {
                let element = this.$refs.messageBox;
                element.scrollTop = element.scrollHeight
            }
        },
        computed: {
            ...mapGetters(['baseURL', 'userData', 'menuWorkspaces']),
            filteredUsers() {
                let filteredItems = this.users.filter((user) => {
                    return user.name.toLowerCase().includes(this.searchUser.toLowerCase());
                })
                let orderedItems = filteredItems.sort((a, b) => {
                    return b.name - a.name;
                })
                return orderedItems;
            },
        },
        mounted() {
            this.userId = JSON.parse(localStorage.getItem('userData')).id
        },
        methods: {
            ...mapActions([
                'getAllMenuWorkspaces',
                'getWorkspaceIdUsers',
                'getCategoryColors',
                'createColor',
                'updateColor',
                'changeColorName',
                'resetColor'
            ]),
            videoStatus(status) {
                this.isVideo = status
            },
            getColor(color_id) {
                let color_item = this.categories.find((item) => {
                    return item.id == color_id
                })
                return color_item.color
            },
            workspaceChange(args) {
                this.getWorkspaceIdUsers(args)
                    .then(res => {
                        if (res.status === 200) {
                            this.users = res.data.success
                        }
                    })
            },
            openChat(user) {
                this.activeUser = user
                let userID = user.id
                let app = this;
                if (app.chatUserID !== userID) {
                    app.chatOpen = true;
                    app.chatUserID = userID;
                    // Start pusher listener
                    Pusher.logToConsole = true;
                    const pusher = new Pusher(this.pusherAppKey, {
                        cluster: 'ap2',
                        forceTLS: true
                    });
                    const channel = pusher.subscribe('my-channel-' + app.chatUserID + '-' + app.userId); // newMessage-[chatting-with-who]-[my-id]
                    // var channel = pusher.subscribe('my-channel');
                    channel.bind('my-event', data => {
                        if (app.chatUserID) {
                            app.messages.push(data.message);
                        }
                    });
                    // End pusher listener
                    app.loadMessages()
                }
            },
            // loadUsers () {
            //     let app = this;
            //     let base = this.baseURL
            //     axios.get(`${base}users`)
            //         .then((resp) => {
            //             app.users = resp.data
            //         })
            // },
            loadMessages() {
                let app = this;
                app.loadingMessages = true;
                app.messages = [];
                let base = this.baseURL
                axios.post(`${base}messages`, {
                    sender_id: app.chatUserID
                }).then((resp) => {
                    app.messages = resp.data;
                    app.loadingMessages = false
                    setTimeout(() => {
                        let element = app.$refs.messageBox;
                        element.scrollTop = element.scrollHeight
                    }, 100)
                })
            },
            sendMessage() {
                let app = this;
                let base = this.baseURL
                let sendUserId = app.userId
                if (!sendUserId) {
                    sendUserId = JSON.parse(JSON.stringify(this.userData)).id
                }
                if (app.newMessage !== '') {
                    axios.post(`${base}messages/send`, {
                            sender_id: sendUserId,
                            receiver_id: app.chatUserID,
                            message: app.newMessage,
                            quote_id: app.quoteId
                        },
                        {
                            headers: {
                                "accept": "application/json, text/plain, */*",
                                "accept-language": "en-US,en;q=0.9",
                                "content-type": "application/json",
                            }
                        }).then((resp) => {
                        resp.data.colors = null
                        if (this.quoteId) {
                            resp.data.message_quotes = {id: null, messages: {message: null}}
                            resp.data.message_quotes.id = this.quoteId
                            resp.data.message_quotes.messages.message = this.quoteMsg
                        } else {
                            resp.data.message_quotes = null
                        }
                        app.messages.push(resp.data);
                        app.newMessage = ''
                        this.quoteMsg = null
                        this.quoteName = null
                        this.quoteId = null
                        this.activeMessage = null
                    })
                }
            },
            sendToMessage(user) {
                let app = this;
                let base = this.baseURL
                let sendUserId = app.userId
                if (!sendUserId) {
                    sendUserId = JSON.parse(JSON.stringify(this.userData)).id
                }
                axios.post(`${base}messages/send`, {
                        sender_id: sendUserId,
                        receiver_id: user.id,
                        message: app.activeMessage.message,
                        quote_id: null
                    },
                    {
                        headers: {
                            "accept": "application/json, text/plain, */*",
                            "accept-language": "en-US,en;q=0.9",
                            "content-type": "application/json",
                        }
                    }).then((resp) => {
                    if (resp.data.receiver_id === this.activeMessage.receiver_id) {
                        resp.data.message_quotes = null
                        app.messages.push(resp.data);
                        app.messageToModal()
                    } else {
                        app.messageToModal()
                    }
                    this.quoteId = null
                    this.activeMessage = null
                })
            },
            getTime(item) {
                const event = new Date(item);
                return event.toLocaleTimeString('en-US')
            },
            getQuoteMsg(msgId) {
                let mes = this.messages.find((item) => {
                    return item.id == msgId
                })
                return mes.message
            },
            forward(item) {
                this.activeMessage = item
                this.quoteMsg = item.message
                this.quoteName = item.sender.name
                this.quoteId = item.id
            },
            sendTo(item) {
                this.activeMessage = item
                this.messageToModal()
            },
            messageToModal() {
                this.$refs['message-modal'].toggle('#message_btn')
            },
            choseCategory(item) {
                this.activeMessage = item
                this.changeModal()
            },
            changeModal() {
                this.$refs['change_color_modal'].toggle('#color_change')
            },
            chooseColor(args) {
                if (this.activeMessage.colors) {
                    let sendData = {
                        old_color_id: this.activeMessage.colors.id,
                        color_id: args.id
                    }
                    this.updateColor(sendData)
                        .then(res => {
                            if (res.status === 200) {
                                let message = this.messages.find((item) => {
                                    return item.id == this.activeMessage.id
                                })
                                message.colors = res.data.success
                                this.changeModal()
                            }
                        })
                } else {
                    let sendData = {
                        message_id: this.activeMessage.id,
                        color_id: args.id
                    }
                    this.createColor(sendData)
                        .then(res => {
                            if (res.status === 200) {
                                let message = this.messages.find((item) => {
                                    return item.id == this.activeMessage.id
                                })
                                message.colors = res.data.success
                                this.changeModal()
                            }
                        })
                }
            },
            clearColor() {
                this.resetColor(this.activeMessage.colors.id)
                    .then(res => {
                        if (res.status === 200) {
                            let message = this.messages.find((item) => {
                                return item.id == this.activeMessage.id
                            })
                            message.colors = null
                            this.changeModal()
                        }
                    })
            },
            copyMessage(item) {
                this.text = 'Copied'
                setTimeout(() => {
                    this.text = 'Copy'
                }, 1000)
                var textarea = document.createElement("textarea");
                textarea.textContent = item.message;
                textarea.style.position = "fixed";
                document.body.appendChild(textarea);
                textarea.select();
                try {
                    return document.execCommand("copy");
                } catch (ex) {
                    console.warn("Copy to clipboard failed.", ex);
                    return false;
                } finally {
                    document.body.removeChild(textarea);
                }

            },
            saveColorName(item) {
                let colorData = {
                    id: item.id,
                    name: this.color_text
                }
                this.changeColorName(colorData)
                    .then(res => {
                        if (res.status === 200) {
                            this.getCategoryColors()
                                .then(res => {
                                    if (res.status === 200) {
                                        this.categories = res.data.success
                                    }
                                })
                        }
                    })
            },
            clearQuote() {
                this.activeMessage = null
                this.quoteMsg = null
                this.quoteName = null
            },
            videoCall(id) {
                this.isVideo = true
                this.caller_id = id
            },
            changeMic() {
                if(this.micStatus === true) {
                    this.micStatus = false
                    this.$refs.vChat.muteMic(this.micStatus)
                } else {
                    this.micStatus = true
                    this.$refs.vChat.muteMic(this.micStatus)
                }
            }

        }
    }
</script>

<style scoped lang="scss">
    .page {
        &_content {
            display: flex;
            max-width: 1760px;
            width: 100%;
            position: relative;
            & .videochat_container {
                display: none;
                &.video {
                    display: block;
                }
            }
        }

        &_container {
            display: flex;
            width: 100%;

            & .users_container {
                position: sticky;
                top: 88px;
                max-width: 270px;
                width: 100%;
                height: calc(100vh - 88px);
                background: $lightBlue;
                padding: 10px 20px;

                & h3 {
                    margin-bottom: 20px;
                }

                & .nav {
                    & .active_user {
                        font-weight: bold;
                        background: #cacdf3;
                    }

                    & .nav-link {
                        cursor: pointer;

                        &:hover {
                            background: #cacdf3;
                            box-shadow: 2px 2px 10px 2px #00000030;
                        }

                        & svg {
                            margin-right: 10px;
                        }
                    }
                }

                & .select_arrow {
                    position: relative;
                    width: 240px;
                    margin-right: 20px;
                    margin-bottom: 15px;

                    & .arrow_down {
                        position: absolute;
                        top: 14px;
                        right: 15px;
                        width: 14px;
                        height: 10px;
                        pointer-events: none;
                        background: url("~@/assets/images/chevron_down.svg") right no-repeat;
                    }

                }

                & select {
                    background: $greyBlue 0 0 no-repeat padding-box;
                    border-radius: 4px;
                    padding-right: 5px;
                }

                & label {
                    color: $primary;
                    font-weight: 600;
                    font-size: 14px;
                }
            }

            & .message_content {
                display: flex;
                flex-direction: column;
                width: 100%;
                max-height: 88vh;
                height: 94%;
                padding: 10px 40px 15px;

                & .active_user {
                    font-size: 18px;
                    color: $black;
                    font-weight: 600;
                    padding: 10px 0;
                    border-bottom: 1px solid $lightBlue;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    & .call_content {
                        max-width: 120px;
                        width: 100%;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        padding: 0 10px;

                        & .video_call {
                            width: 39px;
                            height: 39px;
                            background: url("~@/assets/images/camera_icon.svg");
                            background-position: center;
                            background-repeat: no-repeat;
                            background-size: cover;
                            cursor: pointer;

                            &:hover {
                                box-shadow: 2px 2px 10px 2px #00000030;
                            }
                        }

                        & .mic_call {
                            width: 39px;
                            height: 39px;
                            background: $greyBlue;
                            border-radius: 3px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            cursor: pointer;
                            &:hover {
                                box-shadow: 2px 2px 10px 2px #00000030;
                            }
                        }
                    }
                }

                & .messenger_container {
                    width: 100%;
                    padding: 10px 0;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;

                    & .messages_content {
                        max-height: 72vh;
                        overflow-y: auto;
                        padding-bottom: 70px;

                        & .message_col {
                            margin: 0 10px;
                            position: relative;

                            & .right_side {
                                position: absolute;
                                top: 3px;
                                right: 0;
                                width: 140px;
                                height: 30px;
                                background: $greyBlue;
                                padding-top: 3px;
                                border-radius: 5px;
                                display: none;
                                justify-content: space-evenly;

                                & .item {
                                    cursor: pointer;
                                    height: 30px;

                                    &:hover {
                                        svg {
                                            stroke: $primary;
                                        }
                                    }
                                }
                            }

                            &:hover {
                                .right_side {
                                    display: flex;
                                }

                            }
                        }

                        /*padding-bottom: 50px;*/
                        & .user_text {
                            & .message_item-name {
                                & .name {
                                    color: $darkBlue !important;
                                }

                                /*flex-direction: row-reverse;*/
                            }

                            & .message_item {
                                margin-left: 20px;

                                & .message_text {
                                    background: rgba(0, 255, 248, 0.3);
                                    padding: 5px 15px;
                                    border-radius: 10px;
                                    display: inline-block;
                                }

                                &-name {
                                    padding-bottom: 10px;
                                    display: flex;

                                    & .name {
                                        color: $purple;
                                        font-weight: 600;
                                    }

                                    & .time {
                                        margin: 0 15px;
                                        font-size: 15px;
                                    }
                                }
                            }
                        }

                        &::-webkit-scrollbar {
                            width: 5px;
                            height: 4px;
                        }

                        &::-webkit-scrollbar-track {
                            border-radius: 5px;
                        }

                        &::-webkit-scrollbar-thumb {
                            background: grey;
                            border-radius: 5px;
                        }

                        & .message_item {
                            margin: 0 0 10px 20px;
                            position: relative;

                            & .quoted_msg {
                                background: #cccc;
                                margin-left: 25px;
                                margin-bottom: 5px;
                                border-radius: 8px;
                                padding: 15px;
                            }

                            & .message_dot {
                                position: absolute;
                                top: 10px;
                                left: -25px;
                                width: 16px;
                                height: 16px;
                                border-radius: 50%;
                            }

                            & .message_text {
                                background: $lightBlue;
                                padding: 5px 15px;
                                border-radius: 10px;
                                display: inline-block;
                            }

                            &-name {
                                padding-bottom: 10px;
                                display: flex;

                                & .name {
                                    color: $purple;
                                    font-weight: 600;
                                }

                                & .time {
                                    margin: 0 15px;
                                    font-size: 15px;
                                }
                            }
                        }
                    }

                    & .message_send-content {

                        & .quote_msg {
                            z-index: 20;
                            width: 82%;
                            margin-left: 25px;
                            color: #fff;
                            background: #5454EE;
                            margin-bottom: 0;
                            padding: 10px 20px;
                            border-radius: 8px;
                            display: flex;
                            justify-content: center;
                            flex-direction: column;
                            position: relative;

                            & .x_btn {
                                position: absolute;
                                top: 5px;
                                right: 5px;
                                width: 25px;
                                height: 25px;
                                background: url("~@/assets/images/x_icon.svg");
                                border-radius: 50%;
                                background-position: center;
                                background-repeat: no-repeat;
                                background-size: cover;
                                cursor: pointer;
                            }
                        }

                        & textarea {
                            overflow: auto;
                            border: 1px solid #9E9E9E;
                            border-radius: 10px;
                            resize: none;

                            &::-webkit-scrollbar {
                                display: none;
                            }

                            &:focus {
                                box-shadow: none;
                            }
                        }

                        & .send_btn {
                            background: $purple;
                            border: none;
                            border-radius: 10px;
                            margin-left: 20px;
                        }
                    }

                }
            }
        }
    }

    ::v-deep .modal-content {
        border: none !important;
        width: 500px;

        ::v-deep .modal-body {
            width: 400px !important;
        }
    }

    ::v-deep .modal_content {
        border: none !important;
        width: 470px;
        position: relative;

        ::v-deep .modal-body {
            width: 500px !important;
        }

        & .x_btn {
            position: absolute;
            top: 0;
            right: 0;
            width: 25px;
            height: 25px;
            cursor: pointer;
            color: $light_purple;
        }

        & .modal_title {
            width: 100%;
            padding: 20px 5px;
            font-size: 25px;
            color: $grey;
            border-bottom: 1px solid #CACDF6;
            margin: 0 5px;
        }

        & .modal_colors {
            display: flex;
            flex-direction: column;
            padding: 25px 0;

            & .color_item {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 3px 25px;
                margin: 5px 0;

                &:hover {
                    background: $lightBlue;
                }

                & .name {
                    color: $black;
                    font-size: 18px;
                    font-weight: 500;
                    text-transform: capitalize;
                    max-width: 345px;
                    width: 100%;
                    position: relative;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    & .collapse_input {
                        position: absolute;
                        top: -2px;

                        & .color_text-content {
                            display: flex;
                            max-width: 345px;
                            width: 100%;

                            & input {
                                height: 32px;
                                border: none;
                                background: $lightBlue;
                            }

                            & .text_btn {
                                background: transparent linear-gradient(180deg, #84EEEB 0%, #3AE4DF 100%) 0% 0% no-repeat padding-box;
                                border-radius: 10px;
                                width: 80px;
                                height: 32px;
                                font-size: 14px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                color: $primary;
                                font-weight: 600;
                                border: none;
                            }
                        }
                    }

                    & .edit_name {
                        display: none;
                        position: absolute;
                        content: '';
                        top: -4px;
                        right: 5px;
                        width: 46px;
                        height: 36px;
                        background: url("~@/assets/images/edit_icon.svg");
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: cover;

                    }

                    &:hover {
                        & .edit_name {
                            display: block;
                        }

                    }

                }

                & .color_dot {
                    width: 24px;
                    height: 24px;
                    border-radius: 50%;
                    cursor: pointer;

                    &.blue {
                        background: #002BFF;
                    }

                    &.red {
                        background: #FF0000;

                    }

                    &.yellow {
                        background: #FFDD00;

                    }

                    &.green {
                        background: #08AF51;

                    }

                    &.black {
                        background: #000000;

                    }
                }
            }

            & .clear_btn {
                background: transparent linear-gradient(180deg, #84EEEB 0%, #3AE4DF 100%) 0% 0% no-repeat padding-box;
                border-radius: 10px;
                width: 115px;
                height: 32px;
                font-size: 14px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: $primary;
                font-weight: 600;
                border: none;

                &-container {
                    display: flex;
                    justify-content: flex-end;
                    padding: 15px 25px 5px;
                }

            }
        }

        & .message_to_items {
            padding: 15px 10px;

            & .search_input {
                padding: 5px 0 20px;
                position: relative;

                & input {
                    border: none;
                    background: $greyBlue 0 0 no-repeat padding-box;
                    border-radius: 3px;

                    &:focus {
                        box-shadow: none;
                        outline: none;
                    }

                    &::placeholder {
                        color: #B4B7D5;
                        opacity: 1;
                    }
                }

                svg {
                    position: absolute;
                    top: 13px;
                    right: 20px;
                }
            }

            & .nav {
                width: 100%;
                flex-wrap: inherit;
                max-height: 350px;
                overflow-y: auto;

                & .nav-link {
                    background: $lightBlue;
                    margin: 0 5px 10px 0;
                    cursor: pointer;

                    & svg {
                        margin-right: 15px;
                    }
                }

                &::-webkit-scrollbar {
                    width: 5px;
                    height: 4px;
                }

                &::-webkit-scrollbar-track {
                    border-radius: 5px;
                    background: $greyBlue;
                }

                &::-webkit-scrollbar-thumb {
                    background: $purple;
                    border-radius: 5px;
                }
            }
        }

        & .description_content {
            padding: 20px 0;

            & .description_subtitle {
                font-weight: 500;
                font-size: 18px;
                padding: 0 15px;
            }

            & .description_text {
                padding: 15px 20px;
            }
        }
    }
</style>















