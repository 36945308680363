import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
// import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex)

export default new Vuex.Store({
    // plugins: [createPersistedState({
    //     storage: window.sessionStorage,
    // })],
    state: {
        baseURL: process.env.VUE_APP_BASE_URL,
        pusherAppKey: process.env.VUE_APP_MIX_PUSHER_APP_KEY,
        pusherAppCluster: process.env.VUE_APP_MIX_PUSHER_APP_CLUSTER,
        access_token: localStorage.getItem('access_token') || '',
        userData: localStorage.getItem('userData') || '',
        search_data: '',
        workspaces: [],
        menuWorkspaces: [],
        milestoneWorkspaces: [],
        // allAdmins: [],
        allUsers: [],
        allTasks: [],
        tasksDate: [],
        allMembers: [],
        taskSummary: [],
        keywords: [],
        notifyStatus: false
        // refresh_token: localStorage.getItem('refresh_token') || '',
    },
    mutations: {
        clearUserData(state) {
            state.access_token = "";
            state.userData = ''
            localStorage.removeItem('userData')
            // state.refresh_token = "";
            // state.logInUser = {}
            localStorage.removeItem('access_token')
            sessionStorage.clear();
            // localStorage.removeItem('refresh_token')
            // localStorage.removeItem('logInUser')
        },
        USER_DATA(state, data) {
            state.userData = data
            localStorage.setItem('userData', JSON.stringify(data))
        },
        SEARCH_DATA(state, data) {
            state.search_data = data
        },
        GET_WORKSPACES(state, data) {
            data.forEach(element => element.plusStatus = true)
            state.workspaces = data
        },
        GET_MENU_WORKSPACES(state, data) {
            data.forEach(element => element.plusStatus = true)
            state.menuWorkspaces = data
        },
        GET_MILESTONE_WORKSPACES(state, data) {
            data.forEach(element => element.plusStatus = true)
            state.milestoneWorkspaces = data
        },
        // GET_ADMINS(state, data) {
        //     state.allAdmins = data
        // },
        GET_USERS(state, data) {
            state.allUsers = data
        },
        GET_TASKS(state, data) {
            state.allTasks = data
        },
        ADD_CHANNEL(state, data) {
            let work = state.workspaces.find(item => {
                return item.id === data.work_space_id
            })
            // console.log(state.workspaces, 'state workspace')
            // console.log(work, 'find workspace')
        },
        GET_ALL_MEMBERS(state, data) {
            state.allMembers = data
        },
        GET_TASK_DATE(state, data) {
            state.tasksDate = data
        },
        GET_TASK_SUMMARY(state, data) {
            state.taskSummary = data
        },
        GET_KEYWORDS(state, data) {
            state.keywords = data
        },
        NOTIFY_STATUS(state) {
            state.notifyStatus = true
        }
    },
    actions: {
        registerUser({state, commit}, data) {
            return axios.post(`${state.baseURL}register`, {
                name: data.name,
                email: data.email,
                password: data.password,
                c_password: data.c_password,
                agree: data.agree,
                admin: data.admin
            })
                .then(response => {
                    if (response) {
                        commit('USER_DATA', response.data.success.user)
                        let access_token = response.data.success.token
                        // let refresh_token = response.success.token
                        localStorage.setItem('access_token', access_token)
                        // localStorage.setItem('refresh_token', refresh_token)
                        axios.defaults.headers.Authorization = `Bearer ${access_token}`;
                        state.access_token = access_token
                        // state.refresh_token = refresh_token
                    }

                    console.log(response)
                })
                .catch(error => {
                    console.log(error)
                })
        },
        loginUser({state, commit}, data) {
            return axios.post(`${state.baseURL}login`, {
                email: data.email,
                password: data.password,
            })
                .then((response) => {
                    if (response) {
                        let access_token = response.data.success.token
                        // let refresh_token = response.data.access_token
                        localStorage.setItem('access_token', access_token)
                        // localStorage.setItem('refresh_token', refresh_token)
                        axios.defaults.headers.Authorization = `Bearer ${access_token}`;
                        state.access_token = access_token
                        commit('USER_DATA', response.data.success.user)
                        // state.refresh_token = refresh_token
                        return response
                    }
                })
                .catch((error) => {
                    console.log(error)
                    return error

                })
        },
        sendForgot({state}, data) {
            return axios.post(`${state.baseURL}password/email`, {
                email: data
            })
                .then((response) => {
                    console.log(response)
                    return response
                })
                .catch((error) => {
                    console.log(error)
                    return error
                })
        },
        resetPassword({state}, data) {
            return axios.post(`${state.baseURL}password/reset`, {
                password: data.newPassword,
                password_confirmation: data.confirmPassword,
                token: data.token,
                email: data.email
            })
                .then((response) => {
                    return response
                })
                .catch((error) => {
                    return error
                })
        },
        getUserData({state}) {
            return axios.post(`${state.baseURL}get-user`, {})
                .then((response) => {
                    return response
                })
                .catch((error) => {
                    return error
                })
        },
        getAllWorkspaces({state, commit}) {
            return axios.get(`${state.baseURL}work-space/get`, {})
                .then((response) => {
                    commit('GET_WORKSPACES', response.data.success)
                })
                .catch((error) => {
                    console.log(error)
                })

        },
        getAllMenuWorkspaces({state, commit}) {
            return axios.get(`${state.baseURL}work-space/menu/get`)
                .then(response => {
                    commit('GET_MENU_WORKSPACES', response.data.success)
                })
                .catch(error => {
                    console.log(error)
                })
        },
        getMilestoneWorkspaces({state, commit}) {
            return axios.get(`${state.baseURL}work-space/milestones/get`)
                .then(response => {
                    commit('GET_MILESTONE_WORKSPACES', response.data.success)
                })
                .catch(error => {
                    console.log(error)
                })
        },
        // getAllAdmins({state, commit}) {
        //   return axios.post(`${state.baseURL}get-admin`, {
        //   })
        //       .then((response) => {
        //           commit('GET_ADMINS', response.data.success)
        //       })
        //       .catch((error) => {
        //           console.log(error)
        //       })
        // },
        // getAllUsers({state, commit}) {
        //     return axios.post(`${state.baseURL}get-users`, {})
        //         .then((response) => {
        //             commit('GET_USERS', response.data.success)
        //         })
        //         .catch((error) => {
        //             console.log(error)
        //         })
        // },
        workspaceCreate({state}, data) {
            return axios.post(`${state.baseURL}work-space/create`, {
                name: data
            })
                .then((response) => {
                    return response
                })
                .catch((error) => {
                    return error
                })
        },
        channelCreate({state, commit}, data) {
            return axios.post(`${state.baseURL}channel/create`, {
                work_space_id: data.work_space_id,
                name: data.channelName
            })
                .then((response) => {
                    commit('ADD_CHANNEL', response.data.success)
                    return response
                })
                .catch((error) => {
                    return error
                })
        },
        memberCreate({state}, data) {
            return axios.post(`${state.baseURL}member/create`, {
                work_space_id: data.workspace,
                channel_id: data.channel,
                email: data.memberEmail
            })
                .then((response) => {
                    return response
                })
                .catch((error) => {
                    return error
                })
        },
        taskCreate({state}, data) {
            let copied_list = []
            data.selectedAdmins.forEach(item => {
                copied_list.push(item.id)
            })

            return axios.post(`${state.baseURL}task/create`, {
                work_space_id: data.work_space,
                channel_id: data.channel,
                title: data.task_title,
                description: data.task_description,
                copied_id: copied_list,
                req_date: data.req_date,
                due_date: data.due_date,
                priority: data.priority,
                user_id: data.user_id
            })
                .then((response) => {
                    return response
                })
                .catch((error) => {
                    return error
                })
        },
        getAllTasks({state, commit}) {
            return axios.get(`${state.baseURL}task/get`, {})
                .then((response) => {
                    commit('GET_TASKS', response.data.success)
                })
                .catch((error) => {
                    console.log(error)
                })
        },
        taskEdit({state}, data) {

            let copied_list = []
            data.selectedAdmins.forEach(item => {
                copied_list.push(item.id)
            })
            return axios.post(`${state.baseURL}task/edit`, {
                task_id: data.task_id,
                work_space_id: data.work_space_id,
                user_id: data.user_id,
                channel_id: data.channel,
                title: data.task_title,
                description: data.task_description,
                copied_id: copied_list,
                req_date: data.req_date,
                due_date: data.due_date,
                priority: data.priority,
            })
                .then((response) => {
                    return response
                })
                .catch((error) => {
                    return error
                })
        },
        deleteTask({state}, data) {
            return axios.get(`${state.baseURL}task/delete/${data}`, {})
                .then((response) => {
                    return response
                })
                .catch((error) => {
                    return error
                })
        },
        getChannelMembers({state}, data) {
            return axios.get(`${state.baseURL}get-channel-users/${data}`)
                .then((response) => {
                    return response
                })
                .catch((error) => {
                    return error
                })
        },
        getMembers({state, commit}) {
            return axios.get(`${state.baseURL}member/get`)
                .then((response) => {
                    commit('GET_ALL_MEMBERS', response.data.success)
                })
                .catch((error) => {
                    console.log(error)
                })
        },
        removeMember({state}, data) {
            return axios.post(`${state.baseURL}member/delete`, {
                id: data.id
            })
                .then((response) => {
                    return response
                })
                .catch((error) => {
                    return error
                })
        },

        getTaskDate({state, commit}) {
            return axios.get(`${state.baseURL}task-date/get`)
                .then((response) => {
                    commit('GET_TASK_DATE', response.data.success)
                    return response
                })
                .catch((error) => {
                    return error
                })
        },
        taskAccept({state}, data) {
            return axios.post(`${state.baseURL}task/status`, {
                task_id: data.task_id,
                status: data.status
            })
                .then((response) => {
                    return response
                })
                .catch((error) => {
                    return error
                })
        },
        getTaskSummary({state, commit}) {
            return axios.get(`${state.baseURL}task-summary`)
                .then((response) => {
                    commit('GET_TASK_SUMMARY', response.data.success)
                })
                .catch((error) => {
                    return error
                })
        },
        changeSummaryStatus({state}, data) {
            return axios.post(`${state.baseURL}task-summary/status`, {
                task_id: data.task_id,
                status: data.status
            })
                .then((response) => {
                    return response
                })
                .catch((error) => {
                    return error
                })
        },
        notifyGet({state}) {
            return axios.get(`${state.baseURL}notify-get`)
                .then((response) => {
                    return response
                })
                .catch((error) => {
                    return error
                })
        },
        changeNotifyStatus({state}, data) {
            return axios.post(`${state.baseURL}notify-status`, {
                id: data
            })
                .then((response) => {
                    return response
                })
                .catch((error) => {
                    return error
                })

        },
        getReassignMembers({state}, data) {
            return axios.get(`${state.baseURL}member/channel/${data}`)
                .then((response) => {
                    return response.data.success
                })
                .catch((error) => {
                    return error
                })
        },
        taskReassign({state}, data) {
            return axios.post(`${state.baseURL}reassign`, {
                task_id: data.task_id,
                user_id: data.user_id
            })
                .then((response) => {
                    return response
                })
                .catch((error) => {
                    return error
                })
        },
        openDispute({state}, data) {
            return axios.post(`${state.baseURL}dispute`, {
                task_id: data.task_id,
                description: data.description
            })
                .then((response) => {
                    return response
                })
                .catch((error) => {
                    return error
                })
        },
        disputeKeep({state}, data) {
            return axios.post(`${state.baseURL}dispute/waiting`, {
                task_id: data.task_id
            })
                .then((response) => {
                    return response
                })
                .catch((error) => {
                    return error
                })
        },
        getDisputeDescription({state}, data) {
            return axios.get(`${state.baseURL}dispute/${data}`)
                .then((response) => {
                    return response
                })
                .catch((error) => {
                    return error
                })
        },
        getWorkspaceUsers({state}, data) {
            return axios.get(`${state.baseURL}get-work-space-users/${data}`)
                .then((response) => {
                    return response
                })
                .catch((error) => {
                    return error
                })
        },
        getWorkspaceIdUsers({state}, data) {
            return axios.get(`${state.baseURL}users/${data}`)
                .then((response) => {
                    return response
                })
                .catch((error) => {
                    return error
                })
        },
        getMilestoneShow({state}, data) {
            return axios.get(`${state.baseURL}milestone/${data}`)
                .then(response => {
                    return response
                })
                .catch(error => {
                    return error
                })
        },
        getMilestones({state}) {
            return axios.get(`${state.baseURL}milestone`)
                .then(response => {
                    return response
                })
                .catch(error => {
                    return error
                })
        },
        createNewMilestone({state}, data) {
            return axios.post(`${state.baseURL}milestone`, {
                work_space_id: data.work_space,
                channel_id: data.channel,
                title: data.title,
                milestone_date: data.milestone_date,
                milestone_order_id: data.milestone_order_id,
                previous_or_next: data.previous_or_next,
                users_id: data.users_id
            })
                .then(response => {
                    return response
                })
                .catch(error => {
                    return error
                })
        },
        deleteMilestone({state}, data) {
            return axios.delete(`${state.baseURL}milestone/${data}`)
                .then(response => {
                    return response
                })
                .catch(error => {
                    return error
                })
        },
        milestoneEdit({state}, data) {
            console.log(data)
            return axios.put(`${state.baseURL}milestone/${data.milestone_id}`, {
                title: data.title,
                milestone_date: data.milestone_date,
                milestone_order_id: data.milestone_order_id,
                previous_or_next: data.previous_or_next,
                users_id: data.send_copied_lists
            })
                .then(response => {
                    return response
                })
                .catch(error => {
                    return error
                })
        },
        getMilestoneHistory({state}, data) {
            return axios.get(`${state.baseURL}milestone-history/${data}`)
                .then(response => {
                    return response
                })
                .catch(error => {
                    return error
                })
        },
        getClosedMilestones({state}, data) {
            return axios.get(`${state.baseURL}milestone-closed/${data}`)
                .then(response => {
                    return response
                })
                .catch(error => {
                    return error
                })
        },
        createDiscussion({state}, data) {
            return axios.post(`${state.baseURL}discussion`, data)
                .then(response => {
                    return response
                })
                .catch(error => {
                    return error
                })
        },
        getAllDiscussions({state}) {
            return axios.get(`${state.baseURL}discussion-date/get`)
                .then(response => {
                    return response
                })
                .catch(error => {
                    return error
                })
        },
        editDiscussion({state}, data) {
            let formData = new FormData()
            formData.append('title', data.subject_title)
            formData.append('discussion_agenda', data.discussion_agenda)
            formData.append('date_end', data.target_completion_date)
            formData.append('_method', 'put')
            data.selectedList.forEach((selectedItem, i) => {
                let id = selectedItem.id
                formData.append('copied_id[' + i + ']', id);
            })
            data.milestone_impact.forEach((milestoneItem, i) => {
                let id = milestoneItem.id
                formData.append('milestones_id[' + i + ']', id);
            })

            data.newAttachedFiles.forEach((file, i) => {
                formData.append('attachments[' + i + ']', file)
            })

            data.file_delete_id.forEach((fileDelete, i) => {
                formData.append('file_delete_id[' + i + ']', fileDelete)
            })
            return axios.post(`${state.baseURL}discussion/${data.id}`, formData)
                .then(response => {
                    return response
                })
                .catch(error => {
                    return error
                })
        },
        deleteDiscussion({state}, data) {
            return axios.delete(`${state.baseURL}discussion/${data}`, {})
                .then(response => {
                    return response
                })
                .catch(error => {
                    return error
                })
        },
        changeDiscussionStatus({state}, data) {
            return axios.put(`${state.baseURL}discussion-status/${data.discussion_id}`, {
                status: data.status
            })
                .then(response => {
                    return response
                })
                .catch(error => {
                    return error
                })
        },
        getDiscussionSummary({state}) {
            return axios.get(`${state.baseURL}discussion-summary`)
                .then(response => {
                    return response
                })
                .catch(error => {
                    return error
                })
        },
        createMeetingNote({state}, data) {
            return axios.post(`${state.baseURL}meeting`, {
                work_space_id: data.work_space,
                channel_id: data.channel,
                subject_title: data.subject_title,
                project_title: data.project_title,
                project_number: data.project_number,
                meeting_note_document_number: data.meeting_note_document_number,
                venue: data.venue,
                date_of_meeting: data.date_of_meeting,
                start_time: data.start_time,
                end_time: data.end_time,
                copied_id: data.copied_id,
                users: data.users,
                approvals: data.approvals,
                minutes_of_meetings: data.minutes_of_meetings
            })
                .then(response => {
                    return response
                })
                .catch(error => {
                    return error
                })
        },
        getMeetingNote({state}, data) {
            return axios.get(`${state.baseURL}get-meetings/${data.work_space_id}/${data.channel_id}`)
                .then(response => {
                    return response
                })
                .catch(error => {
                    return error
                })
        },
        meetingNoteRemove({state}, data) {
            return axios.delete(`${state.baseURL}meeting/${data}`)
                .then(response => {
                    return response
                })
                .catch(error => {
                    return error
                })
        },
        sendSignatory({state}, data) {
            return axios.put(`${state.baseURL}signatory/${data.approval_id}`, {
                signatory: data.signatory
            })
                .then(response => {
                    return response
                })
                .catch(error => {
                    return error
                })
        },
        getMeetingNoteById({state}, data) {
            return axios.get(`${state.baseURL}meeting/${data}`)
                .then(response => {
                    return response
                })
                .catch(error => {
                    return error
                })
        },
        getMeetings({state}) {
            return axios.get(`${state.baseURL}meeting`)
                .then(response => {
                    return response
                })
                .catch(error => {
                    return error
                })
        },
        changeMeetingNoteStatus({state}, data) {
            return axios.put(`${state.baseURL}meeting-status/${data}`)
                .then(response => {
                    return response
                })
                .catch(error => {
                    return error
                })
        },
        attachmentsUpload({state}, data) {
            return axios.post(`${state.baseURL}depository`, {
                depository_title: data.attachmentTitle,
                work_space_id: data.work_space_id,
                channel_id: data.channel_id,
                user_id: data.user_id,
                attachments: data.attach_file,
                status: +data.status
            })
                .then(response => {
                    return response
                })
                .catch(error => {
                    return error
                })
        },
        getDepositoryById({state}, data) {
            return axios.get(`${state.baseURL}get-depositories/${data.work_space_id}/${data.channel_id}`)
                .then(response => {
                    return response
                })
                .catch(error => {
                    return error
                })
        },
        // getDepository({state}) {
        //     return axios.get(`${state.baseURL}depository`)
        //         .then(response => {
        //             return response
        //         })
        //         .catch(error => {
        //             return error
        //         })
        // },
        depositoryRemove({state}, data) {
            return axios.delete(`${state.baseURL}depository/${data}`)
                .then(response => {
                    return response
                })
                .catch(error => {
                    return error
                })
        },
        depositoryEdit({state}, data) {
            return axios.put(`${state.baseURL}depository/${data.depository_id}`, {
                depository_title: data.attachmentTitle,
                work_space_id: data.work_space_id,
                channel_id: data.channel_id,
                user_id: data.user_id,
                status: data.status,
                attachments: data.add_attach_file,
                file_delete_id: data.file_delete_id
            })
                .then(response => {
                    return response
                })
                .catch(error => {
                    return error
                })
        },
        lessonCreate({state}, data) {
            return axios.post(`${state.baseURL}lesson`, {
                work_space_id: data.work_space_id,
                project_name: data.projectName,
                category: data.category,
                subject: data.subject,
                observation: data.observation,
                suggestions_for_improvement: data.suggestions,
                keywords: data.keywords
            })
                .then(response => {
                    return response
                })
                .catch(error => {
                    return error
                })
        },
        getAllLessons({state}) {
            return axios.get(`${state.baseURL}lesson`)
                .then(response => {
                    return response
                })
                .catch(error => {
                    return error
                })
        },
        getLessonsById({state}, data) {
            return axios.get(`${state.baseURL}lesson/${data.work_space_id}`)
                .then(response => {
                    return response
                })
                .catch(error => {
                    return error
                })
        },
        deleteLesson({state}, data) {
            return axios.delete(`${state.baseURL}lesson/${data}`)
                .then(response => {
                    return response
                })
                .catch(error => {
                    return error
                })
        },
        updateLesson({state}, data) {
            return axios.put(`${state.baseURL}lesson/${data.id}`, {
                work_space_id: data.work_space_id,
                project_name: data.projectName,
                category: data.category,
                subject: data.subject,
                observation: data.observation,
                suggestions_for_improvement: data.suggestions,
                key_status_notify: data.key_status_notify,
                keywords: data.keywords
            })
                .then(response => {
                    return response
                })
                .catch(error => {
                    return error
                })
        },
        getKeyWords({state, commit}) {
            return axios.get(`${state.baseURL}keywords`)
                .then(response => {
                    let keyword_array = []
                    response.data.success.forEach(item => {
                        keyword_array.push(item.keyword)
                    })
                    commit('GET_KEYWORDS', keyword_array)
                    return response
                })
                .catch(error => {
                    return error
                })
        },
        exportLesson({state}, data) {
            return axios.post(`${state.baseURL}export-lesson`, {
                work_space_id: data.work_space_id,
                lesson_id: data.lesson_id
            })
                .then(response => {
                    return response
                })
                .catch(error => {
                    return error
                })
        },
        getCategoryColors({state}) {
            return axios.get(`${state.baseURL}message-color`)
                .then(response => {
                    return response
                })
                .catch(error => {
                    return error
                })
        },
        createColor({state}, data) {
            return axios.post(`${state.baseURL}message-color/`, {
                message_id: data.message_id,
                color_id: data.color_id
            })
                .then(response => {
                    return response
                })
                .catch(error => {
                    return error
                })

        },
        updateColor({state}, data) {
            return axios.put(`${state.baseURL}message-color/${data.old_color_id}`, {
                color_id: data.color_id
            })
                .then(response => {
                    return response
                })
                .catch(error => {
                    return error
                })
        },
        resetColor({state}, data) {
            return axios.delete(`${state.baseURL}message-color/${data}`)
                .then(response => {
                    return response
                })
                .catch(error => {
                    return error
                })
        },
        changeColorName({state}, data) {
            return axios.put(`${state.baseURL}color-name/${data.id}`, {
                name: data.name
            })
                .then(response => {
                    return response
                })
                .catch(error => {
                    return error
                })
        }
    },
    getters: {
        accessToken: state => state.access_token,
        userData: state => state.userData,
        search_data: state => state.search_data,
        workspaces: state => state.workspaces,
        menuWorkspaces: state => state.menuWorkspaces,
        milestoneWorkspaces: state => state.milestoneWorkspaces,
        // allAdmins: state => state.allAdmins,
        allUsers: state => state.allUsers,
        allTasks: state => state.allTasks,
        allMembers: state => state.allMembers,
        tasksDate: state => state.tasksDate,
        taskSummary: state => state.taskSummary,
        keywords: state => state.keywords,
        notifyStatus: state => state.notifyStatus,
        baseURL: state => state.baseURL
    },
    modules: {}
})
