<template>
    <div class="page_content">
        <div class="page_head">
            <div class="page_title">
                Show/Edit Meeting Note
            </div>
        </div>
        <div class="top_buttons-container">
            <div class="top_buttons-first_group">
                <b-button class="top_btn" >
                    <a :href="`https://1296.freelancedeveloper.site/pdf/${itemId}`">Export</a>
                </b-button>

            </div>
            <div class="top_buttons-last_group">
                <b-button class="top_btn" :disabled="meetingData.status === 1" @click="saveMeetingNoteData()">Saved</b-button>
                <b-button class="top_btn" @click="exitModal()" id="exit_btn">Exit</b-button>
                <b-button class="top_btn" :disabled="meetingData.status === 1" @click="saveExit()">Saved and Exit</b-button>
            </div>
        </div>

        <div class="meeting_note-content">

            <div class="input_content" v-if="meetingData">
                <div class="input_item">
                    <label>Subject Title</label>
                    <b-form-input
                            v-model="meetingData.subject_title"
                            type="text"
                            trim
                    ></b-form-input>
                    <span v-if="meetingNoteErrors['subject_title']" class="text_danger mt-1">{{meetingNoteErrors['subject_title'][0]}}</span>
                </div>
                <div class="input_item">
                    <label>Project Title</label>
                    <b-form-input
                            v-model="meetingData.project_title"
                            type="text"
                            trim
                    ></b-form-input>
                </div>
            </div>

            <div class="input_content" v-if="meetingData">
                <div class="input_item">
                    <label>Workspace</label>
                    <div class="select_arrow">
                        <b-form-select v-model="meetingData.work_space_id" @change="workspaceChange">
                            <option
                                    v-for="(option, id) in workspaces"
                                    :key="id"
                                    :value="option.id"
                                    :title="option.name || null"
                            >
                                {{option.name}}
                            </option>
                        </b-form-select>
                        <div class="arrow_down"></div>
                        <span v-if="meetingNoteErrors['work_space_id']" class="text_danger mt-1">{{meetingNoteErrors['work_space_id'][0]}}</span>
                    </div>
                </div>
                <div class="input_item">
                    <label>Channel</label>
                    <div class="select_arrow">
                        <b-form-select v-model="meetingData.channel_id" @change="channelChange">
                            <option
                                    v-for="(option, id) in channels"
                                    :key="id"
                                    :value="option.id"
                                    :title="option.name || null"
                            >
                                {{option.name}}
                            </option>
                        </b-form-select>
                        <div class="arrow_down"></div>
                        <span v-if="meetingNoteErrors['channel_id']" class="text_danger mt-1">{{meetingNoteErrors['channel_id'][0]}}</span>
                    </div>
                </div>
            </div>

            <div class="input_content" v-if="meetingData">
                <div class="input_item">
                    <label>Project Number</label>
                    <b-form-input
                            v-model="meetingData.project_number"
                            type="number"
                            trim
                    ></b-form-input>
                </div>
                <div class="input_item">
                    <label>Meeting Note Document Number</label>
                    <b-form-input
                            v-model="meetingData.meeting_note_document_number"
                            type="number"
                            trim
                    ></b-form-input>
                </div>
            </div>

            <div class="input_content" v-if="meetingData">
                <div class="input_item">
                    <label>Venue</label>
                    <b-form-input
                            v-model="meetingData.venue"
                            type="text"
                            trim
                    ></b-form-input>
                </div>
                <div class="input_item">
                    <label>Date of Meeting</label>
                    <b-form-input
                            v-model="meetingData.date_of_meeting"
                            type="date"
                            trim
                    ></b-form-input>
                </div>
            </div>

            <div class="input_content" v-if="meetingData">
                <div class="input_item time">
                    <label>Start Time</label>
                    <b-form-timepicker v-model="meetingData.start_time" locale="en"></b-form-timepicker>
                    <span v-if="meetingNoteErrors['start_time']" class="text_danger mt-1">{{meetingNoteErrors['start_time'][0]}}</span>
                </div>
                <div class="input_item time">
                    <label>End Time</label>
                    <b-form-timepicker v-model="meetingData.end_time" locale="en"></b-form-timepicker>
                    <span v-if="meetingNoteErrors['end_time']" class="text_danger mt-1">{{meetingNoteErrors['end_time'][0]}}</span>
                </div>
            </div>

            <div class="table_container" v-if="meetingData">
                <b-table hover :items="meetingData.meeting_attendances" :fields="fields"  responsive="sm">
                    <template v-slot:cell(index)="data">
                        <div class="">
                            {{data.index+1}}
                        </div>
                    </template>

                    <template v-slot:cell(name)="data">
                        <div class="item_name">
                            {{data.item.user_id}}
                        </div>
                    </template>

                    <template v-slot:cell(position)="data">
                        <div class="item_input">
                            <b-form-input
                                    v-model="data.item.position"
                                    type="text"
                                    trim
                            ></b-form-input>
                        </div>
                    </template>
                    <template v-slot:cell(organization)="data">
                        <div class="item_input">
                            <b-form-input
                                    v-model="data.item.organization"
                                    type="text"
                                    trim
                            ></b-form-input>
                        </div>
                    </template>

                    <template #cell(present)="data">
                        <div>
                            <b-form-checkbox value="1" unchecked-value="0" v-model="data.item.present"/>
                        </div>
                    </template>

                </b-table>
            </div>

            <div class="table_container" v-if="meetingData">
                <b-table hover :items="meetingData.minutes_of_meetings" :fields="minutesFields"  responsive="sm">
                    <template v-slot:cell(index)="data">
                        <div class="">
                            {{data.index+1}}
                        </div>
                    </template>

                    <template v-slot:cell(item_details)="data">
                        <div class="item_name">
                            {{data.item.item_details}}
                        </div>
                    </template>

                    <template v-slot:cell(action_by_id)="data">
                        <div class="item_name">
                            {{getActionBy(data.item.action_by_id)}}
                        </div>
                    </template>

                    <template v-slot:cell(due_date)="data">
                        <div class="item_name">
                            {{getDate(data.item.due_date)}}
                        </div>
                    </template>

                    <template v-slot:cell(status)="data">
                        <div class="item_name">
                            {{getStatus(data.item.status)}}
                        </div>
                    </template>

                </b-table>
            </div>

            <div class="table_container checked_items" v-if="meetingData">
                <b-table hover :items="meetingData.approvals" :fields="checkedFields"  responsive="sm">
                    <template v-slot:cell(index)="data">
                        <div class="">
                            {{data.index+1}}
                        </div>
                    </template>

                    <template v-slot:cell(name)="data">
                        <div class="item_name">
                            {{data.item.user_id}}
                        </div>
                    </template>

                    <template v-slot:cell(position)="data">
                        <div class="item_name">
                            {{data.item.position}}
                        </div>
                    </template>
                    <template v-slot:cell(organization)="data">
                        <div class="item_name">
                            {{data.item.organization}}
                        </div>
                    </template>

                    <template #cell(signatory)="data">
                        <div class="item_signatory">
                            <img :src="data.item.signatory" alt="">
                        </div>

                    </template>

                </b-table>
            </div>

            <div class="meeting_save-btn_container" v-if="meetingData">
                <b-button class="meeting_save-btn" :disabled="!buttonDisabled" @click="completion()">Completion of Meeting Note Signatory
                </b-button>
                <div class="success_msg" v-if="success_msg">
                    Completion successful
                </div>
            </div>

        </div>

<!--Exit modal start-->
        <b-modal ref="exit_modal" centered hide-header hide-footer hide-header-close>
            <div class="modal_content">
                <b-icon icon="x" class="x_btn" aria-hidden="true" @click="exitModal()"></b-icon>
                <div class="d-flex flex-column">
                    <div class="modal_title">
                        No actions will be transferred to the Task Notification
                    </div>
                    <div class="modal_text">
                        To transfer the tasks to Task Notification, please "Saved and Exit."
                    </div>
                    <div class="modal_buttons">
                        <div class="cancel_btn" @click="exitModal()">Cancel</div>
                        <b-button class="done_btn ml-3" @click="goBack()">Done</b-button>
                    </div>
                </div>
            </div>
        </b-modal>
<!--Exit modal end-->
    </div>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";

    export default {
        name: "MeetingNoteDetails",
        data() {
            return {
                fields: [
                    {
                        key: 'index',
                        label: 'No',
                    },
                    {
                        key: 'name',
                        label: 'Name',
                    },
                    {
                        key: 'position',
                        label: 'Position',
                    },
                    {
                        key: 'organization',
                        label: 'Organization',
                    },
                    {
                        key: 'present',
                        label: 'Present',
                    },
                ],
                checkedFields: [
                    {
                        key: 'index',
                        label: 'No',
                    },
                    {
                        key: 'name',
                        label: 'Name',
                    },
                    {
                        key: 'position',
                        label: 'Position',
                    },
                    {
                        key: 'organization',
                        label: 'Organization',
                    },
                    {
                        key: 'signatory',
                        label: 'Signatory',
                    },
                ],
                minutesFields: [
                    {
                        key: 'index',
                        label: 'No',
                    },
                    {
                        key: 'item_details',
                        label: 'Item Details',
                    },
                    {
                        key: 'action_by_id',
                        label: 'Action By',
                    },
                    {
                        key: 'due_date',
                        label: 'Due Date',
                    },
                    {
                        key: 'status',
                        label: 'Status',
                    },
                ],
                meetingNoteErrors: [],
                channels: [],
                channelUsers: [],
                itemId: null,
                meetingData: null,
                workspaceUsers: [],
                success_msg: false,
                buttonDisabled: false
            }
        },
        created() {
            this.itemId = this.$route.params.id
            this.getAllWorkspaces()

        },
        computed: {
            ...mapGetters(['workspaces']),
        },
        mounted() {
            this.getMeetingNoteById(this.itemId)
                .then(res => {
                    if(res.status === 200) {
                        this.meetingData =res.data.success[0]
                        this.workspaceChange(this.meetingData.work_space_id)
                        this.meetingData.date_of_meeting= new Date(res.data.success[0].date_of_meeting * 1000 ).toLocaleString("en-CA", {timeZoneName: "short"}).split(',')[0]
                        this.meetingData.start_time = new Date(res.data.success[0].start_time * 1000).toISOString().substr(11, 8)
                        this.meetingData.end_time = new Date(res.data.success[0].end_time * 1000).toISOString().substr(11, 8)
                        let count = 0
                        let button = false
                        this.meetingData.approvals.forEach(item => {
                            if(item.signatory) {
                                button = true
                            } else {
                                count++
                                button = false
                            }
                        })
                        this.buttonDisabled = count === 0 && button;
                        if(this.meetingData.status === 1 ) {
                            this.buttonDisabled = false
                        }
                    }
                })
        },
        methods: {
            ...mapActions([
                'getMeetingNoteById',
                'getAllWorkspaces',
                'getChannelMembers',
                'getWorkspaceUsers',
                'changeMeetingNoteStatus',
                'downloadPdf'
            ]),
            goBack() {
                this.$router.go(-1)
            },
            getDate(item) {
                return new Date(item * 1000 ).toLocaleString("en-US", {timeZoneName: "short"}).split(',')[0]
            },
            getStatus(item) {
              switch (item) {
                      case 0: return  'Open';
                      case 1: return 'Closed';
                      case 2: return'Info';
              }
            },
            getActionBy(id) {
                var actionBy = this.workspaceUsers.find(item => {
                    return item.id == id
                })
                 return actionBy.name
            },
            workspaceChange(args) {
                var workSpace = this.workspaces.find((item) => {
                    return item.id == args
                })
                this.channels = workSpace.channels
                // this.meetingNoteData.work_space = args
                this.getWorkspaceUsers(args)
                .then(res => {
                    if(res.status === 200) {
                        this.workspaceUsers = res.data.success
                    }
                })
            },
            channelChange(args) {
                // this.meetingNoteData.channel = args
                this.getChannelMembers(args)
                    .then((res) => {
                        if(res.status === 200) {
                            res.data.success.forEach((item) => {
                                item.position = ''
                                item.organization = ''
                                item.present = false
                                this.channelUsers.push(item)
                            })
                            // this.channelUsers.forEach(item => {
                            //     let actionItem = {
                            //         id: item.id,
                            //         label: item.name
                            //     }
                            //     this.meeting_fields[1].options.push(actionItem)
                            // })
                            // this.meeting_fields[3].options = this.action_by_status

                        }
                    })
                // this.getWorkspaceUsers(this.meetingNoteData.work_space)
                //     .then((res) => {
                //         if (res.status === 200) {
                //             res.data.success.forEach((item) => {
                //                 this.meetingNoteData.copied_id.push(item.id)
                //             })
                //             let mapped = this.channelUsers.map(ele => ele.id);
                //             res.data.success.map((item) => {
                //                 if(mapped.includes(item.id)) {
                //                     return
                //                 } else {
                //                     item.position = ''
                //                     item.organization = ''
                //                     item.present = false
                //                     this.copiedItems.push(item)
                //                     this.actionByUsers.push(item) //action
                //                 }
                //             })
                //
                //         }
                //     })
            },
            saveMeetingNoteData() {
                console.log('save meeting note')
            },
            exitModal() {
                this.$refs['exit_modal'].toggle('#exit_btn')
            },
            saveExit() {
                console.log('save exit')
            },
            completion() {
                this.changeMeetingNoteStatus(this.meetingData.id)
                .then(res => {
                    if(res.status === 200) {
                        this.success_msg = true
                        setTimeout(() => {
                            this.success_msg = false
                        }, 3000)
                        this.getMeetingNoteById(this.itemId)
                            .then(res => {
                                if(res.status === 200) {
                                    this.meetingData =res.data.success[0]
                                    this.workspaceChange(this.meetingData.work_space_id)
                                    this.meetingData.date_of_meeting= new Date(res.data.success[0].date_of_meeting * 1000 ).toLocaleString("en-CA", {timeZoneName: "short"}).split(',')[0]
                                    this.meetingData.start_time = new Date(res.data.success[0].start_time * 1000).toISOString().substr(11, 8)
                                    this.meetingData.end_time = new Date(res.data.success[0].end_time * 1000).toISOString().substr(11, 8)
                                    let count = 0
                                    let button = false
                                    this.meetingData.approvals.forEach(item => {
                                        if(item.signatory) {
                                            button = true
                                        } else {
                                            count++
                                            button = false
                                        }
                                    })
                                    this.buttonDisabled = count === 0 && button;
                                    if(this.meetingData.status === 1 ) {
                                        this.buttonDisabled = false
                                    }
                                }
                            })
                    }
                })

            },


        }
    }
</script>

<style scoped lang="scss">
    input[type="date"]::-webkit-calendar-picker-indicator {
        background: url("~@/assets/images/calendar.svg") center/80% no-repeat;
        cursor: pointer;
    }
    input[type="date"]::-webkit-calendar-picker-indicator:hover {
        background: url("~@/assets/images/calendar.svg") center/80% no-repeat;
    }
.page {
    &_head {
        padding-bottom: 20px;
        display: flex;
        flex-direction: column;
        & .back_btn {
            width: 40px;
            cursor: pointer;
        }
    }
    &_title {
        padding-top: 10px;
        color: $black;
        font-weight: 600;
        font-size: 25px;
    }
    &_content {
        padding: 10px 40px 20px 1px;
        & .top_buttons {
            &-container {
                display: flex;
                justify-content: space-between;
                & .top_btn {
                    color: $primary;
                    height: 42px;
                    font-weight: 600;
                    background: transparent linear-gradient(180deg, #84EEEB 0%, #3AE4DF 100%) 0% 0% no-repeat padding-box;
                    border-radius: 10px;
                    padding: 0 20px;
                    border: none;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    &:focus,
                    &:active{
                        box-shadow: none;
                        color: $primary;
                    }
                }
            }
            &-first_group {
                width: 270px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                & .select_arrow {
                    position: relative;
                    & .arrow_down {
                        position: absolute;
                        top: 15px;
                        right: 15px;
                        width: 14px;
                        height: 10px;
                        pointer-events: none;
                        background: url("~@/assets/images/chevron_down.svg") right  no-repeat;
                    }
                }
                & select {
                    width: 160px;
                    background: $greyBlue 0 0 no-repeat padding-box;
                    border-radius: 4px;
                    padding-right: 5px;
                }
            }
            &-last_group {
                width: 360px;
                display: flex;
                align-items: center;
                justify-content: space-between;

            }
        }
        & .meeting_note {
            &-content {
                padding-top: 20px;
                & .input_content {
                    width: 580px;
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 20px;
                    & .input_item {
                        padding: 5px 0;
                        width: 270px;
                        flex-direction: column;
                        &.long {
                            width: 100%;
                        }
                        & .field_item {
                            font-size: 20px;
                            font-weight: 600;
                            color: $light_purple;
                        }
                        &.send_alert {
                            width: 100%;
                        }
                        &.insert_milestone {
                            width: 100%;
                            & .radio_item {
                                padding-top: 10px;
                            }
                        }
                        & .edit_workspace {
                            padding: 5px 0;
                            font-size: 22px;
                            font-weight: 600;
                            color: $primary;
                        }
                        & .select_arrow {
                            position: relative;
                            & .arrow_down {
                                position: absolute;
                                top: 15px;
                                right: 15px;
                                width: 14px;
                                height: 10px;
                                pointer-events: none;
                                background: url("~@/assets/images/chevron_down.svg") right  no-repeat;
                            }
                        }
                        & label {
                            color: $primary;
                            font-weight: 600;
                            font-size: 14px;
                        }
                        & input {
                            border: none;
                            background: $greyBlue 0 0 no-repeat padding-box;
                            border-radius: 3px;
                            &:focus {
                                box-shadow: none;
                                outline: none;
                            }
                        }
                        input[type=number] {
                            -moz-appearance: textfield !important;
                        }
                        input::-webkit-outer-spin-button,
                        input::-webkit-inner-spin-button {
                            -webkit-appearance: none !important;
                            margin: 0 !important;
                        }
                        & select {
                            background: $greyBlue 0 0 no-repeat padding-box;
                            border-radius: 4px;
                            padding-right: 5px;
                        }
                        ::v-deep .multiselect {
                            width: 100%;
                            height: 40px;
                            background: $greyBlue 0 0 no-repeat padding-box;
                            border-radius: 4px;
                            cursor: pointer;
                        }
                        ::v-deep .multiselect__tags {
                            background: transparent;
                            padding-left: 0;
                            border: none;
                        }
                        ::v-deep .multiselect__tag {
                            background: transparent;
                            color: $black;
                        }
                        ::v-deep .multiselect__tag-icon:after {
                            color: red;
                        }
                        ::v-deep .multiselect__tag-icon:hover {
                            background: transparent;
                        }
                        ::v-deep .multiselect__option--highlight,
                        ::v-deep .multiselect__option--highlight:after {
                            background: $lightBlue;
                            color: $black;
                        }
                        ::v-deep .multiselect__select:before {
                            border-width: 0;
                            border-style: none;
                        }
                        ::v-deep .multiselect__input {
                            background: $greyBlue;
                            margin: 0;
                            padding: 0;
                        }
                        &.time {
                            & .b-form-timepicker {
                                background-color: #E7E8F5;
                                border: none;
                                ::v-deep svg {
                                    fill: $light_purple !important;
                                }
                            }
                        }
                    }

                }
                & .meeting_save-btn {
                    color: $primary;
                    height: 42px;
                    font-weight: 600;
                    background: transparent linear-gradient(180deg, #84EEEB 0%, #3AE4DF 100%) 0% 0% no-repeat padding-box;
                    border-radius: 10px;
                    padding: 0 20px;
                    border: none;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    &:focus,
                    &:active{
                        box-shadow: none;
                        color: $primary;
                    }
                    &_container {
                        padding: 30px 0;
                        display: flex;
                        align-items: center;
                        & .success_msg {
                            color: green;
                            font-weight: 600;
                            font-size: 20px;
                            padding: 0 30px;
                        }
                    }
                }
                // table
                & .table_container {
                    font-size: 13px;
                    & .add_button {
                        color: $primary;
                        height: 42px;
                        font-weight: 600;
                        background: transparent linear-gradient(180deg, #84EEEB 0%, #3AE4DF 100%) 0% 0% no-repeat padding-box;
                        border-radius: 10px;
                        padding: 0 20px;
                        margin: 30px 0 15px;
                        border: none;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        &:focus,
                        &:active{
                            box-shadow: none;
                            color: $primary;
                        }
                    }
                    ::v-deep thead {
                        height: 60px;
                        color: $primary;
                        background: #E7E8F5 0 0 no-repeat padding-box;
                        th {
                            vertical-align: middle;
                            max-width: 100px;
                            text-align: center;
                        }
                    }
                    ::v-deep tbody {
                        tr {
                            td{
                                vertical-align: middle;
                                text-align: center;
                                text-align: -webkit-center;
                            }
                        }
                    }
                    & .item {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        height: 100%;
                        &_name {

                        }
                        &_signatory {
                            width: 200px;
                            height: 80px;
                            img {
                                width: 100%;
                                height: 100%;
                            }
                        }
                    }
                    & .item_input {
                        width: 270px;
                        height: 38px;
                        padding: 3px 0;
                        input {
                            height: 32px;
                        }
                    }
                    & .item_signature {
                        width: 180px;
                        display: flex;
                        align-items: center;
                        & .signature_btn {
                            width: 60px;
                            height: 20px;
                            color: $primary;
                            font-size: 12px;
                            font-weight: 600;
                            background: transparent linear-gradient(180deg, #84EEEB 0%, #3AE4DF 100%) 0% 0% no-repeat padding-box;
                            border-radius: 10px;
                            padding: 0 5px;
                            border: none;
                            margin: 10px 0;
                            &-content {
                                display: flex;
                                flex-direction: column;
                                justify-content: space-between;
                                padding: 5px 10px;
                            }
                        }
                        ::v-deep canvas {
                            border: 1px solid $lightBlue !important;
                        }
                    }
                    &.checked_items {
                        margin-top: 40px;
                    }
                    input {
                        background: $lightBlue;
                        border: none;
                        border-radius: 3px;
                    }
                    & .select_arrow {
                        position: relative;
                        width: 240px;
                        margin-right: 20px;
                        & .arrow_down {
                            position: absolute;
                            top: 14px;
                            right: 15px;
                            width: 14px;
                            height: 10px;
                            pointer-events: none;
                            background: url("~@/assets/images/chevron_down.svg") right  no-repeat;
                        }
                    }
                    select {
                        background: $lightBlue;
                        position: relative;
                    }
                    ::v-deep .b-form-datepicker  {
                        background: $lightBlue;
                        & .btn svg {
                            fill: $light_purple;
                        }
                    }
                    & .edit_done-btn {
                        color: $primary;
                        height: 40px;
                        font-weight: 600;
                        background: transparent linear-gradient(180deg, #84EEEB 0%, #3AE4DF 100%) 0% 0% no-repeat padding-box;
                        border-radius: 10px;
                        padding: 0 20px;
                        border: none;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        &:focus,
                        &:active{
                            box-shadow: none;
                            color: $primary;
                        }
                    }
                }
            }
        }
    }
}
::v-deep .custom-select {
    border: none;
}

/*modal start*/
::v-deep .modal-content {
    border: none !important;
    width: 700px;
    ::v-deep .modal-body {
        width: 600px !important;
    }
}
::v-deep .modal_content {
    border: none !important;
    width: 600px;
    position: relative;
    ::v-deep .modal-body {
        width: 600px !important;
    }
    & .x_btn {
        position: absolute;
        top: 0;
        right: 0;
        width: 25px;
        height: 25px;
        cursor: pointer;
        color: $light_purple;
    }
    & .modal_title {
        width: 100%;
        padding: 20px 0;
        font-size: 25px;
        color: $grey;
        border-bottom: 1px solid #CACDF6;
        &.attachment {
            padding-left: 10px;
            color: $purple;
        }
    }
    & .modal_text {
        font-size: 16px;
        color: $grey;
        padding: 25px 0;
    }
    & .modal_buttons {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 15px 0 30px;
        & .cancel_btn {
            color: $primary;
            font-weight: 600;
            padding: 10px 0;
            cursor: pointer;

        }
        & .done_btn {
            width: 110px;
            height: 44px;
            background: transparent linear-gradient(180deg, #84EEEB 0%, #3AE4DF 100%) 0% 0% no-repeat padding-box;
            border-radius: 10px;
            color: $primary;
            font-weight: 600;
            border: none;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    & .description_content {
        padding: 10px 0 5px;
        & .description_subtitle {
            font-weight: 500;
            font-size: 18px;
            padding: 0 15px;
            color: $primary;

        }
        & .description_text {
            padding: 10px 20px 5px;
            border-bottom: 1px solid #e9e9e9;
        }

    }
}
/* modal end*/
</style>