<template>
    <div class="not-found-page">
        <p @click="backToHome()">Back to Home</p>

        <h1 class="text-capitalize">404</h1>
        <h1 class="text-capitalize">Page Not Found</h1>
    </div>
</template>

<script>
    export default {
        name: "PageNotFound",
        data() {
            return {

            }
        },
        methods: {
            backToHome() {
                this.$router.go(-1)
            }
        }
    }
</script>

<style scoped lang="scss">
.not-found-page {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 50px;
    height: 100vh;
    background: transparent linear-gradient(180deg, #7882F3 0%, #5454EE 100%) 0% 0% no-repeat padding-box;
    font-weight: bold;
    text-shadow: 3px 3px 3px #66a8ff;
    color: white;
    & p {
        color: #fff;
        cursor: pointer;
    }
}
</style>