<template>
    <div class="">
        <router-link to="/notifications">
            <div class="bell ml-3" @click="openNotify()">
                <div :class="{'dot': status }"></div>
                <div :class="{'dot': notifyStatus }"></div>
                <svg id="Group_6953" data-name="Group 6953" xmlns="http://www.w3.org/2000/svg" width="25.635" height="28.44" viewBox="0 0 25.635 28.44">
                    <g id="bell_5_" data-name="bell (5)" transform="translate(0 0)">
                        <g id="Group_6948" data-name="Group 6948" transform="translate(9.034)">
                            <g id="Group_6947" data-name="Group 6947">
                                <path id="Path_466" data-name="Path 466" d="M191.673,0a3.788,3.788,0,0,0-3.783,3.783V4.925h1.957V3.783a1.827,1.827,0,0,1,3.653,0V4.925h1.957V3.783A3.788,3.788,0,0,0,191.673,0Z" transform="translate(-187.89)" fill="#5454ee"/>
                            </g>
                        </g>
                        <g id="Group_6950" data-name="Group 6950" transform="translate(8.186 23.841)">
                            <g id="Group_6949" data-name="Group 6949">
                                <path id="Path_467" data-name="Path 467" d="M179.93,429.205a2.645,2.645,0,0,1-2.642,2.642h-.065a2.645,2.645,0,0,1-2.642-2.642h-1.957a4.6,4.6,0,0,0,4.6,4.6h.065a4.6,4.6,0,0,0,4.6-4.6Z" transform="translate(-172.624 -429.205)" fill="#5454ee"/>
                            </g>
                        </g>
                        <g id="Group_6952" data-name="Group 6952" transform="translate(0 3.62)">
                            <g id="Group_6951" data-name="Group 6951">
                                <path id="Path_468" data-name="Path 468" d="M50.729,85.029,47.95,80.7V75.154a9.883,9.883,0,1,0-19.765,0V80.7l-2.78,4.324a.979.979,0,0,0,.823,1.508H49.907a.978.978,0,0,0,.823-1.507ZM28.02,84.58l1.966-3.059a.978.978,0,0,0,.156-.529V75.154a7.926,7.926,0,1,1,15.851,0v5.838a.979.979,0,0,0,.155.529l1.966,3.059Z" transform="translate(-25.25 -65.174)" fill="#5454ee"/>
                            </g>
                        </g>
                    </g>
                </svg>
            </div>
        </router-link>

<!--        <div class="row justify-content-center">-->
<!--            <div class="jumbotron">-->
<!--                <h1 class="display-4">Awesome Contact Form</h1>-->
<!--                <p class="lead">This is a simple example of push notification implementation of a "Success" message using Laravel + Vue.js + Pusher</p>-->
<!--            </div>-->
<!--            <div class="col-sm-6 col-sm-offset-3">-->
<!--                <div class="form-group">-->
<!--                    <label for="name">Name</label>-->
<!--                    <input v-model="newMsgName" id="name" type="text" class="form-control">-->
<!--                </div>-->
<!--                <div class="form-group">-->
<!--                    <label for="email">Email</label>-->
<!--                    <input v-model="newMsgEmail" id="email" type="email" class="form-control">-->
<!--                </div>-->
<!--                <div class="form-group">-->
<!--                    <label for="description">Description</label>-->
<!--                    <textarea v-model="newMsgDesc" id="description" rows="8" class="form-control"></textarea>-->
<!--                </div>-->
<!--                <button @click="addMessage(newMsgName, newMsgEmail, newMsgDesc)"-->
<!--                        :class="{disabled: (!newMsgName || !newMsgEmail || !newMsgDesc)}"-->
<!--                        class="btn btn-block btn-success">Submit</button>-->
<!--            </div>-->
<!--        </div>-->
    </div>
</template>

<script>
    import axios from "axios"
    import {mapActions, mapGetters} from "vuex";
    export default {
        name: "Notify",
        data() {
            return {
                newMsgName: "",
                newMsgEmail: "",
                newMsgDesc: "",
                posts: [],
                status: false,
                allNotifications: []
            }
        },
        created() {
            this.listenForChanges();
            this.notifyGet()
            .then((res) => {
                this.allNotifications = res.data.success
                this.allNotifications.forEach((item) => {
                    if(item.status === 1) {
                        this.status = true
                    }
                })
            })
        },
        watch: {
            posts: {
                handler(val) {
                    if(val) {
                        // console.log(val, 'value')
                        this.status = true
                    }
                },
                deep: true
            }
        },
        computed: {
          ...mapGetters(['notifyStatus'])
        },
        methods: {
            ...mapActions(['notifyGet']),
            openNotify() {

            },
            addMessage(msgName, msgEmail, msgDesc) {
                // check if entries are not empty
                if(!msgName || !msgEmail || !msgDesc)
                    return;
                // make API to save message
                axios.post('https://1296.freelancedeveloper.site/api/message', {
                    name: msgName, email: msgEmail, description: msgDesc
                }).then( response => {
                    if(response.data) {
                        this.newMsgName = this.newMsgEmail = this.newMsgDesc = "";
                    }
                })
            },
            listenForChanges() {
                Echo.channel('notification')
                    .listen('NotificationPublished', post => {
                        if (! ('Notification' in window)) {
                            alert('Web Notification is not supported');
                            return;
                        }
                        Notification.requestPermission( permission => {
                            let notification = new Notification('Awesome Website', {
                                body: post.message,
                                icon: "https://pusher.com/static_logos/320x320.png" // optional image url
                            });
                            this.posts = post
                            // console.log(post, 'message')
                            // link to page on clicking the notification
                            notification.onclick = () => {"4,5,8,8"
                                window.open(window.location.href);
                            };
                        });
                    })
            }
        }
    }
</script>

<style scoped lang="scss">
.bell {
    cursor: pointer;
    position: relative;
    & .dot {
        position: absolute;
        width: 9px;
        height: 9px;
        top: 5px;
        left: 16px;
        border-radius: 50%;
        background-color: red;
    }
}
</style>